/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttackSurfaceResults } from '../models/attack-surface-results';
import { AttackSurfaceResultsCreate } from '../models/attack-surface-results-create';
import { AttackSurfaceResultsGetResp } from '../models/attack-surface-results-get-resp';

@Injectable({
  providedIn: 'root',
})
export class AttackSurfaceResultsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiAttacksurfaceresultsGet
   */
  static readonly GetAllApiAttacksurfaceresultsGetPath = '/api/attacksurfaceresults/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiAttacksurfaceresultsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiAttacksurfaceresultsGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    q?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
    scroll_id?: string;
  }

): Observable<StrictHttpResponse<AttackSurfaceResultsGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceResultsService.GetAllApiAttacksurfaceresultsGetPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
      rb.query('scroll_id', params.scroll_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceResultsGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllApiAttacksurfaceresultsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiAttacksurfaceresultsGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    q?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
    scroll_id?: string;
  }

): Observable<AttackSurfaceResultsGetResp> {

    return this.getAllApiAttacksurfaceresultsGet$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceResultsGetResp>) => r.body as AttackSurfaceResultsGetResp)
    );
  }

  /**
   * Path part for operation updateApiAttacksurfaceresultsPut
   */
  static readonly UpdateApiAttacksurfaceresultsPutPath = '/api/attacksurfaceresults/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiAttacksurfaceresultsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiAttacksurfaceresultsPut$Response(params: {
    body: AttackSurfaceResults
  }

): Observable<StrictHttpResponse<AttackSurfaceResults>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceResultsService.UpdateApiAttacksurfaceresultsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceResults>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateApiAttacksurfaceresultsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiAttacksurfaceresultsPut(params: {
    body: AttackSurfaceResults
  }

): Observable<AttackSurfaceResults> {

    return this.updateApiAttacksurfaceresultsPut$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceResults>) => r.body as AttackSurfaceResults)
    );
  }

  /**
   * Path part for operation createApiAttacksurfaceresultsPost
   */
  static readonly CreateApiAttacksurfaceresultsPostPath = '/api/attacksurfaceresults/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiAttacksurfaceresultsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiAttacksurfaceresultsPost$Response(params: {
    body: AttackSurfaceResultsCreate
  }

): Observable<StrictHttpResponse<AttackSurfaceResults>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceResultsService.CreateApiAttacksurfaceresultsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceResults>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApiAttacksurfaceresultsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiAttacksurfaceresultsPost(params: {
    body: AttackSurfaceResultsCreate
  }

): Observable<AttackSurfaceResults> {

    return this.createApiAttacksurfaceresultsPost$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceResults>) => r.body as AttackSurfaceResults)
    );
  }

  /**
   * Path part for operation getApiAttacksurfaceresultsIdGet
   */
  static readonly GetApiAttacksurfaceresultsIdGetPath = '/api/attacksurfaceresults/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiAttacksurfaceresultsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiAttacksurfaceresultsIdGet$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<AttackSurfaceResults>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceResultsService.GetApiAttacksurfaceresultsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceResults>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiAttacksurfaceresultsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiAttacksurfaceresultsIdGet(params: {
    id: string;
  }

): Observable<AttackSurfaceResults> {

    return this.getApiAttacksurfaceresultsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceResults>) => r.body as AttackSurfaceResults)
    );
  }

  /**
   * Path part for operation deleteApiAttacksurfaceresultsIdDelete
   */
  static readonly DeleteApiAttacksurfaceresultsIdDeletePath = '/api/attacksurfaceresults/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiAttacksurfaceresultsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiAttacksurfaceresultsIdDelete$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceResultsService.DeleteApiAttacksurfaceresultsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApiAttacksurfaceresultsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiAttacksurfaceresultsIdDelete(params: {
    id: string;
  }

): Observable<any> {

    return this.deleteApiAttacksurfaceresultsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
