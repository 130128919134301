import { AuthenticationService } from 'src/app/_services/authentication.service';
import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError, of} from 'rxjs';
import {filter, map, tap, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let customerid: any;
    const domain = localStorage.getItem('cid');
    if (domain && domain !== null) {
      customerid = (localStorage.getItem('cid') !== null && localStorage.getItem('cid'))
       ? atob(domain) : '';
      const clonedRequest = req.clone({ headers: req.headers.set('customerid', customerid) });
      return next.handle(clonedRequest);
    }
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(req);
  }
}

