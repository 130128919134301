<mat-tab-group>
    <mat-tab label="Probe Agents">
        <ng-template matTabContent>
            <div class="p-6">
                <app-agents [selectedAgent]= "probeAgent" [mode]="'global'"></app-agents>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="LightWeight Agents">
        <ng-template matTabContent>
            <div class="p-6">
              <app-agents [selectedAgent]= "lightWeightAgent" [mode]="'global'"></app-agents>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Deprecated Agents">
        <ng-template matTabContent>
            <div class="p-6">
              <app-agents [isDeprecated]="true" [mode]="'global'"></app-agents>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>