<div class="remedia-container">
    <mat-sidenav-container class="remedia-sidenav-container h-100vh">
      <mat-sidenav-content>
        <app-s-table aria-label="Agents" role="table" [sTableOptions]="offlineVulTableOptions" (refreshCallback)="getOfflineVulJobs()"
            (sortCallback)="offlineVulJobssortCall($event)" (hyperlinkCallback)="offlineVulJobslinkCall($event)"
                    (filterCallback)="offlineVulJobsfilterCall($event)" (colFilterCallback)="colFilterCall($event)"
            (actionCallback)="offlineVulJobsactionCall($event)" (pageCallback)="offlineVulJobspageCall($event)"
            (totalCallback)="totalCallbackCheck($event)" (globalActionCallback)="offlineVulJobsglobalActionCall($event)">
        </app-s-table>
      </mat-sidenav-content>
      <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p z-index-150">
        <mat-card class="w-100">
          <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                  (click)="currentJob = undefined; snav.close();"><mat-icon>close</mat-icon>
          </button>
          <mat-card-header>
            <mat-card-title class="fs1 fw2 mr-4">Details View</mat-card-title>
          </mat-card-header>
          <mat-card-content class="overflow-auto" *ngIf="currentJob && currentJob.job_data
           && (currentJob.job_data.job_message || currentJob.job_data.report_job_message)">
            <app-job-details [currentJob]="currentJob"></app-job-details>
          </mat-card-content>
        </mat-card>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>