import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseRequestService } from 'src/app/_services/base.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-attack-surface-result',
  templateUrl: './attack-surface-result.component.html',
  styleUrls: ['./attack-surface-result.component.scss']
})
export class AttackSurfaceResultComponent implements OnInit,AfterViewInit {
  @Input() currentCompany: any;
  @Input() jobDomain: any;
  @ViewChild('navi', { static: false }) tabContainer: ElementRef;
  @ViewChild('targetip', { static: false }) targetip: ElementRef;
  @ViewChild('emails', { static: false }) emails: ElementRef;
  @ViewChild('usernames', { static: false }) usernames: ElementRef;
  @ViewChild('subdomains', { static: false }) subdomains: ElementRef;
  @ViewChild('scrollableDiv', { static: false }) scrollableDiv: ElementRef;
  domain = '';
  forceScan = false;
  currentDomain: any;
  data: any = {};
  visibleS3Buckets: number = 30; // Number of S3 buckets to initially show
  showMoreButtonEnabled: boolean = true; // Initialize to true
  showLessButtonEnabled: boolean = false; // Initialize to false
  showUpper = true;
  summary: any = [];
  errMsg: any = '';
  intervalId: any;
  adRoleMembersCols: string[] = [
    'addresses',
    'hostname',
    'preference',
  ];
  objectKeys = Object.keys;
  mxHostsCustomCols: any = [
    {
      col: 'tls', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    }, {
      col: 'starttls', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },];
  constructor(private baseService: BaseRequestService, private loaderService: LoaderService, private toast: MyToastrService, private renderer: Renderer2) { }


   ngOnInit(): void {
    console.log('Reset asm info');
    console.log('this.jobDomain',this.jobDomain)
    this.getDomainDetails()
    this.showMoreS3Buckets()
   }

   ngAfterViewInit() {
    this.checkContentCount();
  }

  showMoreS3Buckets() {
    const totalItems = this.data.s3buckets[0].length;
  
    if (this.visibleS3Buckets < totalItems) {
      // Calculate the new value for visibleS3Buckets without exceeding totalItems.
      this.visibleS3Buckets += 30;
  
      // Ensure visibleS3Buckets doesn't exceed totalItems.
      if (this.visibleS3Buckets > totalItems) {
        this.visibleS3Buckets = totalItems;
        this.showMoreButtonEnabled = false; 
      }
  
      if (this.visibleS3Buckets === totalItems) {
        this.showLessButtonEnabled = true; // Enable the "Show Less" option
      }
  
      console.log('this.visibleS3Buckets', this.visibleS3Buckets);
    }
  }
  
  showLessS3Buckets() {
    // Set the visibleS3Buckets back to 30 to display the first 30 items.
    this.visibleS3Buckets = 30;
    this.showLessButtonEnabled = false; // Disable the "Show Less" option
    this.showMoreButtonEnabled = true; 
    console.log('this.visibleS3Buckets', this.visibleS3Buckets);
  }

  checkContentCount() {
    const contentCount = this.data.subdomains[0].length; // Assuming data is available
    console.log('contentCount',contentCount)
    if (contentCount >= 50) {
      this.renderer.addClass(this.scrollableDiv.nativeElement, 'scrollable-div');
    } else {
      this.renderer.removeClass(this.scrollableDiv.nativeElement, 'scrollable-div');
    }
  }


  processData(): void {
    if (this.data && this.data.target_ips && this.data.mx.error === null) {
      if(!this.data.hashes || !this.data.hashes.length){
        this.data.hashes = [];
      }
      this.data.s3buckets[0] = this.data.s3buckets[0].filter((str: any) => str !== '');
      this.data.guessed_emails[0] = this.data.guessed_emails[0].filter((str: any) => str !== '');
      this.data.emails[0] = (this.data.emails && this.data.emails.length)
        ? this.data.emails[0].filter((str: any) => str !== '') : [];
      this.data.hashes[0] = (this.data.hashes && this.data.hashes.length)
        ? this.data.hashes[0].filter((str: any) => str !== '') : [];
      this.data.usernames[0] = (this.data.usernames && this.data.usernames.length)
        ? this.data.usernames[0].filter((str: any) => str !== '') : [];
      this.data.employees[0] = (this.data.employees && this.data.employees.length)
        ? this.data.employees[0].filter((str: any) => str !== '') : [];
      this.data.subdomains[0] = (this.data.subdomains && this.data.subdomains.length)
        ? this.data.subdomains[0].filter((str: any) => str !== '') : [];
      this.data.creds[0] = (this.data.creds && this.data.creds.length)
        ? this.data.creds[0].filter((str: any) => str !== '') : [];


      let ports: any = [];
      let vuls: any = [];
      this.data.target_ips.forEach((obj: any) => {
        obj['Port_Protocol'].split(',').forEach((port: any) => {
          if (port && port !== '') { ports.push(port); }
        });
        obj.Vulnerabities.split(',').forEach((vul: any) => {
          if (vul && vul !== '') { vuls.push(vul); }
        });
      });
      ports = [...new Set(ports)];
      vuls = [...new Set(vuls)];
      console.log('summary',this.summary)
      this.summary = [
        { title: 'Vulnerabilities', img: '/assets/images/network/bug.svg', count: vuls.length },
        { title: 'Open Ports', img: '/assets/images/network/padlock.svg', count: ports.length },
        { title: 'Target IPs', img: '/assets/images/target-ip.png', count: this.data.target_ips.length },
        {
          title: 'Emails',
          img: '/assets/images/emails.png',
          count: (this.data.emails[0].length && this.data.emails[0][0]) ? this.data.emails[0].length : 0
        },
        {
          title: 'Usernames',
          img: '/assets/images/usernames.png',
          count: (this.data.usernames[0].length && this.data.usernames[0][0]) ? this.data.usernames[0].length : 0
        },
        {
          title: 'Subdomains',
          img: '/assets/images/subdomains.png',
          count: (this.data.subdomains[0].length && this.data.subdomains[0][0]) ? this.data.subdomains[0].length : 0
        }
      ];
    } else if (this.data && this.data.status === 'Completed' && !this.data.target_ips) {
      // this.errMsg = 'No data found!';
      this.toast.sToast('error', 'No data found!');
    }else if (this.data && this.data.status === 'Completed' && this.data.mx.error.length > 0) {
      // this.errMsg = this.data.mx.error;
      // this.errMsg = 'No data found!';
      this.toast.sToast('error', this.data.mx.error);
    } else {
      this.errMsg = '';
    }
  }

  getDomainDetails():void{
      this.loaderService.display(true, `Getting asset's information...`);
      this.baseService.doRequest(`/api/attacksurfaceresults/${this.jobDomain}`, 'get').subscribe((result: any) => {
        this.loaderService.display(false);
        this.data = result;
        // this.data = undefined;
        console.log('result',result)
      setTimeout(() => {
        this.data = result;
      });
        if (result) {
          console.log('result',result)
        }
        this.processData();
      });
    }

  navigateTarget($event: any): void {
    try {
      if ($event.key === 'Target IPs' || $event.key === 'Vulnerabilities' || $event.key === 'Open Ports') {
        this.targetip.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.key === 'Emails') {
        this.emails.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.key === 'Usernames') {
        this.usernames.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if ($event.key === 'Subdomains') {
        this.subdomains.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    } catch (err) { }
  }

  backToScan(): void {
    this.currentDomain = undefined;
    this.domain = '';
    this.forceScan = false;
    this.data = undefined;
    this.errMsg = '';
    localStorage.removeItem('asm');
  }

  redirectUrl(url: any): void {
    url = (url.startsWith('http://') || url.startsWith('https://')) ? url : `https://${url}`;
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    if (this.intervalId !== undefined && this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }
}