<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <span id="ScanScheduler">Scan Scheduler</span>
        </ng-template>
        <ng-template matTabContent>
            <app-schedule *ngIf="!currentCompany"  [schedulerType]="'scan'"></app-schedule>
            <app-schedule *ngIf="currentCompany && currentCompany._id" [currentCompany]="currentCompany" [mode]="mode"
                        [schedulerType]="'scan'"></app-schedule>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span id="ReportScheduler">Report Scheduler</span>
        </ng-template>
        <ng-template matTabContent>
          <app-global-report-scheduler  *ngIf="!currentCompany"></app-global-report-scheduler>
          <app-report-scheduler *ngIf="currentCompany" [companyid]="currentCompany && currentCompany._id"></app-report-scheduler>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span id="PatchingScheduler">Auto Patch</span>
        </ng-template>
        <ng-template matTabContent>
            <app-patching-scheduler *ngIf="!currentCompany"></app-patching-scheduler>
            <app-patching-scheduler *ngIf="currentCompany && currentCompany._id" [currentCompany]="currentCompany"></app-patching-scheduler>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <span id="ReportBuilderScheduler">Report Builder Scheduler</span>
        </ng-template>
        <ng-template matTabContent>
            <app-global-report-builder-scheduler *ngIf="!currentCompany"></app-global-report-builder-scheduler>
            <app-global-report-builder-scheduler *ngIf="currentCompany && currentCompany._id" [currentCompany]="currentCompany"></app-global-report-builder-scheduler>
        </ng-template>
    </mat-tab>
</mat-tab-group>
