import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthenticationService} from '../../_services/authentication.service';
import {MyToastrService} from '../../_services/toastr.service';
import {BaseRequestService} from '../../_services/base.service';
import {LoaderService} from '../../_services/loader.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {ModalService} from '../../_services/modal.service';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subscription} from 'rxjs';
import {debounceTime, delay, filter, map, tap} from 'rxjs/operators';
import {IntegrationActionsService} from '../../_services/integration-actions.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {Tag} from '../../admin/assessment/assess/assess.component';
import { CompanyService } from 'src/app/api/services';

@Component({
  selector: 'app-application-baseline',
  templateUrl: './application-baseline.component.html',
  styleUrls: ['./application-baseline.component.scss']
})

export class ApplicationBaselineComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  removable = true;
  selectable = true;
  ignoreTags: any = [];
  includeTags: any = [];
  isLoading = false;
  public searching = false;
  deniedAppCtrl = new FormControl();
  mandatoryAppCtrl = new FormControl();
  deniedMandatoryTagCtrl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public companyCtrl: FormControl = new FormControl();
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @Input() currentCompany: any;
  @Input() mode: any;
  appBaselineColOptions: any;
  appBaselineQuery: any = {};
  companyHash: any = {};
  allComp: any = [];
  currentAppBaseline: any = {
    name: '',
    os_type: 'windows',
    osname: 'windows',
    type: 'application',
    deniedApplications: [],
    mandatoryApplications: [{serviceName: '', regularExpression: false}],
    deniedTags: [],
    mandatoryTags: [],
    exclude_company:[],
    deniedService: [{serviceName: '', applicationName: '', regularExpression: false}],
    mandatoryService: [{serviceName: '', applicationName: '', regularExpression: false}],
  };
  dynamicColumns: any = [];
  mandatory: any;
  mandappregex = false;
  denied: any;
  deniedappregex = false;
  deniedservice: any;
  deniedapp: any;
  deniedregex: any;
  mandatoryservice: any;
  mandatoryapp: any;
  mandatoryappregex = false;
  deniedtag: any;
  mandatorytag: any;
  getListOfOS: any = [];
  deniedTags: any = [];
  mandatoryTags: any = [];
  mandatoryApplications: any = [];
  mandatoryService: any = [];
  deniedApplications: any = [];
  deniedService: any = [];
  private actEve: Subscription;
  tempDeninedSerivce: string;

  constructor(public aS: AuthenticationService, public loaderService: LoaderService, private toast: MyToastrService,
              private modalService: ModalService, private integrationActionService: IntegrationActionsService,
              private baseService: BaseRequestService, private confirmDialog: ConfirmDialogService,private companyService: CompanyService) {
    this.deniedAppCtrl.valueChanges
      .pipe(
        filter(value => !!value),
        debounceTime(500),
        tap(() => this.isLoading = true),
        map(value => {
          const company = (this.mode === 'company' && this.currentCompany && this.currentCompany._id) ? this.currentCompany._id : '';
          const url = (this.currentAppBaseline.type === 'application')
            ? '/api/company/dummy/getUniqueApplication' : '/api/company/dummy/getUniqueService';
          const params = (this.currentAppBaseline.type === 'application')
            ? {appName: value, companyid: company} : {serviceName: value, companyid: company};
          this.baseService.doRequest(url, 'post', params).subscribe((result: any) => {
            console.log('api data', result);
            this.loaderService.display(false);
            if (result) {
              result.sort((a: any, b: any) => {
                const c = a;
                const d = b;
                if (c < d) {
                  return -1;
                } else if (c > d) {
                  return 1;
                } else {
                  return 0;
                }
              });
              this.deniedApplications = result;
              this.deniedService = result;
              // this.tempDeninedSerivce = JSON.stringify(result);
              // this.deniedService.forEach((obj: any) => {
              //     temp = (temp.concat(result)).concat(',');
              // });
              // console.log('denService', this.deniedService);
              // this.deniedService = temp;
            } else {
              this.deniedApplications = [];
              this.deniedService = [];
              this.isLoading = false;
            }
          });
        }),
        delay(100)
      ).subscribe(filteredApps => {
      this.isLoading = false;
    }, error => {
      // no errors in our simulated example
      this.isLoading = false;
      // handle error...
    });
    this.mandatoryAppCtrl.valueChanges
      .pipe(
        filter(value => !!value),
        debounceTime(500),
        tap(() => this.isLoading = true),
        map(value => {
          const company = (this.mode === 'company' && this.currentCompany && this.currentCompany._id) ? this.currentCompany._id : '';
          const url = (this.currentAppBaseline.type === 'service')
            ? '/api/company/dummy/getUniqueService' : '/api/company/dummy/getUniqueApplication';
          const params = (this.currentAppBaseline.type === 'service')
            ? {serviceName: value, companyid: company} : {appName: value, companyid: company};
          this.baseService.doRequest(url, 'post', params).subscribe((result: any) => {
            this.loaderService.display(false);
            if (result) {
              result.sort((a: any, b: any) => {
                const c = a;
                const d = b;
                if (c < d) {
                  return -1;
                } else if (c > d) {
                  return 1;
                } else {
                  return 0;
                }
              });
              this.mandatoryApplications = result;
              this.mandatoryService = result;
            } else {
              this.mandatoryApplications = [];
              this.mandatoryService = [];
              this.isLoading = false;
            }
          });
        }),
        delay(100)
      ).subscribe(filteredApps => {
      this.isLoading = false;
    }, error => {
      // no errors in our simulated example
      this.isLoading = false;
      // handle error...
    });
   this.getCompanies()
  }

  getCompanies(search?: string): void {
    if (!this.aS || !this.aS.isAuthenticated) {
      setTimeout(() => {
        this.getCompanies();
      }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {
      query: {
        bool: {
          must: [{ exists: { field: 'description' } }, {terms: {'_type_.keyword': ['company', 'companycreate']}}],
          must_not: [
            { match: { isAssessment: true } },
          ],
        },
      },
    };
    const asmq = {
      query: {
        bool: {
          must: [
            { match: { isAssessment: true } },
            { exists: { field: 'description' } },
            {terms: {'_type_.keyword': ['company', 'companycreate']}}
          ],
        },
      },
    };
    cq = this.baseService.showAssessment ? asmq : cmpq;
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    const fields = JSON.stringify(['name', 'c']);
    this.searching = true;
    this.companyService
      .getAllApiCompanyGet({ q, skip, limit, sort, fields })
      .subscribe(
        (result: any) => {
          if (result.data.length) {
            for (const c of result.data) {
              if (c._id) {
                this.companyHash[c._id] = c.name;
              }
            }
            result.data.sort((a: any, b: any) => {
              const c = a.name ? a.name.toLowerCase() : '';
              const d = b.name ? b.name.toLowerCase() : '';
              if (c < d) {
                return -1;
              } else if (c > d) {
                return 1;
              } else {
                return 0;
              }
            });
            console.log('result1',result.data)
            this.allComp = result.data;
            this.filteredCompanies.next(result.data.slice());

            this.searching = false;
          } else {
            this.filteredCompanies.next([]);
            this.searching = false;
          }
        },
        (error: any) => {
          this.searching = false;
        }
      );
  }


  // ngOnDestroy(): void {
  //   this.actEve.unsubscribe();
  // }

  deleteAppBaseline(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this rule ' + idata.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: boolean) => {
      if (res) {
        this.baseService.doRequest(`/api/applicationbaseline/${idata._id}`, 'delete')
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Removed successfully');
              setTimeout(() => {
                this.getAppBaselineData();
              }, 3000);
            } else {
              this.toast.sToast('error', result);
            }
          });
      }
    });
  }

  addTag(event: MatChipInputEvent, target: string): void {
    const input = event.input;
    const value = event.value;
    // Add tag
    if ((value || '').trim()) {
      if (target === 'ignore') {
        this.ignoreTags.push({name: value.trim()});
      }
      if (target === 'include') {
        this.includeTags.push({name: value.trim()});
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: Tag, target: string): void {
    if (target === 'ignore') {
      const index = this.ignoreTags.indexOf(tag);
      if (index >= 0) {
        this.ignoreTags.splice(index, 1);
      }
    }
    if (target === 'include') {
      const index = this.includeTags.indexOf(tag);
      if (index >= 0) {
        this.includeTags.splice(index, 1);
      }
    }
  }

  addmandData(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.mandatoryService) {
      this.currentAppBaseline.mandatoryService = [];
    }
    this.currentAppBaseline.mandatoryService.push({
      serviceName: this.mandatoryservice,
      applicationName: this.mandatoryapp,
      regularExpression: this.mandatoryappregex ,
    });
    this.mandatoryservice = '';
    this.mandatoryapp = '';
    this.mandatoryappregex = false;
  }

  addMandatoryApplications(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.mandatoryApplications) {
      this.currentAppBaseline.mandatoryApplications = [];
    }
    this.currentAppBaseline.mandatoryApplications.push({
      serviceName: this.mandatory,
      regularExpression: this.mandappregex ,
    });
    this.mandatory = '';
    this.mandappregex = false;
  }

  addDeniedApplications(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.deniedApplications) {
      this.currentAppBaseline.deniedApplications = [];
    }
    this.currentAppBaseline.deniedApplications.push({
      serviceName: this.denied,
      regularExpression: this.deniedappregex,
    });
    this.denied = '';
    this.deniedappregex = false;
  }

  adddeniData(): void {
    if (this.currentAppBaseline && !this.currentAppBaseline.deniedService) {
      this.currentAppBaseline.deniedService = [];
    }
    this.currentAppBaseline.deniedService.push({serviceName: this.deniedservice, applicationName: this.deniedapp, regularExpression: this.deniedregex});
    this.deniedservice = '';
    this.deniedapp = '';
    this.deniedregex = false;
  }

  saveAppBaseline(): void {
    console.log(this.currentAppBaseline);
    const url = '/api/applicationbaseline/';
    const method = (this.currentAppBaseline._id) ? 'put' : 'post';
    const msg = (this.currentAppBaseline._id) ? 'Updated successfully' : 'Added successfully';
    const auditmsg = (this.currentAppBaseline._id) ? 'Update Application Baseline Rules' : 'Create Application Baseline Rules';
    if (this.mode === 'company') {
      this.currentAppBaseline.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    } else {
      delete this.currentAppBaseline.companyRef;
    }
    if (this.currentAppBaseline && !this.currentAppBaseline.mandatoryService) {
      this.currentAppBaseline.mandatoryService = [{serviceName: '', applicationName: ''}];
    }
    let denied_Applications: any = [];
    let mandatory_Applications: any = [];
    if (this.currentAppBaseline.type === 'service') {
      this.currentAppBaseline.deniedApplications = [];
      this.currentAppBaseline.mandatoryApplications = [];
    } else if (this.currentAppBaseline.type === 'application') {
      this.currentAppBaseline.deniedApplications.map((data: any) => denied_Applications.push(data.serviceName));
      this.currentAppBaseline.mandatoryApplications.map((data: any) => mandatory_Applications.push(data.serviceName));
      this.currentAppBaseline.deniedService = [];
      this.currentAppBaseline.mandatoryService = [];
    }
    this.currentAppBaseline.ignoreTags = this.ignoreTags.map((x: any) => x.name);
    this.currentAppBaseline.includeTags = this.includeTags.map((x: any) => x.name);
    const currentAppBaselineData = Object.assign({},this.currentAppBaseline)
    currentAppBaselineData.deniedApplications = (this.currentAppBaseline.type === 'application') ? denied_Applications : [];
    currentAppBaselineData.mandatoryApplications = (this.currentAppBaseline.type === 'application') ? mandatory_Applications : [];
    currentAppBaselineData.denied_Applications = (this.currentAppBaseline.type === 'application') ? this.currentAppBaseline.deniedApplications : [];
    currentAppBaselineData.mandatory_Applications = (this.currentAppBaseline.type === 'application') ? this.currentAppBaseline.mandatoryApplications : [];
    this.loaderService.display(true, `Saving ${this.currentAppBaseline.type} baseline rule`);
    this.baseService.doRequest(url, method, currentAppBaselineData).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result._id && result.c !== null && result.u !== null) {
        this.toast.sToast('success', msg);
        this.close();
        this.getAppBaselineData();
      } else {
        this.toast.sToast('error', result._id);
      }
    });
  }


  actionCalback(idata: any): void {
    if (idata.action.text === 'Edit') {
      idata.row.mandatory_Applications = (idata.row.mandatory_Applications) ? idata.row.mandatory_Applications : []
      idata.row.denied_Applications = (idata.row.denied_Applications) ? idata.row.denied_Applications : []
      const currentAppBaselineData = Object.assign({},idata.row)
      if(idata.row.denied_Applications.length > 0 || idata.row.mandatory_Applications.length > 0){
      currentAppBaselineData.deniedApplications = (idata.row.type === 'application') ? idata.row.denied_Applications : [];
      currentAppBaselineData.mandatoryApplications = (idata.row.type === 'application') ? idata.row.mandatory_Applications : [];
      }else if((idata.row.denied_Applications.length == 0 || idata.row.mandatory_Applications.length == 0) &&  (idata.row.deniedApplications.length >0 || idata.row.mandatoryApplications.length >0)){
        currentAppBaselineData.deniedApplications = (idata.row.type === 'application') ? idata.row.deniedApplications.map((row: any) => row = {serviceName: row, regularExpression: false}) : [];
        currentAppBaselineData.mandatoryApplications = (idata.row.type === 'application') ? idata.row.mandatoryApplications.map((row: any) => row = {serviceName: row, regularExpression: false}) : [];
      }else{
        console.log(true);
      }
      this.currentAppBaseline = currentAppBaselineData;
      if (this.currentAppBaseline && !this.currentAppBaseline.mandatoryService) {
        this.currentAppBaseline.mandatoryService = [{serviceName: '', applicationName: ''}];
      }
      if (this.currentAppBaseline && !this.currentAppBaseline.deniedService) {
        this.currentAppBaseline.deniedService = [{serviceName: '', applicationName: ''}];
      }
      this.ignoreTags = []; this.includeTags = [];
      if (this.currentAppBaseline.ignoreTags && this.currentAppBaseline.ignoreTags.length) {
        this.currentAppBaseline.ignoreTags.forEach((obj: any) => {
          this.ignoreTags.push({name: obj});
        });
      }
      if (this.currentAppBaseline.includeTags && this.currentAppBaseline.includeTags.length) {
        this.currentAppBaseline.includeTags.forEach((obj: any) => {
          this.includeTags.push({name: obj});
        });
      }
      // if (idata.row.type === 'service') {
      //   this.currentAppBaseline.deniedApplications = idata.row.deniedServices;
      //   this.currentAppBaseline.mandatoryApplications = idata.row.mandatoryServices;
      // }
      this.currentAppBaseline.isLightWeightMandatory = (idata.row.isLightWeightMandatory) ? idata.row.isLightWeightMandatory : false;
      this.snav.open();
    } else if (idata.action.text === 'Delete') {
      this.deleteAppBaseline(idata.row);
    }
  }

  addEditAppBaseline(): void {
    this.currentAppBaseline = {
      name: '', os_type: 'windows', type: 'service', osname: 'windows', deniedApplications: [], ignoreTags: [], includeTags: [],
      mandatoryApplications: [], deniedService: [], mandatoryService: [], isLightWeightMandatory: false
    };
    this.ignoreTags = [];
    this.includeTags = [];
    this.snav.open();
  }

  getListofos(): void {
    this.baseService.doRequest(`/api/applicationbaseline/dummy/getListOfOS`, 'post', {
      msg: 'hosts',
      type: this.currentAppBaseline.os_type
    })
      .subscribe((result: any) => {
        console.log('getosLISt', result);
        this.getListOfOS = result;
      });
  }

  close(status?: any): void {
    if (status) {
      return;
    }
    this.currentAppBaseline = {
      name: '',
      os_type: 'windows',
      type: 'service',
      osname: 'windows',
      deniedApplications: [{serviceName: '', regularExpression: false}],
      mandatoryApplications: [{serviceName: '', regularExpression: false}],
      deniedService: [{serviceName: '', applicationName: '', regularExpression: false}],
      mandatoryService: [{serviceName: '', applicationName: '', regularExpression: false}],
      isLightWeightMandatory: false,
    };
    this.snav.close();
    this.getAppBaselineData();
  }

  ngOnInit(): void {
    this.dynamicColumns = [
      {col: 'u', header: 'Last updated', colFilters: {type: 'text', hKey: false}, isKeyword: false, filter: 'utcToLocale'},
      {col: 'name', header: 'Name', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'type', header: 'Type', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'deniedApplications', header: 'Denied Application', isKeyword: true, filter: 'ellipse:70'},
      {col: 'mandatoryApplications', header: 'Mandatory Application', isKeyword: true, filter: 'ellipse:70'},
      {col: 'deniedService', header: 'Denied Services', isKeyword: true, filter: 'arrayToObjWithEllipsis:serviceName:70'},
      {col: 'mandatoryService', header: 'Mandatory Services', isKeyword: true, filter: 'arrayToObjWithEllipsis:serviceName:70'},
      {col: 'ignoreTags', header: 'Ignored Tags', isKeyword: true, filter: 'ellipse:70'},
      {col: 'includeTags', header: 'Include Tags', isKeyword: true, filter: 'ellipse:70'},
      {col: 'os_type', header: 'OS Type', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
      {col: 'osname', header: 'OS', colFilters: {type: 'text', hKey: true}, isKeyword: true, noFilter: true},
    ];
    this.getAppBaselineData();
    // this.getListofos();
  }

  getAppBaselineData(): void {
    if (this.mode === 'company' && this.currentCompany && this.currentCompany._id) {
      this.appBaselineQuery = {
        query: {
          bool: {
            must: [
              {match: {'companyRef.id.keyword': this.currentCompany._id}},
              {
                bool: {
                  should: [
                    {exists: {field: 'deniedApplications'}}, {exists: {field: 'mandatoryApplications'}},
                    {exists: {field: 'deniedService'}}, {exists: {field: 'mandatoryService'}},
                  ]
                }
              }
            ]
          }
        }
      };
    } else {
      this.appBaselineQuery = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  should: [
                    {exists: {field: 'deniedApplications'}}, {exists: {field: 'mandatoryApplications'}},
                    {exists: {field: 'deniedService'}}, {exists: {field: 'mandatoryService'}},
                  ]
                }
              }
            ],
            must_not: [{exists: {field: 'companyRef.id.keyword'}}]
          }
        }
      };
    }
    this.appBaselineColOptions = {};
    setTimeout(() => {
      this.appBaselineColOptions = {
        id: 'appBaselineColOptions',
        title: 'Application Baseline Rules',
        refresh: true,
        cFilter: true,
        pSize: 5,
        pList: [1, 5, 10, 25, 50, 100, 200],
        pagination: true,
        loading: true,
        customCols: true,
        showAction: true,
        add: this.aS.hasPermission('applicationbaseline', 'create'),
        faClass: 'ApplicationBaseline',
        serverSide: {
          service: 'applicationBaselineService',
          fn: 'getAllApiApplicationbaselineGet',
          q: this.appBaselineQuery, sort: [{u: {order: 'desc'}}]
        },
        lMsg: 'Getting application baseline...',
        local: false,
        dataList: [],
        columns: this.dynamicColumns,
        actionMenuItems: [
          {text: 'Edit', icon: 'edit', isGlobal: false, callback: 'detailFn'},
          {text: 'Delete', icon: 'delete', isGlobal: false, callback: 'detailFn'},
        ]
      };
    });
  }

  closeDM(): void {
    this.modalService.close('deniedMandatoryApplications');
  }

  openDM(): void {
    this.modalService.open('deniedMandatoryApplications');
  }
}
