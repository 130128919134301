import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseRequestService } from '../../_services/base.service';
import { LoaderService } from '../../_services/loader.service';
import { MyToastrService } from '../../_services/toastr.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-attack-surface',
  templateUrl: './attack-surface.component.html',
  styleUrls: ['./attack-surface.component.scss']
})
export class AttackSurfaceComponent implements OnInit, OnDestroy {
  @Input() currentCompany: any;
  tab: any = {value: 0};
  ngOnInit(): void {}

  ngOnDestroy(): void {}

  tabFun(event: any): void {
    if (event.index === 1) {
    } 
  }
}
