import {Component, Input, OnInit} from '@angular/core';
import {SnmpV1CredentialsService} from '../../../api/services/snmp-v-1-credentials.service'; // Change to Master Cred API service
import {LoaderService} from '../../../_services/loader.service';
import {ModalService} from '../../../_services/modal.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-master-credentials',
  templateUrl: './master-credentials.component.html',
  styleUrls: ['./master-credentials.component.scss']
})
export class MasterCredentialsComponent implements OnInit {
  @Input() currentCompany: any;
  isLoading = false;
  masterCredTableOptions: any;
  addMasterWidth = 600;
  master: any = {name: '', master_type: 0, agentcompanyRef: {id: '', name: ''}};

  constructor(private loaderService: LoaderService, private toast: MyToastrService, private aS: AuthenticationService,
              public modalService: ModalService, private snmpCredentials: SnmpV1CredentialsService) {
    this.masterCredTableOptions = {
      columns: [
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false
        }, {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },

        {
          header: 'Asset ID',
          columnDef: 'assetid',
          filter: '',
          cell: '(element: any) => `${element.assetid}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }
      ],
      sortOptions: {active: 'created', direction: 'desc'},
      _pageData: [],
      tableOptions: {
        id: 'masterCredTableOptions',
        title: 'Master Credentials',
        isServerSide: true,
        selectText: 'agent(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [{text: 'Details', icon: 'info', callback: 'detailFn', isGlobal: false}, {
          text: 'Edit',
          icon: 'edit',
          callback: 'editFn',
          isGlobal: true
        }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 15,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: (this.aS.hasPermission('assetcredentials', 'create')),
        columnSearch: false,
        compareData: false
      }
    };
  }

  ngOnInit(): void {
    this.masterCredTableOptions.pageData = [];
    this.getmasterCreds();
  }

  mastershowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.masterCredTableOptions);
    this.masterCredTableOptions = {};
    this.masterCredTableOptions = data;
    this.masterCredTableOptions.tableOptions.loading = status;
  }

  getmasterCreds(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.mastershowHideLoading(true);
      this.loaderService.display(true, 'Getting SNMP Credentials');
      const query = {
        query: {
          bool: {
            must: [
              {match: {'agentcompanyRef.id.keyword': this.currentCompany._id + ''}},
              {exists: {field: 'agentcompanyRef.id'}}], must_not: [{exists: {field: 'assetid'}}]
          }
        }
      };
      const q = JSON.stringify(query);
      const skip = 0;
      const limit = 100;
      this.snmpCredentials.getAllApiSnmpv1CredentialsGet
      ({q, skip, limit}).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data.length) {
          this.masterCredTableOptions.pageData = result.data;
          this.masterCredTableOptions.tableOptions.pageTotal = result.total;
          this.mastershowHideLoading(false);
        } else {
          this.masterCredTableOptions.pageData = [];
          this.masterCredTableOptions.tableOptions.pageTotal = 0;
          this.mastershowHideLoading(false);
        }
        console.log(result);
      });
    } else {
      setTimeout(() => {
        this.getmasterCreds();
      }, 1000);
    }
  }

  savemasterCreds(): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.master.agentcompanyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    this.snmpCredentials.createApiSnmpv1CredentialsPost({body: this.master}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.isLoading = true;
      if (result._id) {
        this.modalService.close('addAgent');
        this.toast.sToast('success', 'Agent added successfully!');
        this.getmasterCreds();
      } else {
        this.toast.sToast('error', JSON.stringify(result));
      }
    });
  }

  addMaster(): void {
    this.master = {name: '', agent_type: 0, agentcompanyRef: {id: '', name: ''}};
    this.modalService.open('addMaster');
  }
}
