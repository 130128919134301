<!-- <app-s-table aria-label="PatchJobs" role="table" [sTableOptions]="patcTableOptions"
             (sortCallback)="patcsortCall($event)" (filterCallback)="patcfilterCall($event)"
             (actionCallback)="patcactionCall($event)" (pageCallback)="patcpageCall($event)"
             (refreshCallback)="getPatchJobs()" (hyperlinkCallback)="patclinkCall($event)"
             (timerCallback)="patctimerCallData($event)">
</app-s-table> -->

<mat-sidenav-container class="remedia-sidenav-container" (backdropClick)="close('backdrop')">
    <mat-sidenav-content class="h-100vh">
        <app-s-table aria-label="PatchJobs" role="table" [sTableOptions]="patcTableOptions"
        (sortCallback)="patcsortCall($event)" (filterCallback)="patcfilterCall($event)"
        (actionCallback)="patcactionCall($event)" (pageCallback)="patcpageCall($event)"
        (colFilterCallback)="patchcolFilterCall($event)"
        (refreshCallback)="getPatchJobs()" (hyperlinkCallback)="patclinkCall($event)"
        (timerCallback)="patctimerCallData($event)" (globalActionCallback)="patcglobalActionCall($event)">
    </app-s-table>
    </mat-sidenav-content>
    <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p z-index-150">
      <mat-card class="w-100">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="snav.close();"><mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4">Detailed View</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto">
            <div>
                <app-s-table *ngIf="jobTableOptions && jobTableOptions.pageData && jobTableOptions.pageData.length>0"
                  aria-label="Jobs" role="table"  (sortCallback)="jobsortCall($event)" (pageCallback)="jobpageCall($event)"
                  [sTableOptions]="jobTableOptions"  (refreshCallback)="getPatchData($event)"
                ></app-s-table>
            </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
