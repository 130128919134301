
<mat-tab-group (selectedIndexChange)="reportChangeTab($event)">
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="StandardReport">Standard Report</span>
    </ng-template>
    <ng-template matTabContent>
      <div class="mt-2 pl-3 row" *ngIf="companyTags">

        <div class="search ml-3">
          <input  class="search-txt placeholder-text" autocomplete="off" [(ngModel)]="reportFilters" type="text" name="" id="search" placeholder="Search..." (keyup.enter)="handleClickValue()"  (click)="handleChangeFilter($event)" (keypress)="handleChangeFilter($event)">
          <button mat-icon-button class="search-btn" *ngIf="showSearch" ><mat-icon class="mat-18 mb-1">search</mat-icon></button>
          <button mat-icon-button class="search-btn" *ngIf="!showSearch" (click)="reportFilters= ''; showSearch=true; showList=0"><mat-icon class="mat-18 mb-1">close</mat-icon></button>
          <ng-container *ngIf="showList === 1">
            <ul class="list">
              <li class="grptitle" *ngIf="!noFilters">Filter Key</li>
              <li class="grptitle" *ngIf="noFilters">No filter keys</li>
              <ng-container *ngFor="let item of Objectkeys(companyTags)">
                <li class="pointer listshow" (click)="handledFilterKey(item)" *ngIf="companyTags[item] && companyTags[item].length" [value]="item"> {{item}}</li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="showList === 2">
            <ul class="list">
              <li class="grptitle">Operators</li>
              <li class="pointer listshow" (click)="handleChangeOperator(item.key)" *ngFor="let item of operators" [value]="item">
                <span class="column">
                  <span class="searchData">{{ reportFilters }}</span><span> {{item.key}}</span>
                  <p class="opTitle">{{item.title}}</p>
                </span>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="showList === 3">
            <ul class="list overflow-auto h_200">
              <li class="pointer listshow" (click)="handleClickValue(item)" *ngFor="let item of tagsList" [value]="item">{{item}}</li>
            </ul>
          </ng-container>
        </div>
      <div class="date-picker-container ml-3 mt-2">
        <form class="date-range-form">
          <mat-form-field>
            <input matInput [matDatepicker]="fromDatepicker" placeholder="From Date" [formControl]="fromDate" [max]="maxDate" (dateChange)="applyDateFilter()">
            <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatepicker></mat-datepicker>
          </mat-form-field>
      
          <mat-form-field>
            <input matInput [matDatepicker]="toDatepicker" placeholder="To Date" [formControl]="toDate" [max]="maxDate" [min]="fromDate.value" (dateChange)="applyDateFilter()">
            <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatepicker></mat-datepicker>
          </mat-form-field>
        </form>
      </div>
        <mat-form-field class="col-10 mb-2 mt-1" appearance="outline">
          <mat-label>Filters</mat-label>
          <mat-chip-list #insecureList aria-label="port selection">
            <mat-chip class="insecureo" *ngFor="let filter of filteredList" [selectable]="selectable" [removable]="removable"
                      (removed)="removeFilter(filter)">
              {{filter}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input type="text" trim="blur" readonly autocomplete="off" name="insecure"
                   placeholder="" [matChipInputFor]="insecureList" >
          </mat-chip-list>
        </mat-form-field>
        <button mat-button="" class="tprime px-1 mr-2 mb-4" (click)="clearDateFilters()">
          <span class="d-flex flex-row align-items-center">
            <i class="ms-Icon ms-font-lg ms-Icon&#45;&#45;Delete px-1 ms-fontColor-themePrimary"></i>
            <span class="ibtn pl-1" id="AgentVulnerabilityScan"> Clear filters</span>
          </span>
  </button>
      </div>
      <mat-card *ngFor="let item of allReports | search: searchFilter" class="my-2">
        <mat-card-header>
          <img mat-card-avatar *ngIf="item.isBeta" src="/assets/images/beta.png" alt="" class="mr10 w-48">
          <img mat-card-avatar *ngIf="item.isNew" src="/assets/images/new.png" alt="" class="mr10 w-48">
          <h3 class="fw300 c-title mat-title">{{item.Section}}</h3>
        </mat-card-header>
        <mat-card-content>
          <table class="rounded-corner w-100 bor-1">
            <thead><tr><th>Report Name</th><th>Description</th><th>Download as</th></tr></thead>
            <tbody>
            <tr class="" *ngFor="let it of item.Reports| search: searchFilter; let i = index">
              <td class="w-33p">
                <span>{{it.title}}</span>
              </td>
              <td class="w-33p">{{it.description}}</td>
              <td class="">
                <div class="d-flex flex-row align-items-center">
                <ng-container *ngFor="let dwn of it.reports">
                  <span class="mr-2 pointer d-flex flex-row align-items-center" matTooltip="Download {{dwn.reportType}} Report"
                       *ngIf="dwn.reportType === 'xlsx' || !dwn.custom"  (click)="downloadReport(dwn,it.title);" id={{dwn.id}}>
                    <img class="" [ngClass]="{'mat-32': dwn.reportType !== 'pdf', 'mat-24': dwn.reportType === 'pdf'}"
                         src="/assets/images/reports/{{dwn.reportType}}.png" alt="{{dwn.reportType}}">
                    <img src="/assets/images/spinner.gif" alt="Loading..." *ngIf="tmpJobs[dwn.id] && tmpJobs[dwn.id][dwn.reportType]" class="loading-report">
                  </span>
                  <span class="mr-2 pointer  update-template d-flex flex-row align-items-center" matTooltip="Custom Template"
                  *ngIf="dwn.custom"  (click)="downloadReport(dwn, it.title);" id={{dwn.id}}>
                    <img class="mat-32" src="/assets/images/reports/{{dwn.reportType}}.png" alt="{{dwn.reportType}}">
                    <img src="/assets/images/spinner.gif" alt="Loading..." *ngIf="tmpJobs[dwn.id] && tmpJobs[dwn.id][dwn.reportType]" class="loading-report">
                  </span>
                </ng-container>
                  </div>
                <!--<button type="button" mat-icon-button color="primary" (click)="downloadReport(it);"
                        matTooltip="Download Report" [id]="it.title">
                  <mat-icon class="mat-18">cloud_download</mat-icon>
                </button>-->
              </td>
            </tr></tbody>
          </table>
          <!--<table class="rounded-corner w-100">
            <thead><tr><th>Report Name</th><th>Description</th><th>Action</th></tr></thead>
            <tbody>
            <tr class="pull-up" *ngFor="let it of item.Reports| search: searchFilter; let i = index">
              <td>
                <span class="mr-2 "><img class="mat-32" src="/assets/images/reports/{{it.reportType}}.png" alt="{{it.reportType}}"></span>
                <span>{{it.title}}</span>
              </td>
              <td>{{it.description}}</td>
              <td>
                <button type="button" mat-icon-button color="primary" (click)="downloadReport(it);"
                        matTooltip="Download Report" [id]="it.title">
                  <mat-icon class="mat-18">cloud_download</mat-icon>
                </button>
              </td>
            </tr></tbody>
          </table>-->
        </mat-card-content>
      </mat-card>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="StandardReportJobs">Standard Report Jobs</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <app-report-jobs [currentCompany]="currentCompany"></app-report-jobs>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="ReportSchedulers">Report Scheduler</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <app-report-scheduler [companyid]="currentCompany._id"></app-report-scheduler>
    </ng-template>
  </mat-tab>
</mat-tab-group>
