import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IntegrationsService } from '../../../api/services/integrations.service';
import { MyToastrService } from '../../../_services/toastr.service';
import { ConfirmDialogService } from '../../../_services/confirmdialog.service';
import { LoaderService } from '../../../_services/loader.service';
import { IntegrationMappingService } from '../../../api/services/integration-mapping.service';
import { CompanyService } from '../../../api/services/company.service';
import { BaseRequestService } from '../../../_services/base.service';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthenticationService } from '../../../_services/authentication.service';
import { CompanySharedService } from '../../../_services/company-shared.service';
import { IntegrationActionsService } from 'src/app/_services/integration-actions.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-non-psa-event-company-mapping',
  templateUrl: './non-psa-event-company-mapping.component.html',
  styleUrls: ['./non-psa-event-company-mapping.component.scss'],
})
export class NonPsaEventCompanyMappingComponent implements OnInit, OnChanges {
  @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
  @ViewChild('menuRefMenuTrigger', { static: false })
  menuRefMenuTrigger: MatMenuTrigger;
  @Input() currentIntegration: any;
  @Input() integrationsData: any;
  @Input() currentCompany: any;
  @Input() onboarding: any;
  @Output() updateCompanyList = new EventEmitter();
  disableCreate = false;
  currentCredential: any;
  companyActionParams: any;
  name: string;
  companyParams: any = {};
  companyFormElements: any = [];
  companyList: any = [];
  /*Integration Mapping table*/
  cmpView = 'Type';
  eView = 'edit';
  copyCompanyMappingList: any = [];
  copyEvents: any = {};
  @ViewChild('copyCompSelect', { static: true }) copyCompSelect!: MatSelect;
  public copyCompCtrl: FormControl = new FormControl();
  public copyCompFilterCtrl: FormControl = new FormControl();
  isLoading = false;
  configurationIntegration: any = ['Connectwise'];
  cmpMapping: any = { selectedCompanies: [], mapCompanyList: [] };
  companyMappingList: any = [];
  failedMappingList: any = [];
  addEditIntegration = false;
  inteTableOptions: any;
  integmappingcurrentPage = 0;
  integmappingfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;
  alertRuleList: any = [];
  intergrationRuleList: any = [];
  forceSet = false;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  selectedCompany: any;
  eveHash: any = {}; integHash: any = {};
  selectedCompanyList: any = [];
  company: any;
  companies: any;
  allComp: any;
  companyHash: any = {};
  sourceCompany: any[] = [];

  sourceCompanies: any[] = []; // Initialize it as an empty array
  selectedCompanies: any[] = [];
  
  searchTxt: any = 'Search Company';
  isEdit = false;
  currentCompanyMapping: any = {};
  notes: any = [];
  protected onDestroySearch = new Subject<void>();
  @ViewChild('eventSelect', { static: true }) eventSelect!: MatSelect;
  public eventCtrl: FormControl = new FormControl();
  public eventFilterCtrl: FormControl = new FormControl();

  @ViewChild('integSelect', { static: true }) integSelect!: MatSelect;
  public inteCtrl: FormControl = new FormControl();
  public inteFilterCtrl: FormControl = new FormControl();

  @ViewChild('alertSelect', { static: true }) alertSelect!: MatSelect;
  public alertCtrl: FormControl = new FormControl();
  public alertFilterCtrl: FormControl = new FormControl();

  @ViewChild('inteRuleSelect', { static: true }) inteRuleSelect!: MatSelect;
  public inteRuleCtrl: FormControl = new FormControl();
  public inteRuleFilterCtrl: FormControl = new FormControl();
  isDefaultAlert: any = [];
  isDefaultIntegration: any = [];

  

  @ViewChild('snav', { static: true }) snav: MatSidenav;
  currentIntegrationMapping: any = {};
  updateData: any = {
    eventSettingsId: '',
    integrationSettingsId: '',
  };
  /*Integration Mapping table*/
  constructor(
    private integrationsService: IntegrationsService,
    private toast: MyToastrService,
    private companyService: CompanyService,
    private baseService: BaseRequestService,
    private aS: AuthenticationService,
    private cs: CompanySharedService,
    private confirmDialog: ConfirmDialogService,
    public integrationActionService: IntegrationActionsService,
    public integrationMappingService: IntegrationMappingService,
    public loaderService: LoaderService
  ) {
    this.inteTableOptions = {
      columns: [
        {
          header: 'Existing Company Name',
          columnDef: 'sourceCompanyName',
          filter: '',
          cell: '(element: any) => `${element.sourceCompanyName}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: `Company Name`,
          columnDef: 'destCompanyName',
          filter: '',
          cell: '(element: any) => `${element.destCompanyName}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: `Event Set`,
          columnDef: 'eventSet',
          filter: '',
          cell: '(element: any) => `${element.eventSet}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: `Integration Profile`,
          columnDef: 'integrationProfile',
          filter: '',
          cell: '(element: any) => `${element.integrationProfile}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Create Ticket',
          columnDef: 'no_notification',
          filter: '',
          cell: '(element: any) => `${element.no_notification}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: true,
          statusicon: true,
          success: true,
          failure: false,
          successIconPath: '/assets/images/auditLogins/cross.svg',
          successToolTip: 'No',
          failureIconPath: '/assets/images/auditLogins/tick.svg',
          failureToolTip: 'Yes',
        },
        {
          header: 'Mapped Date',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: 'u', direction: 'asc' },
      faClass: 'Company',
      _pageData: [],
      tableOptions: {
        title: 'Mapping Info',
        id: 'inteTableOptions',
        isServerSide: true,
        selectText: 'integrationMapping',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            icon: 'edit',
            callback: 'editFn',
            isGlobal: false,
          },
          {
            text: 'Delete',
            icon: 'delete',
            callback: 'deleteFn',
            isGlobal: false,
          },
          {text: 'Copy Settings', icon: 'content_copy', callback: 'deleteFn', isGlobal: false},
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        add: this.aS.hasPermission('integrations', 'companymapping'),
        columnSearch: true,
        compareData: false,
      },
    };
  }


  ngOnInit(): void {
    if(this.currentIntegration.importCompanies){
      this.notes = [
        "1. Currently showing 25 list of companies. If you can't find your company in this list. Please use the search option to filter.",
        `2. Select the Create ${this.currentIntegration.title} Alert option to create alert with Event Set and the Integration Profile.`,
        "3. Click on Add and then Finish to map all the selected companies."
      ]
    } else {
      this.notes = [
        `1. Select the Create ${this.currentIntegration.title} Alert option to create alert with Event Set and the Integration Profile.`,
        "2. Click on Add and then Finish to map all the selected companies."
      ]
    }

    this.currentCredential = this.integrationsData[0]._id;
    this.getCompanyActionParams();
    this.inteTableOptions.pageData = [];
    this.getIntegrationMapping(true);
    this.companyFilterCtrl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.onDestroySearch))
      .subscribe(() => {
        this.filterCompanies();
      });
    this.getCompanies();
  }

  private filterCompanies(): void {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  getCompanies(search?: string): void {
    let cq: any;
    const cmpq = {
      query: {
        bool: {
          must: [{ exists: { field: 'description' } }, {terms: {'_type_.keyword': ['company', 'companycreate']}}],
          must_not: [
            { match: { isAssessment: true } },
          ],
        },
      },
    };
    const asmq = {
      query: {
        bool: {
          must: [
            { match: { isAssessment: true } },
            { exists: { field: 'description' } },
            {terms: {'_type_.keyword': ['company', 'companycreate']}}
          ],
        },
      },
    };
    cq = this.baseService.showAssessment ? asmq : cmpq;
    if (search && search !== '') {
      cq.query.bool.must.push({
        match_bool_prefix: { name: search.toLowerCase() },
      });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({ q, skip, limit, sort }).subscribe(
      (result: any) => {
        if (result.data.length) {
          for (const c of result.data) {
            if (c._id) {
              this.companyHash[c._id] = c;
            }
          }
          result.data.sort((a: any, b: any) => {
            const c = a.name ? a.name.toLowerCase() : '';
            const d = b.name ? b.name.toLowerCase() : '';
            if (c < d) {
              return -1;
            } else if (c > d) {
              return 1;
            } else {
              return 0;
            }
          });
          if (this.currentCompany && this.currentCompany._id) {
            result.data = result.data.filter((a: any) => a._id === this.currentCompany._id);
          }
          this.companies = result.data;
          if (!search) {
            this.allComp = result.data;
          }
          this.filteredCompanies.next(result.data.slice());
          if (!this.companyCtrl.value) {
            this.companyCtrl.setValue(this.companies[0]._id);
            this.updateCurrentCompany(this.companies[0]._id);
          }
          if (this.forceSet) {
            setTimeout(() => {
              this.companyCtrl.setValue(this.companies[0]._id);
              this.updateCurrentCompany(this.companies[0]._id);
              this.forceSet = false;
            }, 1000);
          }
          this.searching = false;
          this.searchTxt = 'Search Company';
        } else {
          this.filteredCompanies.next([]);
          this.searching = false;
        }
      },
      (error) => {
        // no errors in our simulated example
        this.searching = false;
        // handle error...
      }
    );
  }

  addExisingCmpEveInteg(index: any): void {
    this.cmpMapping.notificationProfiles = (this.cmpMapping.notificationProfiles) ? this.cmpMapping.notificationProfiles : [];
    const eveList = this.cmpMapping.notificationProfiles
      .filter((a: any) => a.eventSettingsId === this.eveHash[index]);
    if (!eveList.length) {
      this.cmpMapping.notificationProfiles.push(
      {eventSettingsId: this.eveHash[index],
      eventName: this.integrationActionService.alertRuleListHash[this.eveHash[index]].name,
      integrationName: this.integrationActionService.integrationRuleListHash[this.integHash[index]].name,
      integrationSettingsId: this.integHash[index]});
      this.setDefault(index);
    } else {
      this.toast.sToast('error', 'Event set already exist');
    }
  }

  notificationEvent(event: MatSlideToggleChange, index: any): void {
    (event.checked) ? this.setDefault(index) : null;
  }

  setDefault(index: any): void {
    this.eveHash[index] = this.isDefaultAlert && this.isDefaultAlert.length ? this.isDefaultAlert[0]._id : [];
    this.integHash[index] = this.isDefaultIntegration && this.isDefaultIntegration.length ? this.isDefaultIntegration[0]._id : [];
  }

  addEveIntegToCompany(index: any): void {
    this.cmpMapping.selectedCompanies[index].notificationProfiles = (this.cmpMapping.selectedCompanies[index].notificationProfiles)
    ? this.cmpMapping.selectedCompanies[index].notificationProfiles : [];
    const eveList = this.cmpMapping.selectedCompanies[index].notificationProfiles
      .filter((a: any) => a.eventSettingsId === this.eveHash[index]);
    if (!eveList.length) {
      this.cmpMapping.selectedCompanies[index].notificationProfiles.push(
      {eventSettingsId: this.eveHash[index],
      eventName: this.integrationActionService.alertRuleListHash[this.eveHash[index]].name,
      integrationName: this.integrationActionService.integrationRuleListHash[this.integHash[index]].name,
      integrationSettingsId: this.integHash[index]});
      this.setDefault(index);
    } else {
      this.toast.sToast('error', 'Event set already exist');
    }
  }
  copyCompanyMapping(): void {
    this.loaderService.display(true, 'Update in progress...');
    this.copyEvents.companyMappingId.forEach((obj: any, index: number) => {
    const reqData = { 
      ...obj, ...{ no_notification: this.copyEvents.no_notification, notificationProfiles: this.copyEvents.notificationProfiles}
    }
    this.baseService
      .doRequest(
        `/api/integrations/companyMapping`,
        'post',
        reqData
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (index === this.copyEvents.companyMappingId.length - 1) {
          this.loaderService.display(false);
          if (result) {
            this.toast.sToast('success', 'Mapping updated successfully');
            this.snav.close();
            setTimeout(() => {
              this.getIntegrationMapping();
            }, 3000);
          } else {
            this.toast.sToast(
              'error',
              'Error updating this mapping. Contact support!'
            );
          }
        }
      });
    });
  }

  getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  isLast(key: string, obj: object): boolean {
    return key === Object.keys(obj).slice(-1)[0];
  }

  getAlertandIntegrationRules(edit?: any): void {
    if (!this.currentIntegration || !this.currentIntegration?.name) {
      setTimeout(() => {
        this.getAlertandIntegrationRules();
      }, 1000);
      return;
    }
    const alertquery = {
      query: {
        bool: {
          must: [
            { exists: { field: 'name' } },
            { exists: { field: 'default' } },
            { exists: { field: 'alertRules' } },
          ],
        },
      },
    };

    const integquery = {
      query: {
        bool: {
          must: [
            { exists: { field: 'name' } },
            { exists: { field: 'default' } },
            { exists: { field: 'integrationId' } },
            { match: { 'integrationName.keyword': this.currentIntegration.name + '' } },
          ],
        },
      },
    };
    const q = JSON.stringify(alertquery);
    const skip = 0;
    const limit = 10000;
    this.baseService
      .doRequest(`/api/alertrulessettings/`, 'get', null, { q, skip, limit })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data && result.data.length) {
          result.data?.map(
            (obj: any) =>
              (this.integrationActionService.alertRuleListHash[obj._id] =
                obj)
          );
          const isDefault = result.data?.filter((d: any) => d.default);
          this.isDefaultAlert = result.data?.filter((d: any) => d.default);
          this.alertRuleList = result.data;
          this.eveHash[0] = isDefault && isDefault.length ? isDefault[0]._id : [];
        }
      });
    const qu = JSON.stringify(integquery);
    this.baseService
      .doRequest(`/api/integrationrulessettings/`, 'get', null, {
        q: qu,
        skip,
        limit,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data && result.data.length) {
          result.data?.map(
            (obj: any) =>
              (this.integrationActionService.integrationRuleListHash[obj._id] =
                obj)
          );
          const isDefault = result.data?.filter((d: any) => d.default);
          this.isDefaultIntegration = result.data?.filter(
            (d: any) => d.default
          );
          this.intergrationRuleList = result.data;
          this.integHash[0] = isDefault && isDefault.length ? isDefault[0]._id : [];
        }
      });
  }
  closeCurrentCompany(event: any): void {
    if (!event && !this.selectedCompany) {
      this.getCompanies();
    }
  }

  removeItem(cindex: any, integrationId: any): void {
    this.cmpMapping.selectedCompanies[cindex].notificationProfiles.splice(integrationId, 1);
  }

  updateCurrentCompany(event: any): void {

    if(this.currentIntegration.name === 'CyberSesEmail'){  
    const sourceCompanies = event.map((companyId: string) => this.companyHash[companyId]);  
    this.sourceCompany = sourceCompanies;  
    if (this.isEdit) {
      this.cmpMapping.existingCompanyData = this.selectedCompanies;
    }
    this.selectedCompanies = this.selectedCompanies.map(company => company.name);
  }else{
      this.sourceCompany = this.companyHash[event];
    this.isEdit
      ? (this.cmpMapping.existingCompanyData = this.companyHash[event])
      : null;
    this.selectedCompany = this.companyHash[event].name;
  }
  }
    
  updateSelected($event: any): void {
    $event.forEach((obj: any, index: number) => {
      if ( !(this.cmpMapping.selectedCompanies.filter((x: any) => x._id === obj._id)).length) {
        const p = {eventSettingsId: '', integrationSettingsId: ''};
        obj.notificationProfiles = [];
        this.cmpMapping.selectedCompanies.push($event[index]);
      }
      this.setDefault(index);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCompanyActionParams();
  }

  showSummary(): void {
    if(this.cmpMapping.selectedCompanies.length){
      this.cmpMapping.selectedCompanies.map((x:any) => {
        if(x.no_notification && !x.notificationProfiles.length){
          this.toast.sToast(
            'error',
            `Please add event set and integration profile for the company ${x.name}`
          );
          return;
        }
      });
    }
    this.cmpMapping.selectedCompanies.map((s: any) => {
      let eventName: any = '';
      let ruleName: any = '';
      s.notificationProfiles.map((x: any) => {
        eventName = eventName ? `${eventName}, <br>${x.eventName}` : x.eventName;
        ruleName = ruleName ? `${ruleName}, <br>${x.integrationName}` : x.integrationName;
      });
      s.eventName = eventName;
      s.ruleName = ruleName;
    });
    setTimeout(() => {
      this.cmpView = 'Summary';
    }, 1000);
  }

  addToMapList(): void {
    console.log('this.cmpMapping.mapCompanyList1',this.cmpMapping.mapCompanyList)
    console.log('this.sourceCompany.name',this.sourceCompany)
    const srcCmp = this.cmpMapping.mapCompanyList.filter((x: any) => x.source.name === this.sourceCompany);
    if (srcCmp.length) {
      this.toast.sToast('error', `${srcCmp[0].source.name} company already added`);
      return;
    }
    if (this.currentIntegration.importCompanies) {
      const destCmp = this.cmpMapping.mapCompanyList.filter((x: any) => x.destination.name === this.cmpMapping.destinationCompany.name);
      if (destCmp.length) {
        this.toast.sToast('error', `${destCmp[0].destination.name} company already added`);
        return;
      }
    }
    if (!this.cmpMapping.notificationProfiles.length && this.cmpMapping.no_notification) {
      this.toast.sToast(
        'error',
        `Please add event set and integration profile`
      );
      return;
    }
    this.cmpMapping.mapCompanyList.push({
      source: Object.assign({}, this.sourceCompany),
      destination: (this.currentIntegration.importCompanies) ? Object.assign({}, this.cmpMapping.destinationCompany) : {name: '*', _id: ''},
      no_notification: this.cmpMapping.no_notification,
      configuration: this.cmpMapping.configuration,
      notificationProfiles: this.cmpMapping.notificationProfiles,
    });
    console.log('this.cmpMapping.mapCompanyList',this.cmpMapping.mapCompanyList)
    this.setDefault(0);
    this.cmpMapping.notificationProfiles = [];
    this.cmpMapping.integrationSettingsId = [];
    this.cmpMapping.no_notification = false;
    this.cmpMapping.configuration = false;
  }

 

  getCompanyActionParams(): void {
    this.loaderService.display(true, 'Getting action params...');
    this.baseService
      .doRequest(
        `/api/integrations/action_params?integrationName=${this.currentIntegration.name}&actionName=getCompanies`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.companyActionParams = result;
        this.companyFormElements = [];
        const tmpCFE: {
          label: any;
          key: any;
          required: any;
          example?: any;
          type: any;
        }[] = [];
        result?.parameters.forEach((obj: any) => {
          const frmEle: any = {
            label: obj.description,
            key: obj.name,
            required: obj.required,
            example: obj.example,
            type: obj.schema.type,
          };
          if (obj.options && obj.schema.type === 'dropdown') {
            frmEle.options = obj.options;
          }
          tmpCFE.push(frmEle);
          this.companyParams[obj.name] = obj.required ? '' : obj.example;
        });
        this.companyFormElements = tmpCFE.slice();
      });
  }

  getIntegrationCompanies(): void {
    this.loaderService.display(true, 'Getting companies...');
    const params: any = {
      integrationName: this.currentIntegration.name,
      integrationId: this.currentCredential,
      params: {
        action: {
          destination: this.companyActionParams.destination,
          verb: this.companyActionParams.verb,
          name: this.companyActionParams.name,
        },
        params: { requestparams: this.companyParams },
      },
    };
    this.baseService
      .doRequest(`/api/integrations/executeAction`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (!result.data.length) {
          this.toast.sToast(
            'info',
            'No companies found! Please change the search key and try.'
          );
          return;
        }
        if (result.status === 'failed') {
          this.companyList = [];
          this.toast.sToast('error', result.data);
          return;
        }
        result.data?.map((d: any) => { d._id = d._id ? d._id : d.id});
        const sortKey = this.companyActionParams.DisplayFields[0];
        this.selectedCompanyList = Object.assign(
          [],
          this.cmpMapping.selectedCompanies
        );
        this.companyList = result.data.sort((a: any, b: any) => {
          const c = a[sortKey] ? a[sortKey].toLowerCase() : a[sortKey];
          const d = b[sortKey] ? b[sortKey].toLowerCase() : b[sortKey];
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
      });
  }

  /*Integration Mapping Table*/
  integmappingshowHideLoading(status: any): void {
    const data = Object.assign({}, this.inteTableOptions);
    this.inteTableOptions = {};
    this.inteTableOptions = data;
    this.inteTableOptions.tableOptions.loading = status;
  }

  intesortCall(idata: any): void {
    this.inteTableOptions.sortOptions = idata;
    this.getIntegrationMapping();
  }

  integmappingeditFn(idata: any): void {
    console.log(idata);
    this.getAlertandIntegrationRules(true);
    this.currentCompanyMapping = idata;
    this.cmpMapping = {
      existingCompanyName: idata.sourceCompanyName,
      companyName: idata.destCompanyName,
      notificationProfiles: idata.notificationProfiles,
      no_notification: !idata.no_notification,
      configuration: idata.configuration,
    };
    this.snav.open();
  }

  updateCompanyMapping(): void {
    const reqData = {
      ...this.currentCompanyMapping,
      ...this.cmpMapping,
    };
    if ((!reqData.notificationProfiles || !reqData.notificationProfiles.length) && reqData.no_notification) {
      this.toast.sToast(
        'error',
        `Please add event set and integration profile`
      );
      return;
    }
    reqData.no_notification = !reqData.no_notification;
    this.loaderService.display(true, 'Update in progress...');
    this.baseService
      .doRequest(
        `/api/integrations/companyMapping`,
        'post',
        reqData
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          this.toast.sToast('success', 'Mapping updated successfully');
          this.snav.close();
          setTimeout(() => {
            this.getIntegrationMapping();
          }, 3000);
        } else {
          this.toast.sToast(
            'error',
            'Error updating this mapping. Contact support!'
          );
        }
      });
  }

  integmappingdeleteFn(idata: any): void {
    const titleName = 'Delete Mapping';
    const message =
      'Are you sure you want to delete the mapping of ' +
      idata.sourceCompanyName +
      ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.loaderService.display(true, 'Deleting in progress...');
        this.baseService
          .doRequest(`/api/integrations/companyMapping/${idata._id}`, 'delete')
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result) {
              this.toast.sToast('success', 'Mapping deleted successfully');
              setTimeout(() => {
                this.getIntegrationMapping();
              }, 3000);
            } else {
              this.toast.sToast(
                'error',
                'Error deleting this mapping. Contact support!'
              );
            }
          });
      }
    });
  }

  integmappingactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      this.loaderService.display(true);
      const dataRow = idata.row;
      this.eView = 'edit';
      setTimeout(() => {
        this.integmappingeditFn(dataRow);
        this.loaderService.display(false);
      }, 1000);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.integmappingdeleteFn(dataRow);
    }
    if (idata.action.text === 'Copy Settings') {
      const dataRow = idata.row;
      this.eView = 'copy'
      this.copyEvents = {
        _id: dataRow._id,
        eventSet: dataRow.eventSet,
        integrationProfile: dataRow.integrationProfile,
        no_notification: dataRow.no_notification,
        notificationProfiles: dataRow.notificationProfiles,
        companyMappingId: []
      }
      this.getAllCompanyMapping(dataRow._id)
    }
  }

  getAllCompanyMapping(id: any): void {
    this.loaderService.display(true);
    const query: any = {
      query: {
        bool: {
          must: [
            {
              match: {
                'integrationName.keyword': this.currentIntegration.name + '',
              },
            },
            { match: { 'credentialId.keyword': this.currentCredential + '' } },
            { exists: { field: 'sourceCompanyId'}}
          ],
        },
      },
    };
    const q = JSON.stringify(query);
    const skip = 0;
    const limit = 1000;
    this.baseService
      .doRequest(`/api/integrations/companyMapping`, 'get', null, {
        q,
        skip,
        limit,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.copyCompanyMappingList = result.data.filter((x: any) => x._id !== id);
        if(!this.copyCompanyMappingList || !this.copyCompanyMappingList.length){
          this.toast.sToast('error', 'No other data to copy!');
          return;
        } else {
          this.snav.open();
        }
      });
  }
  
  integmappingfilterCall(idata: any): void {
    const fields: any = [];
    this.inteTableOptions.columns.forEach((obj: any) => {
      if (
        obj.columnDef !== 'c' &&
        obj.columnDef !== 'u' &&
        obj.columnDef !== 'configuration' &&
        obj.columnDef !== 'no_notification'
      ) {
        fields.push(obj.columnDef);
      }
    });
    this.integmappingfilterQuery =
      idata && idata.length > 0
        ? {
            multi_match: {
              query: idata,
              type: 'phrase_prefix',
              fields,
            },
          }
        : undefined;
    this.getIntegrationMapping();
  }

  integmappingpageCall(event: any): void {
    this.inteTableOptions.tableOptions.pageSize = event.pageSize;
    this.integmappingcurrentPage = event.pageIndex;
    this.getIntegrationMapping();
  }

  integmappingaddTableData(): void {
    this.cmpMapping = { selectedCompanies: [], mapCompanyList: [], notificationProfiles: [] };
    this.getCompanies();
    this.getAlertandIntegrationRules();
    this.addEditIntegration = true;
    this.companyMappingList = [];
    this.failedMappingList = [];
    this.isEdit = false;
    if (!this.currentIntegration.importCompanies) {
      this.cmpMapping.selectedType = 'Map Existing Companies';
      this.cmpView = this.cmpMapping.selectedType;
    } else {
      this.cmpView = 'Type';
    }
  }

  integmappingtimerCallData(): void {
    this.getIntegrationMapping();
  }

  updateSelectedCompanies(): void {
    this.loaderService.display(false);
    this.currentCompanyMapping.sourceCompanyName =
      this.cmpMapping?.existingCompanyData?.name;
    this.currentCompanyMapping.sourceCompanyId =
      this.cmpMapping?.existingCompanyData?._id;
    this.currentCompanyMapping.destCompanyName =
      this.cmpMapping?.destinationCompany?.name;
    this.currentCompanyMapping.destCompanyId =
      (this.cmpMapping?.destinationCompany?._id) ? this.cmpMapping?.destinationCompany?._id : this.cmpMapping?.destinationCompany?.id;
    this.currentCompanyMapping.eventSettingsId =
      this.cmpMapping?.eventSettingsId?._id;
    this.currentCompanyMapping.integrationSettingsId =
      this.cmpMapping?.integrationSettingsId?._id;

    this.currentCompanyMapping.destCompanyId = (this.currentCompanyMapping.destCompanyId) ? this.currentCompanyMapping.destCompanyId : '*'
    this.baseService
      .doRequest(
        `/api/integrations/companyMapping`,
        'put',
        this.currentCompanyMapping
      )
      .subscribe((result: any) => {
        if (result) {
          this.toast.sToast('success', `Updated Successfully!.`);
          this.addEditIntegration = false;
          this.isLoading = false;
          this.loaderService.display(false);
          this.isEdit = false;
          this.cmpMapping = { selectedCompanies: [], mapCompanyList: [] };
          setTimeout(() => this.getIntegrationMapping(), 2000);
        }
      });
  }

  getIntegrationMapping(isInit?: boolean): void {
    this.integmappingshowHideLoading(true);
    this.inteTableOptions.serverSide = {
      service: 'baseService',
      fn: 'doRequest',
      q: {
        query: {
          bool: {
            must: [
              {
                match: {
                  'integrationName.keyword': this.currentIntegration.name + '',
                },
              },
              {
                match: { 'credentialId.keyword': this.currentCredential + '' },
              },
              {"exists": {"field": "sourceCompanyId"}}
            ],
          },
        },
      },
    };
    const query = {
      query: {
        bool: {
          must: [
            {
              match: {
                'integrationName.keyword': this.currentIntegration.name + '',
              },
            },
            { match: { 'credentialId.keyword': this.currentCredential + '' } },
            {"exists": {"field": "sourceCompanyId"}}
          ],
        },
      },
    };
    if (
      this.integmappingfilterQuery &&
      this.integmappingfilterQuery.multi_match
    ) {
      query.query.bool.must.push(this.integmappingfilterQuery);
    }
    const q = JSON.stringify(query);
    const skip = this.integmappingcurrentPage;
    const limit = this.inteTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (
      this.inteTableOptions.sortOptions &&
      this.inteTableOptions.sortOptions.direction &&
      this.inteTableOptions.sortOptions.direction !== ''
    ) {
      const orderArr = ['sourceCompanyName', 'destCompanyName'];
      if (orderArr.indexOf(this.inteTableOptions.sortOptions.active) > -1) {
        sort[0][this.inteTableOptions.sortOptions.active + '.keyword'] = {
          order: this.inteTableOptions.sortOptions.direction,
        };
      } else {
        sort[0][this.inteTableOptions.sortOptions.active] = {
          order: this.inteTableOptions.sortOptions.direction,
        };
      }
    }
    const isCmp = this.inteTableOptions.columns.filter(
      (x: any) => x.header === 'Enable Configuration'
    );
    if (
      this.configurationIntegration.indexOf(this.currentIntegration?.name) >
        -1 &&
      (!isCmp || !isCmp.length)
    ) {
      this.inteTableOptions.columns.push({
        header: 'Enable Configuration',
        columnDef: 'configuration',
        filter: '',
        cell: '(element: any) => `${element.configuration}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        width: '50px',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        selectFilter: true,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: true,
        statusicon: true,
        success: true,
        failure: false,
        successIconPath: '/assets/images/auditLogins/tick.svg',
        successToolTip: 'Yes',
        failureIconPath: '/assets/images/auditLogins/cross.svg',
        failureToolTip: 'No',
      });
    }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify([
      'c',
      'u',
      '_id',
      '_type_',
      'credentialName',
      'credentialId',
      'destCompanyId',
      'destCompanyName',
      'integrationName',
      'sourceCompanyId',
      'sourceCompanyName',
      'notificationProfiles',
      'no_notification',
      'configuration',
    ]);
    this.baseService
      .doRequest(`/api/integrations/companyMapping`, 'get', null, {
        q,
        skip,
        limit,
        sort,
      })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        result.data.map((s: any) => {
          !s.configuration || s.configuration === undefined
            ? (s.configuration = false)
            : null;
          s.eventSet = '';
          s.integrationProfile = '';
          (s.notificationProfiles && s.notificationProfiles.length) ? s.notificationProfiles?.map((x: any, i: any) => {
            s.eventSet = this.integrationActionService.alertRuleListHash[x.eventSettingsId] ? (s.eventSet)
              ? `${s.eventSet}, ${this.integrationActionService.alertRuleListHash[x.eventSettingsId]?.name}`
              : this.integrationActionService.alertRuleListHash[x.eventSettingsId]?.name : null;
            s.integrationProfile = this.integrationActionService.integrationRuleListHash[s.notificationProfiles[i].integrationSettingsId] ? (s.integrationProfile)
              ? `${s.integrationProfile}, ${this.integrationActionService.integrationRuleListHash[s.notificationProfiles[i].integrationSettingsId]?.name}`
              : this.integrationActionService.integrationRuleListHash[s.notificationProfiles[i].integrationSettingsId]?.name : null;
          }) : null;
        });
        this.inteTableOptions.pageData = result.data;
        this.inteTableOptions.tableOptions.pageTotal = result.total;
        this.integmappingshowHideLoading(false);
        if (isInit) {
          this.addEditIntegration = !result.total;
        }
        this.addEditIntegration = this.onboarding;
      });
    this.companyList = [];
    this.filteredCompanies.next([]);
    this.companies = [];
  }

  mapSelectedCompanies(): void {
    this.isLoading = true;
    this.loaderService.display(true, 'Mapping in progress...');
    console.log(this.cmpMapping.mapCompanyList);

    // const mappedCompanies: string[] = [];

    if(this.currentIntegration.name === 'CyberSesEmail'){
      this.cmpMapping.mapCompanyList.forEach((obj: any) => {
        const selectedCred = this.integrationsData.filter(
          (x: any) => x._id === this.currentCredential
        );
        Object.keys(obj.source).forEach((key: string) => {
          const source = obj.source[key];
  
        const params: any = {
          integrationName: this.currentIntegration.name,
          credentialName: selectedCred[0].name,
          credentialId: this.currentCredential,
          sourceCompanyName: source.name,
          sourceCompanyId: source._id,
          destCompanyName: (this.currentIntegration.importCompanies)
            ? obj.destination[this.companyActionParams.DisplayFields[0]] : obj.destination.name,
           destCompanyId: (this.currentIntegration.importCompanies)
           ? (obj.destination._id) ? obj.destination._id + '' : obj.destination.id + '' : (obj.destination._id) ? obj.destination._id + '' : obj.destination.id + '',
          configuration: obj.configuration,
          notificationProfiles: obj.notificationProfiles,
          no_notification: !obj.no_notification,
        };
        params.destCompanyId = (params.destCompanyId) ? params.destCompanyId : '*';
        this.baseService
          .doRequest(`/api/integrations/companyMapping`, 'post', params)
          .subscribe(() => {
            this.companyMappingList.push(source.name);
            // mappedCompanies.push(source.name); 
            this.toast.sToast('success', `successfully mapped.`);
            this.redirectMC();
          });
      });
      
    });

    }else{
      this.cmpMapping.mapCompanyList.forEach((obj: any) => {
        const selectedCred = this.integrationsData.filter(
          (x: any) => x._id === this.currentCredential
        );
        const params: any = {
          integrationName: this.currentIntegration.name,
          credentialName: selectedCred[0].name,
          credentialId: this.currentCredential,
          sourceCompanyName: obj.source.name,
          sourceCompanyId: obj.source._id,
          destCompanyName: (this.currentIntegration.importCompanies)
            ? obj.destination[this.companyActionParams.DisplayFields[0]] : obj.destination.name,
           destCompanyId: (this.currentIntegration.importCompanies)
           ? (obj.destination._id) ? obj.destination._id + '' : obj.destination.id + '' : (obj.destination._id) ? obj.destination._id + '' : obj.destination.id + '',
          configuration: obj.configuration,
          notificationProfiles: obj.notificationProfiles,
          no_notification: !obj.no_notification,
        };
        params.destCompanyId = (params.destCompanyId) ? params.destCompanyId : '*';
        this.baseService
          .doRequest(`/api/integrations/companyMapping`, 'post', params)
          .subscribe(() => {
            this.companyMappingList.push(obj.source.name);
            this.toast.sToast('success', `${obj.source.name} mapped.`);
            this.redirectMC();
          });
      });
    }  
  }
  importSelectedCompanies(): void {
    this.baseService.companyList = [];
    this.companyMappingList = [];
    this.failedMappingList = [];
    this.cmpMapping.selectedCompanies.forEach((obj: any) => {
      this.createCompany(obj);
    });
  }

  createCompany(obj: any): void {
    console.log(obj);
    this.loaderService.display(true, `Importing ${obj.name}`);
    const newCompany = {
      name: obj.name,
      description: `Import from ${this.currentIntegration.title}`,
      source: 2,
      source_id: obj.id,
      tags: [`${this.currentIntegration.title}`],
    };
    this.companyService
      .createApiCompanyPost({ body: newCompany })
      .subscribe((result: any) => {
        if (result._id === 'Company Exists With Same Name') {
          this.loaderService.display(false);
          this.failedMappingList.push(obj.name);
          this.toast.sToast(
            'error',
            `${obj.name} company exist with the same name.`
          );
        } else {
          this.baseService.companyList.push(result);
          const selectedCred = this.integrationsData.filter(
            (x: any) => x._id === this.currentCredential
          );
          const params: any = {
            integrationName: this.currentIntegration.name,
            credentialName: selectedCred[0].name,
            credentialId: this.currentCredential,
            sourceCompanyName: obj.name,
            sourceCompanyId: result._id,
            destCompanyName: obj[this.companyActionParams.DisplayFields[0]],
            destCompanyId: (obj._id) ? obj._id + '' : obj.id + '',
            configuration: obj.configuration ? true : false,
            no_notification: !obj.no_notification,
            notificationProfiles: obj.notificationProfiles,
          };
          params.destCompanyId = (params.destCompanyId) ? params.destCompanyId : '*'
          this.baseService
            .doRequest(`/api/integrations/companyMapping`, 'post', params)
            .subscribe(() => {
              this.companyMappingList.push(obj.name);
              this.toast.sToast('success', `${obj.name} mapped.`);
              this.redirectIM();
            });
        }
      });
  }

  redirectMC(): void {
    if (
      this.cmpMapping.mapCompanyList.length === this.companyMappingList.length
    ) {
      this.addEditIntegration = false;
      this.isLoading = false;
      this.loaderService.display(false);
      setTimeout(() => this.getIntegrationMapping(), 2000);
    }
  }

  redirectIM(): void {
    if (
      this.cmpMapping.selectedCompanies.length ===
      this.companyMappingList.length + this.failedMappingList.length
    ) {
      this.addEditIntegration = false;
      setTimeout(() => {
        if (this.onboarding) {
          this.disableCreate = true;
        }
        this.getIntegrationMapping();
        this.updateCompanyList.emit({});
        this.cs.setCurrentCompany('');
      }, 2000);
    }
  }

  /*Integration Mapping Table*/
  removeSelected(i: number): void {
    this.selectedCompanyList = this.selectedCompanyList.filter((obj: any) => obj.id !== this.cmpMapping.selectedCompanies[i].id);
    this.cmpMapping.selectedCompanies.splice(i, 1);
  }
}
