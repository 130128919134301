<app-s-table aria-label="attackSurfaceDomain" role="table" [sTableOptions]="attackSurfaceDojmainTableOptions"
    (sortCallback)="attackSurfaceDomainsortCall($event)" (filterCallback)="attackSurfaceDomainfilterCall($event)"
    (actionCallback)="attackSurfaceDomainactionCall($event)" (pageCallback)="attackSurfaceDomainpageCall($event)"
    (colFilterCallback)="attackSurfaceDomaincolFilterCall($event)" (refreshCallback)="getAttackSurfaceDomains()"
    (timerCallback)="attackSurfaceDomaintimerCallData($event)" (addCallback)="addTableData()"
    (globalActionCallback)="attackSurfaceDomainglobalActionCall($event)">
</app-s-table>
<s-modal id="addAttackDomain">
    <form #addDomain="ngForm">
        <mat-card class="d-flex flex-row is-align-items-flex-start py-0">
            <div class="d-flex flex-row align-items-center list-right list-right">
                <div class="col-lg-12 text-center">
                    <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Domain" width="200px" />
                    <p class="mat-body-1">
                        Attack Surface Mapper Domain
                    </p>
                </div>
            </div>
            <mat-card class="modal mat-elevation-z0 pr-0 w-100" [style.width.px]="addDomainWidth">
                <button class="position-absolute r-1p" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                    type="button" (click)="modalService.close('addAttackDomain')">
                    <mat-icon>close</mat-icon>
                </button>


                <div class="mat-title">Attack Surface Mapper</div>
                <mat-card-content class="fcard-content">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput id="IPRangesName" placeholder="Enter name" type="text" trim="blur" required
                            name="name" [(ngModel)]="domainCofig.name" autocomplete="off" />
                        <mat-hint>Ex. Set 1</mat-hint>
                    </mat-form-field>

                    <mat-form-field class="w-100" appearance="outline">
                        <!-- <mat-label>Domain Name/IP</mat-label> -->
                        <input matInput placeholder="Enter Domain Name" type="text" trim="blur" required name="start_ip"
                            [(ngModel)]="domainCofig.domain" autocomplete="off" />
                    </mat-form-field>
                </mat-card-content>


                <mat-card-content class="fcard-content">
                    <div class="d-flex flex-column">
                        <mat-checkbox class="mr20" id="domainScanLater" name="domainscanLater" color="primary"
                            *ngIf="editmode" [(ngModel)]="domainCofig.scanlater">Scan Later</mat-checkbox>
                    </div>
                </mat-card-content>


                <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 mr-4 toolbar-top">
                    <button mat-raised-button class="mat-primary mr-2" *ngIf="!domainCofig._id"
                        (click)="saveDomainDetails()" type="button" [disabled]="!addDomain.form.valid" id="savedomain">
                        Save
                    </button>
                    <button mat-raised-button class="mat-primary mr-2" *ngIf="domainCofig._id"
                        (click)="updateIprange(domainCofig)" type="button" [disabled]="!addDomain.form.valid"
                        id="updateip">
                        Update
                    </button>
                    <button mat-stroked-button class="mat-warn" type="button" (click)="cleardata()" id="cancelip">
                        Cancel
                    </button>
                </mat-card-footer>
            </mat-card>
        </mat-card>
    </form>
</s-modal>