import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IntegrationsService} from '../../../api/services/integrations.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {LoaderService} from '../../../_services/loader.service';
import {IntegrationMappingService} from '../../../api/services/integration-mapping.service';
import {CompanyService} from '../../../api/services/company.service';
import {BaseRequestService} from '../../../_services/base.service';
import {MatSelect} from '@angular/material/select';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {MatMenuTrigger} from '@angular/material/menu';
import {AuthenticationService} from '../../../_services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { v4 as uuidv4 } from 'uuid';
import { CommonService } from 'src/app/_services/common.services';

@Component({
  selector: 'app-nonpsa-company-mapping',
  templateUrl: './nonpsa-company-mapping.component.html',
  styleUrls: ['./nonpsa-company-mapping.component.scss']
})
export class NonpsaCompanyMappingComponent implements OnInit, OnChanges {
  @ViewChild('companySelect', {static: true}) companySelect!: MatSelect;
  @ViewChild('menuRefMenuTrigger', {static: false}) menuRefMenuTrigger: MatMenuTrigger;

  @Input() currentIntegration: any;
  @Input() integrationsData: any;
  @Input() onboarding: any;
  @Input() currentCompany: any;
  @Input() mode: any;
  @Output() updateCompanyList = new EventEmitter();
  @ViewChild('snav', { static: true }) snav: MatSidenav;
  currentIntegrationMapping: any = {};
  updateData: any = {
    eventSettingsId: '',
    integrationSettingsId: '',
  };
  notes:any = [];
  disableCreate = false;
  currentCredential: any;
  companyActionParams: any;
  companyParams: any = {};
  companyFormElements: any = [];
  companyList: any = [];
  showSelect = true;
  /*Integration Mapping table*/
  cmpView = 'Type';
  cmpMapping: any = {selectedCompanies: [], mapCompanyList: []};
  companyMappingList: any = [];
  failedMappingList: any = [];
  addEditIntegration = false;
  inteTableOptions: any;
  integmappingcurrentPage = 0;
  integmappingfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  Objectkeys = Object.keys;
  isEdit = false;
  currentCompanyMapping: any = {};


  siteActionParams: any;
  siteParams: any = {};
  siteFormElements: any = [];
  siteList: any = [];
  selectedSiteList: any = [];

  forceSet = false;
  selectedSourceCompany: any = '';
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  selectedCompany: any;
  selectedCompanyList: any = [];
  company: any; companies: any; allComp: any; companyHash: any = {}; sourceCompany: any;
  searchTxt: any = 'Search Company';
  protected onDestroySearch = new Subject<void>();
  isConstentProvided = false;
  getCount = 0;
  importProvideConsent: any = {};
  selectedImportContent: any;
  /*Integration Mapping table*/
  constructor(public commonService: CommonService, private toast: MyToastrService,
              private companyService: CompanyService, private baseService: BaseRequestService,
              private aS: AuthenticationService,
              private confirmDialog: ConfirmDialogService, private integrationMappingService: IntegrationMappingService,
              public loaderService: LoaderService) {
    this.inteTableOptions = {
      columns: [{
        header: 'Existing Company Name',
        columnDef: 'sourceCompanyName',
        filter: '',
        cell: '(element: any) => `${element.sourceCompanyName}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }, {
        header: `Company Name`,
        columnDef: 'destCompanyName',
        filter: '',
        cell: '(element: any) => `${element.destCompanyName}`',
        order: 3,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      },{
        header: 'Mapped Date',
        columnDef: 'c',
        filter: 'utcToLocale',
        cell: '(element: any) => `${element.c}`',
        order: 4,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: false,
        iscolumnSearch: false
      }],
      sortOptions: {active: 'u', direction: 'asc'},
      faClass: 'Company',
      _pageData: [],
      tableOptions: {
        title: 'Mapping Info',
        id: 'inteTableOptions',
        isServerSide: true,
        selectText: 'integrationMapping',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false}],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        add: true,
        columnSearch: true,
        compareData: false
      }
    };
  }

  ngOnInit(): void {

    if((this.aS.hasPermission('integrations', 'companymapping') && this.currentIntegration?.name !== 'AzureAD')){
      this.inteTableOptions.tableOptions.add = true;
    } else {
      this.inteTableOptions.tableOptions.add = false;
    }
    if(this.currentIntegration.importCompanies){
      this.notes = [
        "1. Currently showing 25 list of companies. If you can't find your company in this list. Please use the search option to filter.",
        "2. Click on Add and then Finish to map all the selected companies."
      ]
      if(this.currentIntegration.title ==='Azure AD'){
        this.notes.push("3. If a company has already integrated Azure AD Legacy with any tenant, then when you integrate the same company with Azure AD, the old integration mapping for this company is removed")
      }
    } else {
      this.notes = [
        "Click on Add and then Finish to map all the selected companies."
      ]
    }
    this.currentCredential = this.integrationsData[0]._id;
    this.getCompanyActionParams();
    this.getSiteActionParams();
    this.inteTableOptions.pageData = [];
    this.getIntegrationMapping(true);
    this.companyFilterCtrl.valueChanges.pipe(debounceTime(300),
      takeUntil(this.onDestroySearch)).subscribe(() => {
      this.filterCompanies();
    });
    this.getCompanies();
  }

  private filterCompanies(): void {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  getCompanies(search?: string): void {
    let cq: any;
    const cmpq = {query: {bool: {must: [{exists: {field: 'description'}}, {terms: {'_type_.keyword': ['company', 'companycreate']}}], must_not: [{match: {isAssessment: true}}]}}};
    const asmq = {query: {bool: {must: [{match: {isAssessment: true}}, {exists: {field: 'description'}}, {terms: {'_type_.keyword': ['company', 'companycreate']}}]}}};
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    if (search && search !== '') {
      cq.query.bool.must.push({ match_bool_prefix: { name: search.toLowerCase() } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({q, skip, limit, sort}).subscribe((result: any) => {
      if (result.data.length) {
        for (const c of result.data) { if (c._id) { this.companyHash[c._id] = c; } }
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : ''; const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
        if (this.currentCompany && this.currentCompany._id) {
          result.data = result.data.filter((a: any) => a._id === this.currentCompany._id);
        }
        this.companies = result.data; if (!search) { this.allComp = result.data; }
        this.filteredCompanies.next(result.data.slice());
        if (!this.companyCtrl.value) {
          this.companyCtrl.setValue(this.companies[0]._id);
          this.updateCurrentCompany(this.companies[0]._id);
        }
        if (this.forceSet) {
          setTimeout(() => {
            this.companyCtrl.setValue(this.companies[0]._id);
            this.updateCurrentCompany(this.companies[0]._id);
            this.forceSet = false;
          }, 1000);
        }
        this.searching = false;
        this.searchTxt = 'Search Company';
        //meraki company mapping
        if(this.currentIntegration?.name === 'Meraki' && this.mode === 'company'){
          this.selectedSourceCompany = this.currentCompany?.name;
          this.updateCurrentCompany(this.currentCompany?._id);
          this.showSelect = false;
        } else {
          this.showSelect = true;
        }
      } else {
        this.filteredCompanies.next([]);
        this.searching = false;
      }
    }, error => {
        // no errors in our simulated example
        this.searching = false;
        // handle error...
      });
  }

  closeCurrentCompany(event: any): void {
    if (!event && !this.selectedCompany) {
      this.getCompanies();
    }
  }

  updateCurrentCompany(event: any): void {
    this.sourceCompany = this.companyHash[event];
    this.isEdit
      ? (this.cmpMapping.existingCompanyData = this.companyHash[event])
      : null;
    this.selectedCompany = this.companyHash[event].name;
  }
  provideConsentImport(cmp:any): void {
    const rToken = uuidv4();
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${this.currentCredential}`
    ).replace(/=/g, '');
    const applicationId = this.integrationsData.filter((x:any) => x._id === this.currentCredential);
    const destId = (cmp._id) ? cmp._id + '' : cmp.id + '';
    const baseURL = (applicationId[0] && applicationId[0].auth_endpoint) ? applicationId[0].auth_endpoint : 'https://login.microsoftonline.com';
    let url: any = `${baseURL}/${destId}/v2.0/adminconsent?client_id=${applicationId[0].client_id}&response_mode=query&response_type=code&redirect_uri=https://authccns.mycybercns.com&nonce={{nonce}}&scope=https://api.partnercenter.microsoft.com/.default`;
    url = url.replace("{{nonce}}", rToken);
    const urlCSP = `${url}&state=${stateVar}`;
    localStorage.removeItem('oAuthC');
    localStorage.setItem('ncsp', JSON.stringify({}));
    this.baseService.o365window = window.open(
      urlCSP,
      'popup',
      'width=600,height=600'
    )
    this.selectedImportContent = cmp.name;
    this.importProvideConsent[cmp.name] = false;
    this.checkIsOAuthCodeCSPImport();
  }
  provideConsent(destCompanyId?:any, copyLink?: boolean): void {
    const rToken = uuidv4();
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${this.currentCredential}`
    ).replace(/=/g, '');
    const applicationId = this.integrationsData.filter((x:any) => x._id === this.currentCredential);
    const destId = (destCompanyId) ? destCompanyId : (this.cmpMapping.destinationCompany._id) ? this.cmpMapping.destinationCompany._id + '' : this.cmpMapping.destinationCompany.id + '';
    const baseURL = (applicationId[0] && applicationId[0].auth_endpoint) ? applicationId[0].auth_endpoint : 'https://login.microsoftonline.com';
    let url: any = `${baseURL}/${destId}/v2.0/adminconsent?client_id=${applicationId[0].client_id}&response_mode=query&response_type=code&redirect_uri=https://authccns.mycybercns.com&nonce={{nonce}}&scope=https://api.partnercenter.microsoft.com/.default`;
    url = url.replace("{{nonce}}", rToken);
    const urlCSP = `${url}&state=${stateVar}`;
    if(copyLink){
      this.commonService.copyClip(urlCSP);
    } else {
      localStorage.removeItem('oAuthC');
      localStorage.setItem('ncsp', JSON.stringify({}));
      this.baseService.o365window = window.open(
        urlCSP,
        'popup',
        'width=600,height=600'
      )
      this.isConstentProvided = false;
      this.importProvideConsent[destCompanyId] = false;
      this.checkIsOAuthCodeCSP(destCompanyId);
    }
  }

  checkIsOAuthCodeCSPImport(): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD CSP config');
      }
      this.importProvideConsent[this.selectedImportContent] = true;
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => {
      this.getCount = this.getCount +1;
      this.checkIsOAuthCodeCSPImport();
    }, 1000);
  }
  checkIsOAuthCodeCSP(destCompanyId?:any, type?:any): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD CSP config');
      }
      this.importProvideConsent[destCompanyId] = true;
      (destCompanyId && this.isEdit) ? this.updateCompanyMapping() : null;
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => {
      this.getCount = this.getCount +1;
      this.checkIsOAuthCodeCSP();
    }, 1000);
  }

  getRefresh(): any {
    if(this.currentIntegration.name !== 'AzureADCSP'){
      return false;
    }
    const data = this.integrationsData.filter((x:any) => x._id === this.currentCredential);
    return (data && data.length && data[0].token_expired) ? true : false;
  }
  oAuthCredValidationCSPReauth(): void {
    const event = this.integrationsData.filter((x:any) => x._id === this.currentCredential)[0];
    const rToken = uuidv4();
    let url = this.currentIntegration.token_url;
    url = url.replace("{{tenant_id}}", event.tenant_id);
    url = url.replace("{{csp_client_id}}", event.csp_client_id);
    url = url.replace("{{nonce}}", rToken);
    const stateVar = btoa(
      `${window.location.host}@${rToken}@${event.client_id}`
    ).replace(/=/g, '');
    const urlCSP = `${url}&state=${stateVar}`;
    localStorage.removeItem('oAuthC');
    localStorage.setItem('ncsp', JSON.stringify(event));
    this.baseService.o365window = window.open(
      urlCSP,
      'popup',
      'width=600,height=600'
    );
    this.checkIsOAuthCodeCSPReauth();
  }

  checkIsOAuthCodeCSPReauth(): void {
    const oAuthC = localStorage.getItem('oAuthC');
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD CSP config');
      }
      const cred: any = { credential: { ...ncsp } };
      cred.credential.code = oAuthC;
      const data: any = { ...cred };
      data.integrationName = this.currentIntegration.name;
      this.saveAADCSP(data);
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => {
      this.checkIsOAuthCodeCSPReauth();
    }, 1000);
  }

  saveAADCSP(event: any): void {
    let data: any = this.integrationsData.filter((x:any) => x._id === this.currentCredential)[0];
    data.integrationName =  `${this.currentIntegration.name}`;
    data.credential = event.credential;
    data.refresh  =  true;
    data.code = data.credential.code;
    this.loaderService.display(true, 'Saving credential...');
    this.baseService
      .doRequest(`/api/integrations/storecredentials`, 'post', data)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Successfully updated');
          this.getIntegrationMapping(true);
        } else {
          this.toast.sToast('error', result.errmsg);
        }
      });
  }
  updateCompanyMapping(): void {
    const reqData = {
      ...this.cmpMapping,
    };
    (this.currentIntegration.name === 'AzureADCSP') ?  reqData.consent_enabled = this.importProvideConsent[this.cmpMapping.destCompanyId] : null;
    this.loaderService.display(true, 'Update in progress...');
    this.baseService
      .doRequest(
        `/api/integrations/companyMapping`,
        'post',
        reqData
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          this.toast.sToast('success', 'Mapping updated successfully');
          this.snav.close();
          setTimeout(() => {
            this.getIntegrationMapping();
          }, 3000);
        } else {
          this.toast.sToast(
            'error',
            'Error updating this mapping. Contact support!'
          );
        }
      });
  }

  // updateSelected($event: any): void {
  //   $event.forEach((obj: any, index: number) => {
  //     if ( !(this.cmpMapping.selectedCompanies.filter((x: any) => x._id === obj._id)).length) {
  //       this.cmpMapping.selectedCompanies.push($event[index]);
  //     }
  //   });
  // }

  updateSelected($event: any): void {
    // Reflect select deselect
    const newSelectedIds = $event.map((obj: any) => obj.id);
    for (let i = this.cmpMapping.selectedCompanies.length - 1; i >= 0; i--) {
      const company = this.cmpMapping.selectedCompanies[i];
      if (!newSelectedIds.includes(company.id)) {
        this.cmpMapping.selectedCompanies.splice(i, 1);
        this.removeSelected(i);
      }
    }
    $event.forEach((obj: any, index: number) => {
      if (!this.cmpMapping.selectedCompanies.some((x: any) => x.id === obj.id)) {
        const p = { eventSettingsId: '', integrationSettingsId: '' };
        obj.notificationProfiles = [];
        this.cmpMapping.selectedCompanies.push($event[index]);
        if (this.currentIntegration.importSites) {
          obj.site = {};
          obj.siteList = [];
          obj.siteFormElements = this.siteFormElements;
          obj.siteActionParams = this.siteActionParams;
          obj.siteParams = { ...this.siteParams, companyId: obj.id };
          this.getImportIntegrationSites($event[index], index);
        }
        console.log('cmpMapping.selectedCompanies',this.cmpMapping.selectedCompanies)
        // this.setDefault(index);
      }
    });
  }

  // setDefault(index: any): void {
  //   this.eveHash[index] = this.isDefaultAlert && this.isDefaultAlert.length ? this.isDefaultAlert[0]._id : [];
  //   this.integHash[index] = this.isDefaultIntegration && this.isDefaultIntegration.length ? this.isDefaultIntegration[0]._id : [];
  // }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCompanyActionParams();
  }

  addToMapList(): void {
    if(this.currentIntegration.name === 'Cloudradial'){
      if (!this.sourceCompany || !this.cmpMapping.destinationCompany || !this.cmpMapping.site) {
        this.toast.sToast('error', 'Please complete all required fields.');
        return;
      }
    }

    const srcCmp = this.cmpMapping.mapCompanyList.filter((x: any) => x.source.name === this.sourceCompany.name);
    if (srcCmp.length) {
      this.toast.sToast('error', `${srcCmp[0].source.name} company already added`);
      return;
    }
    if (this.currentIntegration.importCompanies) {
      const destCmp = this.cmpMapping.mapCompanyList.filter((x: any) => x.destination.name === this.cmpMapping.destinationCompany.name);
      if (destCmp.length) {
        this.toast.sToast('error', `${destCmp[0].destination.name} company already added`);
        return;
      }
    }

    this.cmpMapping.mapCompanyList.push({
      source: Object.assign({}, this.sourceCompany),
      destination: (this.currentIntegration.importCompanies) ? Object.assign({}, this.cmpMapping.destinationCompany) : {name: '*', _id: ''},
      archive:(this.currentIntegration.importSites) ? Object.assign({}, this.cmpMapping.site) : {name: '*', _id: ''}
    });
  }

  getCompanyActionParams(): void {
    this.loaderService.display(true, 'Getting action params...');
    this.baseService.doRequest(
      `/api/integrations/action_params?integrationName=${this.currentIntegration.name}&actionName=getCompanies`, 'get')
      .subscribe((result: any) => {
      this.loaderService.display(false);
      this.companyActionParams = result;
      this.companyFormElements = [];
      const tmpCFE: { label: any; key: any; required: any; example?: any; type: any; allcompany?: any }[] = [];
      if (result?.parameters) {
        result.parameters.forEach((obj: any) => {
          tmpCFE.push({label: obj.description, key: obj.name, required: obj.required, example: obj.example, type: obj.schema.type, allcompany : (obj.allcompany) ? obj.allcompany : false });
          this.companyParams[obj.name] = (obj.required) ? '' : obj.example;
        });
      }
      this.companyFormElements = tmpCFE.slice();
    });
  }

  getSiteActionParams(): void {
    this.loaderService.display(true, 'Getting action params...');
    this.baseService
      .doRequest(
        `/api/integrations/action_params?integrationName=${this.currentIntegration.name}&actionName=getSites`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.siteActionParams = result;
        this.siteFormElements = [];
        const tmpCFE: {
          label: any;
          key: any;
          required: any;
          example?: any;
          type: any;
        }[] = [];
        result?.parameters.forEach((obj: any) => {
          const frmEle: any = {
            label: obj.description,
            key: obj.name,
            required: obj.required,
            example: obj.example,
            type: obj.schema.type,
          };
          if (obj.options && obj.schema.type === 'dropdown') {
            frmEle.options = obj.options;
          }
          tmpCFE.push(frmEle);
          this.siteParams[obj.name] = obj.required ? '' : obj.example;
        });
        this.siteFormElements = tmpCFE.slice();
      });
  }

  getImportIntegrationSites(cmp: any, idx: any): void {
    this.loaderService.display(true, 'Getting sites...');
    this.cmpMapping.selectedCompanies[idx].siteParams.companyId = cmp.id;
    const params: any = {
      integrationName: this.currentIntegration.name,
      integrationId: this.currentCredential,
      params: {
        action: {
          destination: this.cmpMapping.selectedCompanies[idx].siteActionParams.destination,
          verb: this.cmpMapping.selectedCompanies[idx].siteActionParams.verb,
          name: this.cmpMapping.selectedCompanies[idx].siteActionParams.name,
        },
        params: { requestparams: this.cmpMapping.selectedCompanies[idx].siteParams },
      },
    };
    this.baseService
      .doRequest(`/api/integrations/executeAction`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (!result.data.length) {
          this.toast.sToast(
            'info',
            'No companies found! Please change the search key and try.'
          );
          return;
        }
        if (result.status === 'failed') {
          this.toast.sToast('error', result.data);
          return;
        }
        const sortKey = this.siteActionParams.DisplayFields[0];
        this.cmpMapping.selectedCompanies[idx].siteList = result.data.sort((a: any, b: any) => {
          const c = a[sortKey] ? a[sortKey].toLowerCase() : a[sortKey];
          const d = b[sortKey] ? b[sortKey].toLowerCase() : b[sortKey];
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
      });
  }
  async processParams(data: any): Promise<any> {
    const value: any = [];
    const drow = await data.row_values;
    if (!drow) { return value; }
    drow.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  getIntegrationCompanies(): void {
    if (!this.currentIntegration.importCompanies) {
      return;
    }
    this.loaderService.display(true, 'Getting companies...');
    const params: any = {
      integrationName: this.currentIntegration.name, integrationId: this.currentCredential,
      params: {
        action: {
          destination: this.companyActionParams.destination,
          verb: this.companyActionParams.verb, name: this.companyActionParams.name
        },
        params: {requestparams: this.companyParams}
      }
    };
    this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (!result.data.length) {
          this.companyList = [];
          this.toast.sToast('info', 'No companies found! Please change the search key and try.');
          return;
        }
        if (result.status === 'failed') {
          this.companyList = [];
          this.toast.sToast('error', result.data);
          return;
        }
        result.data?.map((d: any) => { d._id = d._id ? d._id : d.id});
        const sortKey = this.companyActionParams.DisplayFields[0];
        this.selectedCompanyList = Object.assign([], this.cmpMapping.selectedCompanies);
        this.companyList = result.data.sort((a: any, b: any) => {
          const c = a[sortKey].toLowerCase();
          const d = b[sortKey].toLowerCase();
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        if (this.companyList && this.companyList.length && this.currentIntegration.importSites) {
          this.cmpMapping.destinationCompany = this.companyList[0];
          this.getIntegrationSites();
        }
      });
  }

  getIntegrationSites(): void {
    this.loaderService.display(true, 'Getting sites...');
    this.siteParams.companyId = this.cmpMapping.destinationCompany.id;
    const params: any = {
      integrationName: this.currentIntegration.name,
      integrationId: this.currentCredential,
      params: {
        action: {
          destination: this.siteActionParams.destination,
          verb: this.siteActionParams.verb,
          name: this.siteActionParams.name,
        },
        params: { requestparams: this.siteParams },
      },
    };
    this.baseService
      .doRequest(`/api/integrations/executeAction`, 'post', params)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (!result.data.length) {
          this.toast.sToast(
            'info',
            'No companies found! Please change the search key and try.'
          );
          return;
        }
        if (result.status === 'failed') {
          this.toast.sToast('error', result.data);
          return;
        }
        const sortKey = this.siteActionParams.DisplayFields[0];
        this.selectedSiteList = Object.assign(
          [],
          this.cmpMapping.selectedSites
        );
        this.siteList = result.data.sort((a: any, b: any) => {
          const c = a[sortKey] ? a[sortKey].toLowerCase() : a[sortKey];
          const d = b[sortKey] ? b[sortKey].toLowerCase() : b[sortKey];
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
      });
  }

  /*Integration Mapping Table*/
  integmappingshowHideLoading(status: any): void {
    const data = Object.assign({}, this.inteTableOptions);
    this.inteTableOptions = {};
    this.inteTableOptions = data;
    this.inteTableOptions.tableOptions.loading = status;
  }

  intesortCall(idata: any): void {
    this.inteTableOptions.sortOptions = idata;
    this.getIntegrationMapping();
  }

  integmappingeditFn(idata: any): void {
    console.log(idata);
    this.currentCompanyMapping = idata;
    this.cmpMapping = {
      existingCompanyName: idata.sourceCompanyName,
      companyName: idata.destCompanyName,
      notificationProfiles: idata.notificationProfiles,
      no_notification: !idata.no_notification,
      configuration: idata.configuration,
      siteName: idata.siteName,
    };
    this.snav.open();
  }

  integmappingdeleteFn(idata: any): void {
    const titleName = 'Delete Mapping';
    const message = 'Are you sure you want to delete the mapping of ' + idata.sourceCompanyName + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/integrations/companyMapping/${idata._id}`, 'delete').subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Mapping deleted successfully');
            setTimeout(() => {
              this.getIntegrationMapping();
            }, 3000);
          } else {
            this.toast.sToast('error', 'Error deleting this mapping. Contact support!');
          }
        });
      }
    });
  }

  integmappingactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      this.loaderService.display(true);
      const dataRow = idata.row;
      this.isEdit = true;
      setTimeout(() => {
        this.integmappingeditFn(dataRow);
        this.loaderService.display(false);
      }, 1000);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.integmappingdeleteFn(dataRow);
    }
    if (idata.action.text === 'Provide Consent') {
      this.cmpMapping = idata.row;
      this.provideConsent(this.cmpMapping.destCompanyId, false);
    }
  }

  integmappingfilterCall(idata: any): void {
    const fields: any = [];
    this.inteTableOptions.columns.forEach((obj: any) => {
      if (obj.columnDef !== 'c' && obj.columnDef !== 'u') {
        fields.push(obj.columnDef);
      }
    });
    this.integmappingfilterQuery = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields
      }
    } : undefined;
    this.getIntegrationMapping();
  }

  integmappingpageCall(event: any): void {
    this.inteTableOptions.tableOptions.pageSize = event.pageSize;
    this.integmappingcurrentPage = event.pageIndex;
    this.getIntegrationMapping();
  }

  integmappingaddTableData(): void {
    this.addEditIntegration = true;
    this.isEdit = false;
    this.companyMappingList = [];
    this.failedMappingList = [];
    this.cmpMapping = {selectedCompanies: [], mapCompanyList: []};
    if (!this.currentIntegration.importCompanies) {
      this.cmpMapping.selectedType = 'Map Existing Companies';
      this.cmpView = this.cmpMapping.selectedType;
    } else {
      this.cmpView = 'Type';
    }
  }

  integmappingtimerCallData(): void {
    this.getIntegrationMapping();
  }

  getIntegrationMapping(isInit?: boolean): void {
    const query: any = {
      query: {
        bool: {
          must: [
            {match: {'integrationName.keyword': this.currentIntegration.name + ''}},
            {match: {'credentialId.keyword': this.currentCredential + ''}},
            {exists: {field: 'sourceCompanyId'}}]
        }
      }
    };
    this.integmappingshowHideLoading(true);
    if(this.mode === 'company' || (this.currentCompany && this.currentCompany._id)){
      query.query.bool.must.push({match: {'sourceCompanyId.keyword': this.currentCompany._id + ''}});
    }
    this.inteTableOptions.serverSide = {
      service: 'baseService', fn: 'doRequest', q: query
    };


    if (this.integmappingfilterQuery && this.integmappingfilterQuery.multi_match) {
      query.query.bool.must.push(this.integmappingfilterQuery);
    }
    if(this.currentIntegration.name === 'AzureADCSP'){
      const isCmp = this.inteTableOptions.columns.filter(
        (x: any) => x.header === 'Consent Status'
      );
      if (!isCmp || !isCmp.length) {
        this.inteTableOptions.tableOptions.actionMenuItems = [
          {text: 'Provide Consent', icon: 'verified_user', callback: 'consentFn', isGlobal: false},
          {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false},
        ];
        this.inteTableOptions.columns.push({
          header: 'Consent Status',
          columnDef: 'consent_enabled',
          filter: '',
          cell: '(element: any) => `${element.consent_enabled}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
          statusicon: true,
          success: true,
          failure: false,
          successIconPath: 'assets/images/devices/tick.svg', successToolTip: 'True',
          failureIconPath: 'assets/images/devices/cross.svg', failureToolTip: 'False',
        });
      }
    }

    if(this.currentIntegration.name === 'Cloudradial'){
      const isCmp = this.inteTableOptions.columns.filter(
        (x: any) => x.header === 'Archive'
      );
      if (!isCmp || !isCmp.length) {
        this.inteTableOptions.columns.push({
            header: `Archive`,
            columnDef: 'siteName',
            filter: '',
            cell: '(element: any) => `${element.siteName}`',
            order: 5,
            visible: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: false,
            iscolumnSearch: false
        });
      }
    }



    const q = JSON.stringify(query);
    const skip = this.integmappingcurrentPage;
    const limit = this.inteTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (this.inteTableOptions.sortOptions && this.inteTableOptions.sortOptions.direction && this.inteTableOptions.sortOptions.direction !== '') {
    const orderArr = ['sourceCompanyName', '_id'];
    if (orderArr.indexOf(this.inteTableOptions.sortOptions.active) > -1) {
      sort[0][this.inteTableOptions.sortOptions.active + '.keyword'] = { order: this.inteTableOptions.sortOptions.direction };
    } else {
      sort[0][this.inteTableOptions.sortOptions.active] = { order: this.inteTableOptions.sortOptions.direction };
    }
  }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['c', 'u', '_id', '_type_', 'credentialName','credentialId', 'destCompanyId', 'destCompanyName', 'integrationName', 'sourceCompanyId', 'sourceCompanyName']);
    this.baseService.doRequest(`/api/integrations/companyMapping`,
      'get', null, {q, skip, limit,sort}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.inteTableOptions.pageData = result.data;
      this.inteTableOptions.tableOptions.pageTotal = result.total;
      this.integmappingshowHideLoading(false);
      if (isInit) {
        this.addEditIntegration = !(result.total);
      }
      this.addEditIntegration = this.onboarding;
    });
  }

  mapSelectedCompanies(): void {
    this.cmpMapping.mapCompanyList.forEach((obj: any) => {
      const selectedCred = this.integrationsData.filter((x: any) => x._id === this.currentCredential);
      const params: any = {
        integrationName: this.currentIntegration.name, credentialName: selectedCred[0].name,
        credentialId: this.currentCredential, sourceCompanyName: obj.source.name,
        sourceCompanyId: obj.source._id,
         destCompanyName: (this.currentIntegration.importCompanies)
          ? obj.destination[this.companyActionParams.DisplayFields[0]] : obj.destination.name,
        destCompanyId: (this.currentIntegration.importCompanies)
         ? (obj.destination._id) ? obj.destination._id + '' : obj.destination.id + '' : (obj.destination._id) ? obj.destination._id + '' : obj.destination.id + ''
      };
      (this.currentIntegration.name === 'AzureADCSP') ? params.consent_enabled = this.importProvideConsent[(obj.destination._id) ? obj.destination._id + '' : obj.destination.id + ''] : null;
      params.destCompanyId = (params.destCompanyId) ? params.destCompanyId : '*'
      if(this.currentIntegration.name === 'Cloudradial'){
        params.siteName = (this.currentIntegration.importSites)
        ? obj.archive[this.companyActionParams.DisplayFields[0]] : obj.archive.name,
        params.siteId = (this.currentIntegration.importSites)
       ? (obj.archive._id) ? obj.archive._id + '' : obj.archive.id + '' : (obj.archive._id) ? obj.archive._id + '' : obj.archive.id + ''
      }
      this.baseService.doRequest(`/api/integrations/companyMapping`, 'post', params).subscribe(() => {
        this.companyMappingList.push(obj.source.name);
        this.toast.sToast('success', `${obj.source.name} mapped.`);
        this.redirectMC();
      });
    });
  }

  // importSelectedCompanies(): void {
  //   this.baseService.companyList = []; this.companyMappingList = []; this.failedMappingList = [];
  //   this.cmpMapping.selectedCompanies.forEach((obj: any) => {
  //     if (obj.site && obj.site.name) {
  //         this.createCompany(obj);
  //     } else {
  //         this.toast.sToast('error',"Please go back and select the site name for the "+ obj.name+' company');
  //     }
  // });
  // }

  importSelectedCompanies(): void {
    this.baseService.companyList = []; this.companyMappingList = []; this.failedMappingList = [];
    this.cmpMapping.selectedCompanies.forEach((obj: any) => {
      this.createCompany(obj);
    });
  }

  createCompany(obj: any): void {
    this.loaderService.display(true, `Importing ${obj.name}`);
    const newCompany = {
      name: obj.name, description: `Import from ${this.currentIntegration.title}`,
      source: 2, source_id: obj._id, tags: [`${this.currentIntegration.title}`]
    };
    this.companyService.createApiCompanyPost({body: newCompany}).subscribe((result: any) => {
      if (result._id === 'Company Exists With Same Name') {
        this.loaderService.display(false);
        this.failedMappingList.push(obj.name);
        this.toast.sToast('error', `${obj.name} company exist with the same name.`);
      } else {
        this.baseService.companyList.push(result);
        const selectedCred = this.integrationsData.filter((x: any) => x._id === this.currentCredential);
        const params: any = {
          integrationName: this.currentIntegration.name, credentialName: selectedCred[0].name,
          credentialId: this.currentCredential, sourceCompanyName: obj.name,
          sourceCompanyId: result._id, destCompanyName: obj[this.companyActionParams.DisplayFields[0]],
          destCompanyId: (obj._id) ? obj._id + '' : obj.id + ''
        };
        params.destCompanyId = (params.destCompanyId) ? params.destCompanyId : '*';
        (this.currentIntegration.name === 'AzureADCSP') ? params.consent_enabled =  this.importProvideConsent[obj.name] : null;
        if (this.currentIntegration.importSites) {
          params.siteName = obj.site[this.siteActionParams.DisplayFields[0]]
          params.siteId = obj.site.id
        }
        console.log('params',params.siteName)
        this.baseService.doRequest(`/api/integrations/companyMapping`, 'post', params).subscribe(() => {
          this.companyMappingList.push(obj.name);
          this.toast.sToast('success', `${obj.name} mapped.`);
          this.redirectIM();
        });
      }
    });
  }

  redirectMC(): void {
    if (this.cmpMapping.mapCompanyList.length === this.companyMappingList.length) {
      this.addEditIntegration = false;
      setTimeout(() => this.getIntegrationMapping(), 2000);
    }
  }

  redirectIM(): void {
    if (this.cmpMapping.selectedCompanies.length === (this.companyMappingList.length + this.failedMappingList.length) ) {
      this.addEditIntegration = false;
      this.importProvideConsent = {};
      setTimeout(() => {
        if (this.onboarding) {
          this.disableCreate = true;
        }
        this.getIntegrationMapping();
        this.updateCompanyList.emit({});
      }, 2000);
    }
  }

  /*Integration Mapping Table*/
  removeSelected(i: number): void {
    this.selectedCompanyList = this.selectedCompanyList.filter((obj: any) => obj._id !== this.cmpMapping.selectedCompanies[i]._id);
    this.cmpMapping.selectedCompanies.splice(i, 1);
  }

}
