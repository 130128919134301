/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PdScanSettings } from '../models/pd-scan-settings';
import { PdScanSettingsCreate } from '../models/pd-scan-settings-create';
import { PdScanSettingsGetResp } from '../models/pd-scan-settings-get-resp';

@Injectable({
  providedIn: 'root',
})
export class PdScanSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiPdscansettingsPdscansettingsGet
   */
  static readonly GetAllApiPdscansettingsPdscansettingsGetPath = '/api/pdscansettings';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiPdscansettingsPdscansettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiPdscansettingsPdscansettingsGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<PdScanSettingsGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.GetAllApiPdscansettingsPdscansettingsGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettingsGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiPdscansettingsPdscansettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiPdscansettingsPdscansettingsGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<PdScanSettingsGetResp> {

    return this.getAllApiPdscansettingsPdscansettingsGet$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettingsGetResp>) => r.body as PdScanSettingsGetResp)
    );
  }

  /**
   * Path part for operation updateApiPdscansettingsPdscansettingsPut
   */
  static readonly UpdateApiPdscansettingsPdscansettingsPutPath = '/api/pdscansettings';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiPdscansettingsPdscansettingsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiPdscansettingsPdscansettingsPut$Response(params: {
    body: PdScanSettings
  }): Observable<StrictHttpResponse<PdScanSettings>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.UpdateApiPdscansettingsPdscansettingsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettings>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiPdscansettingsPdscansettingsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiPdscansettingsPdscansettingsPut(params: {
    body: PdScanSettings
  }): Observable<PdScanSettings> {

    return this.updateApiPdscansettingsPdscansettingsPut$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettings>) => r.body as PdScanSettings)
    );
  }

  /**
   * Path part for operation createApiPdscansettingsPdscansettingsPost
   */
  static readonly CreateApiPdscansettingsPdscansettingsPostPath = '/api/pdscansettings';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiPdscansettingsPdscansettingsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiPdscansettingsPdscansettingsPost$Response(params: {
    body: PdScanSettingsCreate
  }): Observable<StrictHttpResponse<PdScanSettings>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.CreateApiPdscansettingsPdscansettingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettings>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiPdscansettingsPdscansettingsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiPdscansettingsPdscansettingsPost(params: {
    body: PdScanSettingsCreate
  }): Observable<PdScanSettings> {

    return this.createApiPdscansettingsPdscansettingsPost$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettings>) => r.body as PdScanSettings)
    );
  }

  /**
   * Path part for operation getApiPdscansettingsPdscansettingsIdGet
   */
  static readonly GetApiPdscansettingsPdscansettingsIdGetPath = '/api/pdscansettings/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPdscansettingsPdscansettingsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPdscansettingsPdscansettingsIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<PdScanSettings>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.GetApiPdscansettingsPdscansettingsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdScanSettings>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiPdscansettingsPdscansettingsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPdscansettingsPdscansettingsIdGet(params: {
    id: string;
  }): Observable<PdScanSettings> {

    return this.getApiPdscansettingsPdscansettingsIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<PdScanSettings>) => r.body as PdScanSettings)
    );
  }

  /**
   * Path part for operation deleteApiPdscansettingsPdscansettingsIdDelete
   */
  static readonly DeleteApiPdscansettingsPdscansettingsIdDeletePath = '/api/pdscansettings/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiPdscansettingsPdscansettingsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPdscansettingsPdscansettingsIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PdScanSettingsService.DeleteApiPdscansettingsPdscansettingsIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiPdscansettingsPdscansettingsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPdscansettingsPdscansettingsIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiPdscansettingsPdscansettingsIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
