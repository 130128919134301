import {Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {BaseRequestService} from 'src/app/_services/base.service';
import {CommonService} from 'src/app/_services/common.services';
import {LoaderService} from 'src/app/_services/loader.service';
import {ConfirmDialogService} from 'src/app/_services/confirmdialog.service';
import {MyToastrService} from '../../_services/toastr.service';

@Component({
  selector: 'app-discovery-timeout',
  templateUrl: './discovery-timeout.component.html',
  styleUrls: ['./discovery-timeout.component.scss']
})

export class DiscoveryTimeoutComponent implements OnInit {
  discovery: any = {};
  constructor(public commonService: CommonService,
              public baseService: BaseRequestService,
              private confirmDialog: ConfirmDialogService,
              public aS: AuthenticationService,
              private toast: MyToastrService,
              public loaderService: LoaderService) { }

  ngOnInit(): void {
  }

  setDiscoveryTimeout(): void {
    if (!this.discovery.timeout) {
      this.toast.sToast('error', 'Please enter valid timeout in hours');
    } else {
      this.loaderService.display(true, 'Updating discovery timeout settings...');
      this.baseService.doRequest(`/api/cyberutils/dummy/updateDiscoveryTimeout`, 'post',
        {timeout: this.discovery.timeout}).subscribe((result: any) => {
         this.loaderService.display(false);
         if (result) {
           this.toast.sToast('success', result);
         } else {
           this.toast.sToast('error', result);
         }
      });
    }
  }

  getDiscoveryTimeout(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/cyberutils/dummy/getDiscoveryTimeout`, 'post', {})
    .subscribe((result: any) => {
      this.loaderService.display(false);
      if (result) {
        this.discovery.timeout = result;
      }
    });
  }
}
