import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {CompanySharedService} from '../../_services/company-shared.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CommonService } from 'src/app/_services/common.services';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { MyToastrService } from 'src/app/_services/toastr.service';
import { CompanyService } from 'src/app/api/services';
import { ModalService } from 'src/app/_services/modal.service';


@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentJob: any;
  @Input() currentCompany: any;
  @Input() isExternal = false;
  localCurrentJob: any;
  reasonList:any = [];
  download1Width = 800;
  showUpper = true;
  currentView: any = {assetJobStatus: [], assetInventoryStatus: {}, assetSnmpJobStatus: [], reportJobStatus: []};
  assetSNMPJobCols: any = ['assetName', 'assetIp', 'reason', 'status', 'vulnerability_count', 'discoveredProtocol',
    'risk_score', 'noauth_vulnerability_count', 'base_score'];
  assetJobStatusCols: any = ['assetName', 'assetIp', 'reason', 'status', 'pingStatus', 'topPortsScan',
    'vulnerability_count', 'risk_score', 'noauth_vulnerability_count'];
  reportJobStatusCols: any = ['report_name', 'report_type', 'reason', 'status'];
  reportJobStatusCustomCols: any = [
    {
      col: 'status', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    }
    ];
  assetJobStatusCustomCols: any = [
    {
      col: 'status', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    }, {
      col: 'pingStatus', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },
    {
      col: 'topPortsScan', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },
    {col: 'reason', filter: 'ellipsis:25'}, {col: 'vulnerability_count', isHtml: true}
  ];
  assetSNMPJobCustomCols: any = [
    {
      col: 'status', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    }, {col: 'reason', filter: 'ellipsis:25'}, {col: 'vulnerability_count', isHtml: true}];

    azureJobStatusCols: any = ['name', 'status', 'description'];
    azureJobStatusCustomCols: any = [
      {
        col: 'status', htmlCols: {
          _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
          _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
        }
      },
      {col: 'description', filter: 'ellipsis:25'}
      ];
      apiDownload = true;
      @Input() value1: any = { showColFilter: true, refreshData: true };
      filterData: any = "";
    constructor(private cs: CompanySharedService,private loaderService: LoaderService,public baseService: BaseRequestService,public toast: MyToastrService,
      private companyService: CompanyService,
      private httpClient: HttpClient, private authService: AuthenticationService,private comS: CommonService,public modalService: ModalService) {
    cs.jobsUpdateEVE.subscribe((value: any) => {
      this.initJobView(value);
    });
  }

  Objectkeys = Object.keys;

  ngOnInit(): void {
    this.assetJobStatusCols = (this.currentJob.job_data.task !== 'Firewall Scan') ? ['assetName', 'assetIp', 'reason', 'status', 'pingStatus', 'topPortsScan',
    'vulnerability_count', 'risk_score', 'noauth_vulnerability_count'] : ['assetIp', 'reason', 'status']
    this.initJobView(this.currentJob);
  }

  initJobView(cJob: any): any {
    this.currentView = {assetJobStatus: [], assetInventoryStatus: {}, assetSnmpJobStatus: [], reportJobStatus: [], azureJobStatus: []};
    const currentView = {assetJobStatus: [], assetInventoryStatus: {}, assetSnmpJobStatus: [], reportJobStatus: [], azureJobStatus: []};
    if (cJob.job_data.job_message) {
      if (!cJob.job_data.job_message.assetJobStatus) {
        cJob.job_data.job_message.assetJobStatus = [];
      } else {
        cJob.job_data.job_message.assetJobStatus.forEach((obj: any) => {
          obj.assetName = (obj.assetRef) ? obj.assetRef.name : '-';
          obj.assetIp = (obj.ipaddress) ? obj.ipaddress : '-';
          obj.noauth_vulnerability_count = obj.noauth_vulscount;
          obj.vulnerability_count = obj.vulcount;
          /*(!obj.vulcount)
            ? `<span class="fw5">${obj.vulcount}</span>`
            : `<span class="fw5 tprime">${obj.vulcount}</span>`;*/
          if (obj.reason.indexOf('did not properly respond') > -1 && obj.reason.indexOf('445') > -1) {
            obj.reason = 'SMB Connection Failed';
          }
        });
      }
      if (!cJob.job_data.job_message.assetInventoryStatus) {
        cJob.job_data.job_message.assetInventoryStatus = {};
      }
      if (!cJob.job_data.job_message.assetSnmpJobStatus) {
        cJob.job_data.job_message.assetSnmpJobStatus = [];
      } else {
        cJob.job_data.job_message.assetSnmpJobStatus.forEach((obj: any) => {
          obj.assetName = (obj.assetRef) ? obj.assetRef.name : '-';
          obj.assetIp = (obj.assetRef) ? obj.assetRef.ip : '-';
          obj.noauth_vulnerability_count = obj.noauth_vulscount;
          obj.vulnerability_count = obj.vulcount;
          /*(!obj.vulcount)
            ? `<span class="fw5">${obj.vulcount}</span>`
            : `<span class="fw5 tprime">${obj.vulcount}</span>`;*/
        });
      }
      currentView.assetJobStatus = cJob.job_data.job_message.assetJobStatus;
      currentView.assetInventoryStatus = cJob.job_data.job_message.assetInventoryStatus;
      currentView.assetSnmpJobStatus = cJob.job_data.job_message.assetSnmpJobStatus;
    } else if(cJob.job_data.report_job_message){
      currentView.reportJobStatus = (cJob.job_data.report_job_message) ? cJob.job_data.report_job_message : [];
    } else {
      currentView.azureJobStatus = (cJob.job_data.azuread_job_message) ? cJob.job_data.azuread_job_message : [];
    }
    this.currentView = Object.assign({}, currentView);
  }

  apiDataDownload() {
    this.filterData = '';
    this.reasonList = [...new Set(
      [
        ...this.currentView.assetJobStatus.map((x: any) => x.reason),
        ...this.currentView.assetSnmpJobStatus.map((x: any) => x.reason)
      ]
    )];    
    this.modalService.open('download1');
  }


  apiDataDownload2(): void {
    this.loaderService.display(true, 'Downloading');
    this.baseService.doRequest(`api/standardreports/getAssetJobdocx`, 'post',{ jobid : this.currentJob._id, reason: this.filterData}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result.length && result[0]) {
        this.modalService.close('download1');
        window.open(result[1], '_blank');
      } else {
        this.toast.sToast('error', result[1]);
        this.modalService.close('download1');
      }
    });  
  }

  ngOnChanges(changes: SimpleChanges): any {
    const currentJob: SimpleChange = changes.currentJob;
    this.localCurrentJob = currentJob.currentValue;
    if (!currentJob.firstChange) {
      this.initJobView(this.localCurrentJob);
    }
  }

  ngOnDestroy(): void {
    this.currentJob = undefined;
  }
}
