/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InstalledDriversCreate } from './installed-drivers-create';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { InstalledDrivers } from './installed-drivers';


export interface CreateApiInstalledDriversPost$Params {
      body: InstalledDriversCreate
}

export function createApiInstalledDriversPost(http: HttpClient, rootUrl: string, params: CreateApiInstalledDriversPost$Params): Observable<StrictHttpResponse<InstalledDrivers>> {
  const rb = new RequestBuilder(rootUrl, createApiInstalledDriversPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json' })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InstalledDrivers>;
    })
  );
}

createApiInstalledDriversPost.PATH = '/api/InstalledDrivers/';
