import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { CompanyService } from 'src/app/api/services';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BaseRequestService } from 'src/app/_services/base.service';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { IntegrationActionsService } from 'src/app/_services/integration-actions.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-pdscan-result',
  templateUrl: './pdscan-result.component.html',
  styleUrls: ['./pdscan-result.component.scss'],
})
export class PdscanResultComponent implements OnInit {
  @ViewChild('result', { static: true }) result: MatSidenav;
  resultTableOption: any;
  searching: boolean;
  filteredCompanies: any;
  loading = false;
  colFilterQuery: any;
  colFilterCols: any = [];
  colScanTypeFiltercols: any = [];
  currentPage = 0;
  filterQuery : any;
  currentResult: any = {
    line_numbers: [],
  };
  isScanTypeMapFilter : any = false;
  suppressFilter = [
    { name: 'All', value: ''},
    { name: 'Suppressed', value: true},
    { name: 'Unsuppressed', value: false},
  ];
  suppressFilterValue: any = '';
  scanTypeMapping: any = {
    all: {
      id: 'all',
      name: 'All',
      value: 0,
    },
    surname: {
      id: 'surname',
      name: 'Surname',
      value: 0,
    },
    date_of_birth: {
      id: 'date_of_birth',
      name: 'Date Of Birth',
      value: 0,
    },
    postal_code: {
      id: 'postal_code',
      name: 'Postal Code',
      value: 0,
    },
    location: {
      id: 'location',
      name: 'Location',
      value: 0,
    },
    email: {
      id: 'email',
      name: 'Email',
      value: 0,
    },
    ip: {
      id: 'ip',
      name: 'IP Address',
      value: 0,
    },
    credit_card: {
      id: 'credit_card',
      name: 'Credit Card',
      value: 0,
    },
    phone: {
      id: 'phone',
      name: 'Phone',
      value: 0,
    },
    ssn: {
      id: 'ssn',
      name: 'Social Security Numbers (SSN)',
      value: 0,
    },
    bank_account_number: {
      id: 'bank_account_number',
      name: 'Bank Account Number',
      value: 0,
    },
    street: {
      id: 'street',
      name: 'Street',
      value: 0,
    },
    oauth_token: {
      id: 'oauth_token',
      name: 'Oauth Token',
      value: 0,
    },
  };
  @Input() currentCompany: any;
  @Input() mode: any;
  @Input() agentRef: any;
  selectFilterQuery: any;
  prodList: any;
  Objectkeys = Object.keys;
  selectedScanType: any = 'all';
  constructor(
    private baseService: BaseRequestService,
    public loaderService: LoaderService,
    public aS: AuthenticationService,
    public confirmDialog: ConfirmDialogService,
    public toast: MyToastrService
  ) {
    this.resultTableOption = {
      columns: [
        {
          header: 'Status',
          columnDef: 'isRemediated',
          filter: '',
          cell: '(element: any) => `${element.isRemediated}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '70px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          statusicon: true,
          success: true,
          failure: false,
          successIconPath: '/assets/images/auditLogins/tick.svg',
          successToolTip: 'Remediated',
          failureIconPath: '/assets/images/auditLogins/alert.svg',
          failureToolTip: 'Pending',
          selectFilterArr: [
            { name: 'Remediated', id: 'AgentOnline', value: true },
            { name: 'Pending', id: 'AgentOffline', value: false },
          ],
        },
        {
          header: 'Asset Name',
          col: 'agentRef.name',
          columnDef: 'agentRef.name',
          filter: '',
          cell: '(element: any) => `${element.agentRef.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          iscolumnSearch: true,
          selectFilter: true,
          selectFilterArr: [],
        },
        {
          header: 'Suppressed',
          columnDef: 'suppression',
          filter: '',
          cell: '(element: any) => `${element.suppression}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false,
          statusicon: true,
          success: true,
          failure: false,
          successIconPath: '/assets/images/auditLogins/tick.svg',
          successToolTip: 'Yes',
          failureIconPath: '/assets/images/auditLogins/cross.svg',
          failureToolTip: 'No',
        },
        {
          header: 'Source Type',
          col: 'scan_type',
          columnDef: 'scan_type',
          filter: 'cameltohuman',
          cell: '(element: any) => `${element.scan_type}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
          selectFilter: false,
          selectFilterArr: [],
        },
        {
          header: 'Details',
          col: 'identifier_type_new',
          columnDef: 'identifier_type_new',
          filter: '',
          cell: '(element: any) => `${element.identifier_type_new}`',
          order: 0,
          align: 'break',
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
        },
        {
          header: 'Line Number',
          col: 'lineNumber',
          columnDef: 'lineNumber',
          filter: '',
          cell: '(element: any) => `${element.lineNumber}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          isHyperlink: true,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
        },
        {
          header: 'PII Data',
          col: 'rule_title',
          columnDef: 'rule_title',
          filter: 'cameltohuman',
          cell: '(element: any) => `${element.rule_title}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
        },
        {
          header: 'Found',
          col: 'c',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
        },
      ],
      sortOptions: { active: 'c', direction: 'desc' },
      faClass: 'Rules',
      _pageData: [],
      tableOptions: {
        id: 'rules',
        title: 'PII Scan Results',
        // isServerSide: true, add: (this.aS.hasPermission('pdscanresults', 'create')),
        isServerSide: true,
        selectText: 'result(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Suppress',
            icon: 'remove_circle_outline',
            callback: 'suppressFn',
            isGlobal: true,
          },
          {
            text: 'Unsuppress',
            icon: 'find_replace',
            callback: 'unsuppressFn',
            isGlobal: true,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 15, 25, 100, 200],
        pageSize: 15,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        apiDownload: false,
        hideDownload: true,
        saveData: false,
        showColFilter: true,
      },
    };
  }

  ngOnInit(): void {
    if(this.mode === 'company' || this.mode === 'asset'){
      this.resultTableOption.tableOptions.actionMenuItems.push(
        {
           text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true
        }
      )
    }
    this.getScanTypeMapping();
    this.getSelectFilterArrayList();
  }

  linkCall(data: any): void {
    if (data) {
      if (data.col === 'lineNumber') {
        this.currentResult = { line_numbers: [] };
        this.currentResult = data.row;
        this.result.open();
      }
    }
  }
  sortCall(event: any): void {
    this.resultTableOption.sortOptions = event;
    this.getScanTypeMapping();
  }

  selectedScan(type: any): void {
    this.selectedScanType = type;
    this.currentPage = 0;
    this.resultTableOption.tableOptions.pageSize = 15;
    setTimeout(() => {
      this.getScanResultData();
    }, 1500);
  }
  getScanTypeMapping(): void {
    this.loaderService.display(true, 'Getting all PII Scan Results...');
    const reqData: any = {
      companyId:
        this.currentCompany && this.currentCompany?._id
          ? this.currentCompany?._id
          : null,
    };
    if (this.mode === 'asset') {
      reqData.agentId = this.agentRef.id;
    }
    if (this.suppressFilterValue !== ''){
      reqData.suppression = this.suppressFilterValue;
    }
    reqData.isFilter = this.isScanTypeMapFilter;
    if(this.isScanTypeMapFilter){
      reqData.colFilterCols = this.colScanTypeFiltercols;
    }
    this.Objectkeys(this.scanTypeMapping).forEach((obj: any) => { this.scanTypeMapping[obj].value = 0;})
    this.baseService
      .doRequest(`/api/pdscanresults/getpdscanmapping`, 'post', reqData)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.Objectkeys(result[1]).forEach((obj: any) => {
          // @ts-ignore
          this.scanTypeMapping[obj]
            ? (this.scanTypeMapping[obj].value = result[1][obj])
            : null;
        });
        const count = this.Objectkeys(result[1])
          .map((x: any) => (x !== '' ? result[1][x] : 0))
          .reduce((a: any, b: any) => a + b, 0);
        this.scanTypeMapping['all'].value = count;
        this.selectedScanType = 'all';
        setTimeout(() => {
          this.getScanResultData();
        }, 1000);
      });
  }
  getScanResultData(): void {
    this.showHideLoading(true);
    this.loaderService.display(true, 'Getting all PII Scan Results...');
    let cq: any;
    cq = {
      query: {
        bool: {
          must: [
            { exists: { field: 'rule_name' } },
            { exists: { field: 'scan_type' } },
            { exists: { field: 'identifier_type' } },
          ],
          must_not: [
            {
              match: {
                'rule_name.keyword': '',
              },
            },
            {
              match: {
                'identifier_type.keyword': '',
              },
            },
          ],
        },
      },
    };
    if (this.selectedScanType !== 'all') {
      cq.query.bool.must.push({
        match: { 'rule_name.keyword': this.selectedScanType },
      });
    }
    if (this.suppressFilterValue !== '') {
      if(this.suppressFilterValue !== false){
        cq.query.bool.must.push({
          match: { suppression: true }
        });
      } else {
        cq.query.bool.must_not.push({
          match: { suppression: true }
        });
        cq.query.bool.must.push({
          exists: { field: 'suppression' }
        });
      }
    }
    if (this.currentCompany && this.currentCompany._id) {
      cq.query.bool.must.push({
        match: { 'companyRef.id.keyword': this.currentCompany._id + '' },
      });
      if (this.mode === 'asset' && this.agentRef) {
        cq.query.bool.must.push({
          match: { 'agentRef.id.keyword': this.agentRef.id + '' },
        });
      }
    }

    if (!this.currentCompany || !this.currentCompany._id) {
      const isCmp = this.resultTableOption.columns.filter(
        (x: any) => x.header === 'Company Name'
      );
      if (!isCmp || !isCmp.length) {
        this.resultTableOption.columns.push({
          header: 'Company Name',
          id: 'companyRefname',
          columnDef: 'companyRef.name',
          filter: '',
          cell: '(element: any) => `${element.companyRef.name}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        });
      }
    }

    this.resultTableOption.query = cq;
    let sort: any;
    sort = [{}];
    if (this.resultTableOption.sortOptions) {
      const orderArr = ['c', 'u', 'isRemediated'];
      if (orderArr.indexOf(this.resultTableOption.sortOptions.active) === -1) {
        sort[0][this.resultTableOption.sortOptions.active + '.keyword'] = {
          order: this.resultTableOption.sortOptions.direction,
        };
      } else {
        sort[0][this.resultTableOption.sortOptions.active] = {
          order: this.resultTableOption.sortOptions.direction,
        };
      }
    }
    if (
      this.filterQuery &&
      (this.filterQuery.multi_match || this.filterQuery.query_string)
    ) {
      // @ts-ignore
      cq.query.bool.must.push(this.filterQuery);
    }
    if (this.colFilterQuery && this.colFilterQuery.length) {
      // @ts-ignore
      cq.query.bool.filter = [];
      this.colFilterQuery.forEach((obj: any) => {
        if (obj.bool.should[0].match) {
          cq.query.bool.must.push(obj);
        } else {
          cq.query.bool.filter.push(obj);
        }
      });
    }
    const q = JSON.stringify(cq);
    this.selectFilterQuery = q;
    const skip = this.currentPage;
    const limit = this.resultTableOption.tableOptions.pageSize;
    this.baseService
      .doRequest(`/api/pdscanresults/`, 'get', null, { q, skip, limit, sort })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.loading = false;
        if (result) {
          result.data.map((x: any) => {
            x.identifier_type_new = x.scan_type
              ? x.scan_type?.charAt(0)?.toUpperCase() +
                x.scan_type?.slice(1) +
                ' - ' +
                x.identifier_type
              : '';
            x.scan_type = x.scan_type !== 'files' ? 'Database' : x.scan_type;
            x.lineNumber =
              x.line_numbers && x.line_numbers.length
                ? x.line_numbers.length
                : '';
          });
          this.resultTableOption.pageData = result.data;
          this.resultTableOption.tableOptions.pageTotal = result.total;
          this.showHideLoading(false);
        } else {
          this.resultTableOption.pageData = [];
          this.resultTableOption.tableOptions.pageTotal = 0;
          this.showHideLoading(false);
        }
      });
  }
  // To get all Filter options
  getSelectFilterArrayList(){
    const cq = this.selectFilterQuery;
    const skip = 0;
    const limit = 10000;
    this.baseService
      .doRequest(`/api/pdscanresults/`, 'get', null, { cq, skip, limit })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.loading = false;
        if (result) {
          const agentList = [...new Set(result.data.map((x: any) => x.agentRef.name))].map((s: any) => { return { id: s, value: s, name: s }});
          this.resultTableOption.columns[1].selectFilterArr = agentList;
        }
        });
  }

  globalResultActionCall(data: any): void {
    if (data.action.text === 'Suppress') {
      const dataRow = data.row;
      this.suppressResults(dataRow, true);
    }
    if (data.action.text === 'Unsuppress') {
      const dataRow = data.row;
      this.suppressResults(dataRow, false);
    }
    if (data.action.text === 'Delete') {
      this.deleteResultsConfirmationDialog(data.row);
    }
  }

  allActioncall(idata: any): void {
    const dataRow = idata.row;
    if (idata.action.text === 'Suppress') {
      const dataRow = idata.row;
      this.suppressResult(dataRow, true);
    }
    if (idata.action.text === 'Unsuppress') {
      const dataRow = idata.row;
      this.suppressResult(dataRow, false);
    }
    else if (idata.action.text === 'Delete') {
      this.deleteResultsConfirmationDialog([idata.row]);
    }
  }

  deleteResultsConfirmationDialog(idata: any ): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete the selected result ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText, '', '', true);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Deleting PII Scan Results...');
        const resIds = idata.map((x: any) => x._id);
          this.baseService.doRequest(`/api/pdscanresults/iamaws/pdscanbulkdelete`,'post',{res_ids: resIds}).subscribe((result: any) => {
              if (result.status) {
                this.toast.sToast('error', result.msg);
              } else {
                this.loaderService.display(false);
                this.toast.sToast('success', 'Removed Successfully!');
                setTimeout(() => {
                  this.getScanTypeMapping();
                 }, 1000);
              }
            }, (error: any) => {
              this.loaderService.display(false);
              this.toast.sToast('error', 'Not Removed');
          });
      }
    });
  }

  suppressResult(idata: any, suppress: any): void {
    const type = suppress ? 'suppress' : 'unsuppress';
    const titleName = 'Confirmation';
    const message = `Are you sure you want to ${type} the selected result ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.loaderService.display(
          true,
          suppress ? 'Suppress in progress...' : 'Unuppress in progress...'
        );
        const reqData = { _id: idata._id, suppression: suppress };
        this.baseService
          .doRequest(`/api/pdscanresults/updatepdscan`, 'post', reqData)
          .subscribe((result: any) => {
            this.loaderService.display(false);
            this.toast.sToast(
              'success',
              suppress ? 'Suppressed successfully' : 'Unsuppressed successfully'
            );
            setTimeout(() => {
              this.getScanResultData();
            }, 1000);
          });
      }
    });
  }

  updateFilterData(event: any): void {
    this.loading = true;
    this.suppressFilterValue = event;
    this.getScanTypeMapping();
  }

  suppressResults(idata: any, suppress: any): void {
    const type = suppress ? 'suppress' : 'unsuppress';
    const titleName = 'Confirmation';
    const message = `Are you sure you want to ${type} the selected results ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.loaderService.display(
          true,
          suppress ? 'Suppress in progress...' : 'Unuppress in progress...'
        );
        idata.forEach((obj: any, index: number) => {
          const reqData = { _id: obj._id, suppression: suppress };
          this.baseService
            .doRequest(`/api/pdscanresults/updatepdscan`, 'post', reqData)
            .subscribe((result: any) => {
              if (index === idata.length - 1) {
                this.loaderService.display(false);
                this.toast.sToast(
                  'success',
                  suppress
                    ? 'Suppressed successfully'
                    : 'Unsuppressed successfully'
                );
                setTimeout(() => {
                  this.getScanResultData();
                }, 1000);
              }
            });
        });
      }
    });
  }

  colFilterCall(event: any): void {
    console.log("event.col--------->",event.col);
    event.col === 'identifier_type_new'
      ? (event.col = 'identifier_type')
      : null;
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') {
        this.colFilterCols.push(event);
      }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colScanTypeFiltercols = this.colFilterCols.filter((x: any) => x.col === 'agentRef.name');
    this.colFilterCols.forEach((obj: any) => {
      const searchValue =
        typeof obj.value === 'string' ? obj.value.trim() : obj.value;
      const tmpObj = {
        bool: {
          should: [
            { query_string: { fields: [obj.col], query: `${searchValue}` } },
          ],
          minimum_should_match: 1,
        },
      };
      this.colFilterQuery.push(tmpObj);
    });
    if (this.colFilterQuery && this.colFilterQuery.length) {
      this.isScanTypeMapFilter = true;
    }else{
      this.isScanTypeMapFilter = false;
    }
    this.getScanTypeMapping();
    this.getScanResultData();
  }
  showHideLoading(status: any): void {
    const data = Object.assign({}, this.resultTableOption);
    this.resultTableOption = {};
    this.resultTableOption = data;
    this.resultTableOption.tableOptions.loading = status;
  }
  refreshCall(): void {
    this.getScanResultData();
  }

  pageCall(event: any): void {
    // if(event.pageSize !== this.resultTableOption.tableOptions.pageSize){ this.loading = true};
    this.resultTableOption.tableOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getScanResultData();
  }

  filterCall(event: any): void {
    let qval = event.replace(
      /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:.\ \\/"])/g,
      '\\$1'
    );
    if (qval.indexOf('\\') === -1) {
      qval = '*' + qval + '*';
    }
    this.filterQuery =
      event && event.length > 0
        ? {
            query_string: {
              query: qval,
              fields: ['agentRef.name', 'rule_title', 'identifier_type_new', 'companyRef.name'],
            },
          }
        : undefined;
    this.getScanResultData();
  }

  PdScanBulkDelete() :void{
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete the selected result ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText, '', '', true);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        const params = {
          company_id: this.currentCompany._id,
          agent_id: this.agentRef? this.agentRef.id : '',
          scan_type: this.selectedScanType ? this.selectedScanType : '',
          isFilter : this.isScanTypeMapFilter,
          colFilterCols : this.isScanTypeMapFilter ? this.colScanTypeFiltercols: []
        }
        this.loaderService.display(true, 'Deleting PII Scan Results...');
          this.baseService.doRequest(`/api/pdscanresults/iamaws/pdscanbulkdelete`,'post',params).subscribe((result: any) => {
              if (result.status) {
                this.toast.sToast('error', result.msg);
              } else {
                this.loaderService.display(false);
                this.toast.sToast('success', 'Removed Successfully!');
                setTimeout(() => {
                  this.getScanTypeMapping();
                 }, 1000);
              }
            }, (error: any) => {
              this.loaderService.display(false);
              this.toast.sToast('error', 'Not Removed');
          });
      }
    });

  }
}
