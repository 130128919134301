import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalService} from '../../_services/modal.service';
import {MyToastrService} from '../../_services/toastr.service';
import {BaseRequestService} from '../../_services/base.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {LoaderService} from '../../_services/loader.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';
import {NotificationRulesService} from '../../api/services/notification-rules.service';
import {CommonService} from '../../_services/common.services';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import { MatSidenav } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { CompanyService } from 'src/app/api/services';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss']
})
export class TicketingComponent implements OnInit {
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @ViewChild('companySelect', {static: true}) companySelect!: MatSelect;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  @Input() currentCompany: any;
  @Input() mode: any;
  selectedIndex = 0;
  alertList: any = [];
  currentNR: any;
  companyModalWidth: 600;
  notiTableOptions: any;
  saveData = true;
  rulecurrentPage = 0;
  rulefilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  notificationRulesData: any = [];
  addEditRules = false;
  notificationRule: any = [];
  integrations: any = [];
  iHash: any = {};
  companyAssign: any = null;
  assignData: any = {
    _id: '',
    companies: [],
    integrations: []
  };
  integrationMappingHash: any = {};
  mappedIntegrations: any = [];
  selectedIntegrations: any = [];
  integrationsAvailable = false;
  selectedIntegrationsCredsHash: any = {};
  inputActionData: any = {};
  step = 0;
  ruleName: any;
  Objectkeys = Object.keys;
  dropdownSettings: any =  {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      position: 'top'
    };
  currentTR: any = {

  };
  companies: any = [];
  rule: any = {
    name: '',
    companies: [],
    integrations: [],
    rules: [
      {
        type: '',
        rulesDataKeys: {},
        rulesSubKeys: [],
        subtype: '',
        summary_template: '',
        description_template: '',
        ishtml: true,
        subtype_name: ''
      }
    ]
  };

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: "15rem",
    // minHeight: "5rem",
    placeholder: 'create template here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Sans',
    sanitize: false
  };

  subTypeList: any = [];
  // subTypeList: any = [{id: 'useradded', name: 'User Added'}, {id: 'userremoved', name: 'User Removed'}, {id: 'computeradded', name: 'Computer Added'}, {id: 'cmputerremoved', name: 'Computer Removed'} ];
  rulesData: any = {};
  rulesDataKeys: any;
  allComp: any;
  rulesSubKeys: any;
  constructor(public modalService: ModalService, public toast: MyToastrService, public authService: AuthenticationService,
              private baseService: BaseRequestService, private aS: AuthenticationService, private sanitizer: DomSanitizer,
              private loaderService: LoaderService, public confirmDialog: ConfirmDialogService,
              private notificationRulesService: NotificationRulesService, private companyService: CompanyService,
              public commonService: CommonService) {
    this.notiTableOptions = {
      columns: [
        {
          header: 'Template Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },
        {
          header: 'Last Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }],
      sortOptions: {active: 'u', direction: 'desc'},
      faClass: 'NotificationRules',
      _pageData: [],
      tableOptions: {
        title: 'Ticketing Templates',
        isServerSide: false,
        selectText: 'rules',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: (this.aS.hasPermission('ticketingrulesconfig', 'create')),
        columnSearch: false,
        compareData: false,
        filterDownload: false
      }
    };
    if (this.aS.hasPermission('ticketingrulesconfig', 'update')){
      this.notiTableOptions.tableOptions.actionMenuItems.unshift({text: 'Edit', icon: 'edit', callback: 'editFn', isGlobal: false});
    }
    if (this.aS.hasPermission('ticketingrulesconfig', 'delete')) {
      this.notiTableOptions.tableOptions.actionMenuItems.push({text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false});
    }
  }

  addEvent(): void {
    this.rule.rules.push({
      type: '',
      subtype: '',
      summary_template: '',
      description_template: '',
      rulesDataKeys: {},
      rulesSubKeys: [],
      subtype_name: '',
      ishtml: true
    });

    this.setStep(this.rule.rules.length);

  }
  setStep(index: number): void {
    this.step = index;
  }

  updateSubTypes(sites: any): void {
    console.log(this.rule.subTypes);
  }
  updateData(event: any): void {
    console.log(event);
  }
  ruleshowHideLoading(status: any): void {
    const data = Object.assign({}, this.notiTableOptions);
    this.notiTableOptions = {};
    this.notiTableOptions = data;
    this.notiTableOptions.tableOptions.loading = status;
  }
  openCompanyModal(rule: any): void {
    this.currentTR = rule;
    this.assignData = { _id: rule._id, companies: rule.companies, integrations: rule.integrations};
    this.snav.open();
  }


  ruledeleteFn(idata: any): void {
    this.deleteNotificationRule(idata);
  }
  ruleactionCall(idata: any): void {
    if (idata.action.text === 'Apply to Companies' || idata.action.text === 'Apply to Integrations') {
      const dataRow = idata.row;
      this.companyAssign = (idata.action.text === 'Apply to Companies') ? true : false;
      this.openCompanyModal(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.ruledeleteFn(dataRow);
    }
    if (idata.action.text === 'Edit') {
      this.rule = idata.row;
      this.saveData = false;
      idata.row.rules.map((x: any, idx: any) => {
        this.changeEventType(x.type, idx, true);
        this.changeSubType(x.subtype, idx, x.type, true);
      });
      this.addEditRules = true;
    }
  }
  rulefilterCall(idata: any): void {
    console.log(idata);
    this.ruleshowHideLoading(true);
    setTimeout(() => {
      this.ruleshowHideLoading(false);
    }, 1000);
  }

  rulepageCall(idata: any): void {
    console.log(idata);
    this.ruleshowHideLoading(true);
    setTimeout(() => {
      this.ruleshowHideLoading(false);
    }, 1000);
  }

  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }

  private filterCompanies(): void {
    if (!this.allComp) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.allComp.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  closeCurrentCompany(event: any): void {
    if (this.allComp) {
      this.filteredCompanies.next(this.allComp.slice());
    }
    if (!event) {
      this.getCompanies();
    }
  }

  addKeyfn(key:any, value:any, index:any): void {
    (key === 'title') ? this.rule.rules[index].summary_template = `${this.rule.rules[index].summary_template} ${value}` : 
    this.rule.rules[index].description_template = `${this.rule.rules[index].description_template} ${value}`
  }

  getCompanies(search?: string): void {
    if (!this.authService || !this.authService.isAuthenticated) {
      setTimeout(() => {
        this.getCompanies();
      }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {query: {bool: {must: [{exists: {field: 'description'}}, {terms: {'_type_.keyword': ['company', 'companycreate']}}], must_not: [{match: {isAssessment: true}}]}}};
    const asmq = {query: {bool: {must: [{match: {isAssessment: true}}, {exists: {field: 'description'}}, {terms: {'_type_.keyword': ['company', 'companycreate']}}]}}};
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    if (search && search !== '') {
      cq.query.bool.must.push({ match_bool_prefix: { name: search.toLowerCase() } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'c']);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
      if (result.data.length) {
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : '';
          const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        this.companies = result.data;
        if (!search) {
          this.allComp = result.data;
        }
        this.filteredCompanies.next(result.data.slice());

        this.searching = false;
      } else {
        this.filteredCompanies.next([]);
        this.searching = false;
      }
    }, error => {
      this.searching = false;
    });
  }
  ruleaddTableData(): void {
    this.currentNR = undefined;
    this.rule = {
      name: '',
      companies: [],
      integrations: [],
      rules: [
        {
          type: '',
          subtype: '',
          summary_template: '',
          description_template: '',
          ishtml: true,
          subtype_name: ''
        }
      ]
    };
    this.saveData = true;
    setTimeout(() => { this.addEditRules = true; }, 1000);
  }

  ngOnInit(): void {
    this.notiTableOptions.pageData = [];
    this.getNotificationRulesData();
    this.getAllIntegrations();
    this.getInitialData();
    this.companyFilterCtrl.valueChanges.pipe(debounceTime(300),
      takeUntil(this.onDestroySearch)).subscribe(() => {
      this.filterCompanies();
    });
    this.getCompanies();
  }
  assignComapny(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/ticketingrulesconfig/`, 'put', this.assignData).subscribe((result: any) => {
      if (result) {
        this.loaderService.display(false);
        this.snav.close();
        this.assignData = {_id: '', companies: [], integrations: []};
        this.getNotificationRulesData();
        this.toast.sToast('success', 'Success');
      }else{
        this.loaderService.display(false);
        this.toast.sToast('error', result);
      }
    });
  }

  getInitialData(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/ticketingrulesconfig/rulesconfig`, 'post', {}).subscribe((result: any) => {
      if (result) {
        this.rulesData = result;
      }
    });
  }

  changeEventType(val: any, idx: any, edit: any): void {
    const list: any = [];
    this.rulesData[val].map((item: any) => {
      list.push({id: item.event_id, name: item.event_name});
    });
    this.rule.rules[idx].subTypeList = list;
    (!edit) ? this.rule.rules[idx].subtype = '' : null;
    if (list && list.length) {
      this.rule.rules[idx].rulesDataKeys = this.rulesData[val][0].description_keys;
      this.rule.rules[idx].rulesSubKeys = this.rulesData[val][0].summary_keys;
    }
  }

  changeSubType(val: any, idx: any, type: any, edit: any): void {
    const data = this.rule.rules[idx].subTypeList.find((s: any) => val === s.id);
    
    if (data) {
      this.rule.rules[idx].subtype_name = data.name;
      const desData = this.rulesData[type].find((d: any) => val === d.event_id);
      
      if (desData) {
        if (!edit) {
          this.rule.rules[idx].summary_template = desData.default_summary_template;
          this.rule.rules[idx].description_template = desData.default_description_template;
        }
        
        this.rule.rules[idx].rulesDataKeys = desData.description_keys;
        this.rule.rules[idx].rulesSubKeys = desData.summary_keys;
      }
    }
  }
  
  getNotificationRulesData(): void {
    this.notificationRulesData = [];
    let qry: any; qry = { query: { bool: { must: [{exists: {field: 'rules.type'}}, {exists: {field: 'rules.subtype'}}, {exists: {field: 'name'}}] } } };
    this.ruleshowHideLoading(true);
    this.loaderService.display(true, 'Getting NotificationRules data');
    this.notiTableOptions.serverSide = {
      service: 'notificationRulesService', fn: 'getAllApiNotificationrulesGet', q: qry
    };
    const q = JSON.stringify(qry);
    const skip = 0;
    const limit = 1000;
    let sort: any = [{}];
    if (this.notiTableOptions.sortOptions && this.notiTableOptions.sortOptions.direction
      && this.notiTableOptions.sortOptions.direction !== '') {
      const orderArr = [ 'name'];
      if (orderArr.indexOf(this.notiTableOptions.sortOptions.active) > -1) {
        sort[0][this.notiTableOptions.sortOptions.active + '.keyword'] = { order: this.notiTableOptions.sortOptions.direction };
      } else {
        sort[0][this.notiTableOptions.sortOptions.active] = { order: this.notiTableOptions.sortOptions.direction };
      }
    }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['c', 'u', '_id', 'name', 'integrationRule', 'alertRules']);
    this.baseService.doRequest(`/api/ticketingrulesconfig/`,
        'get', null, {q, skip, limit, sort}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.notificationRulesData = result.data;
      this.notiTableOptions.pageData = result.data;
      this.notiTableOptions.tableOptions.pagezie = result.total;
      this.ruleshowHideLoading(false);
    });
  }

  getAllIntegrations(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/integrations/`, 'get').subscribe((result: any) => {
      this.loaderService.display(false);
      const integrations = result.filter((x: any) => (x.notificationfunction));
      integrations.forEach((obj: any) => {
        obj.integrationName = obj.name;
      });
      integrations.sort((a: any, b: any) => {
        const c = a.name; const d = b.name;
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
      this.mappedIntegrations = integrations;
    });
  }



  deleteNotificationRule(notificationRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this ticket rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/ticketingrulesconfig/${notificationRules._id}`, 'delete').subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => { this.getNotificationRulesData(); }, 3000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }


  saveNotificationRule(): void {
    const reqData: any = this.rule;
    if (reqData.companies.length === 0 || reqData.integrations.length === 0){
      this.toast.sToast('error', 'Please Add Company/Integration');
      return;
    }
    if (!reqData.rules || reqData.rules.length === 0){
      this.toast.sToast('error', `Please add at least one event`);
      return;
    }
    const template_keys = ['type', 'subtype', 'summary_template', 'description_template', 'ishtml'];
    let validate: any = true;
    reqData.rules.forEach((ele: any) => {
      template_keys.forEach((key: any) => {
        if (!ele[key] || ele[key] === null || ele[key] === ''){
          this.toast.sToast('error', `Please Fill ${key.toUpperCase()}`);
          validate = false;
          return;
        }
      });
      // ele.description_template = this.sanitizer.bypassSecurityTrustHtml(ele.description_template);
    });
    if (validate === false){
      return;
    }
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/ticketingrulesconfig/`, 'post', reqData).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result) {
        setTimeout(() => {
          this.addEditRules = false;
          this.toast.sToast('success', 'Added Successfully');
          this.getNotificationRulesData();
          this.rule = {};
        }, 2000);
      }else{
        this.toast.sToast('error', result);
      }
    });
  }

  updateNotificationRule(): void {
    const reqData: any = this.rule;
    if (reqData.companies.length === 0 || reqData.integrations.length == 0){
      this.toast.sToast('error', 'Please Add Company/Integration');
      return;
    }
    const template_keys = ['type', 'subtype', 'summary_template', 'description_template', 'ishtml'];
    let validate: any = true;
    reqData.rules.forEach((ele: any) => {
      template_keys.forEach((key: any) => {
        if (!ele[key] || ele[key] === null || ele[key] === ''){
          this.toast.sToast('error', `Please Fill ${key.toUpperCase()}`);
          validate = false;
          return;
        }
      });
      // ele.description_template = this.sanitizer.bypassSecurityTrustHtml(ele.description_template);
    });
    if (validate === false){
      return;
    }
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/ticketingrulesconfig/`, 'put', reqData).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result){
        setTimeout(() => {
          this.addEditRules = false;
          this.toast.sToast('success', 'Updated Successfully');
          this.getNotificationRulesData();
          this.rule = {};
        }, 2000);
      }else{
        this.toast.sToast('error', result);
      }
    });
  }
}