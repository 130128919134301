import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';
import {MyToastrService} from '../../_services/toastr.service';
import {Router} from '@angular/router';
import {CompanySharedService} from '../../_services/company-shared.service';
import {ConfirmDialogService} from '../../_services/confirmdialog.service';

@Component({
  selector: 'app-migrate-company',
  templateUrl: './migrate-company.component.html',
  styleUrls: ['./migrate-company.component.scss']
})
export class MigrateCompanyComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  inProgress = false;
  selectedCompanies: any = [];
  max10 = false;
  masterData: any;
  inProgressCount: any = 0;
  migrationData: any;
  totalCompanies: any;
  totalMigrated: any;
  showUpper = true;
  constructor(private loaderService: LoaderService, private confirmDialog: ConfirmDialogService,
              private baseService: BaseRequestService,
              private toast: MyToastrService, private router: Router, private cs: CompanySharedService) {
  }

  ngOnInit(): void {
    this.getMigrationCompanies();
  }

  updateSelection($event: any): void {
    this.selectedCompanies = this.migrationData.filter((x: any) => x.selected);
    let allowedCount = 10 - this.inProgressCount;
    if (allowedCount < 0) { allowedCount = 0; }
    if (this.selectedCompanies && this.selectedCompanies.length > allowedCount) {
      this.toast.sToast('error', `Kindly choose a maximum of ${allowedCount} companies for the replication process.`);
      this.max10 = true;
    } else {
      this.max10 = false;
    }
  }

  migrateCompany(): void {
    let allowedCount = 10 - this.inProgressCount;
    if (allowedCount < 0) { allowedCount = 0; }
    if (this.selectedCompanies && this.selectedCompanies.length > allowedCount) {
      this.toast.sToast('error', `Kindly choose a maximum of ${allowedCount} companies for the replication process.`);
      this.max10 = (!allowedCount);
      return;
    }
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to replicate selected companies?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.selectedCompanies = this.migrationData.filter((x: any) => x.selected);
        if (!this.selectedCompanies || !this.selectedCompanies.length) {
          this.toast.sToast('error', 'Please select the companies.');
          return;
        }
        const selectedIds = this.selectedCompanies.map((x: any) => x.company_id);
        this.loaderService.display(true, 'Initiating...');
        this.baseService.doRequest(`/api/cyberutils/imaws/migrate_companies_tov4`,
          'post', { companyid: selectedIds}).subscribe((result: any) => {
          this.loaderService.display(false); this.inProgress = false;
          if (result[0]) {
            this.toast.sToast('success', result[1]);
            setTimeout(() => this.getMigrationCompanies(), 3000);
            setTimeout(() => this.getMigrationCompanies(), 60000);
          } else {
            this.toast.sToast('error', result[1]);
          }
        }, (err: any) => {
          this.loaderService.display(false);
          this.toast.sToast('error', 'Error processing data.');
          this.getMigrationCompanies();
        });
      }
    });
  }

  getMigrationCompanies(): void {
    this.loaderService.display(true, 'Getting Replication Status...');
    this.baseService.doRequest(`/api/cyberutils/imaws/get_v4_migration_status`,
      'post').subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        this.inProgressCount = 0;
        result[1].forEach((obj: any) => {
          if (obj.reason.toLowerCase() === 'migration in-progress') {
            this.inProgressCount++;
          }
        });
        this.masterData = result[1];
        this.migrationData = result[1].slice();
        this.migrationData.sort((a: any, b: any) => {
          const c = a.name;
          const d = b.name;
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        this.totalCompanies = result[1].length;
        this.totalMigrated = result[1].filter((x: any) => !x.allowed).length;
      } else {
        this.toast.sToast('error', result[1]);
      }
    }, (err: any) => {
      this.loaderService.display(false);
      this.toast.sToast('error', 'Error receiving data.');
      this.masterData = [];
      this.migrationData = [];
    });
  }

}
