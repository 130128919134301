import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/auth.guard';
import {PnfComponent} from './shared/pnf/pnf.component';
import {UnauthorizedComponent} from './shared/unauthorized/unauthorized.component';
import { DynamicFormComponent } from './shared/forms/dynamic-form/dynamic-form.component';
import { MaintenanceComponent } from './login/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'me/:domainName',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'oauth2/auth',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PnfComponent
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
