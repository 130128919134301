import {Injectable, EventEmitter} from '@angular/core';
import {Asset} from '../api/models/asset';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanySharedService {
  // Global Search
  searchFilter = new Subject<string>();
  setCurrentView = new EventEmitter();
  updateMenu = new EventEmitter();
  disableMenu = new EventEmitter();
  disableSnooze = new EventEmitter();
  assetShowTableView = new EventEmitter();
  agentCheckItem = new EventEmitter();
  disableOverview = new EventEmitter();
  companyChange = new EventEmitter();
  refreshOtherAssetEVE = new EventEmitter();
  assessmentChange = new EventEmitter();
  // Discovery Settings Actions
  companyScanEVE = new EventEmitter();
  agentSelectEVE = new EventEmitter();
  hideMenuEVE = new EventEmitter();
  changeSortEVE = new EventEmitter();
  // Asset Actions
  showAssetTable = false;
  agentDeprecateEVE = new EventEmitter();
  integrationExternalSystemEVE = new EventEmitter();
  agentDeprecateCloseEVE = new EventEmitter();
  jobsUpdateEVE = new EventEmitter();
  complianceViewEVE = new EventEmitter();
  cmpScanEVE = new EventEmitter();
  ExtScanEVE = new EventEmitter();
  ADScanEVE = new EventEmitter();
  AssetEVE = new EventEmitter();
  FilterEVE = new EventEmitter();
  AgentpopupEVE = new EventEmitter();
  assetTableView = new EventEmitter();
  globalFilterEVE = new EventEmitter();
  addAssetEVE = new EventEmitter();
  refreshAssetEVE = new EventEmitter();
  updateAssetEVE = new EventEmitter();
  assetScanEVE = new EventEmitter();
  assetSortEVE = new EventEmitter();
  evidenceSortEVE = new EventEmitter();
  firewallScanEVE = new EventEmitter();
  downloadReportEVE = new EventEmitter();
  assetFilterEVE = new EventEmitter();
  getstartintEVE = new EventEmitter();
  getstartRemEVE = new EventEmitter();
  getstartNotificationEVE = new EventEmitter();
  getstartApplicationBaselineEVE = new EventEmitter();
  getstartRolesEVE = new EventEmitter();
  getstartUsersEVE = new EventEmitter();
  getstartSchedulerEVE = new EventEmitter();
  getstartReportBuilderEVE = new EventEmitter();
  currentAsset!: Asset;
  externalScanEVE = new EventEmitter();
  remediationSuppressEVE = new EventEmitter();
  lastAdScanTime = new EventEmitter();
  rmPatchEvent: Subject<any> = new Subject<any>();
  rmViewAssetsEvent: Subject<any> = new Subject<any>();
  rmViewEvidence: Subject<any> = new Subject<any>();
  totalEVE: Subject<any> = new Subject<any>();
  // dateFormatValue: Subject<any> = new Subject<any>();
  reloadAndSetCompanyEVE: Subject<any> = new Subject<any>();
  jobsViewEVE: Subject<any> = new Subject<any>();
  filterUpdateEVE: Subject<any> = new Subject<any>();
  filterUpdate = new Subject<string>();
  reportFilterUpdate = new Subject<string>();
  selectAllUpdate = new Subject<boolean>();
  updateTag = new Subject<any>();
  updateImportance = new Subject<any>();
  deleteAssets = new Subject<any>();
  selectedAssets: boolean | undefined = false;
  // Shared Keys
  totals: any =  {};
  assetFilter = '';
  statusFilter: any = '';
  scanFilter: any = '';
  discoveredFilter: any = 'all';
  reportFilter: any;
  complianceList: any = [];
  filterList: any = {
    uifilters: [],
    reportfilters: {}
  }
  selectedFormat: string ;
  constructor() {
    this.searchFilter.pipe( debounceTime(500), distinctUntilChanged()) .subscribe(value => {
      this.globalFilterEVE.next(value);
    });
    this.filterUpdate.pipe(debounceTime(1500), distinctUntilChanged()).subscribe(value => {
      this.filterUpdateEVE.next(value);
    });
  }

  showAssetTableView(value: any): void {
    this.showAssetTable = value;
    this.assetTableView.next({value});
  }

  setDateFormat(value: any): void {
    console.log("------------in shared service date format---------",value);
    this.selectedFormat = value;
    localStorage.setItem('selectedDateFormat', value);
  }

  setCurrentCompany(company: string): void {
    localStorage.cmp = company;
    this.reloadAndSetCompanyEVE.next({value: company});
  }

  companyScan(scanType: string): void {
    this.companyScanEVE.next({st: scanType});
  }

  addAsset(): void {
    this.addAssetEVE.next({});
  }


  reloadAsset(): void {
    this.refreshAssetEVE.next({});
  }

  updateAsset(): void {
    this.updateAssetEVE.next({});
  }

  firewallScan(): void {
    this.firewallScanEVE.next({});
  }

  assetScan(val: any): void {
    this.assetScanEVE.next({value: val});
  }

  assetSort(key: string, sort: string, isSave: any): void {
    this.assetSortEVE.next({key, sort, isSave});
  }

  evidenceSort(key: string, sort: string): void {
    this.evidenceSortEVE.next({key, sort});
  }

  downloadReport(): void {
    this.downloadReportEVE.next({});
  }

  assetStatusFilter(): void {
    this.refreshAssetEVE.next({});
  }

  externalScan(): void {
    this.externalScanEVE.next({});
  }

  suppressRemediation(): void {
    this.remediationSuppressEVE.next({});
  }

  patchRemediation(): void {
    this.rmPatchEvent.next({});
  }

  viewRemediationAssets(): void {
    this.rmViewAssetsEvent.next({});
  }

  viewRemediateEvidence(): void {
    this.rmViewEvidence.next({});
  }


}
