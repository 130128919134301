<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container h-100vh" (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <app-s-crud-t (addCallback)="addEditAppBaseline()" (actionCallback)="actionCalback($event)"
                    *ngIf="appBaselineColOptions && appBaselineColOptions.columns &&
               aS.hasPermission('applicationbaseline', 'read')"
                    [tOption]="appBaselineColOptions"></app-s-crud-t>
    </mat-sidenav-content>
    <mat-sidenav #snav [mode]="'over'" fixedTopGap="56" position="end" class="w-60 z-index-150">
      <mat-card class="w-100">
        <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
                (click)="snav.close();" mat-icon-button="">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw3">
            <span *ngIf="!currentAppBaseline._id">Add</span>
            <span *ngIf="currentAppBaseline._id">Edit</span> Application Baseline Rules
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="currentAppBaseline">
          <form #appBaseForm="ngForm" role="form">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Rule Name</mat-label>
              <input type="text" matInput placeholder="Rule Title" id="ApplicationRuleName" name="name"
                     [(ngModel)]="currentAppBaseline.name" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select OS Type</mat-label>
              <mat-select name="os_type" id="ApplicationOSType" required [(ngModel)]="currentAppBaseline.os_type">
                <mat-option value="windows" id="AppWindows">Windows</mat-option>
                <mat-option value="linux" id="AppLinux">Linux</mat-option>
                <mat-option value="darwin" id="AppMac">Darwin</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Enter OS Name</mat-label>
              <input type="text" id="AppOSName" matInput placeholder="OS Name" name="osname"
                     [(ngModel)]="currentAppBaseline.osname" required>
              <mat-hint>Windows Server 2012 R2</mat-hint>
            </mat-form-field>
            <br>
            <br>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select Type</mat-label>
              <mat-select name="type" id="appBaselineType" required [(ngModel)]="currentAppBaseline.type">
                <mat-option value="application" id="appli">Application</mat-option>
                <mat-option value="service" id="servi">Service</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select OS Name</mat-label>
              <mat-select name="osname" id="ApplicationOSName" [(ngModel)]="currentAppBaseline.osname">
              <mat-option *ngFor="let item of getListOfOS" [value]="item">{{item}}</mat-option>
            </mat-select>
            </mat-form-field> -->
            <ng-container *ngIf="currentAppBaseline.type === 'application'">
              <h3 class="fs1 fw2 mb-0">Add Denied Application(s)</h3>
              <mat-form-field class="w-50 mat-small" appearance="outline">
                <mat-label>Denied Applications</mat-label>
                <input matInput type="text" name="denied" id="AppDenied2"
                       placeholder="Enter {{currentAppBaseline.type | camelToHuman: true}} full name"
                       [matAutocomplete]="deniedApp" [formControl]="deniedAppCtrl"
                       [(ngModel)]="denied" matTooltip="Enter denied Application name and click 'Plus' icon to add">
                
                <mat-autocomplete #deniedApp="matAutocomplete">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let app of deniedApplications| sortBy:'asc'" [value]="app">
                      <span class="mat-small" [matTooltip]="app">{{app}}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <mat-checkbox name="denRegularExpression" id="denRegularExpression" class="mb-2 mt-1 ml-2 mr-4"
                          [(ngModel)]="deniedappregex"
                          value="" color="primary">Regex 
              </mat-checkbox>
              <button mat-button *ngIf="denied" matSuffix mat-icon-button aria-label="Add Denied"
                        (click)="addDeniedApplications()">
                  <mat-icon class="tprime">add</mat-icon>
                </button>
              <ul class="w-100 bor-1 p-0" *ngIf="currentAppBaseline.deniedApplications &&
               currentAppBaseline.deniedApplications.length">
                <li class="d-flex flex-row aline-items-center bb-1 px-2 py-1"
                    *ngFor="let deniedApp of currentAppBaseline.deniedApplications; index as i; trackBy:loaderService.trackByFn">
                  <span class="tprime">{{deniedApp.serviceName}}</span><span>&nbsp;</span> - <span>&nbsp;</span>
                  <span class="tprime" *ngIf="deniedApp.regularExpression">Regex Condition</span>
                  <span class="tprime" *ngIf="!deniedApp.regularExpression">Exact Match</span>
                  <span class="spacer">&nbsp;</span>
                  <span class="pointer"><mat-icon id="deletedeniedApp2"
                                                  (click)="currentAppBaseline.deniedApplications.splice(i, 1)"
                                                  class="mat-18">delete</mat-icon></span>
                </li>
              </ul>
              <p class="fs1 fw3" *ngIf="!currentAppBaseline.deniedApplications ||
               !currentAppBaseline.deniedApplications.length">No denied {{currentAppBaseline.type}}(s) added!</p>
            </ng-container>
            <ng-container *ngIf="currentAppBaseline.type === 'service'" class="d-flex">
              <h3 class="fs1 fw2 mb-0">Add Denied Service(s)</h3>
              <mat-form-field class="w-48 mat-small" appearance="outline">
                <mat-label>Denied Services</mat-label>
                <input matInput type="text" name="deniedservice" id="AppDenied3"
                       placeholder="Enter text to search {{currentAppBaseline.type | camelToHuman: true}} full name"
                       [matAutocomplete]="deniedSer" [formControl]="deniedAppCtrl"
                       [(ngModel)]="deniedservice" matTooltip="Enter denied Service name">
                <mat-autocomplete #deniedSer="matAutocomplete">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let app of deniedService| sortBy:'asc'" [value]="app">
                      <span class="mat-small" [matTooltip]="app">{{app}}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="w-48 ml-2 mat-small" appearance="outline">
                <mat-label>Service Application Name</mat-label>
                <input matInput type="text" name="deniedapp" id="serviceAppName"
                       placeholder="Enter Application full name"
                       [(ngModel)]="deniedapp" matTooltip="Enter service application name and click 'Plus' icon to add">
              </mat-form-field>
              <mat-checkbox name="denappRegularExpression" id="denappRegularExpression" class="mb-2 mt-1 ml-2 mr-4"
                          [(ngModel)]="deniedregex"
                          value="" color="primary">Regex 
              </mat-checkbox>
              <button mat-button matSuffix mat-icon-button aria-label="Add Denied" *ngIf="deniedservice && deniedapp"
                      (click)="adddeniData()">
                <mat-icon class="tprime">add</mat-icon>
              </button>
              <ul class="w-100 bor-1 p-0" *ngIf="currentAppBaseline.deniedService &&
               currentAppBaseline.deniedService.length">
                <li class="d-flex flex-row aline-items-center bb-1 px-2 py-1"
                    *ngFor="let deniedApp of currentAppBaseline.deniedService; index as i; trackBy:loaderService.trackByFn">
                  <span class="tprime">{{deniedApp.serviceName}}</span><span>&nbsp;</span> - <span>&nbsp;</span>
                  <span class="tprime">{{deniedApp.applicationName}}</span><span>&nbsp;</span> - <span>&nbsp;</span>
                  <span class="tprime" *ngIf="deniedApp.regularExpression">Regex Condition</span>
                  <span class="tprime" *ngIf="!deniedApp.regularExpression">Exact Match</span>
                  <span class="spacer">&nbsp;</span>
                  <span class="pointer"><mat-icon id="deletedeniedApp"
                                                  (click)="currentAppBaseline.deniedService.splice(i, 1)"
                                                  class="mat-18">delete</mat-icon></span>
                </li>
              </ul>
              <p class="fs1 fw3" *ngIf="!currentAppBaseline.deniedService ||
               !currentAppBaseline.deniedService.length">No denied Service(s) added!</p>
            </ng-container>
            <hr>
            <ng-container *ngIf="currentAppBaseline.type === 'application'">
              <h3 class="fs1 fw2">Add Mandatory {{currentAppBaseline.type | camelToHuman: true}}(s)</h3>
              <mat-form-field class="w-50 mat-small" appearance="outline">
                <mat-label>Mandatory {{currentAppBaseline.type | camelToHuman: true}}</mat-label>
                <input matInput type="text" name="denied" id="AppMandatory"
                       placeholder="Enter {{currentAppBaseline.type | camelToHuman: true}} full name"
                       [matAutocomplete]="mandatoryApp" [formControl]="mandatoryAppCtrl"
                       [(ngModel)]="mandatory"
                       matTooltip="Enter mandatory {{currentAppBaseline.type | camelToHuman: true}} name and press enter">
                <mat-autocomplete #mandatoryApp="matAutocomplete">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let app of mandatoryApplications| sortBy:'asc'" [value]="app">
                      <span class="mat-small" [matTooltip]="app">{{app}}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <mat-checkbox name="manRegularExpression" id="manRegularExpression" class="mb-2 mt-1 ml-2 mr-4"
                          [(ngModel)]="mandappregex"
                          value="" color="primary">Regex 
              </mat-checkbox>
              <button mat-button *ngIf="mandatory" matSuffix mat-icon-button aria-label="Add Denied"
                        (click)="addMandatoryApplications();">
                  <mat-icon class="tprime">add</mat-icon>
              </button>
              <ul class="w-100 bor-1 p-0" *ngIf="currentAppBaseline.mandatoryApplications &&
               currentAppBaseline.mandatoryApplications.length">
                <li class="d-flex flex-row aline-items-center bb-1 px-2 py-1"
                    *ngFor="let mandatoryApp of currentAppBaseline.mandatoryApplications; index as i; trackBy:loaderService.trackByFn">
                    <span class="tprime">{{mandatoryApp.serviceName}}</span><span>&nbsp;</span> - <span>&nbsp;</span>
                    <span class="tprime" *ngIf="mandatoryApp.regularExpression">Regex Condition</span>
                    <span class="tprime" *ngIf="!mandatoryApp.regularExpression">Exact Match</span>
                  <span class="spacer">&nbsp;</span>
                  <span class="pointer"><mat-icon id="deletemandatoryApp"
                                                  (click)="currentAppBaseline.mandatoryApplications.splice(i, 1)"
                                                  class="mat-18">delete</mat-icon></span>
                </li>
              </ul>
              <p class="fs1 fw3" *ngIf="!currentAppBaseline.mandatoryApplications ||
               !currentAppBaseline.mandatoryApplications.length">No mandatory {{currentAppBaseline.type}}(s) added!</p>
            </ng-container>
            <ng-container *ngIf="currentAppBaseline.type === 'service'" class="d-flex">
              <h3 class="fs1 fw2 mb-0">Add Mandatory Service(s)</h3>
              <mat-form-field class="w-48 mat-small" appearance="outline">
                <mat-label>Mandatory Services</mat-label>
                <input matInput type="text" name="mandatoryservice" id="AppDenied"
                       placeholder="Enter text to search {{currentAppBaseline.type | camelToHuman: true}} full name"
                       [matAutocomplete]="mandatorySer" [formControl]="mandatoryAppCtrl"
                       [(ngModel)]="mandatoryservice" matTooltip="Enter mandatory service name">
                <mat-autocomplete #mandatorySer="matAutocomplete">
                  <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                  <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let app of mandatoryService| sortBy:'asc'" [value]="app">
                      <span class="mat-small" [matTooltip]="app">{{app}}</span>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="w-48 ml-2 mat-small" appearance="outline">
                <mat-label>Service Application Name</mat-label>
                <input matInput type="text" name="mandatoryapp" id="mServiceAppName"
                       placeholder="Enter Application full name"
                       [(ngModel)]="mandatoryapp"
                       matTooltip="Enter service application name and click 'Plus' icon to add">
              </mat-form-field>
              <mat-checkbox name="manappRegularExpression" id="manappRegularExpression" class="mb-2 mt-1 ml-2 mr-4"
                          [(ngModel)]="mandatoryappregex"
                          value="" color="primary">Regex 
              </mat-checkbox>
              <button mat-button matSuffix mat-icon-button aria-label="Add Denied"
                      *ngIf="mandatoryservice && mandatoryapp"
                      (click)="addmandData()">
                <mat-icon class="tprime">add</mat-icon>
              </button>
              <ul class="w-100 bor-1 p-0" *ngIf="currentAppBaseline.mandatoryService &&
               currentAppBaseline.mandatoryService.length">
                <li class="d-flex flex-row aline-items-center bb-1 px-2 py-1"
                    *ngFor="let mandApp of currentAppBaseline.mandatoryService; index as i; trackBy:loaderService.trackByFn">
                  <span class="tprime">{{mandApp.serviceName}}</span><span>&nbsp;</span> - <span>&nbsp;</span>
                  <span class="tprime">{{mandApp.applicationName}}</span><span>&nbsp;</span> - <span>&nbsp;</span>
                  <span class="tprime" *ngIf="mandApp.regularExpression">Regex Condition</span>
                  <span class="tprime" *ngIf="!mandApp.regularExpression">Exact Match</span>
                  <span class="spacer">&nbsp;</span>
                  <span class="pointer"><mat-icon id="deletedeniedApp1"
                                                  (click)="currentAppBaseline.mandatoryService.splice(i, 1)"
                                                  class="mat-18">delete</mat-icon></span>
                </li>
              </ul>
              <p class="fs1 fw3" *ngIf="!currentAppBaseline.mandatoryService ||
               !currentAppBaseline.mandatoryService.length">No mandatory service(s) added!</p>
            </ng-container>
            <mat-form-field class="w-100 mb-2" appearance="outline">
              <mat-label>Ignore Tags</mat-label>
              <mat-chip-list #ignoreList aria-label="Tag">
                <mat-chip class="deniedr" *ngFor="let tag of ignoreTags" [selectable]="selectable" [removable]="removable"
                          (removed)="removeTag(tag, 'ignore')"> {{tag.name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input type="text" trim="blur" autocomplete="off" name="ignore tag"
                       placeholder="Enter a tag and press comma or enter to add." [matChipInputFor]="ignoreList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addTag($event, 'ignore')">
              </mat-chip-list>
            </mat-form-field>
            <mat-form-field class="w-100 mb-2" appearance="outline">
              <mat-label>Include Tags</mat-label>
              <mat-chip-list #includeList aria-label="Tag">
                <mat-chip class="allowedg" *ngFor="let tag of includeTags" [selectable]="selectable" [removable]="removable"
                          (removed)="removeTag(tag, 'include')"> {{tag.name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input type="text" trim="blur" autocomplete="off" name="ignore tag"
                       placeholder="Enter a tag and press comma or enter to add." [matChipInputFor]="includeList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addTag($event, 'include')">
              </mat-chip-list>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-element w-100 mt-2" *ngIf="mode === 'global'">
              <mat-select #companySelect [multiple]="true"
                  placeholder="Select Exclude companies" name="Company"
                  [(ngModel)]="currentAppBaseline.exclude_company" [formControl]="companyCtrl"
                  (selectionChange)="currentAppBaseline.companyid = ($event.value?.includes('*')) ? ['*'] : $event.value">
                  <mat-option>
                      <ngx-mat-select-search [formControl]="companyFilterCtrl"
                          [placeholderLabel]="'Search Company'"
                          [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
                      </ngx-mat-select-search>
                  </mat-option>
                  <!-- <mat-option matTooltip="All Companies" value="*">All Companies</mat-option> -->
                  <mat-option [matTooltip]="company.name" *ngFor="let company of allComp | search: companyFilterCtrl.value"
                      [value]="company._id">
                      {{company.name}}
                  </mat-option>
              </mat-select>
            </mat-form-field>

            <hr>
            <mat-checkbox name="isLightWeightMandatory" id="LightMandatory" class="mb-2 mt-1"
                          [(ngModel)]="currentAppBaseline.isLightWeightMandatory"
                          value="" color="primary">Lightweight Agent -Mandatory
            </mat-checkbox>
            <br>
            <button type="button" color="primary" class="mr-2" id="saveAppBaseline1"
                    *ngIf="currentAppBaseline.type === 'application'"
                    [disabled]="!appBaseForm.form.valid || !currentAppBaseline || ( !currentAppBaseline.mandatoryApplications.length
                     && !currentAppBaseline.deniedApplications.length)" (click)="saveAppBaseline()"
                    mat-raised-button="">
              Save
            </button>
            <button type="button" color="primary" class="mr-2" id="saveAppBaseline2"
                    *ngIf="currentAppBaseline.type === 'service'"
                    [disabled]="!appBaseForm.form.valid || !currentAppBaseline ||
                     ( !currentAppBaseline.mandatoryService.length && !currentAppBaseline.deniedService.length )"
                    (click)="saveAppBaseline()" mat-raised-button="">
              Save
            </button>
            <button (click)="snav.close()" mat-stroked-button="">Cancel</button>
          </form>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
