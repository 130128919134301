import { Directive, HostListener } from '@angular/core';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';

@Directive({
  selector: '[appFormDataCheck]'
})

export class FormDataCheckDirective {
  

  constructor(private formComponent: DynamicFormComponent) { }
  //Pop up message while refreshing the page
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.formComponent.isDirty) {
      $event.returnValue = true;
    }
  }

}
