import {Component, Inject, Input, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {BaseRequestService} from 'src/app/_services/base.service';
import {CommonService} from 'src/app/_services/common.services';
import {LoaderService} from 'src/app/_services/loader.service';
import {ConfirmDialogService} from 'src/app/_services/confirmdialog.service';
import {MyToastrService} from '../../_services/toastr.service';

@Component({
  selector: 'app-log4j-email-notify',
  templateUrl: './log4j-email-notify.component.html',
  styleUrls: ['./log4j-email-notify.component.scss']
})
export class Log4jEmailNotifyComponent implements OnInit {
  @Input() currentCompany: any;
  log4jNotificationEmail: any;
  constructor(public commonService: CommonService,
              public baseService: BaseRequestService,
              private confirmDialog: ConfirmDialogService,
              public aS: AuthenticationService,
              private toast: MyToastrService,
              public loaderService: LoaderService) { }

  ngOnInit(): void {
    this.getLog4jNotificationEmail();
  }

  setLog4jNotificationEmail(): void {
    if (!this.log4jNotificationEmail.length) {
      this.toast.sToast('error', 'Please enter valid email address(es)');
    } else {
      const arry = this.log4jNotificationEmail.split(',');
      const emails: any = {}; arry.forEach((element: any) => {
        emails[element] = element;
      });
      if (Object.keys(emails).length !== arry.length) {
        this.toast.sToast('error', 'Please enter valid and unique email address(es)');
        return;
      }
      this.loaderService.display(true, 'Updating discovery timeout settings...');
      this.baseService.doRequest(`/api/company/addL4jNotificationEmail`, 'post',
        {companyid: (this.currentCompany && this.currentCompany._id) ? this.currentCompany._id : null, email: this.log4jNotificationEmail}).subscribe((result: any) => {
         this.loaderService.display(false);
         if (result[0]) {
           this.toast.sToast('success', result[1]);
         } else {
           this.toast.sToast('error', result[1]);
         }
      });
    }
  }

  getLog4jNotificationEmail(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/company/getL4jNotificationEmail`, 'post', {companyid: (this.currentCompany && this.currentCompany._id) ? this.currentCompany._id : null})
    .subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        this.log4jNotificationEmail = result[1];
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

}
