import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {BaseRequestService} from 'src/app/_services/base.service';
import {ModalService} from 'src/app/_services/modal.service';
import {LoaderService} from 'src/app/_services/loader.service';
import {ConfirmDialogService} from 'src/app/_services/confirmdialog.service';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {CommonService} from '../../../_services/common.services';
import {MyToastrService} from '../../../_services/toastr.service';
import {SchedulerService} from '../../../api/services/scheduler.service';
import {CompanySharedService} from '../../../_services/company-shared.service';
import { ReportFiltersComponent } from '../../report-filters/report-filters.component';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-report-scheduler',
  templateUrl: './report-scheduler.component.html',
  styleUrls: ['./report-scheduler.component.scss']
})
export class ReportSchedulerComponent implements OnInit {
  @Input() currentCompany: any;
  hideEmail = true;
  private qP: any;
  filterData: any;
  companyId: any;

  constructor(public baseService: BaseRequestService, private schedulerService: SchedulerService,
              private commonService: CommonService, private cs: CompanySharedService,
              public modalService: ModalService, public toast: MyToastrService, private aS: AuthenticationService,
              public loaderService: LoaderService, public confirmDialog: ConfirmDialogService) {

    this.scanTypes = [{name: 'Vulnerability Scan', action: 'vulnerability', siterequired: true, assetrequired: true},
      {name: 'Asset Discovery', action: 'discovery', siterequired: true, assetrequired: false},
    ];

    this.repeatList = [
      {value: 'hourly', text: 'Hourly'},
      {value: 'daily', text: 'Daily'},
      {value: 'monthly', text: 'Monthly'},
      {value: 'lastDay', text: 'Last Day Of Every Month'},
      {value: 'yearly', text: 'Yearly'},
    ];

    this.monthList = [
      {value: '1', text: 'January'},
      {value: '2', text: 'February'},
      {value: '3', text: 'March'},
      {value: '4', text: 'April'},
      {value: '5', text: 'May'},
      {value: '6', text: 'June'},
      {value: '7', text: 'July'},
      {value: '8', text: 'August'},
      {value: '9', text: 'September'},
      {value: '10', text: 'October'},
      {value: '11', text: 'November'},
      {value: '12', text: 'December'},
    ];

    this.schList = [
      {value: 'every_day', text: 'Every Day'},
      {value: 'hourly', text: 'Hourly'},
      {value: 'weekly', text: 'Weekly'},
      {value: 'days_in_a_month', text: 'Days In A Month'},
      {value: 'last_day_of_every_month', text: 'Last Day Of Every Month'},
      {value: 'yearly', text: 'Yearly'}
    ];

    this.days = [
      {value: '0', text: 'Sunday'},
      {value: '1', text: 'Monday'},
      {value: '2', text: 'Tuesday'},
      {value: '3', text: 'Wednesday'},
      {value: '4', text: 'Thursday'},
      {value: '5', text: 'Friday'},
      {value: '6', text: 'Saturday'}
    ];
    this.weekl = [
      {value: '1', text: '1st Week'},
      {value: '2', text: '2nd Week'},
      {value: '3', text: '3rd Week'},
      {value: '4', text: '4th Week'}
    ];

    this.hourl = [];

    for (let index = 0; index < 60; index++) {
      const valueOrText = index + 1;
      if (index < 31) {
        this.daysList.push({value: valueOrText, text: valueOrText});
      }
      if (index < 23) {
        this.hoursList.push({value: index, text: index});
      }
      this.mintsList.push({value: index, text: index});
    }

    this.scheTableOptions = {
      columns: [
        {
          header: 'Is Active',
          columnDef: 'isActive',
          filter: '',
          cell: '(element: any) => `${element.isActive}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
          statusicon: true,
          success: true, 
          failure: false,
          successIconPath: 'assets/images/devices/tick.svg', successToolTip: 'True',
          failureIconPath: 'assets/images/devices/cross.svg', failureToolTip: 'False',
        },
        {
          header: 'Scheduler Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true
        },
        {
          header: 'Scheduler',
          columnDef: 'scheduler',
          filter: 'cameltohuman',
          cell: '(element: any) => `${element.scheduler}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true
        },
        {
          header: 'Scheduled Time',
          columnDef: 'time',
          filter: '',
          cell: '(element: any) => `${element.time}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false
        },
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 5,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false
        }
        ],
      sortOptions: {active: 'name', direction: 'asc'},
      faClass: 'Scheduler',
      _pageData: [],
      tableOptions: {
        id: 'schedulers',
        title: 'Schedulers',
        isServerSide: true, add: (this.aS.hasPermission('scheduler', 'create')),
        selectText: 'schedulers(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 30,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        hideDownload: true,
        saveData: true,
        filterDownload: false
      }
    };
    for ( let i = 12; i <= 23; i ++) {
      const hrData = (i === 1) ? {value: i, name: `Every ${i} hour`} : {value: i, name: `Every ${i} hours`};
      this.hourl.push(hrData);
    }
    if (this.aS.hasPermission('scheduler', 'update')){
      this.scheTableOptions.tableOptions.actionMenuItems.unshift({text: 'Edit', icon: 'edit', callback: 'editFn', isGlobal: false});
    }
    if (this.aS.hasPermission('scheduler', 'delete')) {
      this.scheTableOptions.tableOptions.actionMenuItems.push({text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true});
    }
  }

  @Input() companyid: string;
  // tslint:disable-next-line:no-input-rename
  @Input('companyName') companyName: string;
  emailList: any = [''];
  agentList: any = [];
  agentHash: any = {};
  agList: any;
  schedulerTypeList: any = [];
  schedulerTypeHash: any = [];
  public siteServerSideCtrl: FormControl = new FormControl();
  public siteServerSideFilteringCtrl: FormControl = new FormControl();
  public searching = false;
  protected onDestroy = new Subject<void>();
  public filteredServerSideSites: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('reportSelect', {static: true}) reportSelect!: MatSelect;
  public reportCtrl: FormControl = new FormControl();
  public reportFilterCtrl: FormControl = new FormControl();
  
  sites: any[] = [];
  schedules: any = [];
  scanTypes: any;
  allSchedList: any = [];
  repeatList: any;
  scheTableOptions: any;
  scheduler: any;
  schedulerData: any;
  scheTitle: any;
  filterQuery: any;
  pageTotal = 0;
  pageSize = 30;
  currentPage = 0;
  row: any;
  sublevelData: any = {};
  thirdlevelData: any = {};
  // tslint:disable-next-line:variable-name
  _sublevelData: any = {};
  _thirdlevelData: any = {};
  scheDetailsObj = new SchedulerDetails();

  isAddOrEdit = false;
  schList: any = [];
  days: any = [];
  weekl: any = [];
  hourl: any = [];
  monthList: any = [];
  daysList: any = [];
  hoursList: any = [];
  mintsList: any = [];
  daily = false;
  weekly = false;
  hourly = false;
  monthly = false;
  lastDay = false;
  quarterly = false;
  yearly = false;
  asset = false;
  monList: any;
  hourList: any;
  minList: any;
  yearList: any;
  siteId: any = '';
  deviceData: any = [];
  site = false;
  daysListOpt: any = [];
  hourMintTime: any;
  // tslint:disable-next-line:variable-name
  compList: any;
  _companyList: any;
  scheObjRow: any = {};

  companyHash: any = {};
  schedulerFinalData: any = [];

  ngOnInit(): void {
    this.commonService.getTimeZone();
    this.isAddOrEdit = false;
    this.scheTableOptions.pageData = [];
    setTimeout(() => { this.getSchedulers(); }, 1000);
    this.getSite();
    this.getAllCompanies();
    this.getSchedulerType();
    // this.checkEmailEnabled();
    this.getAppliances();
    /* this.siteServerSideFilteringCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this.onDestroy),
          debounceTime(800),
          map(search => {
            if (!this.sites) {
              return [];
            }
            this.baseService.doRequest(`/api/company/`, 'get', null,
              {
                skip: 0, limit: 25, query: {
                  'query': {
                    'bool': {
                      'must': [{ 'match': { 'family.keyword': 'company' } },
                      { 'match': { 'species.keyword': 'company' } },
                      { 'regexp': { 'name': { 'value': '.*' + search.toLowerCase() + '.*' } } }]
                    }
                  }
                }
              }
            ).subscribe(result => {
              if (result) {
                result.msg.data.push({ _id: '', name: 'All' });
                this.filteredServerSideSites.next(result.msg.data);
                this.searching = false;
              } else {
                this.searching = false;
              }
            });
            // simulate server fetching and filtering data
            return this.sites.filter(site => site.name.toLowerCase().indexOf(search) > -1);
          }),
          delay(100)
        ).subscribe(filteredSites => {
          if (!this.searching) {
            // this.filteredServerSideSites.next(filteredSites);
          }
          this.searching = false;
        }, error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });
        */
  }

  confirmToggle(scheDetailsObj: any, form: NgForm): void {
    this.scheObjRow = scheDetailsObj.row;
    const titleName = 'Confirmation';
    const message = (this.scheObjRow.active) ? 'Deactivating ' + this.scheObjRow.name +
      ' schedule. Proceed ?' : 'Activating ' +
      this.scheObjRow.name + ' schedule. Proceed ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any
    ) => {
      if (res) {
        this.loaderService.display(false);
        this.baseService.doRequest(`/api/scheduler/${this.scheObjRow._id}`,
          'put', {active: !this.scheObjRow.active}).subscribe((result: any) => {
          this.loaderService.display(false);
          if (result && result.c !== null && result.u !== null) {
            this.toast.sToast('success', 'Scheduler updated Successfully!');
            setTimeout(() => { this.getSchedulers(); }, 3000);
            form.resetForm();
          } else {
            this.toast.sToast('error', result._id);
          }
        });
      }
    });
  }

  getAllCompanies(): void {
    let cq;
    cq = {query: {bool: {must: [{exists: {field: 'description'}}, {"terms":{"_type_.keyword":["company","companycreate"]}}], must_not: [{exists: {field: 'companyRef'}}]}}};
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'c']);
    this.baseService.doRequest(`/api/company/`, 'get', null,
      {q, skip, limit, sort, fields}).subscribe((result: any) => {
      if (result) {
        result.data.forEach((obj: any) => {
          this.companyHash[obj._id] = obj.name;
        });
        result.data.unshift({_id: '*', name: 'All', companyRef: {name: 'Global', id: '*'}});
        this.compList = result.data.filter((x:any) => x._id === this.companyid);
        this._companyList = result.data.filter((x:any) => x._id === this.companyid);
      }
    });
  }

  updateCurrentSite(event: any): void {
    if (event[0] === '*') {
      this.scheDetailsObj.companyid = ['*'];
      this.compList = [{_id: '*', name: 'All', companyRef: {name: 'Global', id: '*'}}];
    } else {
      this.compList = this._companyList.slice();
    }
  }

  updateCurrentAgent(event: any): void {
    if (event[0] === '*') {
      this.scheDetailsObj.agentid = ['*'];
      this.agentList = [{_id: '*', name: 'All', companyRef: {name: 'Global', id: '*'}}];
    } else {
      this.agentList = this.agList.slice();
    }
  }

  updateSecondLevel(event: any): void {
    if (event[0] === '*') {
      this.scheDetailsObj.subvalue = ['*'];
      this.sublevelData.options = [{value: '*', name: 'All', companyRef: {name: 'Global', id: '*'} }];
    } else {
      this.sublevelData.options = this._sublevelData.options.slice();
    }
  }

  updateThirdLevel(event: any): void {
    if (event[0] === '*') {
      this.scheDetailsObj.thirdvalue = ['*'];
      this.thirdlevelData.options = [{value: '*', name: 'All', companyRef: {name: 'Global', id: '*'}}];
    } else {
      this.thirdlevelData.options = this._thirdlevelData.options.slice();
    }
  }

  changeScanType(value: any): void {
    const scanObj = this.schedulerTypeList.filter((s:any) => s.name === 'Standard Report Generation');
    this.scheDetailsObj.level = scanObj[0].level;
    this.scheDetailsObj.sublevel = scanObj[0].sublevel;
    this.scheDetailsObj.thirdlevel = (scanObj[0].thirdlevel) ? scanObj[0].thirdlevel : undefined;
    if (scanObj[0].ispassword) {
      this.scheDetailsObj.ispassword = scanObj[0].ispassword;
      this.scheDetailsObj.password = scanObj[0].password;
    }
    if (this.scheDetailsObj.sublevel) {
      scanObj[0].sublevelData.options.sort((a: any, b: any) => {
        const c = a.value;
        const d = b.value;
        if (c < d) {
          return -1;
        } else if (c > d) {
          return 1;
        } else {
          return 0;
        }
      });
      this.sublevelData = Object.assign({}, scanObj[0].sublevelData);
      this._sublevelData = Object.assign({}, scanObj[0].sublevelData);
      console.log(this.sublevelData);
    }
    if (this.scheDetailsObj.thirdlevel) {
      this.thirdlevelData = Object.assign({}, scanObj[0].thirdlevelData);
      this._thirdlevelData = Object.assign({}, scanObj[0].thirdlevelData);
      console.log(this.thirdlevelData);
    }
    if (this.scheDetailsObj.level === 'agent') {
      this.getAppliances();
    }
  }


  getAppliances(): void {
    this.loaderService.display(false, 'Getting all probes');
    const query = {
      query: {
        bool: {
          must: [{exists: {field: 'agent_type'}}],
          filter: [{bool: {should: [{match: {ostype: 2}}]}}]
        }
      }
    };
    const q = JSON.stringify(query);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'c', 'companyRef']);
    this.baseService.doRequest(`/api/agent/`, 'get',
      null, {q, skip, limit, sort, fields}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result) {
        result.data.sort((a: any, b: any) => {
          const c = a.name;
          const d = b.name;
          if (c < d) {
            return -1;
          } else if (c > d) {
            return 1;
          } else {
            return 0;
          }
        });
        result.data.forEach((agent: any) => {
          this.agentHash[agent._id] = agent;
        });
        result.data.unshift({_id: '*', companyRef: {name: 'Global', id: '*' }, name: 'All'});
        this.agList = result.data;
        this.agentList = result.data;
      }
    });
  }

  getDevices(): void {
    // const params = {};
    // if (this.filterQuery) {
    //   params['globalConditions'] = this.filterQuery;
    // }
    // if (this.siteId && this.siteId !== '') {
    //   params['perColConditions'] = { 'companyid': this.siteId };
    // }

    const params = this.qP.assetsQuery();
    if (this.siteId && this.siteId !== '') {
      // params['companyid'] = this.siteId
      params.query.bool.must.push({match: {'companyid.keyword': this.siteId + ''}});
    }
    this.baseService.doRequest(`/api/asset/`, 'get', null, {
      query: params, fields: ['assetName', 'ip_addr'],
      limit: 10000,
      skip: 0
    }).subscribe((result: any) => {
      if (result) {
        this.deviceData = [];
        this.deviceData = result.msg.data;
        this.deviceData.unshift({_id: '*', ip: 'All'});
        //  this.assetCtrl.setValue([this.deviceData[this.deviceData.length - 1]]);
        this.asset = true;
      } else {
        this.toast.sToast('error', result.msg);
        this.asset = false;
      }
    });
  }

  globalActionCall(data: any): void {
    this.deleteScheConfirmationDialogForAll(data);
  }

  deleteScheConfirmationDialogForAll(data: any): void {
    let cnt = 0;
    // let supportMsg = '';
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete selected schedulers?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
        this.allSchedList = data.row;
        this.allSchedList.forEach((element: any) => {
          // if (element.isGlobal) { this.toast.sToast('error', `${element.name} cannot be deleted.`); return;}
          this.baseService.doRequest(`/api/scheduler/${element._id}`,
            'delete').subscribe((result: any) => {
            cnt++;
            if (result) {
              if (cnt === this.allSchedList.length) {
                this.toast.sToast('success', 'Scheduler removed successfully');
                this.getSchedulers();
                this.loaderService.display(false);
              }
            } else {
              // supportMsg += element.scheduler + ', ';
              if (cnt === this.allSchedList.length) {
                this.toast.sToast('error', 'Scheduler Not Deleted');
                this.getSchedulers();
                this.loaderService.display(false);
              }
            }
          }, (error: any) => {
            cnt++;
            // supportMsg += element.first_name + ', ';
            if (cnt === this.allSchedList.length) {
              this.toast.sToast('error', 'Scheduler Not Deleted');
              this.getSchedulers();
              this.loaderService.display(false);
            }
          });
        });
      }
    });
  }

  changeSchedulerType(value: any): void {
    this.scheDetailsObj.settings.hourly = '';
    this.scheDetailsObj.settings.months = [];
    this.scheDetailsObj.settings.days = [];
    this.scheDetailsObj.settings.mins = [];
    this.scheDetailsObj.settings.week = [];
    this.scheDetailsObj.settings.weekdays = [];
    this.hourMintTime = '00:01';
    this.switchSchView(value);
  }

  switchSchView(value: string): void {
    switch (value) {
      case 'every_day':
        this.daily = true;
        this.weekly = false;
        this.hourly = false;
        this.monthly = false;
        this.lastDay = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'hourly':
        this.daily = false;
        this.weekly = false;
        this.hourly = true;
        this.monthly = false;
        this.lastDay = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'weekly':
        this.weekly = true;
        this.daily = false;
        this.hourly = false;
        this.monthly = false;
        this.lastDay = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'days_in_a_month':
        this.monthly = true;
        this.lastDay = false;
        this.daily = true;
        this.hourly = false;
        this.weekly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
        case 'last_day_of_every_month':
        this.monthly = false;
        this.lastDay = true;
        this.daily = false;
        this.hourly = false;
        this.weekly = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      case 'quarterly':
        this.quarterly = true;
        this.daily = false;
        this.weekly = false;
        this.monthly = false;
        this.lastDay = false;
        this.yearly = false;
        break;
      case 'yearly':
        this.yearly = true;
        this.daily = false;
        this.hourly = false;
        this.weekly = false;
        this.monthly = true;
        this.lastDay = false;
        this.quarterly = false;
        break;
      default: {
        this.daily = false;
        this.hourly = false;
        this.weekly = false;
        this.monthly = false;
        this.lastDay = false;
        this.quarterly = false;
        this.yearly = false;
        break;
      }
    }
  }

  changeSchedulerTyped(value: any): void {
    this.scheDetailsObj.settings.months = [];
    this.scheDetailsObj.settings.days = [];
    this.scheDetailsObj.settings.mins = [];
    this.hourMintTime = '00:01';
    this.changeSchView(value);
  }

  changeSchView(value: string): void {
    switch (value) {
      case 'daily':
        this.daily = true;
        this.monthly = false;
        this.lastDay = false;
        this.yearly = false;
        break;
      case 'monthly':
        this.monthly = true;
        this.lastDay = false;
        this.daily = false;
        this.yearly = false;
        break;
        case 'lastDay':
        this.monthly = false;
        this.lastDay = true;
        this.daily = false;
        this.yearly = false;
        break;
      case 'yearly':
        this.yearly = true;
        this.daily = false;
        this.monthly = true;
        this.lastDay = false;
        break;
      default: {
        this.daily = false;
        this.monthly = false;
        this.lastDay = false;
        this.yearly = false;
        break;
      }
    }
  }

  MonthFinalVal(val: any): void {
    this.daily = false;
    this.scheDetailsObj.settings.days = [];
    //  console.log(val);
    val.forEach((element: any) => {
      this.scheDetailsObj.settings.days.push(element);
    });
    // this.scheDetailsObj.settings.days = Object.assign([], val);
    if (val.length > 0) {
      this.daily = true;
    }
    this.modalService.close('calPopup');

  }

  monthlySchedulerType(value: any): void {
    if (value) {
      this.monList = value;
      this.daily = true;
    }
  }

  yearlySchedulerType(value: any): void {
    if (value) {
      this.yearList = value;
      this.monthly = true;
    }
  }

  refreshSchedulers(): void {
    this.getSchedulers();
  }

  getSchedulerType(): void {
    this.loaderService.display(false);
    this.baseService.doRequest(`/api/scheduler/dummy/getScheduleTemplates`, 'post',
      {body: {}}).subscribe((result: any) => {
      this.loaderService.display(false);
      result.forEach((obj: any) => {
        this.schedulerTypeHash[obj.name] = obj;
      });
      this.schedulerTypeList = result.filter((s:any) => s.name === 'Standard Report Generation');
    });
  }

  checkEmailEnabled(): void {
    this.loaderService.display(false);
    this.baseService.doRequest(`/integrations/api/ticket_integration/dummy/getIntegrations`, 'post',
      {product: 'email'}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result) {
        this.hideEmail = false; // (result.msg.data && result.msg.data.length) ? false : true;
      }
    });
  }
  
  saveScheduler(scheDetailsObj: any, form: NgForm): void {
    if(!scheDetailsObj.companyid || !scheDetailsObj.companyid.length){
      this.toast.sToast('error', 'Please select minimum of one company.');
      return;
    }
    if(!scheDetailsObj.subvalue || !scheDetailsObj.subvalue.length){
      this.toast.sToast('error', 'Please select minimum of one report.');
      return;
    }
    if(!scheDetailsObj.email|| !scheDetailsObj.email.length || scheDetailsObj.email[0] === ''){
      this.toast.sToast('error', 'Please enter minimum one email address.');
      return;
    }
    if (scheDetailsObj.scheduler !== 'hourly') {
      const timecovert = this.hourMintTime;
      const tms = timecovert.split(':');
      this.scheDetailsObj.settings.hour = [];
      this.scheDetailsObj.settings.mins = [];
      this.scheDetailsObj.settings.hour.push(tms[0]);
      this.scheDetailsObj.settings.mins.push(tms[1]);
      if(this.hourMintTime == '00:00'){
        this.modalService.open('rpModal')
        return;
      }
    } else {
      this.scheDetailsObj.settings.hours = [scheDetailsObj.settings.hourly];
    }
    // this.scheDetailsObj.active = this.scheDetailsObj.active === undefined ? false : this.scheDetailsObj.active;
    this.scheDetailsObj.name = this.scheDetailsObj.name === undefined ? '' : this.scheDetailsObj.name;
    scheDetailsObj.agentRef = []; scheDetailsObj.companyRef = [];
    scheDetailsObj.agentid.forEach((id: any) => {
      if (id === '*') {
        this.scheDetailsObj.isGlobal = true; scheDetailsObj.isGlobal = true;
        return;
      } else {
        this.scheDetailsObj.isGlobal = true; scheDetailsObj.isGlobal = true;
        scheDetailsObj.agentRef.push({id, name: this.agentHash[id].name});
      }
    });
    scheDetailsObj.companyid.forEach((id: any) => {
      if (id === '*') {
        this.scheDetailsObj.isGlobal = true; scheDetailsObj.isGlobal = true;
        return;
      } else {
        this.scheDetailsObj.isGlobal = false; scheDetailsObj.isGlobal = false;
        scheDetailsObj.companyRef.push({id, name: this.companyHash[id]});
      }
    });
    scheDetailsObj.uifilters = (this.cs.filterList.uifilters) ? this.cs.filterList.uifilters : [];
    scheDetailsObj.reportfilters = (this.cs.filterList.uifilters && this.cs.filterList.uifilters.length) ?  JSON.stringify(this.cs.filterList.reportfilters) : "";
    const method = scheDetailsObj._id === undefined ? 'post' : 'put';
    const url = `/api/scheduler/`;
    const msg = (scheDetailsObj._id) ? 'Scheduler updated successfully!' : 'Scheduler added successfully!';
    const auditmsg = (scheDetailsObj._id) ? 'Update Scheduler' : 'Create Scheduler';
    
    this.loaderService.display(true, 'Saving scheduler...');
    this.baseService.doRequest(url, method, scheDetailsObj).subscribe((result: any) => {
      if (result && result.c !== null && result.u !== null) {
        this.toast.sToast('success', msg);
        this.isAddOrEdit = false;
        this.cs.filterList = {
          uifilters: [],
          reportfilters: {}
        }
        setTimeout(() => {
          this.getSchedulers();
        }, 5000);
        form.resetForm();
        this.loaderService.display(false);
      } else {
        this.toast.sToast('error', result._id);
        this.loaderService.display(false);
      }
      this.loaderService.display(false);
    });
  }

  close(form: NgForm): void {
    this.daily = false;
    this.monthly = false;
    this.yearly = false;
    form.resetForm();
    this.isAddOrEdit = false;
    this.filterQuery = '';
    this.getSchedulers();
    this.cs.FilterEVE.next({})
    this.filterData = [];
  }

  filterCall(event: any): void {
    const fields = [];
    this.scheTableOptions.columns.forEach((obj: any) => {
      if (obj.visible && obj.columnDef !== 'status') {
        fields.push(obj.columnDef);
      }
    });

    this.filterQuery = (event && event.length > 0) ?
      {
        multi_match: {
          query: event, type: 'phrase_prefix',
          fields: ['siteName', 'scanType', 'scheduler', 'role', 'name']
        }
      } : undefined;

    this.getSchedulers();
  }

  nth(n: any): any {
    return [n + 'st', n + 'nd', n + 'rd'][((n + 90) % 100 - 10) % 10 - 1] || n + 'th';
  }

  getSchedulers(): void {
    this.schedulerData = [];
    this.loaderService.display(true, 'Getting Scheduler data');
    this.scheTableOptions.serverSide = {
      service: 'schedulerService', fn: 'getAllApiSchedulerGet', q: {
        "query": {
          "bool": {
            "must": [
              {
                "exists": {
                  "field": "settings"
                }
              },
              {
                "exists": {
                  "field": "uniqueid"
                }
              },
              {
                "match": {
                  "uniqueid.keyword": "standard_report_generation"
                }
              },
              {
                "match": {
                  "companyRef.id.keyword": this.companyid
                }
              }
            ],
            "must_not": [
              {
                "exists": {
                  "field": "scanType"
                }
              }
            ]
          }
        }
      }
        /*{
        query: {bool: {must: [{exists: {field: 'settings'}}], must_not: [{exists: {field: 'scanType'}}]}}}*/
    };
    const query = {
      "query": {
        "bool": {
          "must": [
            {
              "exists": {
                "field": "settings"
              }
            },
            {
              "exists": {
                "field": "uniqueid"
              }
            },
            {
              "match": {
                "uniqueid.keyword": "standard_report_generation"
              }
            },
            {
              "match": {
                "companyRef.id.keyword": this.companyid
              }
            }
          ],
          "must_not": [
            {
              "exists": {
                "field": "scanType"
              }
            }
          ]
        }
      }
    };
    if (this.filterQuery && this.filterQuery.multi_match) {
      query.query.bool.must.push(this.filterQuery);
    }
    const q = JSON.stringify(query);
    const skip =  this.currentPage;
    const limit =  this.scheTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (this.scheTableOptions.sortOptions && this.scheTableOptions.sortOptions.direction && this.scheTableOptions.sortOptions.direction !== '') {
    const orderArr = [ 'scheduler', 'name', 'uniqueid', 'uifilters'];
    if (orderArr.indexOf(this.scheTableOptions.sortOptions.active) > -1) {
      sort[0][this.scheTableOptions.sortOptions.active + '.keyword'] = { order: this.scheTableOptions.sortOptions.direction };
    } else {
      sort[0][this.scheTableOptions.sortOptions.active] = { order: this.scheTableOptions.sortOptions.direction };
    }
  }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['isActive', 'name', 'uniqueid', 'scheduler']);
    this.schedulerService.getAllApiSchedulerGet({q, skip, limit, sort})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.schedulerData = result;
        result.data.forEach((obj: any) => {
          if(!obj.uifilters) { obj.uifilters = []};
          if (obj.settings && obj.settings.mins.length && obj.settings.hour.length) {
            const mins = (obj.settings.mins[0] === 0) ? '00' :  ('0' + obj.settings.mins).slice(-2);
            const hour = (obj.settings.hour[0] === 0) ? '00' : ('0' + obj.settings.hour).slice(-2);
            obj.time = `${hour}:${mins}`;
          } else if (obj.settings && obj.settings.hours && obj.settings.hours.length) {
            obj.time = `Every ${obj.settings.hours[0]} hour(s)`;
          }
        });
        this.scheTableOptions.pageData = result.data;
        this.scheTableOptions.tableOptions.pageTotal = result.total;
        this.schedulerFinalData = result.data;
        this.isAddOrEdit = false;
        this.showHideLoading(false);
      });
  }

  showHideLoading(status: any): void {
    const data = Object.assign({}, this.scheTableOptions);
    this.scheTableOptions = {};
    this.scheTableOptions = data;
    this.scheTableOptions.tableOptions.loading = status;
  }

  sortCall(event: any): void {
    this.scheTableOptions.sortOptions = event;
    this.getSchedulers();
  }

  addScheduler(): void {
    this.daily = false;
    this.monthly = false;
    this.yearly = false;
    this.asset = false;
    const site = [{_id: '', name: 'All'}];
    /*this.filteredServerSideSites.next(site);
    this.siteServerSideCtrl.setValue(site[0]);*/
    this.isAddOrEdit = true;
    this.showHideLoading(true);
    this.scheTitle = 'Add';
    this.siteId = undefined;
    this.filterData = [];
    this.scheDetailsObj = new SchedulerDetails();
    this.scheDetailsObj.isActive = true;
    this.scheDetailsObj.settings = {mins: [], hour: [], days: [], week: [], months: [], weekdays: []};
    this.scheDetailsObj.email = [''];
    setTimeout(() => {
      this.scheDetailsObj.uniqueid = this.schedulerTypeList[0].uniqueId;
      this.scheDetailsObj.companyid = [this.compList[0]._id];
      this.changeScanType(this.schedulerTypeList[0].uniqueId);
      this.updateCurrentSite(this.compList[0]._id);
     }, 1000);
  }

  actionCall(data: any): void {
    if (data.action.text === 'Edit') {
      this.scheTitle = 'Edit';
      this.isAddOrEdit = true;
      this.editScheduler(data);
    } else if (data.action.text === 'Delete') {
      this.isAddOrEdit = false;
      // if (data.row.isGlobal) { this.toast.sToast('error', `${data.row.name}  cannot be deleted`); return;}
      this.deleteSchedulerConfirmationDialog(data.row);
    }
  }

  pageCall(event: any): void {
    this.scheTableOptions.tableOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getSchedulers();
  }

  editScheduler(element: any): void {
    this.scheDetailsObj = new SchedulerDetails();
    this.hourMintTime = '00:01';
    if (element.row.settings.hour !== undefined && element.row.settings.hour[0] !== undefined &&
      element.row.settings.mins !== undefined && element.row.settings.mins[0] !== undefined) {
        if(JSON.stringify(element.row.settings.hour[0]).length <2){
          element.row.settings.hour[0] = '0'+element.row.settings.hour[0];
        }
        if(JSON.stringify(element.row.settings.mins[0]).length <2){
          element.row.settings.mins[0] = '0'+element.row.settings.mins[0];
        }
        this.hourMintTime = element.row.settings.hour[0] + ':' + element.row.settings.mins[0];
    }
    this.scheDetailsObj = Object.assign({}, element.row);
    if (element.row.settings.hours && element.row.settings.hours[0]) {
      this.scheDetailsObj.settings.hourly = element.row.settings.hours[0];
    }
    if (element.row.isGlobal) {
      this.scheDetailsObj.agentid = ['*'];
      this.scheDetailsObj.companyid = ['*'];
      this.companyId = ['*']
    } else {
      this.scheDetailsObj.agentid = (element.row.agentRef) ? element.row.agentRef.map((x: any) => x.id) : [];
      this.scheDetailsObj.companyid = (element.row.companyRef) ? element.row.companyRef.map((x: any) => x.id) : [];
      this.companyId = element.row.companyRef.map((x: any) => x.id);
    }
    this.scheDetailsObj.c = (!element.row.c) ?  '2021-07-29T05:17:28.448967' : element.row.c;
    this.siteId = element.row.siteid;
    if(element.row?.uifilters) { this.filterData = element.row.uifilters; this.cs.filterList.uifilters = element.row.uifilters;}
    if(element.row?.reportfilters) { this.cs.filterList.reportfilters = JSON.parse(element.row.reportfilters);}
    this.scheDetailsObj.siteName = element.row.siteName === '' ? 'All' : element.row.siteName;
    this.switchSchView(element.row.scheduler);
    this.scheDetailsObj.email = (this.scheDetailsObj.email && this.scheDetailsObj.email.length) ? this.scheDetailsObj.email : [''];
    this.scheDetailsObj.settings.week = (this.scheDetailsObj.settings.week) ? this.scheDetailsObj.settings.week.map(String) : [];
    this.scheDetailsObj.settings.weekdays = this.scheDetailsObj.settings.weekdays.map(String);
    this.scheDetailsObj.settings.months = this.scheDetailsObj.settings.months.map(String);
    setTimeout(() => { this.changeScanType(element.row.uniqueid); }, 2000);
    if (this.scheDetailsObj.settings.days.length > 0) {
      this.scheDetailsObj.settings.days.map(String);
      this.daily = true;
    }
  }

  deleteSchedulerConfirmationDialog(element: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to delete this scheduler ${element.name}?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.baseService.doRequest(`/api/scheduler/${element._id}`, 'delete').subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Schedulers removed successfully');
            this.isAddOrEdit = false;
            setTimeout( () => this.getSchedulers(), 2000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }

  getSite(): void {
    let site = [{_id: '', name: 'All'}];
    this.filteredServerSideSites.next(site);
    this.siteServerSideCtrl.setValue(site[0]);
    let cq;
    cq = {query: {bool: {must: [{exists: {field: 'description'}}, {"terms":{"_type_.keyword":["company","companycreate"]}}], must_not: [{exists: {field: 'companyRef'}}]}}};
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'c']);
    this.baseService.doRequest(`/api/company/`, 'get', null,
      {q, skip, limit, sort, fields}).subscribe((result: any) => {
      if (result && result.data.length > 0) {
        site = result.data;
        site.unshift({_id: '', name: 'All'});
        this.filteredServerSideSites.next(site);
        this.siteServerSideCtrl.setValue(site[0]);
      }
    });
  }

  changeToIntegration(): void {
    this.cs.setCurrentView.next({value: 'Integrations'});
  }

  isEmptyArray(email: any): boolean {
    if (!email) { return false; }
    return (email.filter((x: any) => ( x === null || x === '')).length);
  }
}

export class SchedulerDetails {
  site: any;
  // tslint:disable-next-line:variable-name
  c: any;
  _id: any;
  isActive: any;
  uniqueid: any;
  settings: any;
  scantype: number;
  isGlobal: boolean;
  companyRef: any;
  agentRef: any;
  level: string;
  sublevel: string;
  thirdlevel: string;
  ispassword: boolean;
  password: string;
  subkey: string;
  thirdvalue: any = [];
  subvalue: any = [];
  agentid: any = [];
  companyid: any = [];
  email: any = [];
  scanType: any;
  scheduler: any;
  active: any;
  siteName: any;
  siteid: any;
  assets: any;
  name: any;
  uifilters: any = [];
  reportfilters: any = "";
  excludecompany?:any = []
}
