<mat-tab-group mat-infoalign-tabs="start" [selectedIndex]="tab.value" (selectedTabChange)="tabFun($event)">
  <mat-tab label="Configurations">
    <ng-template mat-tab-label>
      <mat-icon>settings_suggest</mat-icon>
      <span> &nbsp; Configurations</span>
    </ng-template>
    <ng-template matTabContent>
      <app-domain-configuration [currentCompany]="currentCompany"></app-domain-configuration>
    </ng-template>
  </mat-tab>

  <mat-tab label="Results">
    <ng-template mat-tab-label>
      <mat-icon>summarize</mat-icon>
      <span> &nbsp; Results</span>
    </ng-template>
    <ng-template matTabContent>
      <app-attack-surface-result-tab [currentCompany]="currentCompany"></app-attack-surface-result-tab>
    </ng-template>
  </mat-tab>

  <mat-tab label="Jobs">
    <ng-template mat-tab-label>
      <mat-icon>hourglass_bottom</mat-icon>
      <span> &nbsp; Jobs</span>
    </ng-template>
    <ng-template matTabContent>
      <app-attack-surface-mapper-job [currentCompany]="currentCompany"></app-attack-surface-mapper-job>
    </ng-template>
  </mat-tab>
</mat-tab-group>