import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../../_services/loader.service';
import {ModalService} from '../../../_services/modal.service';
import {AgentService} from '../../../api/services/agent.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {AssetCredentialsService} from '../../../api/services/asset-credentials.service';
import {AssetCredentials} from '../../../api/models/asset-credentials';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {CommonService} from '../../../_services/common.services';
import {CompanyService} from "../../../api/services/company.service";

@Component({
  selector: 'app-ad-credentials',
  templateUrl: './ad-credentials.component.html',
  styleUrls: ['./ad-credentials.component.scss']
})

export class AdCredentialsComponent implements OnInit {
  @Input() currentCompany: any;
  @Input() currentAgent: any;
  currentAsset: any;
  adCredTableOptions: any;
  agentList: any[] = [];
  agentHash: any = {};
  addadCredWidth = 600;
  adCred: any = {};
  assetCred: any = {};
  isLoading = false;
  currentAdCred: any = {};
  probeList: any = [];
  assetTags: any = [];
  agentRef: any = {};
  Objectkeys = Object.keys;
  constructor(private loaderService: LoaderService, private toast: MyToastrService,
              public modalService: ModalService, private agentService: AgentService,
              private commonService: CommonService, private companyService: CompanyService,
              private confirmDialog: ConfirmDialogService, private aS: AuthenticationService,
              public assetCredentialsService: AssetCredentialsService) {
    this.adCredTableOptions = {
      columns: [
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false
        },
        {
          header: 'Host Name',
          columnDef: 'hostname',
          filter: '',
          cell: '(element: any) => `${element.hostname}`',
          order: 4,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },
        {
          header: 'IP/Domain',
          columnDef: 'domain',
          filter: '',
          cell: '(element: any) => `${element.domain}`',
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }, {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 6,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },
        {
          header: 'ID',
          columnDef: '_id',
          cell: '(element: any) => `${element._id}`',
          order: 3,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },
      ],
      sortOptions: {active: 'c', direction: 'desc'},
      faClass: 'AssetCredentials',
      _pageData: [],
      tableOptions: {
        id: 'adCredTableOptions',
        title: 'Active Directory Credentials',
        isServerSide: true,
        selectText: 'adcred(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {text: 'Copy to Probe', icon: 'content_copy', callback: 'deleteFn', isGlobal: true, hidelocal: true},
          {
          text: 'Edit',
          icon: 'edit',
          callback: 'editFn',
          isGlobal: false
        }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false}],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 15,
        search: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        filterDownload: false,
        add: (this.aS.hasPermission('assetcredentials', 'create')),
        service: 'assetCredentialsService',
        columnSearch: false,
        compareData: false,
        filename: 'ActiveDirectoryCredentials'
      }
    };
  }

  ngOnInit(): void {
    this.adCredTableOptions.pageData = [];
    this.getActiveDirectoryCred();
    this.getAgents();
  }

  activesortCall(idata: any): void {
    this.adCredTableOptions.sortOptions = idata;
    this.getActiveDirectoryCred();
  }

  adCredshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.adCredTableOptions);
    this.adCredTableOptions = {};
    this.adCredTableOptions = data;
    this.adCredTableOptions.tableOptions.loading = status;
  }



  getActiveDirectoryCred(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.adCredshowHideLoading(true);
      this.loaderService.display(true, 'Getting active directory credentials');
      this.adCredTableOptions.serverSide = {
        service: 'assetCredentialsService', fn: 'getAllApiAssetcredentialsGet', q: {
          query: {
            bool: {
              must: [{exists: {field: 'cred_type'}}, {exists: {field: 'hostname'}},
                {match: {'agentRef.id.keyword': this.currentAgent._id + ''}},
                {exists: {field: 'agentRef.id'}}],
              must_not: [{match: {'hostname.keyword': ''}}]
            }
          }
        }
      };
      const query = {
        query: {
          bool: {
            must: [{exists: {field: 'cred_type'}}, {exists: {field: 'hostname'}},
              {match: {'agentRef.id.keyword': this.currentAgent._id + ''}},
              {exists: {field: 'agentRef.id'}}],
            must_not: [{match: {'hostname.keyword': ''}}]
          }
        }
      };
      let sort: any = [{}];
      if (this.adCredTableOptions.sortOptions && this.adCredTableOptions.sortOptions.direction
        && this.adCredTableOptions.sortOptions.direction !== '' ) {
      const orderArr = ['name', 'domain', 'hostname'];
      if (orderArr.indexOf(this.adCredTableOptions.sortOptions.active) > -1) {
        sort[0][this.adCredTableOptions.sortOptions.active + '.keyword'] = {order: this.adCredTableOptions.sortOptions.direction};
      } else {
       sort[0][this.adCredTableOptions.sortOptions.active] = {order: this.adCredTableOptions.sortOptions.direction};
      }
      }
      const q = JSON.stringify(query);
      const skip = 0;
      const limit = 100;
      sort = JSON.stringify(sort);
      const fields = JSON.stringify(['name', 'c', 'u', 'domain', '_id', 'hostname']);
      this.assetCredentialsService.getAllApiAssetcredentialsGet({q, skip, limit, sort})
        .subscribe((result: any) => { // change interface to ad cred and ad credservice
        this.loaderService.display(false);
        if (result && result.data.length) {
          this.adCredTableOptions.pageData = result.data;
          this.adCredTableOptions.tableOptions.pageTotal = result.total;
          this.adCredshowHideLoading(false);
        } else {
          this.adCredTableOptions.pageData = [];
          this.adCredTableOptions.tableOptions.pageTotal = 0;
          this.adCredshowHideLoading(false);
        }
      });
    } else {
      setTimeout(() => {
        this.getActiveDirectoryCred();
      }, 1000);
    }
  }

  addActiveDirectoryCred(): void {
    this.adCred = {
      cred_type: 1,
      name: '',
      domain: '',
      hostname: '',
      username: '',
      password: '', agentRef: {id: '', name: ''}
    };
    this.adCred.agentRef.id = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
    this.adCred.agentRef.name = (this.currentAgent) ? this.currentAgent.name : this.agentList[0].name;
    this.modalService.open('addadCred');
  }

  saveActiveDirectoryCred(): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.adCred.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    console.log(this.currentCompany);
    if (this.adCred._id) {
      this.assetCredentialsService.updateApiAssetcredentialsPut({body: this.adCred})
      .subscribe((result: AssetCredentials) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = false;
        if (result._id) {
          this.modalService.close('addadCred');
          this.toast.sToast('success', 'ActiveDirectory Credentials updated successfully!');
          setTimeout(() => {
            this.getActiveDirectoryCred();
          }, 2000);
        } else {
          this.toast.sToast('error', JSON.stringify(result));
          this.isLoading = false;
        }
      });
    } else {
      this.assetCredentialsService.createApiAssetcredentialsPost({body: this.adCred})
      .subscribe((result: AssetCredentials) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = false;
        if (result._id) {
          this.modalService.close('addadCred');
          this.toast.sToast('success', 'ActiveDirectory Credentials added successfully!');
          setTimeout(() => {
            this.getActiveDirectoryCred();
          }, 2000);
        } else {
          this.toast.sToast('error', JSON.stringify(result));
          this.isLoading = false;
        }
      });
    }
  }

  adCredglobalActionCall(idata: any): void {
    if (idata.action.text === 'Copy to Probe') {
      this.isLoading = false;
      this.currentAdCred = [];
      this.currentAdCred = idata.row;
      this.openCopyToProbe();
    }
  }

  openCopyToProbe(): void {
    this.agentRef = {id: '', name: '', agents: []};
    this.probeList = this.probeList.filter((x: any) => x && x._id);
    this.agentRef.agents = this.probeList.filter((x: any) => x._id !== this.currentAgent._id);
    if (!this.agentRef.agents || !this.agentRef.agents.length) {
      this.toast.sToast('error', 'No other probes to copy!');
    } else {
      this.modalService.open('copyAdCredToProbe');
    }
  }

  copyToSelectProbe(agentRef: any): void {
    this.isLoading = true;
    const param: any = {
      src_agent_id: this.currentAgent._id, dest_agent_id: agentRef.id, clone_records: []
    };
    this.currentAdCred.forEach((obj: any) => {
      param.clone_records.push(obj._id);
    });
    this.companyService.migrateAgentSettingsApiCompanyIdCloneAgentSettingsPost({id: 'imaws', body: param})
    .subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', 'Copied successfully!');
      this.isLoading = false;
      this.toast.sToast('success', 'Copied successfully!');
      this.closeCopyProbe();
    });
  }

  closeCopyProbe(): void {
    this.currentAdCred = []; this.agentRef = {id: '', name: '', agents: []};
    this.getActiveDirectoryCred();
    this.modalService.close('copyAdCredToProbe');
  }

  getAgents(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.commonService.getAgents(this.currentCompany._id).then((v2: any) => {
        this.commonService.sortFn(v2.data, 'name').then((val: any) => {
          this.agentList = val.filter((x: any) => x._id);
          this.probeList = val.filter((x: any) => x?._id);
          this.agentHash = new Map(this.agentList.map((i: any) => [i._id, i.name]));
        });
      });
    } else {
      setTimeout(() => {
        this.getAgents();
      }, 1000);
    }
  }

  adCredactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      this.loaderService.display(true, 'Getting cred details...');
      this.assetCredentialsService.getApiAssetcredentialsIdGet({id: idata.row._id}).subscribe((res: any) => {
        this.loaderService.display(false);
        this.adCred = res;
        this.modalService.open('addadCred');
      });
    }
    if (idata.action.text === 'Copy to Probe') {
      this.isLoading = false;
      this.currentAdCred = [];
      this.currentAdCred = [idata.row];
      this.openCopyToProbe();
    }
    if (idata.action.text === 'Delete') {
      const adCred = idata.row;
      const titleName = 'Confirmation';
      const message = 'Are you sure you want to delete this Active Directory Credentials ' + adCred.name + ' ?';
      const cancelText = 'No';
      const acceptText = 'Yes';
      this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
      this.confirmDialog.dialogResult.subscribe(res => {
        if (res) {
          this.assetCredentialsService.deleteApiAssetcredentialsIdDelete(
            {id: adCred._id}).subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getActiveDirectoryCred();
            }, 2000);
          });
        }
      });
    }
  }
}
