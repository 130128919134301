<app-s-table aria-label="PatchingScheduler" role="table" [sTableOptions]="patchTableOptions" *ngIf="!addEditPatch"
             (filterCallback)="patchfilterCall($event)" (actionCallback)="patchactionCall($event)"
             (refreshCallback)="getPatchingSchedulerData()" (globalActionCallback)="globalActionCall($event)"
             (pageCallback)="patchpageCall($event)" (addCallback)="patchaddTableData()"
             (timerCallback)="getPatchingSchedulerData()"></app-s-table>
<ng-container *ngIf="addEditPatch">
  <mat-card>
    <button class="position-absolute r-1p mt--10" aria-label="close modal icon" matTooltip="Close"
            (click)="addEditPatch = !addEditPatch; getPatchingSchedulerData();" mat-icon-button="">
      <mat-icon>close</mat-icon>
    </button>
    <mat-card-header>
      <mat-card-title>Auto Patch</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form #scheAdd="ngForm" (ngSubmit)='saveScheduler(scheDetailsObj, scheAdd)'>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="scheDetailsObj.name" autocomplete="off" name="Name" id="Name" type="text" required placeholder="Enter scheduler name">
          <mat-error> Name is required </mat-error>
        </mat-form-field>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Companies</mat-label>
          <mat-select #companySelect [multiple]="true" required class="" placeholder="Select companies"
          name="Companyname" (openedChange)="closeCurrentCompany($event)" [(ngModel)]="scheDetailsObj.companyid"
          [formControl]="companyCtrl"
          (selectionChange)="scheDetailsObj.companyid = ($event.value.includes('*')) ? ['*'] : $event.value">
          <mat-select-trigger>
            <mat-chip-list>
              <ng-container *ngFor="let topping of scheDetailsObj.companyid; let i = index">
                <mat-chip  *ngIf="(companyHash &&  companyHash[topping]) || (topping === '*')" [removable]="true" (removed)="removedKeys(i,'companyid')">
                  {{ (topping === '*') ? 'All Companies' : companyHash[topping]['name'] }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </ng-container>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="companyFilterCtrl" [placeholderLabel]="'Search Company'"
              [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngIf="!currentCompany" matTooltip="All Companies" value="*">All Companies</mat-option>
          <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async"
            [value]="company._id">
            {{company.name}}
          </mat-option>
        </mat-select>
        <mat-error> Companies is required </mat-error>
        </mat-form-field>

        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Excluded companies</mat-label>
          <mat-select #companySelect1 [multiple]="true" placeholder="Select companies"
          name="Exculedcompanyname" (openedChange)="closeExculedCurrentCompany($event)" [(ngModel)]="scheDetailsObj.excludecompany"
          [formControl]="companyCtrl1"
          (selectionChange)="scheDetailsObj.excludecompany = ($event.value.includes('*')) ? ['*'] : $event.value">
          <mat-select-trigger>
            <mat-chip-list>
              <ng-container *ngFor="let topping of scheDetailsObj.excludecompany; let i = index">
                <mat-chip  *ngIf="(companyHash &&  companyHash[topping]) || (topping === '*')" [removable]="true" (removed)="removedKeys(i,'excludecompany')">
                  {{ (topping === '*') ? 'All Companies' : companyHash[topping]['name'] }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </ng-container>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="companyFilterCtrl1" [placeholderLabel]="'Search Company'"
              [noEntriesFoundLabel]="'No matching company found'" [searching]="searching">
            </ngx-mat-select-search>
          </mat-option>
          <!-- <mat-option *ngIf="!currentCompany" matTooltip="All Companies" value="*">All Companies</mat-option> -->
          <mat-option [matTooltip]="company.name" *ngFor="let company of filteredCompanies | async"
            [value]="company._id">
            {{company.name}}
          </mat-option>
        </mat-select>
        <!-- <mat-error> Companies is required </mat-error> -->
        </mat-form-field>

        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Included Applications</mat-label>
          <mat-select #includesSelect [multiple]="true" required class="" placeholder="Select application"
          name="IncludesApp" (selectionChange)="scheDetailsObj.includedApplication = ($event.value.includes('*')) ? ['*'] : $event.value" (openedChange)="closeInclude($event)" [(ngModel)]="scheDetailsObj.includedApplication"
          [formControl]="includesCtrl">
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip *ngFor="let app of scheDetailsObj.includedApplication; let i = index"
                [removable]="true" (removed)="removedKeys(i,'includedApplication')">
                <ng-container *ngIf="app === '*'"> 
                  All Applications
                  <mat-icon matChipRemove>cancel</mat-icon>
                </ng-container>
                <ng-container *ngIf="app !== '*' && applictionsHash[app.toLowerCase()] && applictionsHash[app.toLowerCase()].name">
                  {{ applictionsHash[app.toLowerCase()].name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </ng-container>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="includesFilterCtrl" [placeholderLabel]="'Search application'"
              [noEntriesFoundLabel]="'No matching application found'" [searching]="searching">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option matTooltip="All Applications" value="*">All Applications</mat-option>
          <mat-option [matTooltip]="inapp.name" *ngFor="let inapp of includeApplictions | sortBy:'asc':'name' | search:includesFilterCtrl.value"
            [value]="inapp.id">
            {{inapp.name}}
          </mat-option>
        </mat-select>
        <mat-error> Included Applications is required </mat-error>
        </mat-form-field>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Excluded Applications</mat-label>
          <mat-select #excludesSelect [multiple]="true" class="" placeholder="Select application"
          name="ExcludesApp" (selectionChange)="scheDetailsObj.execludedApplication = ($event.value.includes('*')) ? ['*'] : $event.value"(openedChange)="closeExclude($event)" [(ngModel)]="scheDetailsObj.execludedApplication"
          [formControl]="excludesCtrl">
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip *ngFor="let app of scheDetailsObj.execludedApplication; let i = index"
                [removable]="true" (removed)="removedKeys(i,'execludedApplication')">
                <ng-container *ngIf="app === '*'"> 
                  All Applications
                  <mat-icon matChipRemove>cancel</mat-icon>
                </ng-container>
                <ng-container *ngIf="app !== '*' && applictionsHash[app.toLowerCase()] && applictionsHash[app.toLowerCase()].name">
                  {{ applictionsHash[app.toLowerCase()].name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </ng-container>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="excludesFilterCtrl" [placeholderLabel]="'Search application'"
              [noEntriesFoundLabel]="'No matching application found'" [searching]="searching">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [matTooltip]="exapp.name" *ngFor="let exapp of excludeApplictions | sortBy:'asc':'name' | search:excludesFilterCtrl.value"
            [value]="exapp.id">
            {{exapp.name}}
          </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Included Tags</mat-label>
          <mat-select #includeTags [multiple]="true" class="" placeholder="Select Tag"
          name="IncludesTags" (selectionChange)="scheDetailsObj.include_tags = $event.value" [(ngModel)]="scheDetailsObj.include_tags"
          [formControl]="includeTagCtrl">
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip *ngFor="let tag of scheDetailsObj.include_tags; let i = index"
                [removable]="true" (removed)="removedKeys(i,'include_tags')">
                {{ tag }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="includeTagFilterCtrl" [placeholderLabel]="'Search Tag'"
              [noEntriesFoundLabel]="'No matching tag found'" [searching]="searching">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [matTooltip]="tag" *ngFor="let tag of tagList | search:includeTagFilterCtrl.value"
            [value]="tag">
            {{tag}}
          </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Excluded Tags</mat-label>
          <mat-select #excludeTags [multiple]="true" class="" placeholder="Select Tag"
          name="ExcludeTags" (selectionChange)="scheDetailsObj.exclude_tags = $event.value" [(ngModel)]="scheDetailsObj.exclude_tags"
          [formControl]="excludeTagCtrl">
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip *ngFor="let tag of scheDetailsObj.exclude_tags; let i = index"
                [removable]="true" (removed)="removedKeys(i,'exclude_tags')">
                {{ tag }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [formControl]="excludeTagFilterCtrl" [placeholderLabel]="'Search Tag'"
              [noEntriesFoundLabel]="'No matching tag found'" [searching]="searching">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [matTooltip]="tag" *ngFor="let tag of tagList | search:excludeTagFilterCtrl.value"
            [value]="tag">
            {{tag}}
          </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
          <mat-label>Scheduler</mat-label>
          <mat-select id="schedulerDropdown" (selectionChange)="changeSchedulerType(scheDetailsObj.scheduler)" required
                      [(ngModel)]="scheDetailsObj.scheduler" name="repeat">
            <mat-option *ngFor="let scheduler of schList| sortBy:'asc':'text'" [value]="scheduler.value" required
                        id="{{scheduler.text}}opt"> {{scheduler.text}} </mat-option>
          </mat-select>
          <mat-error> Scheduler is required </mat-error>
        </mat-form-field>
        <mat-form-field class="mb-2 ml-3 w-100 mr-2" *ngIf="yearly" appearance="outline">
          <mat-label>Months</mat-label>
          <mat-select multiple="true" (selectionChange)="yearlySchedulerType(scheDetailsObj.settings.months)" required
                      [(ngModel)]="scheDetailsObj.settings.months" name="months">
            <mat-option *ngFor="let year of monthList" [value]="year.value" id="{{year.text}}opt">
              {{year.text}}
            </mat-option>
          </mat-select>
          <mat-error> Months is required </mat-error>
        </mat-form-field>
        <ng-container *ngIf="monthly || yearly">
          <mat-form-field *ngIf="monthly" class="mb-2 ml-3 w-100 mr-2" appearance="outline">
            <mat-label>Days</mat-label>
            <input #caldays matInput readonly [(ngModel)]="scheDetailsObj.settings.days" required name="days"
                    (click)="modalService.open('calPopup')" id="daysInpt">
            <mat-error> Days is required </mat-error>
          </mat-form-field>
          <s-modal id="calPopup" [width]="'28%'">
            <span class="mat-title p-10">Choose Date(s)</span>
            <app-custom-calendar (cancelClick)="modalService.close('calPopup')" *ngIf="monthly"
                                  (finalValueChange)="MonthFinalVal($event)" [inputarray]="daysList">
            </app-custom-calendar>
          </s-modal>
        </ng-container>
        <ng-container *ngIf="weekly || hourly">
          <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline" placeholder="Hourly" *ngIf="hourly">
            <mat-select class="mat-small" required [(ngModel)]="scheDetailsObj.settings.hourly"
                        name="hourl" placeholder="Hourly">
              <mat-option *ngFor="let hr of hourl" [value]="hr.value" id="{{hr.name}}hr">
                {{hr.name}}
              </mat-option>
            </mat-select>
            <mat-error> Hour is required </mat-error>
          </mat-form-field>
          <mat-form-field class="mb-2 ml-3 w-100 mr-2" placeholder="Week Days" *ngIf="weekly" appearance="outline" >
            <mat-select class="mat-small" multiple="true"  required [(ngModel)]="scheDetailsObj.settings.weekdays"
                        name="weekd" placeholder="Week Days">
              <mat-option *ngFor="let week of days" [value]="week.value" id="{{week.text}}days">
                {{week.text}}
              </mat-option>
            </mat-select>
            <mat-error> Week is required </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="daily || weekly || monthly">
          <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
            <mat-label>Start Time</mat-label>
            <input matInput placeholder="12hr format" aria-label="12hr format" type="time" class="datepicker-input"
                         name="hour"  [(ngModel)]="hourMintTime" id="hrInpt">
            <mat-error> Start Time is required </mat-error>
          </mat-form-field>
          <mat-form-field class="mb-2 ml-3 w-100 mr-2" appearance="outline">
            <mat-label>End Time</mat-label>
            <input matInput placeholder="12hr format" aria-label="12hr format"  type="time" class="datepicker-input" name="endhour" [(ngModel)]="hourMintTimeEnd" id="endhrInpt">
          <mat-error> End Time is required </mat-error>
          </mat-form-field>
        </ng-container>
        <mat-action-row>
          <button mat-raised-button id="ValidRemNext" [disabled]="!scheAdd.form.valid" type="submit" color="primary">Save</button>
          <button type="button" mat-button (click)="close(scheAdd);" color="default" id="schClsbtn">Close</button>
        </mat-action-row>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
<s-modal id="patchModal" >
  <mat-toolbar-row class="p-0">
    <mat-icon  class="float-left mb-2 ml-2 mat-warn">warning</mat-icon>
    <h2 class="ml-1 mt-1">Time Error</h2>
  </mat-toolbar-row>
  <div mat-dialog-content>
    <p class="center ml-2 mr-2">Time of 12:00 AM is not supported. Please set the time as either 11:59 PM or 12:01 AM.</p>
    <div mat-dialog-actions class="mt-4 ">
      <button type="button" mat-button (click)="modalService.close('patchModal');" mat-raised-button color="primary" class="mb-2 ml-2">Ok </button>
    </div>
  </div>
 </s-modal>

