/* tslint:disable */
/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { UpdateApiInstalledDriversPut$Params, updateApiInstalledDriversPut } from '../models/update-api-installed-drivers-put';
import { CreateApiInstalledDriversPost$Params, createApiInstalledDriversPost } from '../models/create-api-installed-drivers-post';
import { GetApiInstalledDriversIdGet$Params, getApiInstalledDriversIdGet } from '../models/get-api-installed-drivers-id-get';
import { DeleteApiInstalledDriversIdDelete$Params, deleteApiInstalledDriversIdDelete } from '../models/delete-api-installed-drivers-id-delete';
import { InstalledDrivers } from '../models/installed-drivers';
import { GetAllApiInstalledDriversGet$Params, getAllApiInstalledDriversGet } from '../models/get-all-api-installed-drivers-get';


@Injectable({ providedIn: 'root' })
export class InstalledDriversService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllApiInstalledDriversGet()` */
  static readonly GetAllApiInstalledDriversGetPath = '/api/InstalledDrivers/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiInstalledDriversGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiInstalledDriversGet$Response(params?: GetAllApiInstalledDriversGet$Params): Observable<StrictHttpResponse<InstalledDrivers>> {
    return getAllApiInstalledDriversGet(this.http, this.rootUrl, params);
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllApiInstalledDriversGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiInstalledDriversGet(params?: GetAllApiInstalledDriversGet$Params): Observable<InstalledDrivers> {
    return this.getAllApiInstalledDriversGet$Response(params).pipe(
      map((r: StrictHttpResponse<InstalledDrivers>): InstalledDrivers => r.body)
    );
  }

  /** Path part for operation `updateApiInstalledDriversPut()` */
  static readonly UpdateApiInstalledDriversPutPath = '/api/InstalledDrivers/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiInstalledDriversPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiInstalledDriversPut$Response(params: UpdateApiInstalledDriversPut$Params): Observable<StrictHttpResponse<InstalledDrivers>> {
    return updateApiInstalledDriversPut(this.http, this.rootUrl, params);
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateApiInstalledDriversPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiInstalledDriversPut(params: UpdateApiInstalledDriversPut$Params): Observable<InstalledDrivers> {
    return this.updateApiInstalledDriversPut$Response(params).pipe(
      map((r: StrictHttpResponse<InstalledDrivers>): InstalledDrivers => r.body)
    );
  }

  /** Path part for operation `createApiInstalledDriversPost()` */
  static readonly CreateApiInstalledDriversPostPath = '/api/InstalledDrivers/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiInstalledDriversPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiInstalledDriversPost$Response(params: CreateApiInstalledDriversPost$Params): Observable<StrictHttpResponse<InstalledDrivers>> {
    return createApiInstalledDriversPost(this.http, this.rootUrl, params);
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApiInstalledDriversPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiInstalledDriversPost(params: CreateApiInstalledDriversPost$Params): Observable<InstalledDrivers> {
    return this.createApiInstalledDriversPost$Response(params).pipe(
      map((r: StrictHttpResponse<InstalledDrivers>): InstalledDrivers => r.body)
    );
  }

  /** Path part for operation `getApiInstalledDriversIdGet()` */
  static readonly GetApiInstalledDriversIdGetPath = '/api/InstalledDrivers/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiInstalledDriversIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiInstalledDriversIdGet$Response(params: GetApiInstalledDriversIdGet$Params): Observable<StrictHttpResponse<InstalledDrivers>> {
    return getApiInstalledDriversIdGet(this.http, this.rootUrl, params);
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiInstalledDriversIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiInstalledDriversIdGet(params: GetApiInstalledDriversIdGet$Params): Observable<InstalledDrivers> {
    return this.getApiInstalledDriversIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<InstalledDrivers>): InstalledDrivers => r.body)
    );
  }

  /** Path part for operation `deleteApiInstalledDriversIdDelete()` */
  static readonly DeleteApiInstalledDriversIdDeletePath = '/api/InstalledDrivers/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiInstalledDriversIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiInstalledDriversIdDelete$Response(params: DeleteApiInstalledDriversIdDelete$Params): Observable<StrictHttpResponse<any>> {
    return deleteApiInstalledDriversIdDelete(this.http, this.rootUrl, params);
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApiInstalledDriversIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiInstalledDriversIdDelete(params: DeleteApiInstalledDriversIdDelete$Params): Observable<any> {
    return this.deleteApiInstalledDriversIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
