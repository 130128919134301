<div class="over-flow w-100">
  <mat-card class="mat-elevation-z0 pt-0" *ngFor="let asset of assets;">
    <mat-card-header>
      <mat-card-title *ngIf="asset.name">
          <div class="d-flex row align-items-center ml-0">
              <ng-container *ngIf="asset.agent_type === 3">
                  <span *ngIf="asset.agent_status === true" matTooltip="ONLINE"
                        class="badge badge-pill position-absolute fs--7 badge-success">&nbsp;</span>
                  <span *ngIf="asset.agent_status === false" matTooltip="OFFLINE"
                        class="badge badge-pill position-absolute fs--7 badge-danger">&nbsp;</span>
              </ng-container>
              <span class="ml-3">{{asset.name}}</span>
          </div>
      </mat-card-title>
      <mat-card-title *ngIf="asset.assetRef?.name">
          <div class="d-flex row align-items-center ml-0">
              <ng-container *ngIf="asset.agent_type === 3">
                  <span *ngIf="asset.patchable === true" matTooltip="ONLINE"
                        class="badge badge-pill position-absolute fs--7 badge-success">&nbsp;</span>
                  <span *ngIf="asset.patchable === false" matTooltip="OFFLINE"
                        class="badge badge-pill position-absolute fs--7 badge-danger">&nbsp;</span>
              </ng-container>
              <span class="ml-3">{{asset.assetRef.name}}</span>
          </div>
      </mat-card-title>
      <mat-card-subtitle class="mt-2 fs1 fw3">Evidence details</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="loading">
        <span class="fs1 fw3 mr-2"> Loading...</span>
      </ng-container>
      <ng-container *ngIf="!loading">
        <ng-container *ngIf="statusFilterKey === 'remediated' || statusFilterKey === 'suppressed'">
          <ng-container *ngIf="statusFilterKey === 'suppressed'">
            <span class="fs1 fw3">Suppressed On - {{asset.remediatedAt | utcToLocale}}</span>
          </ng-container>
          <ng-container *ngIf="statusFilterKey === 'remediated'">
            <span class="fs1 fw3"> Remediated On - {{asset.remediatedAt |
              utcToLocale}}</span><br>
            <span class="fs1 fw3 mt-2"> Remediated Version -
              {{asset.remediated_version}}</span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="statusFilterKey === 'pending' || statusFilterKey === 'suppressed'">
          <span *ngIf="solution.remediation_type === 'os' && asset.platform !== 'vmware' && asset.platform !== 'darwin'" class="fs1 fw3 mr-2"> Missing
            windows update - {{solution.fix}}</span>
          <span *ngIf="solution.remediation_type === 'os' && asset.platform === 'darwin'" class="fs1 fw3 mr-2"> Missing
            MacOS update - {{solution.fix}}</span>
          <span *ngIf="asset.platform === 'vmware'" class="fs1 fw3 mr-2"> Missing {{solution.product}} update -
            {{solution.fix}}</span>

            <span *ngIf="asset.remediation_type === 'os' && asset.assetRef.platform !== 'vmware' && asset.assetRef.platform !== 'darwin'" class="fs1 fw3 mr-2"> Missing
              windows update - {{asset.fix}}</span>
            <span *ngIf="asset.remediation_type === 'os' && asset.assetRef.platform === 'darwin'" class="fs1 fw3 mr-2"> Missing
              MacOS update - {{asset.fix}}</span>
            <span *ngIf="asset?.assetRef?.platform === 'vmware'" class="fs1 fw3 mr-2"> Missing {{asset.product}} update -
              {{asset.fix}}</span>

          <table class="rounded-corner table w-100 bor-1"
            *ngIf="asset.evidence && asset.evidence.productRef && prodHash[asset.evidence.productRef] && isObject(prodHash[asset.evidence.productRef])">
            <tbody>
              <tr>
                <td class="w-30p fs1 fw3">Name</td>
                <td>{{prodHash[asset.evidence.productRef].full_name}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Path</td>
                <td>{{prodHash[asset.evidence.productRef].path}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Uninstall Path</td>
                <td>{{prodHash[asset.evidence.productRef].uninstall_string}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Install Date</td>
                <td>{{prodHash[asset.evidence.productRef].install_date}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Identifying Number</td>
                <td>{{prodHash[asset.evidence.productRef].identifying_number}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Version</td>
                <td>{{prodHash[asset.evidence.productRef].version}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Last Vulnerability Scan Time</td>
                <td>{{prodHash[asset.evidence.productRef].lastvul_scannedtime | utcToLocale}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Vulnerability</td>
                <td>{{prodHash[asset.evidence.productRef].vulcount}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Critical</td>
                <td><span classb></span>{{prodHash[asset.evidence.productRef].critical_vuls_count}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">High</td>
                <td>{{prodHash[asset.evidence.productRef].high_vuls_count}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Medium</td>
                <td>{{prodHash[asset.evidence.productRef].medium_vuls_count}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Low</td>
                <td>{{prodHash[asset.evidence.productRef].low_vuls_count}}</td>
              </tr>
              <tr>
                <td class="w-30p fs1 fw3">Last Logon Username</td>
                <td>{{prodHash[asset.evidence.productRef].assetlogonuser}}</td>
              </tr>
            </tbody>
          </table>
          <span
            *ngIf="asset.evidence && asset.evidence.productRef && !isObject(prodHash[asset.evidence.productRef])">{{prodHash[asset.evidence.productRef]}}</span>
          <span *ngIf="solution.is_mandatory_service || solution.is_mandatory_application">Install
            {{solution.product}}</span>

          <div *ngIf="asset.platform">
          <span
            *ngIf="(!asset.evidence || !asset.evidence.productRef || !prodHash[asset.evidence.productRef]) &&  asset.platform !== 'vmware' && !solution.is_mandatory_service && !solution.is_mandatory_application &&  solution.remediation_type !== 'os'  &&  solution.remediation_type !== 'other'"
            class="fs1 fw3 mr-2"> No evidence found!</span>
          </div>

          <div *ngIf="!asset.platform">
            <span
              *ngIf="(!asset.evidence || !asset.evidence.productRef || !prodHash[asset.evidence.productRef]) && asset.assetRef?.platform !== 'vmware'  && !solution.is_mandatory_service && !solution.is_mandatory_application &&  asset.remediation_type !== 'os'  &&  asset.remediation_type !== 'other'"
              class="fs1 fw3 mr-2"> No evidence found!</span>
            </div>

          <span *ngIf="(!asset.evidence || !asset.evidence.productRef || !prodHash[asset.evidence.productRef]) && (asset?.assetRef?.platform !== 'vmware'|| asset.platform !== 'vmware') && !solution.is_mandatory_service && !solution.is_mandatory_application && (asset.remediation_type === 'other' || solution.remediation_type === 'other')"
          >
            <table class="rounded-corner table w-100 bor-1" *ngIf="asset.evidence.path">
            <tbody>
              <tr>
                <td class="w-30p fs1 fw3">Path</td>
                <td>{{asset.evidence.path}}</td>
              </tr>
            </tbody>
          </table>
          </span>
        </ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>