<app-s-table aria-label="Asset Credentials" role="table" [sTableOptions]="assetCredTableOptions" (sortCallback)="assetCredsortCall($event)"
             (filterCallback)="assetCredfilterCall($event)" (actionCallback)="assetCredactionCall($event)"
             (pageCallback)="assetCredpageCall($event)" (refreshCallback)="getAssetCredentials()"
             (globalActionCallback)="assetCredglobalActionCall($event)" (addCallback)="assetCredaddTableData()"
             (timerCallback)="assetCredtimerCallData($event)"></app-s-table>
<s-modal id="addAssetCredentials">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
          <p class="mat-body-1">Add an Asset Credentials <strong>goes</strong> here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addAssetCredentialsWidth">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close" type="button"
                (click)="modalService.close('addAssetCredentials');">
          <mat-icon>close</mat-icon>
        </button>
        <form #addI="ngForm">
        <div class="mat-title">Asset Credentials</div>
        <mat-card-content class="fcard-content" *ngIf="assetCred && assetCred.agentRef">
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput id="AssetCredentialsName" placeholder="Enter name" type="text" trim="blur" required name="name"
                   [(ngModel)]="assetCred.name" autocomplete="off">
            <mat-hint>Ex. Server Credential</mat-hint>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select placeholder="Choose Type" name="cred_type"
                        (selectionChange)="changeDiscoveryType(assetCred.cred_type)"
                        [(ngModel)]="assetCred.cred_type" id="AssetCredentialsType">
              <mat-option *ngFor="let item of credTypeList" [value]="item.value" [id]="item.text">{{item.text}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="assetCred.cred_type !== 1">
            <mat-label>Port</mat-label>
            <input matInput placeholder="Enter port" type="number" min="0" max="65535" trim="blur" required
                   name="cred_port"
                   [(ngModel)]="assetCred.cred_port" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput id="AssetCredentialsUsername" placeholder="Enter username" type="text" trim="blur" required name="username"
               [(ngModel)]="assetCred.username"
                 autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput id="AssetCredentialsPassword" placeholder="Enter password" type="password" name="password"
               [(ngModel)]="assetCred.password"
                 autocomplete="new-password">
          </mat-form-field>
          <div *ngIf="assetCred.cred_type === 1 ">
          <mat-form-field class="w-75" appearance="outline">
              <mat-label>Domain</mat-label>
              <input matInput id="AssetCredentialsDomain" placeholder="Enter domain" type="text" trim="blur"  name="domain"
               [(ngModel)]="assetCred.domain"
                 autocomplete="off">
          </mat-form-field>
          </div>
          <div *ngIf="assetCred.cred_type !== 1">
            <mat-form-field class="w-75" appearance="outline">
              <mat-label>PrivateKey</mat-label>
              <input matInput id="AssetCredentialsKey" placeholder="Enter PrivateKey" type="password" trim="blur"
                name="key" [(ngModel)]="assetCred.passkey" autocomplete="new-password">
            </mat-form-field>
          </div>
          <mat-form-field class="w-75" appearance="outline" *ngIf="!currentAgent">
            <mat-label>Choose Probe/Agent</mat-label>
            <mat-select placeholder="Choose Probe/Agent" name="agentRef" required
                        (selectionChange)="changeAgent(assetCred.agentRef.id)"
                        [(ngModel)]="assetCred.agentRef.id">
              <mat-option *ngFor="let item of agentList| sortBy:'asc':'name'" [value]="item._id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" *ngIf="!assetCred._id" type="button"
                  (click)="saveAssetCredentials()" [disabled]="!addI.form.valid || isLoading"
                  id="saveip">Save</button>
          <button mat-raised-button class="mat-primary mr-2" *ngIf="assetCred._id" type="button"
                  (click)="updateAssetCredentials(assetCred)" [disabled]="!addI.form.valid || isLoading"
                  id="updateip">Update</button>
          <button mat-stroked-button class="mat-warn" type="button" (click)="modalService.close('addAssetCredentials')"
                  id="cancelip">Cancel</button>
        </mat-card-footer>
      </form>
      </mat-card>
    </mat-card>

</s-modal>
<s-modal id="copyAssetCredToProbe">
  <form #copyToProbeForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="'600'">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Copy To Other Probe</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="closeCopyProbe();">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Copy To Other Probe</div>
        <mat-card-content class="">
          <p><strong>Selected Asset Credential(s):</strong></p>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <span class="mr-2 py-1 px-2 badge badge-primary mb-2" *ngFor="let range of currentAssetCred"> {{range.name}}</span>
          </div>
          <hr class="">
          <mat-form-field *ngIf="probeList && probeList.length" appearance="fill" class="w-100">
            <mat-label>Choose Probe</mat-label>
            <mat-select name="agentRef" [(ngModel)]="agentRef.id" placeholder="Choose Probe To Copy" required>
              <mat-option value="">Select Probe</mat-option>
              <mat-option *ngFor="let agent of agentRef.agents| sortBy:'asc':'name';" [value]="agent?._id">{{agent?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="copyToSelectProbe(agentRef)" [disabled]="!copyToProbeForm.form.valid || isLoading"
                  id="copys">Copy
          </button>
          <button mat-stroked-button class="mat-warn" (click)="closeCopyProbe();"
                  id="cancels">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
