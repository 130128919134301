import { isDataSource } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { BaseRequestService } from 'src/app/_services/base.service';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';
import { AttackSurfaceDomainService } from 'src/app/api/services/attack-surface-domain.service';

@Component({
  selector: 'app-domain-configuration',
  templateUrl: './domain-configuration.component.html',
  styleUrls: ['./domain-configuration.component.scss']
})
export class DomainConfigurationComponent implements OnInit {
  @Input() currentCompany: any;
  attackSurfaceDojmainTableOptions: any;
  attackSurfacecurrentDomainPage = 0;
  attackSurfaceDomainfilterQuery: any;
  addDomainWidth = 600;
  colFilterQuery: any;
  colFilterCols: any = [];
  editmode:any = true;
  scheduledId: any;
  showUpper = true;
  allAttackSurfaceDomainList: any;
  jobDomain: any = '';
  domainCofig: any = {};
  constructor( 
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    public attackSurfaceDomainService: AttackSurfaceDomainService,
    private aS: AuthenticationService,
    private baseService: BaseRequestService,
    public modalService: ModalService
  ) {
    this.attackSurfaceDojmainTableOptions = {
      columns: [
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Domain',
          columnDef: 'domain',
          filter: '',
          cell: '(element: any) => `${element.domain}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
      ],
      
      sortOptions: { active: 'c', direction: 'desc' },
      _pageData: [],
      faClass: 'attackSurfacehDomain',
      tableOptions: {
        id: 'attackSurfaceDojmainTableOptions',
        title: 'Attack Surface Mapper Domain(s)',
        isServerSide: true,
        selectText: 'attack surface domain(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          { text: 'Scan Now', icon: 'find_in_page', isGlobal: true, callback: 'detailFn' },
          {
            text: 'Edit',
            icon: 'edit',
            callback: 'editFn',
            isGlobal: false
          },
          {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true},
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        showColFilter: true,
        add: this.aS.hasPermission('attacksurfacedomain', 'create'),
        columnSearch: false,
        compareData: false,
        hideDownload: true,
      },
    };
  }

  ngOnInit(): void {
    this.attackSurfaceDojmainTableOptions.pageData = [];
    this.getAttackSurfaceDomains();
  }

  attackSurfaceDomaincolFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') {
        this.colFilterCols.push(event);
      }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      let qval: any;
      const char = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"g, '\\$1'])/;
      const searchValue =
        typeof obj.value === 'string' ? obj.value.trim() : obj.value;
      if (obj.col === 'job_data.job_status') {
        qval =
          char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
            ? `\"${searchValue}\"`
            : '' + searchValue + '';
      } else {
        qval =
          char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
            ? `\"${searchValue}\"`
            : '*' + searchValue + '*';
      }
      const tmpObj = {
        bool: {
          should: [{ query_string: { fields: [obj.col], query: qval } }],
        },
      };
      this.colFilterQuery.push(tmpObj);
    });
    this.getAttackSurfaceDomains();
  }

  attackSurfaceDomainshowHideLoading(status: any): void {
    const data = Object.assign({}, this.attackSurfaceDojmainTableOptions);
    this.attackSurfaceDojmainTableOptions = {};
    this.attackSurfaceDojmainTableOptions = data;
    this.attackSurfaceDojmainTableOptions.tableOptions.loading = status;
  }


  attackSurfaceDomainsortCall(idata: any): void {
    this.attackSurfaceDojmainTableOptions.sortOptions = idata;
    this.getAttackSurfaceDomains();
  }

  attackSurfaceDomainactionCall(idata: any): void {
    if (idata.action.text === 'Delete') {
      this.deleteattackSurfaceDomainConfirmationDialog(idata);
    } else if(idata.action.text === 'Scan Now'){
      const data = {
        attack_surface_id:idata.row._id,
        companyId: idata.row.companyRef.id
      }
      this.scanNow(data)
   } else if(idata.action.text === 'Edit'){
    this.editscandata(idata.row);
    console.log('data',idata.row)
 }
  }

  editscandata(idata:any):void{
    console.log('idata',idata)
    this.domainCofig = Object.assign({}, idata);
    this.editmode = false;
    this.modalService.open('addAttackDomain');
  }

  updateIprange(domainCofig:any):void{
    console.log('iprange',domainCofig)
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(`/api/attacksurfacedomain/`, 'put', domainCofig).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', 'Updated successfully');
      setTimeout(() => { this.getAttackSurfaceDomains(); });
      this.modalService.close('addAttackDomain');
    });
    
   
  }

  cleardata(){
    this.domainCofig = ''
    this.modalService.close('addAttackDomain');
  }



  attackSurfaceDomainfilterCall(idata: any): void {
    const fields: any[] = ['domain'];
    this.attackSurfaceDomainfilterQuery =
      idata && idata.length > 0
        ? {
            multi_match: {
              query: idata,
              type: 'phrase_prefix',
              fields,
            },
          }
        : undefined;
    this.getAttackSurfaceDomains();
  }

  attackSurfaceDomainpageCall(event: any): void {
    this.attackSurfaceDojmainTableOptions.tableOptions.pageSize = event.pageSize;
    this.attackSurfacecurrentDomainPage = event.pageIndex;
    this.getAttackSurfaceDomains();
  }

  attackSurfaceDomaintimerCallData(idata: any): void {
    this.getAttackSurfaceDomains();
  }

  getAttackSurfaceDomains(): void {
    if (this.currentCompany && this.currentCompany._id) {
          this.attackSurfaceDomainshowHideLoading(true);
          const query: any = {
            query: {
              bool: {
                must: [{match: {'companyRef.id.keyword': `${this.currentCompany._id}`}},
                  { exists: {field: 'domain.keyword'}},
                  { terms: {"_type_.keyword": ["attacksurfacedomain", "attacksurfacedomaincreate"]}}
                ]
              }
            }
          };
          if (this.attackSurfaceDomainfilterQuery && this.attackSurfaceDomainfilterQuery.multi_match) {
            query.query.bool.must.push(this.attackSurfaceDomainfilterQuery);
          }
          if (this.attackSurfaceDomainfilterQuery && this.attackSurfaceDomainfilterQuery.filter) {
            query.query.bool.filter = this.attackSurfaceDomainfilterQuery.filter;
          }
    
          if (this.colFilterQuery && this.colFilterQuery.length) {
            query.query.bool.filter = [];
            this.colFilterQuery.forEach((obj: any) => {
              if (obj.bool.should[0].match) {
                query.query.bool.must.push(obj);
              } else {
                query.query.bool.filter.push(obj);
              }
            });
          }
          const q = JSON.stringify(query);
          const skip = this.attackSurfacecurrentDomainPage;
          const limit = this.attackSurfaceDojmainTableOptions.tableOptions.pageSize;
          let sort: any = [{}];
          if (this.attackSurfaceDojmainTableOptions.sortOptions && this.attackSurfaceDojmainTableOptions.sortOptions.direction
            && this.attackSurfaceDojmainTableOptions.sortOptions.direction !== '') {
            const orderArr = ['domain','name'];
            if (orderArr.indexOf(this.attackSurfaceDojmainTableOptions.sortOptions.active) > -1) {
              sort[0][this.attackSurfaceDojmainTableOptions.sortOptions.active + '.keyword'] = {order: this.attackSurfaceDojmainTableOptions.sortOptions.direction};
            } else {
              sort[0][this.attackSurfaceDojmainTableOptions.sortOptions.active] = {order: this.attackSurfaceDojmainTableOptions.sortOptions.direction};
            }
          }
          sort = JSON.stringify(sort);
          const fields = JSON.stringify(['c', 'u', 'companyRef', '_id', 'domain','name']);
          // @ts-ignore
          this.attackSurfaceDomainService.getAllApiAttacksurfacedomainGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
            this.loaderService.display(false);
            if (result && result.data.length) {
              this.attackSurfaceDojmainTableOptions.pageData = result.data;
              this.attackSurfaceDojmainTableOptions.tableOptions.pageTotal = result.total;
              this.attackSurfaceDomainshowHideLoading(false);
            } else {
              this.attackSurfaceDojmainTableOptions.pageData = [];
              this.attackSurfaceDojmainTableOptions.tableOptions.pageTotal = 0;
              this.attackSurfaceDomainshowHideLoading(false);
            }
          });
        } else {
          setTimeout(() => {
            this.getAttackSurfaceDomains();
          }, 1000);
        }
  }

  deleteattackSurfaceDomainConfirmationDialog(data: any): void {
    const titleName = 'Delete message';
    const message = `Are you sure you want to delete this ${data?.row?.domain} ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService.doRequest(`/api/attacksurfacedomain/${data?.row?._id}`, 'delete')
          .subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getAttackSurfaceDomains();
            }, 1000);
          });
      }
    });
  }
  attackSurfaceDomainglobalActionCall(idata: any): void {
    if (idata.action.text === 'Delete') {
      this.deleteattackSurfaceDomainConfirmationDialogForAll(idata);
    }else if(idata.action.text === 'Scan Now'){
      // const data = {
      //   attack_surface_id:idata.row._id,
      //   companyId: idata.row.companyRef.id
      // }
      // console.log('data',data)
      this.scanNowall(idata)
    }
  }

  scanNowall(data: any): void {
    let cnt = 0;
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to scan the selected domains?';
    const cancelText = 'No';
    const acceptText = 'Yes';
  
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
  
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
          data.row.forEach((element: any) => {
          const postData = {
            attack_surface_id: element._id,
            companyId: element.companyRef.id
          };
          this.baseService
            .doRequest('/api/attacksurfacedomain/scan_asm', 'post', postData)
            .subscribe((result: any) => {
              cnt++;
              if (cnt === data.row.length) {
                this.toast.sToast('success', 'Scanned job successfully');
                this.getAttackSurfaceDomains();
                this.loaderService.display(false);
              }
            });
        });
      }
    });
  }
  



  deleteattackSurfaceDomainConfirmationDialogForAll(data: any): void {
    let cnt = 0;
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete selected domain?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
        this.allAttackSurfaceDomainList = data.row;
        data.row.forEach((element: any) => {
          this.baseService
            .doRequest(`/api/attacksurfacedomain/${element._id}`, 'delete')
            .subscribe((result: any) => {
              cnt++;
              if (cnt === this.allAttackSurfaceDomainList.length) {
                this.toast.sToast(
                  'success',
                  'Scheduled job removed successfully'
                );
                this.getAttackSurfaceDomains();
                this.loaderService.display(false);
              }
            });
        });
      }
    });
  }

  saveDomainDetails(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/attacksurfacedomain`, 'post', this.domainCofig)
    .subscribe((result: any) => {
      console.log('result',result)
      this.loaderService.display(false);
      if(result[0] === true){
        this.toast.sToast('success', 'Successfully saved');
        setTimeout(() => {
          this.getAttackSurfaceDomains();
        }, 1000);
        this.modalService.close('addAttackDomain');
      } else {
        this.toast.sToast('error','Domain is already available');
        setTimeout(() => {
          this.getAttackSurfaceDomains();
        }, 1000);
        this.modalService.close('addAttackDomain');
      }
     });

  }

  addTableData(): void {
    this.domainCofig = { name:'',
      domain: '', scanlater: false,
      companyRef: { id: this.currentCompany._id, name: this.currentCompany.name },
    };
    this.modalService.open('addAttackDomain');
  }

  scanNow(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to initiate a scan now ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Scanning...');
        this.baseService.doRequest(`/api/attacksurfacedomain/scan_asm`, 'post', idata).subscribe((result: any) => {
          this.loaderService.display(false);
          if(result[0] === true){
            this.toast.sToast('success', 'scan started Successfully');
          } else {
            this.toast.sToast('error','Already scanned');
          }
        });
      }
    });
  }
}
