import {Component, OnInit, Input} from '@angular/core';
import {LoaderService} from '../../../_services/loader.service';
import {ModalService} from '../../../_services/modal.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {CommonService} from '../../../_services/common.services';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-snmp-credentials',
  templateUrl: './snmp-credentials.component.html',
  styleUrls: ['./snmp-credentials.component.scss']
})

export class SnmpCredentialsComponent implements OnInit {
@Input() currentCompany: any;
@Input() currentAgent: any;
snmpv = 'SNMP v1/v2';
snmpVersions: string[] = ['SNMP v1/v2', 'SNMP v3'];
isLoading = false;
snmpCredTableOptions: any;
addSnmpWidth = 600;
agentHash: any = {};
agentList: any = [];
snmp: any = {name: '', agent_type: 0, agentRef: { id: '', name: '' }};
Objectkeys = Object.keys;
  constructor(private loaderService: LoaderService, private toast: MyToastrService,
              private commonService: CommonService, private aS: AuthenticationService,
              public modalService: ModalService) {
      this.snmpCredTableOptions = {
        columns: [
          {
            header: 'Name',
            columnDef: 'name',
            filter: '',
            cell: '(element: any) => `${element.name}`',
            order: 1,
            visible: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            isSort: true,
            iscolumnSearch: false
          }, {
            header: 'Created',
            columnDef: 'c',
            filter: 'utcToLocale',
            cell: '(element: any) => `${element.c}`',
            order: 2,
            visible: false,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false,
          },
          {
            header: 'Updated',
            columnDef: 'u',
            filter: 'utcToLocale',
            cell: '(element: any) => `${element.u}`',
            order: 3,
            visible: false,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false
          },

          {
            header: 'Asset ID',
            columnDef: 'assetid',
            filter: '',
            cell: '(element: any) => `${element.assetid}`',
            order: 4,
            visible: true,
            isToolTip: false,
            isToolTipCol: '',
            hasMultiData: false,
            class: '',
            color: '',
            isProgressCntrl: false,
            isColoredCntrl: false,
            colList: [],
            isfaicon: false,
            isAddingText: false,
            addingText: '',
            img: false,
            imgPath: '',
            isSort: true,
            iscolumnSearch: false
          }
        ],
        sortOptions: {active: 'created', direction: 'desc'},
        _pageData: [],
        tableOptions: {
          id: 'snmpCredTableOptions',
          title: 'SNMP Credentials',
          isServerSide: true,
          selectText: 'agent(s)',
          loading: true,
          floatingFilter: true,
          rowSelection: false,
          showAction: true,
          actionMenuItems: [ {text: 'Details', icon: 'info', callback: 'detailFn', isGlobal: false},
            {text: 'Copy to Probe', icon: 'content_copy', callback: 'deleteFn', isGlobal: true, hidelocal: true},
            {
            text: 'Edit',
            icon: 'edit',
            callback: 'editFn',
            isGlobal: true
          }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}],
          pagination: true,
          pageOptions: [5, 10, 25, 50, 100],
          pageSize: 15,
          search: true,
          showhideList: true,
          refreshData: true,
          filterDownload: false,
          exportExcel: true,
          add: (this.aS.hasPermission('snmpv2credentials', 'create')),
          columnSearch: false,
          compareData: false
        }
      };
     }

  updateView(event: any): void {
    this.snmpv = event.option._value;
  }
  ngOnInit(): void {
    this.snmpCredTableOptions.pageData = [];
    this.getAgents();
  }
  snmpshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.snmpCredTableOptions);
    this.snmpCredTableOptions = {};
    this.snmpCredTableOptions = data;
    this.snmpCredTableOptions.tableOptions.loading = status;
  }
  getsnmpCreds(): void {
    /*if (this.currentCompany && this.currentCompany._id) {
      this.snmpshowHideLoading(true);
      this.loaderService.display(true, 'Getting SNMP Credentials');
      const query = {query: {bool: {must: [
         {match: {'agentRef.id.keyword': this.currentCompany._id + ''}}, {exists: {field: 'agentRef.id'}}]}}};
      const q = JSON.stringify(query);
      const skip = 0;
      const limit = 100;
      this.snmpCredentials.getAllApiSnmpv1CredentialsGet({q, skip, limit}).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data.length) {
          this.snmpCredTableOptions.pageData = result.data;
          this.snmpCredTableOptions.tableOptions.pageTotal = result.total;
          this.snmpshowHideLoading(false);
        }else {
          this.snmpCredTableOptions.pageData = [];
          this.snmpCredTableOptions.tableOptions.pageTotal = 0;
          this.snmpshowHideLoading(false);
        }
        console.log(result);
      });
    } else {
     // setTimeout(() => { this.getsnmpCreds(); }, 1000);
    }*/
  }
  addSnmp(): void {
    this.snmp = {name: '', agent_type: 0, agentRef: { id: '', name: '' }};
    this.snmp.agentRef.id = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
    this.snmp.agentRef.name = (this.currentAgent) ? this.currentAgent.name : this.agentList[0].name;
    this.modalService.open('addSnmp');
  }

  saveAgent(): void {
    /*this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.snmpCredentials.createApiSnmpv1CredentialsPost({body: this.snmp}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false); this.isLoading = true;
      if (result._id) {
        this.modalService.close('addSnmp');
        this.toast.sToast('success', 'Agent added successfully!');
        this.getsnmpCreds();
      } else {
        this.toast.sToast('error', JSON.stringify(result));
      }
    });*/
  }

  getAgents(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.commonService.getAgents().then((value: any) => {
        const externalAgent = value.data[0];
        this.commonService.getAgents(this.currentCompany._id).then((v2: any) => {
          this.commonService.sortFn(v2.data, 'name').then((val: any) => {
            this.agentList = val;
            this.agentList.unshift(externalAgent);
            this.agentHash = new Map(this.agentList.map((i: any) => [i._id, i.name]));
          });
        });
        console.log(this.agentList);
      });
    } else {
      setTimeout(() => { this.getAgents(); }, 1000);
    }
  }
}
