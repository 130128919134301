import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { JobsService } from 'src/app/api/services';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CommonService } from 'src/app/_services/common.services';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-offline-vulnerability-scan-jobs',
  templateUrl: './offline-vulnerability-scan-jobs.component.html',
  styleUrls: ['./offline-vulnerability-scan-jobs.component.scss'],
})
export class OfflineVulnerabilityScanJobsComponent implements OnInit {
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  @Input() currentCompany: any;
  offlineVulTableOptions: any;
  currentJob: any;
  filterQuery: any;
  jobcurrentPage = 0;
  colFilterQuery: any;
  colFilterCols: any = [];
  selectedIndex = 0;

  constructor(
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    private comS: CommonService,
    private cs: CompanySharedService,
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private jobsService: JobsService
  ) {
    this.offlineVulTableOptions = {
      columns: [
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 1,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isHyperlink: true,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Agent Name',
          columnDef: 'agentRef.name',
          filter: '',
          cell: '(element: any) => `${element.agentRef.name}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Job Status',
          columnDef: 'job_data.job_status',
          filter: '',
          cell: '(element: any) => `${element.job_data.job_status}`',
          order: 4,
          img: true,
          conditionList: [
            {
              _img: '/assets/images/loading.gif',
              value: 1,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 2,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 3,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/tick.svg',
              value: 5,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/cross.svg',
              value: 6,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/alert.svg',
              value: 4,
              class: 'imageWidth',
            },
          ],
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Running', value: 3 },
            { name: 'Success', value: 5 },
            { name: 'Partial', value: 4 },
            { name: 'Failed', value: 6 },
          ],
        },

        {
          header: 'JOB ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Task',
          columnDef: 'job_data.task',
          filter: '',
          cell: '(element: any) => `${element.job_data.task}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Time Elapsed',
          columnDef: 'timeConsumed',
          filter: '',
          cell: '(element: any) => `${element.timeConsumed}`',
          order: 7,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Status',
          columnDef: 'job_data.status_message',
          filter: '',
          cell: '(element: any) => `${element.job_data.status_message}`',
          order: 8,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Scheduler Name',
          columnDef: 'sch_name',
          filter: '',
          cell: '(element: any) => `${element.sch_name}`',
          order: 9,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: true,
        },
      ],
      title: 'Offline Vulnerability Scan Jobs',
      cFilter: true,
      faClass: 'Jobs',
      sortOptions: { active: 'u', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'offlinevulnerabilityscanjobs',
        title: 'Offline Vulnerability Scan Jobs',
        isServerSide: true,
        selectText: 'job(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Details',
            icon: 'info',
            callback: 'detailFn',
            isGlobal: false,
          },
          {
            text: 'Terminate',
            icon: 'stop_circle',
            callback: 'detailFn',
            isGlobal: true,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        saveData: true,
        columnSearch: false,
        compareData: false,
        hideDownload: true,
      },
    };
  }

  ngOnInit(): void {
    this.offlineVulTableOptions.pageData = [];
    this.getOfflineVulJobs();
  }

  getOfflineVulJobs(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.offlineVulJobsshowHideLoading(true);
      const query: any = {
        query: {
          bool: {
            must: [{match: {'companyRef.id.keyword': `${this.currentCompany._id}`}},
            { match: { 'job_data.task.keyword': `Offline Vulnerability Scan` } },
             {exists: {field: 'job_data'}},
            ]
          }
        }
      };
      this.offlineVulTableOptions.serverSide = {
        service: 'jobsService', fn: 'getAllApiJobsGet', q: query
      };
      if (this.filterQuery && this.filterQuery.multi_match) {
        query.query.bool.must.push(this.filterQuery);
      }
      if (this.filterQuery && this.filterQuery.filter) {
        query.query.bool.filter = this.filterQuery.filter;
      }

      if (this.colFilterQuery && this.colFilterQuery.length) {
        query.query.bool.filter = [];
        this.colFilterQuery.forEach((obj: any) => {
          if (obj.bool.should[0].match) {
            query.query.bool.must.push(obj);
          } else {
            query.query.bool.filter.push(obj);
          }
        });
      }


      const q = JSON.stringify(query);
      const skip = this.jobcurrentPage;
      const limit = this.offlineVulTableOptions.tableOptions.pageSize;
      let sort: any = [{}];
      if (this.offlineVulTableOptions.sortOptions && this.offlineVulTableOptions.sortOptions.direction
        && this.offlineVulTableOptions.sortOptions.direction !== '') {
        const orderArr = ['job_data.task', 'agentRef.name', 'job_data.status_message'];
        if (orderArr.indexOf(this.offlineVulTableOptions.sortOptions.active) > -1) {
          sort[0][this.offlineVulTableOptions.sortOptions.active + '.keyword'] = {order: this.offlineVulTableOptions.sortOptions.direction};
        } else {
          sort[0][this.offlineVulTableOptions.sortOptions.active] = {order: this.offlineVulTableOptions.sortOptions.direction};
        }
      }
      sort = JSON.stringify(sort);
      const fields = JSON.stringify(['c', 'u', 'job_data.job_status', '_id',
        'job_data.task', 'timeConsumed', 'job_data.status_message', 'agentRef.name', 'task_id']);
      // @ts-ignore
      this.jobsService.getAllApiJobsGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data.length) {
          result.data.forEach((item: any) => {
            if (!item?.agentRef || item.agentRef === null) { item.agentRef = {name: '-', id: '-'}; }
            if (!item?.sch_name || item.sch_name === null) { item.sch_name = ('-'); }
          });
          this.offlineVulTableOptions.pageData = result.data;
          this.offlineVulTableOptions.tableOptions.pageTotal = result.total;
          this.offlineVulJobsshowHideLoading(false);
        } else {
          this.offlineVulTableOptions.pageData = [];
          this.offlineVulTableOptions.tableOptions.pageTotal = 0;
          this.offlineVulJobsshowHideLoading(false);
        }
      });
    } else {
      setTimeout(() => {
        this.getOfflineVulJobs();
      }, 1000);
    }
  }

  offlineVulJobsglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Terminate') {
      this.terminateJobs(idata);
    }
  }

  offlineVulJobsshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.offlineVulTableOptions);
    this.offlineVulTableOptions = {};
    this.offlineVulTableOptions = data;
    this.offlineVulTableOptions.tableOptions.loading = status;
  }

  offlineVulJobslinkCall(idata: any): void {
    if (idata.col === 'u') {
      this.currentJob = undefined;
      setTimeout(() => { this.currentJob = idata.row;  this.showDetails(); });
    }
  }

  showDetails(): void {
    this.loaderService.display(true, 'Getting job details...');
    this.jobsService.getApiJobsIdGet({id: this.currentJob._id}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.currentJob = undefined;
      setTimeout(() => {
        this.currentJob = result;
        if (this.currentJob && this.currentJob.job_data && (this.currentJob.job_data.job_message || this.currentJob.job_data.report_job_message)) {
          this.cs.jobsUpdateEVE.next(this.currentJob);
          this.snav.open();
        } else {
          this.toast.sToast('error', 'Sorry details not available.');
        }
      });
    });
  }

  offlineVulJobsactionCall(idata: any): void {
    if (idata.action.text === 'Details') {
      this.currentJob = undefined;
      setTimeout(() => { this.currentJob = idata.row; this.showDetails(); });
    } else if (idata.action.text === 'Terminate') {
      if (idata.row.job_data.job_status < 4) {
        this.terminateJob(idata.row);
      } else {
        this.toast.sToast('error', 'This job cannot be terminated');
      }
    }
  }

  terminateJobs(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to terminate the selected job entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        idata.row.forEach((obj: any, index: number) => {
          if (obj.job_data.job_status < 4) {
            this.loaderService.display(true, `Terminating ${obj.job_data.task} job....`);
            this.baseService.doRequest(`api/jobs/${obj._id}/terminatejob`, 'post', {jobid: obj._id})
            .subscribe((result: any) => {
              this.loaderService.display(false);
              if (index === idata.row.length - 1) {
                this.loaderService.display(false);
                this.toast.sToast('success', result[1]);
                setTimeout(() => { this.getOfflineVulJobs(); }, 2000);
              }
            });
          } else {
            this.loaderService.display(false);
            this.toast.sToast('error', `${obj.job_data.task} job cannot be terminated`);
            if (index === idata.row.length - 1) {
              setTimeout(() => { this.getOfflineVulJobs(); }, 2000);
            }
          }
        });
      }
    });
  }

  terminateJob(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to terminate this ${idata.job_data.task} job ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        this.baseService.doRequest(`api/jobs/${idata._id}/terminatejob`, 'post', {jobid: idata._id})
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getOfflineVulJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  offlineVulJobsfilterCall(event: any): void {
    const fields: any = [];
    this.offlineVulTableOptions.columns.forEach((obj: any) => {
      fields.push(obj.columnDef);
    });
    this.filterQuery = (event && event.length > 0)
      ? {
        multi_match: {
          query: event, type: 'phrase_prefix',
          fields: [ 'job_data.task', 'job_data.status_message', 'agentRef.name']
        }
      } : undefined;
    if (this.comS.isUuid(event)) {
      this.filterQuery = { filter: [ {ids: {values: [event]}}] };
    }
    this.getOfflineVulJobs();
  }

  offlineVulJobssortCall(event: any): void {
    this.offlineVulTableOptions.sortOptions = event;
    this.getOfflineVulJobs();
  }

  offlineVulJobspageCall(event: any): void {
    this.offlineVulTableOptions.tableOptions.pageSize = event.pageSize;
    this.jobcurrentPage = event.pageIndex;
    this.getOfflineVulJobs();
  }

  colFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') { this.colFilterCols.push(event); }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      let qval: any;
      const char = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"g, '\\$1'])/;
      const searchValue = (typeof(obj.value) === 'string') ? obj.value.trim() : obj.value;
      if (obj.col === 'job_data.job_status'){
        qval = (char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version') ? `\"${searchValue}\"` : '' + searchValue + '';
      }else{
        qval = (char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version') ? `\"${searchValue}\"` : '*' + searchValue + '*';
      }
      const tmpObj = { bool: { should: [{ query_string: { fields: [obj.col], query: qval} }] } };
      this.colFilterQuery.push(tmpObj);
    });
    this.getOfflineVulJobs();
  }

  totalCallbackCheck($event: any): void { // @ts-ignore
    if (this[$event.id]) { // @ts-ignore
      this[$event.id].hideTable = ($event.value === 0);
    } else {
      console.log($event.id + ' not available');
    }
  }
}
