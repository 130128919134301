/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatchScheduler } from '../models/patch-scheduler';
import { PatchSchedulerCreate } from '../models/patch-scheduler-create';
import { PatchSchedulerGetResp } from '../models/patch-scheduler-get-resp';
import { TerminatePatchParams } from '../models/terminate-patch-params';

@Injectable({
  providedIn: 'root',
})
export class PatchSchedulerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllApiPatchschedulerGet
   */
  static readonly GetAllApiPatchschedulerGetPath = '/api/patchscheduler';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiPatchschedulerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiPatchschedulerGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<StrictHttpResponse<PatchSchedulerGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, PatchSchedulerService.GetAllApiPatchschedulerGetPath, 'get');
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatchSchedulerGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllApiPatchschedulerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiPatchschedulerGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    'q'?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
  }): Observable<PatchSchedulerGetResp> {

    return this.getAllApiPatchschedulerGet$Response(params).pipe(
      map((r: StrictHttpResponse<PatchSchedulerGetResp>) => r.body as PatchSchedulerGetResp)
    );
  }

  /**
   * Path part for operation updateApiPatchschedulerPut
   */
  static readonly UpdateApiPatchschedulerPutPath = '/api/patchscheduler';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiPatchschedulerPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiPatchschedulerPut$Response(params: {
    body: PatchScheduler
  }): Observable<StrictHttpResponse<PatchScheduler>> {

    const rb = new RequestBuilder(this.rootUrl, PatchSchedulerService.UpdateApiPatchschedulerPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatchScheduler>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateApiPatchschedulerPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiPatchschedulerPut(params: {
    body: PatchScheduler
  }): Observable<PatchScheduler> {

    return this.updateApiPatchschedulerPut$Response(params).pipe(
      map((r: StrictHttpResponse<PatchScheduler>) => r.body as PatchScheduler)
    );
  }

  /**
   * Path part for operation createApiPatchschedulerPost
   */
  static readonly CreateApiPatchschedulerPostPath = '/api/patchscheduler';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiPatchschedulerPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiPatchschedulerPost$Response(params: {
    body: PatchSchedulerCreate
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PatchSchedulerService.CreateApiPatchschedulerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createApiPatchschedulerPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiPatchschedulerPost(params: {
    body: PatchSchedulerCreate
  }): Observable<any> {

    return this.createApiPatchschedulerPost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation patchTerminateApiPatchschedulerPatchterminatePost
   */
  static readonly PatchTerminateApiPatchschedulerPatchterminatePostPath = '/api/patchscheduler/patchterminate';

  /**
   * Patchterminate.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTerminateApiPatchschedulerPatchterminatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTerminateApiPatchschedulerPatchterminatePost$Response(params: {
    body: TerminatePatchParams
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PatchSchedulerService.PatchTerminateApiPatchschedulerPatchterminatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Patchterminate.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTerminateApiPatchschedulerPatchterminatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTerminateApiPatchschedulerPatchterminatePost(params: {
    body: TerminatePatchParams
  }): Observable<any> {

    return this.patchTerminateApiPatchschedulerPatchterminatePost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getApiPatchschedulerIdGet
   */
  static readonly GetApiPatchschedulerIdGetPath = '/api/patchscheduler/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiPatchschedulerIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPatchschedulerIdGet$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<PatchScheduler>> {

    const rb = new RequestBuilder(this.rootUrl, PatchSchedulerService.GetApiPatchschedulerIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PatchScheduler>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiPatchschedulerIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiPatchschedulerIdGet(params: {
    id: string;
  }): Observable<PatchScheduler> {

    return this.getApiPatchschedulerIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<PatchScheduler>) => r.body as PatchScheduler)
    );
  }

  /**
   * Path part for operation deleteApiPatchschedulerIdDelete
   */
  static readonly DeleteApiPatchschedulerIdDeletePath = '/api/patchscheduler/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiPatchschedulerIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPatchschedulerIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PatchSchedulerService.DeleteApiPatchschedulerIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApiPatchschedulerIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiPatchschedulerIdDelete(params: {
    id: string;
  }): Observable<any> {

    return this.deleteApiPatchschedulerIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
