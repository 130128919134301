<div class="row">
  <div class="col-lg-12">
    <form #log4EmailForm="ngForm" role="form">
    <mat-form-field class="w-100 mb-2" appearance="outline">
      <mat-label>Notification Email Address</mat-label>
      <input matInput name="log4jNotificationEmail" required appEmailCheck
             [(ngModel)]="log4jNotificationEmail" type="text" placeholder="Enter Email Address">
      <mat-hint>Ex. Enter comma separated email address(es)</mat-hint>
    </mat-form-field>
    <button mat-raised-button color="primary" class="float-right mb-2" type="button" [disabled]="!log4jNotificationEmail || !log4EmailForm.form.valid"
     (click)="setLog4jNotificationEmail()">Save</button>
    </form>
  </div>
</div>
