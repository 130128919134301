import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';
import {MyToastrService} from '../../_services/toastr.service';
import {MatSidenav} from '@angular/material/sidenav';
import {CommonService} from '../../_services/common.services';
import { CompanyService } from 'src/app/api/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vulnerability-overview',
  templateUrl: './vulnerability-overview.component.html',
  styleUrls: ['./vulnerability-overview.component.scss']
})

export class VulnerabilityOverviewComponent implements OnInit {
  @Input() currentCompany: any;
  @Input() mode: any;
  @ViewChild('snav', {static: true}) snav: MatSidenav;
  Objectkeys = Object.keys;
  vulnerabilityOverviewData: any = [];
  prodList: any;
  vulnerabilityProductOverviewData: any;
  vulnerabilityListData: any ;
  showUpper = true;
  pageSize = 50;
  currentVStat: any;
  currentProduct: any;
  statusFilterKey : any = 'active';
  statusFilterSource: any = [
    { name: 'Active', value: 'active' },
    { name: 'Suppressed', value: 'suppressed' },
  ];
  suppression_vul : any = false;
  severityHtmlCols = {
    Critical: `<span class=" fw4  px-1 badge scritical">CRITICAL</span>`,
    High: `<span class=" fw4  px-1 badge shigh">HIGH</span>`,
    Medium: `<span class=" fw4  px-1 badge smedium">MEDIUM</span>`,
    Low: `<span class=" fw4  px-1 badge slow">LOW</span>`,
    Info: `<span class=" fw4  px-1 badge sinfo">INFO</span>`
  };
  vulnerabilityListCols: any = ['CVEID', 'title', 'severity', 'base_score',
    'impact_score', 'exploit_score', 'affected_assets'];
  vulnerabilityListCustomCols: any = [
    {col: 'CVEID', hyperLinkCall: {noFilter: true}},
    {col: 'title', filter: 'ellipsis:25'},
    {
      col: 'severity', isKeyword: true, header: 'Severity', htmlCols: this.severityHtmlCols,
      colFilters: {type: 'text', hKey: true}, noFilter: false
    },
    {col: 'affected_assets', hyperLinkCall: {noFilter: true}},
  ];
  vulnerabilityOverviewCols: any = ['os', 'Critical', 'High', 'Medium', 'Low', 'risk_score'];
  vulnerabilityOverviewCustomCols: any = [
    {col: 'Critical', hyperLinkCall: {noFilter: true}},
    {col: 'High', hyperLinkCall: {noFilter: true}},
    {col: 'Medium', hyperLinkCall: {noFilter: true}},
    {col: 'Low', hyperLinkCall: {noFilter: true}}
  ];
  view: string;
  currentCVE: any;
  companyHash: any = {};
  private refEve: Subscription;
  constructor(private loaderService: LoaderService, private commonService: CommonService,
              private baseService: BaseRequestService, private toast: MyToastrService, private companyService: CompanyService,) {
               
  }

  updateFilterStatus($event: any): void {
      if ($event.value === 'active') {
        this.suppression_vul = false;
        this.getVulnerabilityOverview();
      } else if($event.value === 'suppressed')  {
        this.suppression_vul = true;
       this.getVulnerabilityOverview();
      }
      // this.suppression_vul = ($event.value === 'active')? false : true;
  }


  getVulnerabilityOverview(): void {
    if (!this.mode && !this.currentCompany) {
      setTimeout(() => this.getVulnerabilityOverview(), 2000);
      return;
    }
   
    if (this.mode === 'global') {
      this.currentCompany = {_id: '', name: ''};
    }
    this.vulnerabilityOverviewData = [];
    this.loaderService.display(true, 'Getting vulnerability stat overview...');
    this.baseService.doRequest(`/api/company/imaws/getVulnerabilityOsView`, 'post',
      {companyId: this.currentCompany._id, suppression: this.suppression_vul}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        this.vulnerabilityOverviewData = result[1];
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  updateSeverityData(severity: string): void {
    // this.updateFilterStatus({value: this.suppression_vul});
    this.getVulnerabilityProductOverview({row: this.currentVStat, col: severity});
  }

  getVulnerabilityProductOverview($event: any): void {
    this.updateFilterStatus({value: this.suppression_vul});
    if (!$event.row[$event.col]) {
      this.toast.sToast('info', `No ${$event.col} vulnerabilities found!`);
      return;
    }
    this.currentVStat = $event.row;
    this.currentVStat.severity = $event.col;
    this.loaderService.display(true, 'Getting vulnerability product overview...');
    const params: any = {companyId: this.currentCompany._id, os: $event.row.os, severity: $event.col,suppression: this.suppression_vul};
    this.baseService.doRequest(`/api/company/imaws/getVulnerabilityProductView`, 'post',
      params).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        if (result[1] && result[1].length) {
          this.vulnerabilityProductOverviewData = result[1][0];
          this.prodList = this.Objectkeys(result[1][0]);
          this.getVulnerabilities(this.prodList[0]);
        }
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  ngOnInit(): void {
    this.updateFilterStatus({value : this.statusFilterKey});
    // this.getVulnerabilityOverview();
  }

  getVulnerabilities(prod: string): void {
    this.updateFilterStatus({value : this.statusFilterKey});
    this.currentProduct = prod;
    this.loaderService.display(true, 'Getting vulnerability overview...');
    this.vulnerabilityListData = [];
    const params: any = {
      companyId: this.currentCompany._id, os: this.currentVStat.os, severity: this.currentVStat.severity,
      productName: prod, suppression: this.suppression_vul
    };
    this.baseService.doRequest(`/api/company/imaws/getVulnerabilityView`, 'post',
      params).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        result[1].forEach((obj: any, index: number) => {
          obj.CVEID = obj.vul_id;
          obj.affected_assets = 0;
          obj.score = { base_score: 0, cvss_score: 0, exploit_score: 0, impact_score: 0, ...obj.score};
          obj.affected_assets = obj.data.map((x: any) => x.assets.length).reduce((a: any, b: any) => a + b, 0);
          result[1][index] = {...obj, ...obj.score};
        });
        this.vulnerabilityListData = result[1];
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  closeView(): void {
    this.currentVStat = undefined;
    this.currentProduct = undefined;
    this.currentCVE = undefined;
    this.vulnerabilityListData = [];
  }

  getCompanyAssetOverview($event: any): void {
    this.currentCVE = $event.row;
    this.snav.open();
  }

  close(back?: any): void {
    if (back) {
      return;
    }
    this.currentCVE = undefined;
    this.snav.close();
  }

  getAllCompany(): void {
    let cq: any;
    const cmpq = {query: {bool: {must: [{exists: {field: 'description'}}, {"terms":{"_type_.keyword":["company","companycreate"]}}], must_not: [{match: {isAssessment: true}}, {exists: {field: 'companyRef'}}]}}};
    const asmq = {query: {bool: {must: [{match: {isAssessment: true}}, {exists: {field: 'description'}}, {"terms":{"_type_.keyword":["company","companycreate"]}}], must_not: [{exists: {field: 'companyRef'}}]}}};
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{'name.keyword': {order: 'asc'}}]);
    const fields = JSON.stringify(['name', 'customerInfo', 'c', 'isAssessment']);
    this.companyService.getAllApiCompanyGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
      if (result.data.length) {
        result.data.map((x: any) => (x._id) ?  this.companyHash[x._id] = x : null);
      }
    });
  }

  redirectToAsset(company: any, asset: any): void {
    localStorage.setItem('cmp', company.name);
    this.baseService.doRequest(`/api/company/${company.id}`, 'get').subscribe((result: any) => {
      if (result){
        this.commonService.currentCompany = result;
        localStorage.setItem('newTab', 'cveAssets');
        localStorage.setItem('asset', asset.id);
        window.open('/companies/company', '_blank');
      }
    });
  }
}
