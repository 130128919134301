import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CommonService } from 'src/app/_services/common.services';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';
import { AttackSurfaceResultsService, JobsService } from 'src/app/api/services';

@Component({
  selector: 'app-attack-surface-result-tab',
  templateUrl: './attack-surface-result-tab.component.html',
  styleUrls: ['./attack-surface-result-tab.component.scss']
})
export class AttackSurfaceResultTabComponent implements OnInit {
  @ViewChild('snav', { static: true }) snav: MatSidenav;
  @Input() currentCompany: any;
  attackSurfaceresultTableOptions:any
  attackSurfaceresultcurrentPage = 0;
  attackSurfacefilterQuery: any;
  colFilterQuery: any;
  jobDomain: any = '';
  allAttackSurfaceDomainList: any;

  constructor(
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    private comS: CommonService,
    private jobsService: JobsService,
    private cs: CompanySharedService,
    private baseService: BaseRequestService,
    public modalService: ModalService,
    public attackSurfaceResultsService: AttackSurfaceResultsService,
  ) { 

    this.attackSurfaceresultTableOptions = {
      columns: [
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'IP',
          columnDef: 'target_ips.IPAddress',
          filter: '',
          cell: '(element: any) => `${element.target_ips.IPAddress}`',
          order: 1,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Domain Name',
          columnDef: 'website',
          filter: '',
          cell: '(element: any) => `${element.website}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          isHyperlink: true,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        }
      ],
      
      sortOptions: { active: 'c', direction: 'desc' },
      _pageData: [],
      faClass: 'attackSurfaceresult',
      tableOptions: {
        id: 'attackSurfaceresultTableOptions',
        title: 'Attack Surface Mapper Results',
        isServerSide: true,
        selectText: 'attack surface Result',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Details',
            icon: 'info',
            callback: 'detailFn',
            isGlobal: false,
          },{text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        search: false,
        selectedTimer: '60',
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        showColFilter: false,
        add: false,
        columnSearch: false,
        compareData: false,
        hideDownload: true,
      },
    };

  }

  ngOnInit(): void {
    this.attackSurfaceresultTableOptions.pageData = [];
    this.getAttackSurfaceJobsresult();
  }

  attackSurfaceResultshowHideLoading(status: any): void {
    const data = Object.assign({}, this.attackSurfaceresultTableOptions);
    this.attackSurfaceresultTableOptions = {};
    this.attackSurfaceresultTableOptions = data;
    this.attackSurfaceresultTableOptions.tableOptions.loading = status;
  }


  attackSurfaceResultpageCall(event: any): void {
    this.attackSurfaceresultTableOptions.tableOptions.pageSize = event.pageSize;
    this.attackSurfaceresultTableOptions = event.pageIndex;
    this.getAttackSurfaceJobsresult();
  }

  attackSurfaceresultsortCall(idata: any): void {
    this.attackSurfaceresultTableOptions.sortOptions = idata;
    this.getAttackSurfaceJobsresult();
  }

  attackSurfaceresulttimerCallData(idata: any): void {
    this.getAttackSurfaceJobsresult();
  }


  attackSurfaceresultactionCall(idata: any): void {
    if (idata.action.text === 'Details') {
      if(idata.row.mx.error === null)
      {
        // this.snav.open();
        this.jobDomain = idata.row;
      }else{
        this.toast.sToast('error', idata.row.mx.error);
      }
    }else if (idata.action.text === 'Delete') {
      this.deleteattackSurfaceConfirmationDialog(idata);
    }
  }

  attackSurfaceglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Delete') {
      this.deleteattackSurfaceConfirmationDialogForAll(idata);
    } 
  }

  attackSurfacelinkCall(idata: any): void {
    if (idata.col === 'website') {
      // this.jobDomain = idata.row;
      if(idata.row.mx.error === null)
      {
        // this.snav.open();
        this.jobDomain = idata.row;
        console.log('this.jobDomain',this.jobDomain)
      }else{
        this.toast.sToast('error', idata.row.mx.error);
        console.log('idata.row.mx.error',idata.row.mx.error)
      }
    }
  }


  deleteattackSurfaceConfirmationDialogForAll(data: any): void {
    let cnt = 0;
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete selected domain?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
        this.allAttackSurfaceDomainList = data.row;
        console.log('deletall',this.allAttackSurfaceDomainList)
        data.row.forEach((element: any) => {
          this.baseService
            .doRequest(`/api/attacksurfaceresults/${element._id}`, 'delete')
            .subscribe((result: any) => {
              cnt++;
              if (cnt === this.allAttackSurfaceDomainList.length) {
                this.toast.sToast(
                  'success',
                  'Scheduled job removed successfully'
                );
                this.getAttackSurfaceJobsresult();
                this.loaderService.display(false);
              }
            });
        });
      }
    });
  }


  deleteattackSurfaceConfirmationDialog(data: any): void {
    console.log('data',data)
    const titleName = 'Delete message';
    const message = `Are you sure you want to delete this ${data?.row?._id} scheduled job?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/attacksurfaceresults/${data?.row?._id}`, 'delete', {
            id: data?.row?._id,
          })
          .subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getAttackSurfaceJobsresult();
            }, 1000);
          });
      }
    });
  }

  getAttackSurfaceJobsresult():void{
    console.log('printdata1')
    if (this.currentCompany && this.currentCompany._id) {
      this.attackSurfaceResultshowHideLoading(true);
      const query: any = {
          query: {bool: {must: [ {match: {'companyRef.id.keyword': `${this.currentCompany._id}`}},
            {exists: {field: "website"}},
            {match: {
              "status.keyword": "Completed"
            }},
            {terms: {"_type_.keyword": ["attacksurfaceresultscreate","attacksurfaceresults"]}}
          ]}}
      };
      if (this.attackSurfacefilterQuery && this.attackSurfacefilterQuery.multi_match) {
        query.query.bool.must.push(this.attackSurfacefilterQuery);
      }
      if (this.attackSurfacefilterQuery && this.attackSurfacefilterQuery.filter) {
        query.query.bool.filter = this.attackSurfacefilterQuery.filter;
      }

      if (this.colFilterQuery && this.colFilterQuery.length) {
        query.query.bool.filter = [];
        this.colFilterQuery.forEach((obj: any) => {
          if (obj.bool.should[0].match) {
            query.query.bool.must.push(obj);
          } else {
            query.query.bool.filter.push(obj);
          }
        });
      }
      const q = JSON.stringify(query);
      const skip = this.attackSurfaceresultcurrentPage;
      const limit = this.attackSurfaceresultTableOptions.tableOptions.pageSize;
      let sort: any = [{}];
      if (this.attackSurfaceresultTableOptions.sortOptions && this.attackSurfaceresultTableOptions.sortOptions.direction
        && this.attackSurfaceresultTableOptions.sortOptions.direction !== '') {
        const orderArr = ['website','name'];
        if (orderArr.indexOf(this.attackSurfaceresultTableOptions.sortOptions.active) > -1) {
          sort[0][this.attackSurfaceresultTableOptions.sortOptions.active + '.keyword'] = {order: this.attackSurfaceresultTableOptions.sortOptions.direction};
        } else {
          sort[0][this.attackSurfaceresultTableOptions.sortOptions.active] = {order: this.attackSurfaceresultTableOptions.sortOptions.direction};
        }
      }
      sort = JSON.stringify(sort);
      const fields = JSON.stringify(['c', 'u', 'companyRef', '_id', 'website','name']);
      // @ts-ignore
      this.attackSurfaceResultsService.getAllApiAttacksurfaceresultsGet({q, skip, limit, sort}).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data.length) {
          this.attackSurfaceresultTableOptions.pageData = result.data;
          this.attackSurfaceresultTableOptions.tableOptions.pageTotal = result.total;
          this.attackSurfaceResultshowHideLoading(false);
        } else {
          this.attackSurfaceresultTableOptions.pageData = [];
          this.attackSurfaceresultTableOptions.tableOptions.pageTotal = 0;
          this.attackSurfaceResultshowHideLoading(false);
        }
      });
    } else {
      setTimeout(() => {
        this.getAttackSurfaceJobsresult();
      }, 1000);
    }

  }

}
