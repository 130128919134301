

<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
    <span id="ActiveDirectoryCredentials"> Active Directory Credentials</span>
    </ng-template>
    <ng-template matTabContent>
      <app-s-table aria-label="ActiveDirectory-Cred" role="table" [sTableOptions]="adCredTableOptions"
                   (globalActionCallback)="adCredglobalActionCall($event)"
             (addCallback)="addActiveDirectoryCred()" (actionCallback)="adCredactionCall($event)" (sortCallback)="activesortCall($event)"
             (refreshCallback)="getActiveDirectoryCred()"></app-s-table>
      <s-modal id="addadCred">

          <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
            <div class="d-flex flex-row align-items-center list-right list-right w-25">
              <div class="col-lg-12 text-center">
                <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
                <p class="mat-body-1">Add an Active Directory Credentials text <strong>goes</strong> here</p>
              </div>
            </div>
            <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addadCredWidth">
              <button class="float-right" mat-icon-button type="button" aria-label="close modal icon" matTooltip="Close"
                      (click)="modalService.close('addadCred');">
                <mat-icon>close</mat-icon>
              </button>
              <form #addadCred="ngForm">
              <div class="mat-title">Add Active Directory Credentials</div>
              <mat-card-content class="">
                <mat-form-field class="w-75 mb-2" appearance="outline">
                  <mat-label>Name</mat-label>
                  <input matInput id="ADName" placeholder="Enter ActiveDirectory-Cred name" type="text" trim="blur" required name="adCredname"
                        [(ngModel)]="adCred.name"
                        autocomplete="off">
                  <mat-hint>Ex. DB Server</mat-hint>
                </mat-form-field>
                <mat-form-field class="w-75 mb-2" appearance="outline">
                  <mat-label>Domain</mat-label>
                  <input matInput id="ADDomain" placeholder="Enter ActiveDirectory-Cred domain" type="text" trim="blur" required name="adCreddomain"
                        [(ngModel)]="adCred.domain"
                        autocomplete="off">
                </mat-form-field>
                <mat-form-field class="w-75 mb-2" appearance="outline">
                  <mat-label>Active Directory DC Name</mat-label>
                  <input matInput id="ADDcName" placeholder="Enter ActiveDirectory-Cred hostname" type="text" trim="blur" required name="adCredhostname"
                        [(ngModel)]="adCred.hostname"
                        autocomplete="off">
                </mat-form-field>
                <mat-form-field class="w-75 mb-2" appearance="outline">
                  <mat-label>User Name</mat-label>
                  <input matInput id="ADUserName" placeholder="Enter ActiveDirectory-Cred username" type="text" trim="blur" required name="adCredusername"
                        [(ngModel)]="adCred.username"
                        autocomplete="off">
                </mat-form-field>
                <mat-form-field class="w-75 mb-2" appearance="outline">
                  <mat-label>Password</mat-label>
                  <input matInput id="ADUserPassword" placeholder="Enter ActiveDirectory-Cred password" type="password" trim="blur" [required]="!adCred._id" name="adCredpassword"
                        [(ngModel)]="adCred.password"
                        autocomplete="off">
                </mat-form-field>
              <!--<mat-form-field class="w-75 mb-2 " appearance="outline">
                <mat-label>Agent</mat-label>
                <mat-select [multiple]="false" name="adCredagentref"
                            placeholder="Choose agent"
                            [(ngModel)]="adCred.agentref">
                            <mat-option *ngFor="let ref of agentref"
                            [value]="ref.value">
                  {{ref.name}}
                </mat-option>
                </mat-select>
              </mat-form-field>-->
              </mat-card-content>
              <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
                <button mat-raised-button class="mat-primary mr-2" type="button"
                        (click)="saveActiveDirectoryCred()" [disabled]="!addadCred.form.valid || isLoading"
                        id="saveadCred">Save
                </button>
                <button mat-stroked-button class="mat-warn" type="button" (click)="modalService.close('addadCred')"
                        id="canceladCred">Cancel
                </button>
              </mat-card-footer>
            </form>
            </mat-card>
          </mat-card>

      </s-modal>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="currentAgent && currentAgent.agent_type === 'Probe'">
    <ng-template mat-tab-label>
    <span id="ExcludeIp">Exclude IP</span>
    </ng-template>
    <ng-template matTabContent>
      <span>&nbsp;</span>
      <app-exclude-ip [currentAgent]="currentAgent" [currentCompany]="currentCompany"></app-exclude-ip>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<s-modal id="copyAdCredToProbe">
  <form #copyToProbeForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="'600'">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Copy To Other Probe</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="closeCopyProbe();">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Copy To Other Probe</div>
        <mat-card-content class="">
          <p><strong>Selected AD Credential(s):</strong></p>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <span class="mr-2 py-1 px-2 badge badge-primary mb-2" *ngFor="let range of currentAdCred"> {{range.name}}</span>
          </div>
          <hr class="">
          <mat-form-field *ngIf="probeList && probeList.length" appearance="fill" class="w-100">
            <mat-label>Choose Probe</mat-label>
            <mat-select name="agentRef" [(ngModel)]="agentRef.id" placeholder="Choose Probe To Copy" required>
              <mat-option value="">Select Probe</mat-option>
              <mat-option *ngFor="let agent of agentRef.agents| sortBy:'asc':'name';" [value]="agent?._id">{{agent?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="copyToSelectProbe(agentRef)" [disabled]="!copyToProbeForm.form.valid || isLoading"
                  id="copys">Copy
          </button>
          <button mat-stroked-button class="mat-warn" (click)="closeCopyProbe();"
                  id="cancels">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
