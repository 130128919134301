import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CommonService } from 'src/app/_services/common.services';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';
import { JobsService } from 'src/app/api/services';

@Component({
  selector: 'app-attack-surface-mapper-job',
  templateUrl: './attack-surface-mapper-job.component.html',
  styleUrls: ['./attack-surface-mapper-job.component.scss'],
})
export class AttackSurfaceMapperJobComponent implements OnInit {
  @ViewChild('inte1', { static: true }) inte1: MatSidenav;
  @Input() currentCompany: any;
  attackSurfaceTableOptions: any;
  attackSurfacecurrentPage = 0;
  attackSurfacefilterQuery: any;

  colFilterQuery: any;
  colFilterCols: any = [];
  scheduledId: any;
  showUpper = true;
  allAttackSurfaceList: any;
  jobDomain: any = '';
  Objectkeys = Object.keys;
  currentJob1: any;

  constructor(
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    private comS: CommonService,
    private jobsService: JobsService,
    private cs: CompanySharedService,
    private baseService: BaseRequestService,
    public modalService: ModalService
  ) {
    this.attackSurfaceTableOptions = {
      columns: [
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 1,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isHyperlink: true,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Job Status',
          columnDef: 'job_data.job_status',
          filter: '',
          cell: '(element: any) => `${element.job_data.job_status}`',
          order: 4,
          img: true,
          conditionList: [
            {
              _img: '/assets/images/loading.gif',
              value: 1,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 2,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 3,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/tick.svg',
              value: 5,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/cross.svg',
              value: 6,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/alert.svg',
              value: 4,
              class: 'imageWidth',
            },
          ],
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Running', value: 3 },
            { name: 'Success', value: 5 },
            { name: 'Partial', value: 4 },
            { name: 'Failed', value: 6 },
          ],
        },

        {
          header: 'JOB ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Task',
          columnDef: 'job_data.task',
          filter: '',
          cell: '(element: any) => `${element.job_data.task}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Time Elapsed',
          columnDef: 'timeConsumed',
          filter: '',
          cell: '(element: any) => `${element.timeConsumed}`',
          order: 7,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Status',
          columnDef: 'job_data.message',
          filter: '',
          cell: '(element: any) => `${element.job_data.message}`',
          order: 8,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Scheduler Name',
          columnDef: 'sch_name',
          filter: '',
          cell: '(element: any) => `${element.sch_name}`',
          order: 9,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: true,
        },
      ],
      sortOptions: { active: 'c', direction: 'desc' },
      _pageData: [],
      faClass: 'attackSurfacehScheduler',
      tableOptions: {
        id: 'attackSurfaceTableOptions',
        title: 'Attack Surface Mapper Jobs',
        isServerSide: true,
        selectText: 'attack surface job(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Details',
            icon: 'info',
            callback: 'detailFn',
            isGlobal: false,
          },
          {text: 'Terminate', icon: 'stop_circle', callback: 'detailFn', isGlobal: false},
          // {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true},
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        selectedTimer: '60',
        search: false,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        showColFilter: true,
        add: false,
        columnSearch: false,
        compareData: false,
        hideDownload: true,
      },
    };
  }

  ngOnInit(): void {
    this.attackSurfaceTableOptions.pageData = [];
    this.getAttackSurfaceJobs();
  }

  attackSurfacecolFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') {
        this.colFilterCols.push(event);
      }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      let qval: any;
      const char = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"g, '\\$1'])/;
      const searchValue =
        typeof obj.value === 'string' ? obj.value.trim() : obj.value;
      if (obj.col === 'job_data.job_status') {
        qval =
          char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
            ? `\"${searchValue}\"`
            : '' + searchValue + '';
      } else {
        qval =
          char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
            ? `\"${searchValue}\"`
            : '*' + searchValue + '*';
      }
      const tmpObj = {
        bool: {
          should: [{ query_string: { fields: [obj.col], query: qval } }],
        },
      };
      this.colFilterQuery.push(tmpObj);
    });
    this.getAttackSurfaceJobs();
  }

  attackSurfaceshowHideLoading(status: any): void {
    const data = Object.assign({}, this.attackSurfaceTableOptions);
    this.attackSurfaceTableOptions = {};
    this.attackSurfaceTableOptions = data;
    this.attackSurfaceTableOptions.tableOptions.loading = status;
  }

  attackSurfacelinkCall(idata: any): void {
    if (idata.col === 'u') {
      this.currentJob1 = undefined;
      setTimeout(() => { this.currentJob1 = idata.row;  this.showDetails(); });
    }
  }

  attackSurfacesortCall(idata: any): void {
    this.attackSurfaceTableOptions.sortOptions = idata;
    this.getAttackSurfaceJobs();
  }

  attackSurfaceactionCall(idata: any): void {
    if (idata.action.text === 'Details') 
    {
      this.currentJob1 = undefined;
      setTimeout(() => { this.currentJob1 = idata.row; this.showDetails(); });
    }
    else if (idata.action.text === 'Delete') {
      this.deleteattackSurfaceConfirmationDialog(idata);
    } else if (idata.action.text === 'Terminate') {
      if (idata.row.job_data.status_message !== 'Completed' && idata.row.job_data.status_message !== 'Failed') {
        this.terminateattackSurface(idata);
        console.log('idata', idata);
      } else {
        this.toast.sToast('error', 'This job cannot be terminated');
      }     
    }
  }

  showDetails(): void {
    this.loaderService.display(true, 'Getting job details...');
    this.baseService.doRequest(`api/jobs/${this.currentJob1._id}`, 'get').subscribe((result: any) => {
      this.loaderService.display(false);
      this.currentJob1 = undefined;
      setTimeout(() => {
        this.currentJob1 = result;
        if (this.currentJob1 && this.currentJob1.job_data) {
          this.cs.jobsUpdateEVE.next(this.currentJob1);
          console.log('this.currentJob1',this.currentJob1)
          console.log('this.currentJob1-message',this.currentJob1.job_data.status_message)
          this.inte1.open();
        } else {
          this.toast.sToast('error', 'Sorry details not available.');
        }
      });
    });
  }

  attackSurfacefilterCall(idata: any): void {
    const fields: any[] = ['product', 'all_status', 'integrationName'];
    /*this.patcTableOptions.columns.forEach((obj: any) => {
      if (obj.columnDef !== 'c' || obj.columnDef !== 'patching_status') {
        fields.push(obj.columnDef);
      }
    });*/
    this.attackSurfacefilterQuery =
      idata && idata.length > 0
        ? {
            multi_match: {
              query: idata,
              type: 'phrase_prefix',
              fields,
            },
          }
        : undefined;
    this.getAttackSurfaceJobs();
  }

  attackSurfacepageCall(event: any): void {
    this.attackSurfaceTableOptions.tableOptions.pageSize = event.pageSize;
    this.attackSurfacecurrentPage = event.pageIndex;
    this.getAttackSurfaceJobs();
  }

  attackSurfacetimerCallData(idata: any): void {
    this.getAttackSurfaceJobs();
  }
  getAttackSurfaceJobs(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.attackSurfaceshowHideLoading(true);
      const query: any = {
        query: {
          bool: {
            must: [{match: {'companyRef.id.keyword': `${this.currentCompany._id}`}},
            { match: { 'job_data.task.keyword': `Attack Surface Mapper` } },
             {exists: {field: 'job_data'}},
            ]
          }
        }
      };
      this.attackSurfaceTableOptions.serverSide = {
        service: 'jobsService', fn: 'getAllApiJobsGet', q: query
      };
      if (this.attackSurfacefilterQuery && this.attackSurfacefilterQuery.multi_match) {
        query.query.bool.must.push(this.attackSurfacefilterQuery);
      }
      if (this.attackSurfacefilterQuery && this.attackSurfacefilterQuery.filter) {
        query.query.bool.filter = this.attackSurfacefilterQuery.filter;
      }

      if (this.colFilterQuery && this.colFilterQuery.length) {
        query.query.bool.filter = [];
        this.colFilterQuery.forEach((obj: any) => {
          if (obj.bool.should[0].match) {
            query.query.bool.must.push(obj);
          } else {
            query.query.bool.filter.push(obj);
          }
        });
      }


      const q = JSON.stringify(query);
      const skip = this.attackSurfacecurrentPage;
      const limit = this.attackSurfaceTableOptions.tableOptions.pageSize;
      let sort: any = [{}];
      if (this.attackSurfaceTableOptions.sortOptions && this.attackSurfaceTableOptions.sortOptions.direction
        && this.attackSurfaceTableOptions.sortOptions.direction !== '') {
        const orderArr = ['job_data.task', 'job_data.message'];
        if (orderArr.indexOf(this.attackSurfaceTableOptions.sortOptions.active) > -1) {
          sort[0][this.attackSurfaceTableOptions.sortOptions.active + '.keyword'] = {order: this.attackSurfaceTableOptions.sortOptions.direction};
        } else {
          sort[0][this.attackSurfaceTableOptions.sortOptions.active] = {order: this.attackSurfaceTableOptions.sortOptions.direction};
        }
      }
      sort = JSON.stringify(sort);
      const fields = JSON.stringify(['c', 'u', 'job_data.job_status', '_id', 'job_data.attack_surface_message',
        'job_data.task', 'timeConsumed', 'job_data.message','sch_name']);
      // @ts-ignore
      this.jobsService.getAllApiJobsGet({q, skip, limit, sort, fields}).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data.length) {
          result.data.forEach((item: any) => {
            if (!item?.sch_name || item.sch_name === null) { item.sch_name = ('-'); }
          });
          this.attackSurfaceTableOptions.pageData = result.data;
          this.attackSurfaceTableOptions.tableOptions.pageTotal = result.total;
          this.attackSurfaceshowHideLoading(false);
        } else {
          this.attackSurfaceTableOptions.pageData = [];
          this.attackSurfaceTableOptions.tableOptions.pageTotal = 0;
          this.attackSurfaceshowHideLoading(false);
        }
      });
    } else {
      setTimeout(() => {
        this.getAttackSurfaceJobs();
      }, 1000);
    }
  }

  close(status?: any): void {
    this.inte1.close();
  }

  deleteattackSurfaceConfirmationDialog(data: any): void {
    console.log('data',data)
    const titleName = 'Delete message';
    const message = `Are you sure you want to delete this ${data?.row?._id} scheduled job?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/attacksurfaceresults/${data?.row?._id}`, 'delete', {
            id: data?.row?._id,
          })
          .subscribe((result: any) => {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getAttackSurfaceJobs();
            }, 1000);
          });
      }
    });
  }
  terminateattackSurface(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to terminate this scheduled job ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true, 'Terminating scheduled job....');
        const terminateData = { job_id: idata.row._id };
        console.log('terminateData',terminateData)
        this.baseService
          .doRequest(`api/attacksurfacedomain/terminate_asm`, 'post', terminateData) 
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getAttackSurfaceJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }


  attackSurfaceglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Terminate') {
      this.terminateattackSurfaces(idata);
    } else {
      this.deleteattackSurfaceConfirmationDialogForAll(idata);
    }
  }

  deleteattackSurfaceConfirmationDialogForAll(data: any): void {
    let cnt = 0;
    // let supportMsg = '';
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete selected scheduled job?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(false);
        this.allAttackSurfaceList = data.row;
        data.row.forEach((element: any) => {
          this.baseService
            .doRequest(`/api/attacksurfaceresults/${element._id}`, 'delete')
            .subscribe((result: any) => {
              cnt++;
              if (cnt === this.allAttackSurfaceList.length) {
                this.toast.sToast(
                  'success',
                  'Scheduled job removed successfully'
                );
                this.getAttackSurfaceJobs();
                this.loaderService.display(false);
              }
            });
        });
      }
    });
  }
  terminateattackSurfaces(idata: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to terminate the selected job entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        idata.row.forEach((obj: any, index: number) => {
          if (
            obj.isActive &&
            obj.scheduledStatus &&
            obj.scheduledStatus === 1
          ) {
            this.loaderService.display(
              true,
              `Terminating ${obj.product} job....`
            );
            const terminateData = { scheduledId: obj._id };
            this.baseService
              .doRequest(
                `api/patchscheduler/patchterminate`,
                'post',
                terminateData
              )
              .subscribe((result: any) => {
                this.loaderService.display(false);
                if (index === idata.row.length - 1) {
                  this.loaderService.display(false);
                  this.toast.sToast('success', result[1]);
                  setTimeout(() => {
                    this.getAttackSurfaceJobs();
                  }, 2000);
                }
              });
          } else {
            this.loaderService.display(false);
            this.toast.sToast(
              'error',
              `${obj.product} job cannot be terminated`
            );
            if (index === idata.row.length - 1) {
              setTimeout(() => {
                this.getAttackSurfaceJobs();
              }, 2000);
            }
          }
        });
      }
    });
  }
}
