import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IntegrationsService } from '../../../api/services/integrations.service';
import { MyToastrService } from '../../../_services/toastr.service';
import { ConfirmDialogService } from '../../../_services/confirmdialog.service';
import { LoaderService } from '../../../_services/loader.service';
import { IntegrationMappingService } from '../../../api/services/integration-mapping.service';
import { CompanyService } from '../../../api/services/company.service';
import { BaseRequestService } from '../../../_services/base.service';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthenticationService } from '../../../_services/authentication.service';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NotificationRulesService } from 'src/app/api/services';
import { IntegrationActionsService } from 'src/app/_services/integration-actions.service';
import { MatRadioGroup } from '@angular/material/radio';
@Component({
  selector: 'app-default-templates',
  templateUrl: './default-templates.component.html',
  styleUrls: ['./default-templates.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})

export class DefaultTemplatesComponent implements OnInit {
  @Input() currentIntegration: any;
  @Input() integrationsData: any;
  @Input() onboarding: any;
  @Input() cView: any = 'EventSet';
  @ViewChild('stepper') stepper: MatStepper; alertList: any;
  @ViewChild('alertSelect', { static: true }) alertSelect!: MatSelect;
  @ViewChild(MatRadioGroup) radioGroup: MatRadioGroup;
  public alertCtrl: FormControl = new FormControl();
  public alertFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;

  @ViewChild('inteRuleSelect', {static: true}) inteRuleSelect!: MatSelect;
  public inteRuleCtrl: FormControl = new FormControl();
  public credRuleCtrl: FormControl = new FormControl();
  public inteRuleFilterCtrl: FormControl = new FormControl();

  protected onDestroySearch = new Subject<void>();
  notificationRule: any;
  defaultTemplatesData: any = [];
  currentIntegrationRule: any;
  inputActionData: any = {};
  panelOpenState = false;
  Objectkeys = Object.keys;
  defaultTemplates: any = [];
  isLoading = false;
  loading = true;
  companies: any = [];
  disabledSubmit = false;
  disableEveMapSave = false;
  allComp: any;
  integrationTableOptions: any = {};
  alertTableOptions: any = {};
  actionData: any;
  integrationRulecurrentPage = 0;
  alertRulecurrentPage = 0;
  eventData:any = {};
  currentEventSetId : any ;
  currentCredId : any;
  eveMapping: any[] = [];
  eveMappingArray: any[] = []; 
  alertRule:any = [];
  defaultActionData: any = {};
  defaultActionIntegration: any;
  selectedAlertRules : any;
  integHash: any = {};
  credHash: any = {};
  eveHash : any = {};
  intergrationRuleList: any = [];
  filteredIntegRuleList: any = [];
  isDefaultAlert: any = [];
  isDefaultIntegration: any = [];
  credData: any = {};
  iconList:any = {
    agent : { icon: './assets/images/notification/agent.png', description: 'Choose the agent alerts that you would like to get notified on.' },
    company : { icon: './assets/images/notification/company.png', description: 'Choose the company alerts that you would like to get notified on.' },
    azureerror: { icon: './assets/images/notification/azure.png', description: 'Choose the azure alerts that you would like to get notified on.' },
    asset: { icon: './assets/images/notification/assets.png', description: 'Choose the asset alerts that you would like to get notified on.' },
    ports: { icon: './assets/images/notification/ports.png', description: 'Choose the port alerts that you would like to get notified on.' },
    remediation: { icon: './assets/images/notification/remediation.png', description: 'Choose the remediation alerts that you would like to get notified on.' },
    "ADAUDIT": { icon: './assets/images/notification/audit.png', description: 'Choose the adaduit alerts that you would like to get notified on.' },
    applicationbaseline: { icon: './assets/images/notification/applicationbaseline.png', description: 'Choose the application baseline alerts that you would like to get notified on.' },
    vulnerability: { icon: './assets/images/notification/vulnerability.png', description: 'Choose the vulnerability alerts that you would like to get notified on.' },
    "Unquoted service path": { icon: './assets/images/asset-details/without-path.png', description: 'choose the Unquoted Service path alerts that you would like to get notified on.' },
    "AD AUDIT USER": { icon: './assets/images/notification/adlog.png', description: 'choose the AD audit user alerts that you would like to get notified per user.' },
    "Azure AD Audit": { icon: './assets/images/providers/provider-azuread.png', description: 'choose the Azure AD Audit alerts that you would like to get notified on.' },
    "Reports": { icon: './assets/images/notification/reports.png', description: 'Choose the reporting alert that you would like to receive notifications for failed jobs.' },
    "Patch": { icon: './assets/images/notification/patching.png', description: 'Choose the patching alert that you would like to receive notifications for failed jobs.' },
  }
  constructor(private integrationsService: IntegrationsService, private toast: MyToastrService,
    private companyService: CompanyService, private baseService: BaseRequestService,
    private aS: AuthenticationService, private notificationRulesService: NotificationRulesService,
    private confirmDialog: ConfirmDialogService, public integrationActionService: IntegrationActionsService,
    public loaderService: LoaderService) {
      integrationActionService.integrationValidationEVE.subscribe((res: any) => {
        this.disabledSubmit = res;
      });
    this.integrationTableOptions = {
      columns: [{
        header: 'Name',
        columnDef: 'name',
        filter: '',
        cell: '(element: any) => `${element.name}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      },
      {
        header: 'Is Default',
        columnDef: 'is_default',
        filter: '',
        cell: '(element: any) => `${element.is_default}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }
    ],
      sortOptions: { active: 'name', direction: 'asc' },
      faClass: 'Company',
      _pageData: [],
      tableOptions: {
        title: 'Integration Rules',
        id: 'notiTableOptions',
        isServerSide: true,
        selectText: 'integrationMapping',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            icon: 'edit',
            callback: 'editFn',
            isGlobal: false
          },
          { text: 'Set as default', icon: 'info', callback: 'deleteFn', isGlobal: false },
          { text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false }],
        pagination: true,
        pageOptions: [5, 10, 20, 25, 100],
        pageSize: 20,
        showhideList: true,
        refreshData: true,
        add: (this.aS.hasPermission('integrationrulessettings', 'create')),
        search: false,
        hideDownload: true,
        exportExcel: false,
        saveData: false
      }
    };
    this.alertTableOptions = {
      columns: [
        {
        header: 'Name',
        columnDef: 'name',
        filter: '',
        cell: '(element: any) => `${element.name}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      },
      {
        header: 'Is Default',
        columnDef: 'is_default',
        filter: '',
        cell: '(element: any) => `${element.is_default}`',
        order: 0,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        imgPath: '',
        isSort: true,
        iscolumnSearch: false
      }
    ],
      sortOptions: { active: 'name', direction: 'asc' },
      faClass: 'Company',
      _pageData: [],
      tableOptions: {
        title: 'Alert Rules',
        id: 'alertTableOptions',
        isServerSide: true,
        selectText: 'alertTableOptions',
        loading: true,
        floatingFilter: true,
        rowSelection: false,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Edit',
            icon: 'edit',
            callback: 'editFn',
            isGlobal: false
          },
          { text: 'Set as default', icon: 'info', callback: 'deleteFn', isGlobal: false },
          { text: 'Map Event Set', icon: 'settings', callback: 'deleteFn', isGlobal: false },
          { text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: false }],
        pagination: true,
        pageOptions: [5, 10, 20, 25, 100],
        pageSize: 20,
        showhideList: true,
        refreshData: true,
        add: (this.aS.hasPermission('alertrulessettings', 'create')),
        search: false,
        hideDownload: true,
        exportExcel: false,
        saveData: false
      }
    };
  }

  ngOnInit(): void {
    this.getIntegrationRules();
    this.getAlertRules();
    this.getAlertNotification();
    this.getIntegCredData();
  }
  sortCall(event: any): void {
    this.integrationTableOptions.sortOptions = event;
    this.getIntegrationRules();
  }

  pageCall(event: any): void {
    this.integrationTableOptions.tableOptions.pageSize = event.pageSize;
    this.integrationRulecurrentPage = event.pageIndex;
    this.getIntegrationRules();
  }
  actionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.editIntegrationRule(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.deleteIntegrationRule(dataRow);
    }
    if (idata.action.text === 'Set as default') {
      const dataRow = idata.row;
      this.defaultIntegrationRule(dataRow);
    }
  }

  selectDeselectAll(status: boolean): void {
    this.alertRule.forEach((obj: any) => {
      obj.alerts.forEach((al: any) => {
        al.events.forEach((ev: any) => {
          ev.selected = status;
        });
      });
    });
  }
  showHideLoading(status: any): void {
    const data = Object.assign({}, this.integrationTableOptions);
    this.integrationTableOptions = {};
    this.integrationTableOptions = data;
    this.integrationTableOptions.tableOptions.loading = status;
  }

  alertsortCall(event: any): void {
    this.alertTableOptions.sortOptions = event;
    this.getIntegrationRules();
  }

  alertpageCall(event: any): void {
    this.alertTableOptions.tableOptions.pageSize = event.pageSize;
    this.alertRulecurrentPage = event.pageIndex;
    this.getIntegrationRules();
  }
  alertactionCall(idata: any): void {
    this.loading = false;
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.alertRule = Object.assign([], this.alertList);
      this.editAlertRule(dataRow);
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.deleteAlertRule(dataRow);
    }
    if (idata.action.text === 'Set as default') {
      const dataRow = idata.row;
      this.defaultAlertRule(dataRow);
    }
    if(idata.action.text === 'Map Event Set'){
      const dataRow = idata.row;
      this.mapEachEvent(dataRow);
    }
  }

  alertshowHideLoading(status: any): void {
    const data = Object.assign({}, this.alertTableOptions);
    this.alertTableOptions = {};
    this.alertTableOptions = data;
    this.alertTableOptions.tableOptions.loading = status;
  }
  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }

  getIntegrationRules(): void {
    this.loaderService.display(true, 'Getting integration rules...');
    let sort: any = [{}];
    const query = {
      query: {
        bool: {
          must: [ {match: {'integrationName.keyword': this.currentIntegration.name + ''}},
            {exists: {field: 'name'}}, {exists: {field: 'default'}}, {exists: {field: 'integrationId'}}]
        }
      }
    };
    if (this.integrationTableOptions.sortOptions) {
      const orderArr = [''];
      if (orderArr.indexOf(this.integrationTableOptions.sortOptions.active) === -1) {
        sort[0][this.integrationTableOptions.sortOptions.active + '.keyword'] = { order: this.integrationTableOptions.sortOptions.direction };
      } else {
        sort[0][this.integrationTableOptions.sortOptions.active] = { order: this.integrationTableOptions.sortOptions.direction };
      }
    }
    const q = JSON.stringify(query);
    const skip = this.integrationRulecurrentPage;
    const limit = this.integrationTableOptions.tableOptions.pageSize;
    this.baseService.doRequest(`/api/integrationrulessettings/`, 'get', null,  {q, skip, limit, sort}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result.data && result.data.length) {
        this.showHideLoading(false);
        result.data.map((list:any) => {
          list.is_default = (list.default) ? 'Yes' : 'NO';
        })
        this.integrationTableOptions.pageData = result.data;
        this.integrationTableOptions.tableOptions.pageTotal = result.total;
        // New
          result.data?.map(
            (obj: any) =>
            (this.integrationActionService.integrationRuleListHash[obj._id] =
              obj)
          );
          const isDefault = result.data?.filter((d: any) => d.default);
          this.isDefaultIntegration = result.data?.filter(
            (d: any) => d.default
          );
          this.intergrationRuleList = result.data;
          this.integHash[0] = isDefault && isDefault.length ? isDefault[0]._id : [];
      } else {
        this.showHideLoading(false);
        this.integrationTableOptions.pageData = [];
      }
    });
  }

  getAlertRules(): void {
    this.loading = false;
    this.loaderService.display(true, 'Getting alert rules...');
    let sort: any = [{}];
    if (this.alertTableOptions.sortOptions) {
      const orderArr = [''];
      if (orderArr.indexOf(this.alertTableOptions.sortOptions.active) === -1) {
        sort[0][this.alertTableOptions.sortOptions.active + '.keyword'] = { order: this.alertTableOptions.sortOptions.direction };
      } else {
        sort[0][this.alertTableOptions.sortOptions.active] = { order: this.alertTableOptions.sortOptions.direction };
      }
    }
    const query = {
      query: {
        bool: {
          must: [{exists: {field: 'name'}}, {exists: {field: 'default'}}, {exists: {field: 'alertRules'}}]
        }
      }
    };
    const q = JSON.stringify(query);
    const skip = this.alertRulecurrentPage;
    const limit = this.alertTableOptions.tableOptions.pageSize;
    this.baseService.doRequest(`/api/alertrulessettings/`, 'get', null,  {q, skip, limit, sort}).subscribe((result: any) => {
      this.loaderService.display(false);
      this.alertshowHideLoading(false);
      if (result.data && result.data.length) {
        result.data.map((list:any) => {
          list.is_default = (list.default) ? 'Yes' : 'NO';
        })
        this.alertTableOptions.pageData = result.data;
        this.alertTableOptions.tableOptions.pageTotal = result.total;
      } else {
        this.alertTableOptions.pageData = [];
      }
    });
    // this.getSelectedEvents();
  }

  getActionParams(): void {
    this.loaderService.display(
      true,
      `Getting ${this.defaultActionIntegration.name}`
    );
    this.baseService
      .doRequest(
        `/api/integrations/action_params?integrationName=${this.defaultActionIntegration.name}&actionName=${this.defaultActionIntegration.notificationfunction}`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result) {
          result.parameters = result.parameters.filter(
            (x: any) => x.notification && x.requiredforglobal !== false
          );
          this.actionData = result;
          this.defaultActionIntegration.actionResult = result;
          this.processFields(this.defaultActionIntegration.actionResult);
        } else {
          this.actionData = {};
          this.defaultActionIntegration.actionResult = {};
        }
      });
  }
  processFieldsParams(result: any): void {
    this.defaultActionIntegration.actionData = this.defaultActionIntegration
      .actionData
      ? this.defaultActionIntegration.actionData
      : [];
    if (
      this.defaultActionIntegration.actionData.length === 0 &&
      this.defaultActionData.params
    ) {
      this.defaultActionIntegration.actionData = this.defaultActionData.params;
    }
    const tmpAP: {
      label: any;
      key: any;
      required: any;
      example?: any;
      type: any;
    }[] = [];
    result.parameters.forEach(async (obj: any) => {
      const tmpFormField = this.defaultActionIntegration.formElements
        ? this.defaultActionIntegration.formElements.filter(
            (x: any) => x.key === obj.name
          )
        : [];
      const tFF: any = {
        label: obj.description,
        key: obj.name,
        required: obj.required,
        example: obj.example,
        type: obj.schema.type,
        allcompany: obj.allcompany ? obj.allcompany : false,
      };
      tFF.callback = obj.callback;
      if (obj.source && !obj.depends) {
        if (
          tmpFormField &&
          tmpFormField.length &&
          tmpFormField[0].options &&
          tmpFormField[0].options.length
        ) {
          tFF.options = tmpFormField[0].options;
          tFF.searchElement = tmpFormField[0]?.searchElement;
        } else {
          this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
            const requestParams: any = {};
            obj.subParams = result;
            if (subObj.parameters) {
              subObj.parameters.forEach((pObj: any) => {
                requestParams[pObj.name] = pObj.example;
              });
            }
            tFF.searchElement = subObj;
            this.loaderService.display(true, `Executing ${subObj.name}`);
            this.executeAction(subObj, requestParams).then((subOut: any) => {
              if (subOut.status === 'failed') {
                this.toast.sToast('error', subOut.data);
              } else {
                subObj.integrationName = this.defaultActionIntegration.name;
                subObj.name = subObj.name;
                subObj.mappedValue = obj.source.mappedValue;
                subObj.credentialId =  this.defaultActionIntegration.credentialId;
                this.processParams(subOut.data).then((edata: any) => {
                  this.loaderService.display(false);
                  obj.subOut = edata;
                  tFF.options = [];
                  edata.forEach((val: any) => {
                    tFF.options.push({
                      key: val[subObj.DisplayFields[0]],
                      value: val[obj.source.mappedValue] + '',
                    });
                  });
                });
              }
            });
          });
        }
      } else if (
        obj.source &&
        obj.depends &&
        this.defaultActionIntegration.actionData[obj.depends[0]]
      ) {
        this.getSubActionParams(obj.source.name).then(async (subObj: any) => {
          const requestParams: any = {};
          obj.subParams = result;
          const sources: any =
            this.defaultActionIntegration.actionParams.filter(
              (x: any) => x.source && x.source.mappedValue
            );
          if (subObj.parameters) {
            subObj.parameters.forEach((pObj: any) => {
              requestParams[pObj.name] = pObj.example;
              const mValue = sources.filter(
                (x: any) => x.source.mappedValue === pObj.name
              );
              if (mValue.length) {
                requestParams[pObj.name] =
                  this.defaultActionIntegration.actionData[mValue[0].name];
              }
            });
          }
          tFF.searchElement = subObj;

          // requestParams[obj.source.parameter] = this.defaultActionIntegration.actionData[obj.depends[0]];
          this.loaderService.display(true, `Executing ${subObj.name}`);
          this.executeAction(subObj, requestParams).then((subOut: any) => {
            if (subOut.status === 'failed') {
              this.toast.sToast('error', subOut.data);
            } else {
              subObj.integrationName = this.defaultActionIntegration.name;
              subObj.name = subObj.name;
              subObj.mappedValue = obj.source.mappedValue;
              subObj.credentialId =  this.defaultActionIntegration.credentialId;
              this.processParams(subOut.data).then((edata: any) => {
                this.loaderService.display(false);
                obj.subOut = edata;
                tFF.options = [];
                edata.forEach((val: any) => {
                  tFF.options.push({
                    key: val[subObj.DisplayFields[0]],
                    value: val[obj.source.mappedValue] + '',
                  });
                });
              });
            }
          });
        });
      }
      tmpAP.push(tFF);
      if (
        this.inputActionData[obj.name] &&
        !this.defaultActionIntegration.actionData[obj.name]
      ) {
        this.defaultActionIntegration.actionData[obj.name] =
          this.inputActionData[obj.name];
      } else if (
        !this.inputActionData[obj.name] &&
        this.defaultActionIntegration.actionData[obj.name]
      ) {
        this.defaultActionIntegration.actionData[obj.name] =
          this.defaultActionIntegration.actionData[obj.name];
      } else {
        this.defaultActionIntegration.actionData[obj.name] = this.inputActionData[obj.name];
        // if(obj.name === 'company'){ this.defaultActionIntegration.actionData[obj.name] = }
      }
    });
    this.defaultActionIntegration.formElements = tmpAP.slice();
    this.defaultActionIntegration.actionParams = result.parameters;
  }
  processFields(result: any): void {
    if (!result || !result.parameters) {
      return;
    }
    if (result.getparamsfunc) {
      const params: any = {
        name: this.defaultActionIntegration.name
          ? this.defaultActionIntegration.name
          : this.defaultActionIntegration.integrationName,
        credentialId: this.defaultActionIntegration.credentialId,
        getparamsfunc: result.getparamsfunc,
      };
      this.integrationActionService
        .executeActionParams(params)
        .then((item: any) => {
          if (item.status) {
            this.processFieldsParams(result);
          }
        });
    } else {
      this.processFieldsParams(result);
    }
  }

  actionChange($event: any): void {
    if ($event && $event !== '') {
      setTimeout(() => {
        this.processFields(this.defaultActionIntegration.actionResult);
      }, 2000);
    }
  }
  getSubActionParams(actionName: string): Promise<any> {
    return this.baseService.doRequest(
      // tslint:disable-next-line:max-line-length
      `/api/integrations/action_params?integrationName=${this.defaultActionIntegration.name}&actionName=${actionName}`, 'get').toPromise();
  }
  executeAction(param: any, requestparams: any): Promise<any> {
    const params: any = {
      integrationName: this.defaultActionIntegration.name,
      integrationId: this.defaultActionIntegration.credentialId,
      params: { action: { name: param.name, destination: param.destination, verb: param.verb }, params: { requestparams } }
    };
    return this.baseService.doRequest(`/api/integrations/executeAction`, 'post', params).toPromise();
  }
  async processParams(data: any): Promise<any> {
    const value: any = [];
    const drow = await data.row_values;
    if (!drow) { return value; }
    drow.forEach((obj: any) => {
      const obj2: any = {};
      data.column_definitions.forEach((obj1: any, index: number) => {
        const key = this.Objectkeys(obj1)[0];
        obj2[key] = obj[index];
      });
      value.push(obj2);
    });
    return value;
  }

  getAlertNotification(): void {
    this.loaderService.display(true, 'Getting notification rules');
    this.notificationRulesService.notificationRulesgetAlertingRulesApiNotificationrulesIdGetAlertingRulesPost(
      { id: 'dummy', body: {} }).subscribe((result: any) => {
        this.loaderService.display(false);
        result.forEach((obj: any) => {
            obj.alerts.forEach((alert: any) => {
              alert.eventGroups = [];
              const groups: any = [...new Set(alert.events.map((x: any) => x.group))];
              groups.forEach((grp: any) => {
                if (grp) {
                  alert.eventGroups.push({name: grp, events: []})
                }
              })
              alert.events.forEach((event: any) => {
                if (event.group) {
                  alert.eventGroups.forEach((grp: any) => {
                    if (grp.name === event.group) {
                      grp.events.push(event);
                    }
                  });
                }
              });
              alert.events = alert.events.filter((eve: any) => !eve.group);
            });
        });
        this.alertList = result;
        this.notificationRule = Object.assign([], result);
      });
  }

  editIntegrationRule(item: any): void {
    const currentIntegration = this.currentIntegration;
    this.defaultActionIntegration = Object.assign({}, currentIntegration);
    this.currentIntegrationRule = item;
    this.defaultActionIntegration.credentialId = item.integrationId;
    this.currentIntegrationRule.credentialId = item.integrationId;
      if (this.currentIntegrationRule.integrationName === (this.defaultActionIntegration.integrationName || this.defaultActionIntegration.name)) {
        this.defaultActionIntegration.actionData = this.currentIntegrationRule; delete this.defaultActionIntegration.actionData.integrationName; delete this.defaultActionIntegration.actionData.integrationId;
      }
    this.getActionParams();
    this.cView = 'addEditIntegrationSetting';
  }

  editAlertRule(item: any): void {
    this.eventData = item;
    let selectedEvents: any = [];
    const filter1 = item.alertRules.map((x: any) => x.rules).map((x: any) => x.map((y: any) => y.eventId));
    if (filter1.length) {
      const filter2 = [].concat.apply([], filter1.filter((x: any) => x));
      selectedEvents = [].concat.apply([], filter2.filter((x: any) => x));
    } else {
      selectedEvents = [].concat.apply([], filter1.filter((x: any) => x));
    }
    this.alertRule.forEach((sec: any) => {
      sec.alerts.forEach((rule: any) => {
        rule.events.forEach((eve: any) => {
          eve.selected = selectedEvents.includes(eve.id);
        });
        rule.eventGroups.forEach((r: any) => {
          const ids = r.events.map((x: any) => x.id);
          ids.forEach((id: any) => {
            if (selectedEvents.includes(id)) {
              r.value = id;
              r.events.map((x: any) => {
                if(x.isCheckbox && selectedEvents.includes(x.id)){
                  x.selected = true;
                }
              })
            }
          });
        });
      });
    });
    this.cView = 'addEditEventSet';
  }

  closeTemplate(): void {
    this.cView = 'allTemplates';
    this.currentIntegrationRule = undefined;
  }

  addIntegrationRule(): void {
    const currentIntegration = this.currentIntegration;
    this.defaultActionIntegration = Object.assign({}, currentIntegration);
    this.currentIntegrationRule = {};
    this.defaultActionIntegration.credentialId = this.integrationsData[0]._id;
    this.currentIntegrationRule.credentialId = this.integrationsData[0]._id;
    this.cView = 'addEditIntegrationSetting';
    this.getActionParams();
  }

  addAlertRule(): void {
    this.eventData = {};
    this.eventData.default = false;
    this.alertRule = Object.assign([], this.alertList);
    this.alertRule.forEach((obj: any) => {
      obj.alerts.forEach((al: any) => {
        al.events.forEach((ev: any) => {
          ev.selected = false;
        });
        al.eventGroups.forEach((eve: any, index: any) => {
          eve.value = '';
          eve.events.map((x: any) => {
            if(x.isCheckbox){
              x.selected = false;
            }
          })
        })
      });
    });
    this.cView = 'addEditEventSet';
  }

  saveAlertRules(): void {
    let selected = false;
    let companySelected = false; let assetSelected = false; let prodSelected = false; let prodAssetSelected = false;
    /*this.alertRule.forEach((sec: any) => {
      sec.alerts.forEach((rule: any) => {
        rule.events.forEach((eve: any) => {
          if (eve.selected) { selected = true; }
          if (!companySelected) {
            companySelected = (eve.name === 'Remediation By Company' && eve.selected);
          }
          if (!assetSelected) {
            assetSelected = (eve.name === 'Remediation By Asset' && eve.selected);
          }
          if (!prodSelected) {
            prodSelected = (eve.name === 'Remediation By Product' && eve.selected);
          }
          if (!prodAssetSelected) {
            prodAssetSelected = (eve.name === 'Remediation By Asset And Product' && eve.selected);
          }
        });
      });
    });
    if (!selected) {
      this.toast.sToast('error', `Please choose at-least one event`);
      return;
    }
    const remSelected = [companySelected, assetSelected, prodSelected, prodAssetSelected];
    const len = remSelected.filter((x: any) => !x).length;
    if (len < 3) {
      this.toast.sToast('error', `Please choose either 'Remediation By Company' or 'Remediation By Asset' or 'Remediation By Product' or 'Remediation By Asset And Product'`);
      return;
    }*/
    this.loaderService.display(true);
    let alertRule:any = {};
    alertRule.alertRules = [];
    this.alertRule.forEach((sec: any) => {
      let alertObj: any = {}; alertObj = { sectionId: sec.sectionId, rules: [] };
      sec.alerts.forEach((rule: any) => {
        let ruleObj: any = {}; ruleObj = { ruleId: rule.ruleId, eventId: [] };
        rule.events.forEach((eve: any) => {
          if (eve.selected) { 
            ruleObj.eventId.push(eve.id);
           }
        });
        rule.eventGroups.forEach((eve: any, index: any) => {
          const selectedCheck = eve.events.filter((x: any) => x.isCheckbox && x.selected).map((s: any) => s.id);
          selectedCheck.push(eve.value)
          if (eve.value) { 
            ruleObj.eventId = [ ...ruleObj.eventId, ...selectedCheck];
           }
        })
        alertObj.rules.push(ruleObj);
      });
      alertRule.alertRules.push(alertObj);
    });
    // this.selectedAlertRules = alertRule;
    alertRule.name = this.eventData.name;
    alertRule.default = this.eventData.default;
    (this.eventData._id) ? alertRule._id = this.eventData._id : null;
    const msg = (this.eventData._id) ? 'Updated' : 'Saved';
    const method = (this.eventData._id) ? 'put' : 'post';
    this.baseService.doRequest(`/api/alertrulessettings/`, method, alertRule).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result !== null) {
        this.toast.sToast('success', `${msg} successfully`);
        this.cView = 'EventSet';
        setTimeout(() => {this.loading = false; this.getAlertRules(); }, 3000);
      } else {
        this.toast.sToast('error', `Not ${msg}.`);
      }
    });
  }
  saveIntegrationRules(): void {
    this.loaderService.display(true);
    let integrationRule:any = Object.assign({}, this.currentIntegrationRule);
    const integrationObj = Object.assign({}, this.defaultActionIntegration.actionData);
    integrationObj.integrationName = (this.defaultActionIntegration.integrationName) ? this.defaultActionIntegration.integrationName : this.defaultActionIntegration.name;
    integrationObj.integrationId = (this.defaultActionIntegration.credentialId) ? this.defaultActionIntegration.credentialId : '';
    integrationRule = {...integrationRule, ...integrationObj};
    const method = (integrationRule._id) ? 'put' : 'post';
    const msg = (integrationRule._id) ? 'Updated' : 'Saved';
    this.baseService.doRequest(`/api/integrationrulessettings/`, method, integrationRule).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result !== null) {
        this.defaultActionIntegration.actionData = Object.assign({}, this.actionData);
        this.toast.sToast('success', `${msg} successfully`);
        this.cView = 'IntegrationSetting';
        setTimeout(() => { this.getIntegrationRules(); }, 3000);
      } else {
        this.toast.sToast('error', `Not ${msg}.`);
      }
    });
  }
  deleteIntegrationRule(notificationRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this integration rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/integrationrulessettings/${notificationRules._id}`, 'delete').subscribe((result: any) => {
          if (result.status) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => { this.getIntegrationRules(); }, 3000);
          } else {
            this.toast.sToast('error', result.msg);
          }
        });
      }
    });
  }

  deleteAlertRule(alertRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this alert rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/alertrulessettings/${alertRules._id}`, 'delete').subscribe((result: any) => {
          if (result.status) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => { this.getAlertRules(); }, 3000);
          } else {
            this.toast.sToast('error', result.msg);
          }
        });
      }
    });
  }

  defaultIntegrationRule(notificationRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to default this integration rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/integrationrulessettings/setdefaultsetting`, 'post',{
          integrationName: notificationRules.integrationName,
          integrationSettingId: notificationRules._id
        }
        ).subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Added successfully');
            setTimeout(() => { this.getIntegrationRules(); }, 3000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }

  defaultAlertRule(alertRules: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to default this alert rule ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/alertrulessettings/setdefaultsetting`, 'post', {
          eventSettingId: alertRules._id
        }).subscribe((result: any) => {
          if (result) {
            this.toast.sToast('success', 'Added successfully');
            setTimeout(() => { this.loading = false; this.getAlertRules(); }, 3000);
          } else {
            this.toast.sToast('error', result);
          }
        });
      }
    });
  }

  setCred(cred: any): void {
    this.defaultActionIntegration = undefined;
    this.defaultActionIntegration = Object.assign({}, this.currentIntegration);
    this.defaultActionIntegration.credentialId = cred;
    this.getDefaultActionSettings();
  }

  getDefaultActionSettings(): void {
    this.loaderService.display(true, 'Getting default action settings...');
    this.baseService
      .doRequest(
        `/api/integrations/global_action_params?integrationName=${this.defaultActionIntegration.name}&credentialid=${this.defaultActionIntegration.credentialId}&actionName=${this.defaultActionIntegration.notificationfunction}`,
        'get'
      )
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.defaultActionIntegration.actionData = [];
        this.defaultActionData = {};
        if (result[0]) {
          this.defaultActionData = result[1];
          this.defaultActionIntegration.actionData = result[1].params;
        }
        setTimeout(() => {
          this.getActionParams();
        }, 500);
      });
  }

  mapEachEvent(item:any): void{
    this.eventData = item;
    this.currentEventSetId = item._id;
    this.disableEveMapSave = false;
    this.integHash= {};
    this.credHash= {};
    this.eveHash= {};
    this.getSelectedEvents(this.currentEventSetId);
    this.getMappedEventSet(this.currentEventSetId);
    this.getIntegrationRules();
    this.cView = "addEditEventSetStep2";
  }

  getSelectedEvents(eventid: any):void{
    const params = {
      id: eventid,
    };
    this.baseService.doRequest(`/api/notificationrules/iamaws/getSelectedEvents`, 'post', params).subscribe((result: any) => {
      this.selectedAlertRules = result;
    });
  }

  
  addEveSetInteg(index: number): void {
    if (!this.eveHash[index] || !this.integHash[index]) {
      this.toast.sToast('error', 'Please select an event and integration profile');
      return;
    }
  
    // Check if any value is empty
    if (
      !this.eveHash[index] ||
      !this.integHash[index] ||
      !this.selectedAlertRules.find((alert: any) => alert.id === this.eveHash[index]) ||
      !this.credData.find((cred: any) => cred._id === this.currentCredId) ||
      !this.intergrationRuleList.find((integration: any) => integration._id === this.integHash[index])
    ) {
      this.toast.sToast('error', 'Please ensure all values are selected and not empty');
      return;
    }
  
    const newMapping = {
      eventSettingsId: this.eveHash[index],
      integrationSettingsId: this.integHash[index],
      eventName: this.selectedAlertRules.find((alert: any) => alert.id === this.eveHash[index])?.name,
      credName: this.credData.find((cred: any) => cred._id === this.currentCredId)?.name,
      integrationSetName: this.intergrationRuleList.find((integration: any) => integration._id === this.integHash[index])?.name,
    };
  
    // Ensure that the currentEventSetId exists in the eveMapping object
    this.eveMapping[this.currentEventSetId] = this.eveMapping[this.currentEventSetId] || [];
  
    // Check if the eventSettingsId already exists in the current eventSetId
    const eventExists = this.eveMapping[this.currentEventSetId].some((mapping: any) =>
      mapping.eventSettingsId === newMapping.eventSettingsId
    );
    if (!eventExists) {
      // Check if the mapping already exists in the current eventSetId
      const existingMapping = this.eveMapping[this.currentEventSetId].find((mapping: any) =>
        mapping.eventSettingsId === newMapping.eventSettingsId &&
        mapping.integrationSettingsId === newMapping.integrationSettingsId
      );
  
      if (!existingMapping) {
        // Add the new mapping to the current eventSetId
        this.eveMapping[this.currentEventSetId].push(newMapping);
        this.disableEveMapSave = true;
      } else {
        this.toast.sToast('error', 'The provided configuration already exists.');
      }
    } else {
      this.toast.sToast('error', 'The provided event already mapped.');
    }
  }
  
  

  removeEveMapping(index: number): void {
    if (this.currentEventSetId && this.eveMapping[this.currentEventSetId] && index >= 0 && index < this.eveMapping[this.currentEventSetId].length) {
      this.eveMapping[this.currentEventSetId].splice(index, 1);
      this.disableEveMapSave = true;
    }
  }

  // Save Each Event mapping
  saveEventMapping(): void {
    if (this.currentEventSetId && this.eveMapping[this.currentEventSetId]) {
      const selectedValues = this.eveMapping[this.currentEventSetId].map((mapping: any) => ({
        event_id: mapping.eventSettingsId,
        integration_id: mapping.integrationSettingsId,
      }));
      const params = { 
        event_set_id : this.currentEventSetId,
        credential_id: this.currentCredId,
        eventmapping: selectedValues ,
        integrationName: this.currentIntegration.name
        }; 
      this.baseService.doRequest(`/api/notificationeventmap/`, 'post', params).subscribe((result: any) => {
        this.loaderService.display(false);
      if (result && result !== null) {
        this.toast.sToast('success', `Added successfully`);
      }
      });
    }
    this.cView = "EventSet";
  }

  
  getMappedEventSet(eventid: any): void {
    const query = {
      query: {
        bool: {
          must: [
            { match: { 'event_set_id.keyword': eventid } },
            { match: { 'integrationName.keyword': this.currentIntegration.name } },
            { exists: { field: 'event_set_id' } }
          ]
        }
      }
    };
    const q = JSON.stringify(query);
    const skip = 0;
    const limit = 30;
    this.baseService.doRequest(`/api/notificationeventmap/`, 'get', null, { q, skip, limit }).subscribe(
      (result: any) => {
        this.loaderService.display(false);
        if (result.data && result.data.length) {
          this.showHideLoading(false);
          this.eveMapping = [];
  
          result.data.forEach((mapping: any) => {
            const eventSetId = mapping.event_set_id;
            if (!this.eveMapping[eventSetId]) {
              this.eveMapping[eventSetId] = [];
            }
  
            mapping.eventmapping.forEach((eventMap: any) => {
              const eventExistsInList = this.selectedAlertRules.some((alert: any) => alert.id === eventMap.event_id);
              if (eventExistsInList) {
                const newMapping = {
                  eventSettingsId: eventMap.event_id,
                  integrationSettingsId: eventMap.integration_id,
                  eventName: this.selectedAlertRules.find((alert: any) => alert.id === eventMap.event_id)?.name,
                  credName: this.credData.find((cred: any) => cred._id === mapping.credential_id)?.name,
                  integrationSetName: this.intergrationRuleList.find((integration: any) => integration._id === eventMap.integration_id)?.name,
                };
                this.eveMapping[eventSetId].push(newMapping);
              }
            });
          });
        } else {
          this.showHideLoading(false);
        }
      },
      (error: any) => {
        console.error('Error fetching mapped events:', error);
      }
    );
  }
  
    

  getIntegCredData(): void {
    this.loaderService.display(true);
    const url = `/api/integrations/getcredentials?integrationName=${this.currentIntegration.name}`;
    this.baseService.doRequest(url, 'get').subscribe((result: any) => {
      this.loaderService.display(false);
      this.credData = {};
      if (result.data && result.data.length) {
        this.integrationsData = result.data;
        this.credData = result.data;
      }
    });
  }

  updateIntegrationProfiles(event:any) {
    this.currentCredId = event.value;
     this.filteredIntegRuleList = this.intergrationRuleList.filter((profile:any) => {
      return profile.integrationId === event.value;
    });
  }
}
