import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { LoaderService } from '../../_services/loader.service';
import { BaseRequestService } from '../../_services/base.service';
import { CommonService } from '../../_services/common.services';
import { AuthenticationService } from '../../_services/authentication.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { ModalService } from '../../_services/modal.service';
import { MyToastrService } from '../../_services/toastr.service';
import { CompanySharedService } from '../../_services/company-shared.service';
import { FormControl, NgForm } from '@angular/forms';
import { BehaviorSubject, interval, ReplaySubject, Subject } from 'rxjs';
import { CompanyService } from '../../api/services/company.service';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatMenuTrigger } from '@angular/material/menu';
import { TableSettingsService } from 'src/app/api/services/table-settings.service';
import { cache } from 'browserslist';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemingService } from 'src/app/_services/theming.service';
import { SearchPipe } from 'src/app/_filters/app.filter.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const INTERVAL = interval(1800);

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('companySelect', { static: true }) companySelect!: MatSelect;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('createMSPMenuTrigger', { static: false }) createMSPMenuTrigger: MatMenuTrigger;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  public domain = window.location.host;
  isLoading = false;
  showAlert = false;
  addlogsWidth = 1080;
  addSnmpv2Width = 800;
  protected onDestroySearch = new Subject<void>();
  filterUpdate = new Subject<string>();
  contentText: any;
  showLoader = new BehaviorSubject<boolean>(false);
  loading$ = this.showLoader.asObservable();
  result: any;
  menuHide = false;
  enableMfa = false;
  integrationUrl: any = '';
  Objectkeys = Object.keys;
  config: any = {};
  showSearch = false; searchGlobal = '';
  company: any; companies: any; companyHash: any = {}; allComp: any;
  changePasswordData: any = {
    password: null,
    confirmPassword: null,
    oldPassword: null
  };
  alert: any = {
    message: '',
  };
  currentEventLogs: any = {
    name: '',
    agentId: '',
    startDate: null,
    endDate: null,
  };
  fromMaxStartDate = new Date();
  fromMinEndDate: any = null;
  fromMaxEndDate: any = null;
  addAgentWidth = 600;
  agentLogData: any = { name: '', log: '' };
  ccve: any; ccveList: any; ccveHash: any = {};
  // sidenavVisible = false;
  cveid: any;
  logoHeight = 40;
  trial: any;
  logo = {
    fallback: '/assets/images/cybercns_logo.png',
    src: '/assets/images/cybercns_logo.png', srcOn: '/assets/images/cybercns_logo.png',
    srcOut: '/assets/images/cybercns_logo.png'
  };
  build: any;
  feeds: any;
  isOpen = true;
  totalCompanies: any;
  patchingDetails: any = {
    title: '',
    type: '',
    info: '',
    sections: [],
  };
  releaseNotes: any = {}; tableId: any; sessionData: any;
  selectedCompany: any;
  searchTxt: any = 'Search Company';
  searchid = 'CompSearch';
  showTimeAgo = false;
  forceSet = false;
  trialPeriodWidth = 700;
  mspDomain: any; mspEmail: any;
  restrictRedirect: any = ['global', 'toolkit'];
  toggleControl = new FormControl(false);
  updateStatus: any;
  expireDate: any = '';
  // gettingStarted = false;
  isHidden: boolean;
  toggleSetting: any;
  sideNavView = '';
  // @ts-ignore
  favIcon: HTMLLinkElement = document.querySelector('#_appImg');
  cid: any;
  agentDepURL: any;
  agentIds: any;
  reason: any;
  isDeprecated: any;
  isOnlionAgent: any;
  searchK = false;
  migrationInfo: any = {
    data: {}
  };
  allCompanyHash: any = {};
  constructor(public baseService: BaseRequestService, private router: Router, private loaderService: LoaderService,
    private confirmDialog: ConfirmDialogService, public modalService: ModalService, private searchPipe: SearchPipe,
    private toast: MyToastrService, public cs: CompanySharedService, private companyService: CompanyService, private httpClient: HttpClient,
    public commonService: CommonService, public authService: AuthenticationService, private overlay: OverlayContainer,
    private tableSettingsService: TableSettingsService, public themingService: ThemingService) {
    this.cid = localStorage.getItem('cid');
    if (!!(this.cid && true && this.cid !== '')) {
      try {
        this.logo.src = `/assets/images/tenant/logo/${atob(this.cid)}_logo.png`;
      } catch (e) {
        this.logo.src = `/assets/images/cybercns_logo.png`;
      }
    }

    this.router.events.subscribe((event: any) => {
      this.sidenav.close();
      this.sideNavView = '';
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('companies') > -1) {
          this.baseService.showAssessment = false;
          this.baseService.showCompany = true;
          if (this.baseService.isAssessment) {
            this.allComp = []; this.commonService.cHash = undefined;
            this.companies = undefined;
            this.companyCtrl.setValue('');
            this.filteredCompanies.next([]); this.getCompanies();
            this.baseService.isAssessment = false;
          } else if (!this.allComp || !this.allComp.length) {
            this.getCompanies();
          }
          if (event.url.indexOf('companies/onboarding') > -1) {
            this.baseService.showCompany = false;
          } else {
            this.baseService.showCompany = true;
          }
        } else if (event.url.indexOf('assessments') > -1) {
          this.baseService.showAssessment = true;
          this.baseService.showCompany = false;
          this.baseService.isAssessment = true;
          this.allComp = []; this.ccveList = []; this.showAlert = false;
          this.commonService.cHash = undefined;
          this.companies = undefined;
          this.filteredCompanies.next([]);
          this.commonService.currentCompany = { _id: '_assessment' };
          localStorage.removeItem('cmp');
          console.log('cmp remove called in assessment');
          this.getCompanies();
        } else {
          if (this.authService.currentUser && this.authService.currentUser.permissions
            && (this.authService.currentUser.permissions.includes !== ''
              && this.authService.currentUser.permissions.includes !== '*')
            && event.url.indexOf('toolkit') === -1) {
            this.router.navigateByUrl('/companies');
          }
          this.allComp = []; this.ccveList = []; this.showAlert = false; this.commonService.cHash = undefined;
          this.companies = undefined;
          this.filteredCompanies.next([]);
          this.baseService.showCompany = false;
          this.baseService.showAssessment = false;
        }
      }
      if (event instanceof NavigationError) {
        if (event.url.indexOf('companies') === -1) {
          this.baseService.showCompany = false;
        }
      }
    });
    console.log(this.showSearch);
    this.cs.totalEVE.subscribe((value: any) => {
      // this.searchTrendingCVE();
    });
    this.cs.hideMenuEVE.subscribe((value: any) => {
      this.menuHide = value;
    });
    cs.agentDeprecateEVE.subscribe((value: any) => {
      if (value.id === 'eventAgentLogs') {
        this.currentEventLogs = value.currentEventLogs;
        this.modalService.open(value.id);
      } else if (value.id === 'agentLogs') {
        this.agentLogData = value.agentLogData;
        this.viewAgentUpdateLogs(value.agentLogData._id);
      } else {
        this.reason = '';
        this.isDeprecated = value.isDeprecated;
        this.agentIds = value.agentIds;
        this.isOnlionAgent = value.agentIds.filter((s: any) => s.agentStatus).length;
        this.modalService.open(value.id);
      }
    });
    this.cs.reloadAndSetCompanyEVE.subscribe((value: any) => {
      if (!value.value) {
        setTimeout(() => { this.getCompanies(); }, 2000);
      } else {
        this.forceSet = true;
        if (value.value.indexOf(' ') > -1) { value.value = value.value.split(' ')[0]; }
        this.searchK = true;
        setTimeout(() => { this.getCompanies(value.value); }, 2000);
      }
    });
  }
  @HostBinding('class') className = '';
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.loaderService.display(false);
      this.loaderService.Modeldisplay(false);
    }
  }

  initFavicon(): void {
    const url = `/assets/images/tenant/logo/${atob(this.cid)}_only_logo.png`;
    try {
      this.favIcon.href = url;
    } catch (e) {
      this.favIcon.href = `/assets/images/logo/cybercns_only_logo.png`;
    }
  }
  closeCurrentCompany(event: any): void {
    this.showTimeAgo = event;
    this.cveid = undefined;
    this.ccve = undefined;
    this.sidenav.close();
    if (this.allComp) {
      this.companies = Object.assign([], this.allComp);
      this.filteredCompanies.next(this.allComp.slice());
    }
    if (!event && !this.selectedCompany) {
      this.getCompanies();
    }
    if (this.searchK) { this.searchK = false; this.getCompanies(); }
  }

  updateCurrentCompany(event: any): void {
    this.selectedCompany = this.companyHash[event].name;
    const cmp = localStorage.getItem('cmp');
    this.commonService.currentCompany = this.companyHash[event];
    this.cs.statusFilter = '';
    this.cs.scanFilter = '';
    this.cs.discoveredFilter = 'all';
    if (cmp !== this.selectedCompany) {
      setTimeout(() => this.searchTrendingCVE(), 1000);
    } else {
      const cveList = this.commonService.tCHash[this.commonService.currentCompany._id];
      if (cveList) {
        this.processSearchTrendingCVE(cveList);
      }
    }
    localStorage.removeItem('cmp');
    localStorage.setItem('cmp', this.selectedCompany);
    this.loaderService.selectedSiteChange(this.companyHash[event]);
  }

  setScheme(scheme: any): void {
  }

  getCompanies(search?: string): void {
    if (!this.authService || !this.authService.isAuthenticated) {
      setTimeout(() => { this.getCompanies(); }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {
      query: {
        bool: {
          must: [{ exists: { field: 'description' } },
          { terms: { '_type_.keyword': ['company', 'companycreate'] } }],
          must_not: [{ match: { isAssessment: true } }]
        }
      }
    };
    const asmq = {
      query: {
        bool: {
          must: [{ match: { isAssessment: true } },
          { exists: { field: 'description' } }, { terms: { '_type_.keyword': ['company', 'companycreate'] } }]
        }
      }
    };
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    if (search && search !== '') {
      // @ts-ignore
      cq.query.bool.must.push({ match_bool_prefix: { name: search.toLowerCase() } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 300;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    const fields = JSON.stringify(['name', 'customerInfo', 'c', 'isAssessment']);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({ q, skip, limit, sort, fields }).subscribe((result: any) => {
      if (!result.total && !this.forceSet && !search && !this.baseService.showAssessment) {
        this.router.navigateByUrl('companies/onboarding').then(r => console.log(r));
      }
      if (result.data.length) {
        for (const c of result.data) { if (c._id) { this.companyHash[c._id] = c; } }
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : ''; const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
        if (search) {
          result.data = this.searchPipe.transform(result.data, search);
        }
        this.commonService.cHash = this.companyHash;
        this.commonService.companiesList = result.data;
        this.companies = result.data; if (!search) { this.allComp = result.data; }
        const cmp = localStorage.getItem('cmp');
        const mergedDict: { [key: string]: object } = {};
        (this.allComp.concat(result.data)).forEach((item: any) => {
          const key = item._id;
          if (!mergedDict[key]) {
            mergedDict[key] = {};
          }
          Object.assign(mergedDict[key], item);
        });
        this.allComp = Object.values(mergedDict);

        if (window.location.pathname === '/companies/company') {
          (this.commonService.currentCompany) ? delete this.commonService.currentCompany : null;
        }
        if (this.forceSet) { this.filteredCompanies.next([]); this.allComp = result.data; }
        this.filteredCompanies.next(result.data.slice());
        const allc = this.allComp.map((x: any) => x.name);
        if (!this.companyCtrl.value && !cmp && !this.commonService.currentCompany) {
          this.companyCtrl.setValue(this.companies[0]._id);
          this.updateCurrentCompany(this.companies[0]._id);
        } else if (cmp || !this.commonService.currentCompany) {
          const company = this.companies.filter((x: any) => x.name === cmp);
          if (company.length) {
            this.companyCtrl.setValue(company[0]._id);
            this.updateCurrentCompany(company[0]._id);
            console.log(cmp + ' -> company set and removing');
            localStorage.removeItem('cmp');
          } else {
            console.log(this.companies);
            this.companyCtrl.setValue(this.companies[0]._id);
            this.updateCurrentCompany(this.companies[0]._id);
          }
        }
        if (window.location.pathname === '/assessments/assessment-company') {
          const disableMenuItem = false;
          this.cs.disableMenu.next(disableMenuItem);
          this.cs.disableOverview.next(disableMenuItem);
        }
        if (this.forceSet) {
          setTimeout(() => {
            const company = this.companies.filter((x: any) => x.name === cmp);
            if (company.length) {
              this.companyCtrl.setValue(company[0]._id);
              this.updateCurrentCompany(company[0]._id);
              console.log(cmp + ' -> company set and removing');
              localStorage.removeItem('cmp');
            } else {
              console.log(this.companies);
              this.companyCtrl.setValue(this.companies[0]._id);
              this.updateCurrentCompany(this.companies[0]._id);
            }
            this.forceSet = false;
          }, 1000);
        }
        this.searching = false;
        this.searchTxt = (result.total === -1) ? `Search Company from ${this.allComp.length} Companies` : 'Search Company';
      } else {
        if (this.baseService.showAssessment) {
          this.companies = [];
          this.companyCtrl.setValue('');
        }
        if (window.location.pathname === '/assessments/assessment-company') {
          const disableMenuItem = true;
          this.cs.disableMenu.next(disableMenuItem);
          this.cs.disableOverview.next(disableMenuItem);
        }
        this.filteredCompanies.next([]);
        this.searching = false;
      }
    }, error => {
      // no errors in our simulated example
      this.searching = false;
      // handle error...
    });
  }

  agentGlobalDeprecate(): void {
    this.loaderService.display(true);
    const agentDepURL = (this.isDeprecated) ? 'activate_agents' : 'deprecate_agents';
    this.baseService.doRequest(`/api/agent/imaws/${agentDepURL}`,
      'post', { agent_ids: this.agentIds.map((x: any) => x._id), reason: this.reason }).subscribe((result: any) => {
        // @ts-ignore
        if (result[0]) {
          this.toast.sToast('success', result[1]);
          this.modalService.close('agentDeprecate');
          this.isDeprecated = undefined;
          setTimeout(() => { this.loaderService.display(false); this.cs.agentDeprecateCloseEVE.next(); }, 2000);
        } else {
          this.toast.sToast('error', result[1]);
          this.loaderService.display(false);
        }
      });
  }

  agentSingleDeprecate(): void {
    this.loaderService.display(true);
    const agentDepURL = (this.isDeprecated) ? 'activate_agents' : 'deprecate_agents';
    this.baseService.doRequest(`/api/agent/imaws/${agentDepURL}`,
      'post', { agent_ids: this.agentIds.map((x: any) => x._id), reason: this.reason }).subscribe((result: any) => {
        // @ts-ignore
        if (result[0]) {
          this.toast.sToast('success', result[1]);
          this.modalService.close('singleagentDeprecate');
          this.isDeprecated = undefined;
          setTimeout(() => { this.loaderService.display(false); this.cs.agentDeprecateCloseEVE.next(); }, 2000);
        } else {
          this.toast.sToast('error', result[1]);
          this.loaderService.display(false);
        }
      });
  }

  viewAgentUpdateLogs(agentId: any): void {
    this.loaderService.display(true);
    const reqData = { agentId: agentId };
    this.baseService
      .doRequest(`/api/agent/imaws/getAgentUpdateLogs`, 'post', reqData)
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res[0]) {
          this.agentLogData.log = res[1];
          this.modalService.open('agentLogs');
        } else {
          this.toast.sToast('error', res[1]);
        }
      });
  }

  getFullDate(date: any): any {
    return (
      date.getFullYear() +
      '-' +
      `${date.getMonth() + 1}`.padStart(2, '0') +
      '-' +
      `${date.getDate()}`.padStart(2, '0')
    );
  }

  changeEvent(event: any, key: any) {
    const date = event.value;
    if (key === 'startDate') {
      this.fromMinEndDate = this.commonService.getLastDay(1, event.value);
      this.fromMaxEndDate = this.commonService.getLastDay(10, event.value);
    }
    // @ts-ignore
    this.currentEventLogs[key] = this.getFullDate(date);
  }

  downloadAgentEventLogs(): void {
    this.loaderService.display(true);
    this.baseService
      .doRequest(
        `/api/agent/imaws/fetchAgentEventLogs`,
        'post',
        this.currentEventLogs
      )
      .subscribe((res: any) => {
        this.loaderService.display(false);
        if (res[0]) {
          this.toast.sToast('success', res[1]);
          this.modalService.close('eventAgentLogs');
        } else {
          this.toast.sToast('error', res[1]);
        }
      });
  }

  ngOnInit(): void {
    this.themingService.getThemeMode();
    this.initFavicon();
    // this.getMfaEnable();
    this.loaderService.status.subscribe((val: boolean, text: string) => {
      this.result = val;
      setTimeout(() => { this.showLoader.next(this.result.value); });
      this.contentText = this.result.text;
    });
    this.trialPeriod();
    this.authService.getSessionSettings();

    setTimeout(() => {
      const rn = localStorage.getItem('isReleaseNotes');
      if (rn === undefined || rn === null || rn === '') {
        this.getSessionSettings();
      }
    }, 3000);
    this.companyFilterCtrl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroySearch)).subscribe(() => {
      this.filterCompanies();
    });
    // this.getCompanies();
    const query = { query: { bool: { must: [{ match: { userId: this.authService.currentUser?.email } }, { match: { tableId: 'toggle' } }] } } };
    const q = JSON.stringify(query);
    const skip = 0;
    const limit = 1;
    this.tableSettingsService.getAllApiTablesettingsGet({ q, skip, limit }).subscribe((result: any) => {
      console.log('toggle Setting Result', result);
      if (result.data.length) {
        this.toggleSetting = result.data[0];
        this.isHidden = this.toggleSetting.columnRepr === 'True';
      } else {
        this.isHidden = false;
      }
      if (!this.isHidden) {
        this.sideNavView = 'gettingStarted';
        setTimeout(() => this.sidenav.open());
      }
    });
  }

  apiLink(): void {
    window.open('/docs', '_blank');
  }

  closeSideNav(reason: string): void {
    this.sideNavView = '';
    this.sidenav.close();
    this.ccve = (!this.showAlert) ? undefined : this.ccve;
    if (this.ccveList.length > 0) {
      this.cveid = this.ccveList.length > 0 ? this.ccveList[0].cve : '';
    } else {
      this.cveid = undefined;
    }

    if (!this.showAlert) {
      this.ccve = undefined; this.cveid = ''; this.ccveList = []; this.ccveHash = {};
    }
  }

  gettingStartedOpen(): void {
    this.sideNavView = 'gettingStarted';
    setTimeout(() => this.sidenav.open());
  }

  migrateCompanyOpen(): void {
    this.sideNavView = 'migrateCompany';
    setTimeout(() => this.sidenav.open());
  }

  integrationExternalSystemsOpen(url: any): void {
    this.integrationUrl = url;
    this.sideNavView = 'integrationExternalSystems';
    setTimeout(() => this.sidenav.open());
  }

  searchCVE(): void {
    this.sideNavView = 'cveSearch';
    this.baseService.doRequest(`/api/company/${this.commonService.currentCompany._id}/searchCVE`, 'post', { companyid: this.commonService.currentCompany._id, cve: this.ccveList[0]?.cve }).subscribe((result: any) => {
      console.log('result', result[1])
      this.ccve = result[1];
    });
    if (!this.showAlert) {
      this.ccve = undefined; this.cveid = ''; this.ccveList = []; this.ccveHash = {};
    }
    setTimeout(() => this.sidenav.open());
  }

  searchcvemaster(): void {
    this.sideNavView = 'cveSearch';
    if (this.ccveList[0]?.cve) {
      this.baseService.doRequest(`/api/company/${this.commonService.currentCompany._id}/searchCVE`, 'post', { companyid: this.commonService.currentCompany._id, cve: this.ccveList[0]?.cve }).subscribe((result: any) => {
        console.log('result', result[1])
        this.ccve = result[1];
      });
    }
    if (!this.showAlert) {
      this.ccve = undefined; this.cveid = ''; this.ccveList = []; this.ccveHash = {};
    }
    setTimeout(() => this.sidenav.open());
  }

  updateSelectedCVE(cve: any): void {
    // this.ccve = this.ccveHash[cve];
    this.baseService
      .doRequest(`/api/company/${this.commonService.currentCompany._id}/searchCVE`, 'post', {
        companyid: this.commonService.currentCompany._id,
        cve: cve
      })
      .subscribe((result: any) => {
        console.log('result', result[1]);
        this.ccve = result[1];
      });
  }

  searchTrendingCVE(): void {
    const cveList = this.commonService.tCHash[this.commonService.currentCompany._id];
    if (cveList) {
      this.processSearchTrendingCVE(cveList);
    } else {
      this.loaderService.display(true);
      this.baseService.doRequest(`/api/company/dummy/searchTrendingCVE`, 'post',
        { companyid: this.commonService.currentCompany._id }).subscribe((result: any) => {
          this.loaderService.display(false);
          this.processSearchTrendingCVE(result);
        });
    }
  }

  processSearchTrendingCVE(result: any): void {
    this.commonService.ccve = [];
    if (result.length) {
      this.commonService.tCHash[this.commonService.currentCompany._id] = result;
      localStorage.setItem('tcve', JSON.stringify(this.commonService.tCHash));
      this.ccveList = [];
      result.forEach((res: any) => {
        if (this.baseService.showCompany) {
          Object.keys(res.companyRef).forEach((key) => { res.assets = res.companyRef[key].assets; });
        } else {
          Object.keys(res.companyRef).forEach((key) => {
            res.companyRef[key].visible = false;
          });
        }
        this.ccveHash[res.cve] = res;
        this.commonService.ccve = [...this.commonService.ccve, ...(res.assets) ? res.assets.map((i: any) => i.id) : []];
        if (res.epss) {
          res.epss_score = +parseFloat(res.epss)?.toFixed(2);
        }
        if (res.assets && res.assets.length) {
          // this.cveid = res.cve;
          if (this.ccveList.length > 0) {
            this.cveid = this.ccveList.length > 0 ? this.ccveList[0].cve : '';
          } else {
            this.cveid = res.cve;
          }
          this.ccveList.push(res);
        }
        if (!this.showAlert) {
          if (res.assets && res.assets.length) {
            this.showAlert = true;
          } else {
            this.showAlert = false;
          }
        }
      });
    } else {
      this.ccveList = []; this.cveid = '';
    }
  }

  searchCVEDetail(CVE: string): void {
    this.loaderService.display(true, `CVE-${CVE} Details...`);
    const cmp = (this.baseService.showCompany) ? this.commonService.currentCompany._id : '';
    this.companyService.companysearchCveApiCompanyIdSearchCvePost(
      { id: this.commonService.currentCompany._id, body: { companyid: cmp, cve: `CVE-${CVE}` } }).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          if (this.baseService.showCompany) {
            Object.keys(result[1].companyRef).forEach((key) => { result[1].assets = result[1].companyRef[key].assets; });
          } else {
            Object.keys(result[1].companyRef).forEach((key) => { result[1].companyRef[key].visible = false; });
          }
          if (result[1].epss) {
            result[1].epss_score = + parseFloat(result[1].epss)?.toFixed(2);
          }
          this.ccve = result[1];
          console.log('searchcve detail', this.ccve)
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
  }
  navToAsset(company: any, asset: any): void {
    localStorage.setItem('cmp', company);
    localStorage.setItem('asset', asset);
    localStorage.setItem('newTab', 'cveAssets');
    window.open('/companies/company', '_blank');
  }
  getMfaEnable(): void {
    const query = `search=${this.authService?.currentUser?.email}`;
    this.baseService.doRequest(`/api/kusers`, 'get', null,
      { query }).subscribe((result: any) => {
        if (result && result.length) {
          this.enableMfa = result[0].totp;
        }
      });
  }

  getSessionSettings(): void {
    const cq = {
      query: {
        bool: {
          must: [{ match: { 'userid.keyword': this.authService.currentUser?.email } }, { exists: { field: 'releaseNotesCheck' } }]
        }
      }
    };
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1;
    this.loaderService.display(true, 'Loading...');
    this.baseService.doRequest('/api/usersettings', 'get', null, { q, skip, limit }).subscribe((res: any) => {
      this.loaderService.display(false);
      if (res && res.data && res.data.length) {
        this.sessionData = res.data[0];
        this.checkReleaseNotes(res.data[0].releaseNotesCheck);
      }
    });
  }

  getBuild(): void {
    this.modalService.open('build');
    this.loaderService.Modeldisplay(true, 'Getting build info...');
    this.baseService.doRequest(`/api/cyberutils/dummy/getBuildInfo`, 'post',
      {}, null, this.baseService.authHeader).subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        if (result && result[0]) {
          this.build = result[1];
          this.getUpdatesAvailable();
        }
      });
  }

  getUpdatesAvailable(): void {
    this.loaderService.display(true, 'Checking is new updates available... ');
    this.baseService.doRequest(`/api/cyberutils/dummy/checkCyberUpdates`, 'post', {}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        this.updateStatus = result[1];
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  installUpdate(): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to update the latest patch?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.baseService.doRequest(`/api/cyberutils/dummy/installCyberUpdates`, 'post', {}).subscribe((result: any) => {
          if (result[0]) {
            this.toast.sToast('success', result[1]);
          } else {
            this.toast.sToast('error', result[1]);
          }
        });
      }
    });
  }
  onChangePassword(): void {
    this.getMfaEnable();
    this.changePasswordData = {
      password: null,
      confirmPassword: null,
      oldPassword: null
    };
    this.modalService.open('changePassword');
  }

  changeUserPassword(form: NgForm): void {
    if (this.changePasswordData.oldPassword === this.changePasswordData.password) {
      this.toast.sToast('error', 'Old Password & Password should not be the same.');
      return;
    }
    if (this.changePasswordData.password !== this.changePasswordData.confirmPassword) {
      this.toast.sToast('error', 'Password & Confirm Password does not match.');
      return;
    }
    this.loaderService.Modeldisplay(true);
    this.baseService.doRequest(`/api/kusers/changepassword`, 'post', this.changePasswordData)
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        if (result[0]) {
          this.modalService.close('changePassword');
          form.resetForm();
          this.toast.sToast('success', 'Password changed successfully!');
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
  }

  getVulsSyncInfo(): void {
    this.modalService.open('feedInfo');
    this.loaderService.Modeldisplay(true, 'Getting vulnerability feed info...');
    this.baseService.doRequest(`/api/cyberutils/dummy/getFeedInfo`, 'post', {})
      .subscribe((result: any) => {
        this.loaderService.Modeldisplay(false);
        if (result && result[0]) {
          this.feeds = result[1];
        }
      });
  }

  trialPeriod(): void {
    if (!this.authService.currentUser || !this.authService.currentUser.customerid) {
      setTimeout(() => this.trialPeriod(), 1984);
      return;
    }
    this.trial = { domain: window.location.hostname, action: 'buyNow', comment: '', button: 'Buy Now' };
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/partner/getpartnerstatus`,
      'post', { tenant: this.authService.currentUser.customerid }).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0] && result[1].status.toLowerCase() !== 'paid' && result[1].days > 14) {
          const percent = ((result[1].days / 14 * 100) >= 100) ? 100 : (result[1].days / 14 * 100);
          this.trial.days = result[1].days;
          this.trial.color = (percent > 75) ? '#CD3F32FF' : '#32CD32';
          this.trial.percent = percent;
          this.trial.textcolor = (result[1].days <= 14) ? '#32CD32' : '#CD3F32FF';
          this.trial.message = `${result[1].days} Days`;
          this.expireDate = result[1].trialenddate;
          this.modalService.open('trialPeriod');
        }
      });
  }

  checkReleaseNotes(show: boolean): void {
    this.loaderService.display(true, 'Getting release notes');
    this.baseService.doRequest(`/api/cyberutils/dummy/getReleaseNotesNew`, 'post', {})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          this.patchingDetails = (result[1]) ? result[1] : {};
          if (show) {
            this.modalService.open('releaseNotesModal');
          } else if (!this.tableId) {
            this.modalService.open('releaseNotesModal');
          } else if (this.tableId && this.sessionData) {
            let s = false;
            // @ts-ignore
            this.sessionData.forEach(obj => {
              if (obj.userdata === this.releaseNotes.date) {
                s = true; return false;
              }
            });
            if (!s) {
              this.modalService.open('releaseNotesModal');
            }
          }
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
  }

  readWhatsNew(): void {
    this.modalService.close('releaseNotesModal');
    const method = (this.sessionData && this.sessionData._id) ? 'put' : 'post';
    const url = `/api/usersettings`;
    const data = (this.sessionData) ? this.sessionData : { releaseNotesCheck: false, userid: this.authService.currentUser?.email };
    this.baseService.doRequest(url, method, data).subscribe((result: any) => {
      if (result) {
        return;
      } else {
        return;
      }
    });
  }

  onLogOut(): void {
    const titleName = 'Logout message';
    const message = 'Are you sure you want to logout?';
    const cancelText = 'Cancel';
    const acceptText = 'OK';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText, '', '', true);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        localStorage.removeItem('isLoggedin');
        this.authService.logout();
      }
    });
  }

  toggleSearch(): void {
    this.showSearch = !this.showSearch;
  }

  ngOnDestroy(): void {
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }

  private filterCompanies(): void {
    if (!this.companies) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.companies = Object.assign([], this.allComp);
      this.filteredCompanies.next(this.companies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  updateTrial(): void {
    console.log(JSON.stringify(this.trial));
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/partner/partnerresponse`, // @ts-ignore
      'post', { tenant: this.authService.currentUser.customerid, partnerresponse: 'buy' }).subscribe((result: any) => {
        this.loaderService.display(false);
        if (result[0]) {
          this.toast.sToast('success', result[1]);
          this.modalService.close('trialPeriod');
        } else {
          this.toast.sToast('error', result[1]);
        }
      });
    this.modalService.close('trialPeriod');
  }
  cancelTrial(): void {
    const titleName = 'Confirmation';
    const message = 'Once you confirm that your instance will be terminated and you\'ll no longer have access to the data. Are you sure you want to cancel? ';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true);
        this.baseService.doRequest(`/api/partner/partnerresponse`, // @ts-ignore
          'post', { tenant: this.authService.currentUser.customerid, partnerresponse: 'cancel' }).subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('error', result[1]);
              this.modalService.close('trialPeriod');
              setTimeout(() => this.authService.logout());
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  updateTrialForm(action: any): void {
    if (action === 'buyNow') {
      this.trial.button = 'Buy Now';
    } else if (action === 'cancelNow') {
      this.trial.button = 'Cancel Trial';
    } else {
      this.trial.button = 'Request Extention';
    }
  }
  SaveSettings(): void {
    let item: any; item = {
      tableId: 'toggle',
      userId: this.authService.currentUser?.email,
      columnRepr: this.isHidden ? 'True' : 'False'
    };
    const method = (this.toggleSetting && this.toggleSetting._id) ? 'put' : 'post';
    if (this.toggleSetting && this.toggleSetting._id) { item._id = this.toggleSetting._id; }
    this.baseService.doRequest(`/api/tablesettings/`, method, item).subscribe((res: any) => {
      if (res && res._id && res.c !== null && res.u !== null) {
        this.toast.sToast('success', 'Successfully updated!');
      } else {
        this.toast.sToast('error', res._id);
      }
    });
  }
  gettingStartedtoggle(isHidden: any): void {
    this.isHidden = isHidden;
    this.SaveSettings();
  }

  createMSP(): void {
    this.loaderService.display(true, 'Creating MSP...');
    this.isLoading = true;
    this.companyService.companycreateMspApiCompanyIdCreateMspPost(
      { id: 'dummy', body: { mspdomain: 'beta' + this.mspDomain, email: this.mspEmail } }).subscribe((result: any) => {
        this.loaderService.display(false);
        this.toast.sToast('success',
          `beta${this.mspDomain}.mycybercns.com domain initiated. Please browse after 2 minutes.`);
        this.isLoading = false;
        this.mspDomain = ''; this.mspEmail = '';
        this.createMSPMenuTrigger.closeMenu();
      });
  }

  jobsOverview(): void {
    this.sideNavView = 'jobsView';
    this.sidenav.open();
  }

  partnerOverview(): void {
    this.sideNavView = 'partnerOverview';
    this.sidenav.open();
  }

  getMigCompanies(search?: string): void {
    if (!this.authService || !this.authService.isAuthenticated) {
      setTimeout(() => { this.getMigCompanies(); }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {
      query: {
        bool: {
          must: [{ exists: { field: 'description' } },
          { terms: { '_type_.keyword': ['company', 'companycreate'] } }],
          must_not: [{ match: { isAssessment: true } }]
        }
      }
    };
    const asmq = {
      query: {
        bool: {
          must: [{ match: { isAssessment: true } },
          { exists: { field: 'description' } }, { terms: { '_type_.keyword': ['company', 'companycreate'] } }]
        }
      }
    };
    cq = (this.baseService.showAssessment) ? asmq : cmpq;
    if (search && search !== '') {
      // @ts-ignore
      cq.query.bool.must.push({ match_bool_prefix: { name: search.toLowerCase() } });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 500;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    const fields = JSON.stringify(['name', 'customerInfo', 'c', 'isAssessment']);
    this.searching = true;
    this.companyService.getAllApiCompanyGet({ q, skip, limit, sort, fields }).subscribe((result: any) => {
      if (result.data.length) {
        for (const c of result.data) { if (c._id) { this.companyHash[c._id] = c; } }
        result.data.sort((a: any, b: any) => {
          const c = (a.name) ? a.name.toLowerCase() : ''; const d = (b.name) ? b.name.toLowerCase() : '';
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
        result.data.forEach((obj: any) => {
          if (obj._id) {
            this.allCompanyHash[obj._id] = obj;
          }
        });
        this.viewMigrationStatusPage();
      }
    }, error => {
      // no errors in our simulated example
      this.searching = false;
      // handle error...
    });
  }
  viewMigrationStatusPage(): void {
    this.showAlert = false;
    this.loaderService.display(true);
    try {
      const httpOptions: any = new HttpHeaders({
        "X-Tenant-Id": this.authService?.currentUser?.v4_tenant_id + '',
      });
      // @ts-ignore
      httpOptions.hashOptions = { isLoading: false };
      this.loaderService.display(true);
      let options = { headers: httpOptions };
      this.baseService.doRequest(`/api/cyberutils/dummy/get_v4_migration_stats`, 'post', {}).subscribe((res: any) => {
          this.loaderService.display(false);
          if (res[0]) {
            this.loaderService.display(false);
            if (res[0] && res[1]) {
              this.migrationInfo.data = res[1];
              this.migrationInfo.companies = Object.keys(this.migrationInfo.data).length;
              this.migrationInfo.migrated = 0;
              this.migrationInfo.pending = 0;
              this.migrationInfo.offline = 0;
              Object.keys(this.migrationInfo.data).forEach((obj: any) => {
                this.migrationInfo.migrated += this.migrationInfo.data[obj].migrated;
                this.migrationInfo.pending += this.migrationInfo.data[obj].pending;
                this.migrationInfo.offline += this.migrationInfo.data[obj].offline;
              });
              this.showAlert = false;
              this.modalService.open('migrationStatus');
            } else {
              this.showAlert = true;
              this.alert.message = 'We appreciate your patience. The replication process is in queue.' +
                ' Please revisit at a later time for updates. Thank you.';
              this.modalService.open('migrationStatus');
            }
          } else {
            this.toast.sToast('error', res[1]);
          }
        }, (error: any) => {

        });
    } catch (e) {
      console.log(e);
    }

  }


  replicationresync():void{
      const titleName = 'Confirmation';
      const message = `Are you sure you want to replicate/resync all the companies to v4?`;
      const cancelText = 'No';
      const acceptText = 'Yes';
      const htmlMsg = true;
      this.confirmDialog.confirmDialog(
        titleName,
        message,
        cancelText,
        acceptText,
        '',
        '',
        htmlMsg
      );
      this.confirmDialog.dialogResult.subscribe((res: any) => {
        if (res) {
          this.loaderService.display(true);
          this.baseService
            .doRequest(
              `/api/cyberutils/test/resync_v4_replication`,'post',{})
            .subscribe((result: any) => {
              this.loaderService.display(false);
              console.log('resultmigration',result)
              if (result[0]) {
                setTimeout(() => {
                 this.toast.sToast('success',result[1]);
                }, 2000);
              }else{
                setTimeout(() => {
                  this.toast.sToast('error', 'Replication failed');
                 }, 2000);
              }
            });
        }
      });
    }

}



