/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { InstalledDrivers } from './installed-drivers';


export interface GetAllApiInstalledDriversGet$Params {

/**
 * Filter query to be executed against database.
 */
  q?: string;
  skip?: number;
  limit?: number;
  sort?: string;

/**
 * Comma seperated list of fields to return.
 */
  fields?: string;
  scroll_id?: string;
}

export function getAllApiInstalledDriversGet(http: HttpClient, rootUrl: string, params?: GetAllApiInstalledDriversGet$Params): Observable<StrictHttpResponse<InstalledDrivers>> {
  const rb = new RequestBuilder(rootUrl, getAllApiInstalledDriversGet.PATH, 'get');
  if (params) {
    rb.query('q', params.q, {});
    rb.query('skip', params.skip, {});
    rb.query('limit', params.limit, {});
    rb.query('sort', params.sort, {});
    rb.query('fields', params.fields, {});
    rb.query('scroll_id', params.scroll_id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json' })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<InstalledDrivers>;
    })
  );
}

getAllApiInstalledDriversGet.PATH = '/api/InstalledDrivers/';
