
<div *ngIf="!jobDomain" [@zoomInOnEnter]>
<app-s-table aria-label="attackSurfaceDomainResult" role="table" [sTableOptions]="attackSurfaceresultTableOptions"
    (refreshCallback)="getAttackSurfaceJobsresult()" (sortCallback)="attackSurfaceresultsortCall($event)"
    (pageCallback)="attackSurfaceResultpageCall($event)" (actionCallback)="attackSurfaceresultactionCall($event)"  (hyperlinkCallback)="attackSurfacelinkCall($event)"
    (timerCallback)="attackSurfaceresulttimerCallData($event)" (globalActionCallback)="attackSurfaceglobalActionCall($event)"></app-s-table>
</div>

<div *ngIf="currentCompany && jobDomain" [@zoomInOnEnter]>
    <mat-card>
      <mat-card-header>
        <mat-card-title class="d-flex align-items-center">
          <button class="position-absolute r-1p" mat-icon-button aria-label="close modal icon" matTooltip="Close"
            (click)="jobDomain = '';"><mat-icon>close</mat-icon>
          </button>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="pt-2">
      <app-attack-surface-result [currentCompany]="currentCompany" [jobDomain]="jobDomain._id"></app-attack-surface-result>
      </mat-card-content>
    </mat-card>
  </div>