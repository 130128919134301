import {Component, Input, OnInit} from '@angular/core';
import {AssetCredentials} from '../../../api/models/asset-credentials';
import {LoaderService} from '../../../_services/loader.service';
import {AgentService} from '../../../api/services/agent.service';
import {ModalService} from '../../../_services/modal.service';
import {CommonService} from '../../../_services/common.services';
import {AssetCredentialsService} from '../../../api/services/asset-credentials.service';
import {MyToastrService} from '../../../_services/toastr.service';
import {ConfirmDialogService} from '../../../_services/confirmdialog.service';
import {BaseRequestService} from '../../../_services/base.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {DiscoverySettings} from "../../../api/models/discovery-settings";
import {CompanyService} from "../../../api/services/company.service";

@Component({
  selector: 'app-asset-credentials',
  templateUrl: './asset-credentials.component.html',
  styleUrls: ['./asset-credentials.component.scss']
})
export class AssetCredentialsComponent implements OnInit {
  @Input() currentCompany: any;
  @Input() currentAgent: any;
  addAssetCredentialsWidth = 600;
  currentAsset: any;
  assetCred: any = {};
  isLoading = false;
  assetCredTableOptions: any;
  assetCredcurrentPage = 0;
  assetCredfilterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  netmaskList: any = [];
  isEndIp = false;
  isNetMask = true;
  agentHash: any = {};
  agentList: any[] = [];
  credTypeList: any[] = [
    {text: 'Windows', value: 1},
    {text: 'Linux', value: 2},
    {text: 'VMWare', value: 3},
    {text: 'Darwin', value: 4},
    // {text: 'Network Device', value: 5}
  ];
  currentAssetCred: any = {};
  probeList: any = [];
  assetTags: any = [];
  agentRef: any = {};
  Objectkeys = Object.keys;
  constructor(public baseService: BaseRequestService, public modalService: ModalService,
              public toast: MyToastrService, private loaderService: LoaderService,
              public confirmDialog: ConfirmDialogService, public commonService: CommonService,
              public agentService: AgentService, private aS: AuthenticationService,
              public assetCredentialsService: AssetCredentialsService, private companyService: CompanyService) {
    this.netmaskList = this.commonService.getSubnets();
    this.assetCredTableOptions = {
      columns: [
        {
          header: 'Name',
          columnDef: 'name',
          filter: '',
          cell: '(element: any) => `${element.name}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        },
        {
          header: 'Username',
          columnDef: 'username',
          filter: '',
          cell: '(element: any) => `${element.username}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Domain',
          columnDef: 'domain',
          filter: '',
          cell: '(element: any) => `${element.domain}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: false,
          iscolumnSearch: false
        },
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 3,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }, {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 4,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false
        }
      ],
      sortOptions: {active: 'name', direction: 'asc'},
      faClass: 'AssetCredentials',
      _pageData: [],
      tableOptions: {
        id: 'assetCredTableOptions',
        title: 'AssetCredentials',
        isServerSide: true,
        selectText: 'assetCred(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {text: 'Copy to Probe', icon: 'content_copy', callback: 'deleteFn', isGlobal: true, hidelocal: true},
          {
          text: 'Edit',
          icon: 'edit',
          callback: 'editFn',
          isGlobal: false
        }, {text: 'Delete', icon: 'delete', callback: 'deleteFn', isGlobal: true}],
        pagination: true,
        pageOptions: [5, 10, 25, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        filterDownload: false,
        add: (this.aS.hasPermission('assetcredentials', 'create')),
        columnSearch: false,
        compareData: false,
        service: 'assetCredentialsService',
        collection: 'AssetCredentials',
        filename: 'MasterCredentials'
      }
    };
  }

  changeAgent(agent: string): void {
    this.assetCred.agentRef.name = this.agentHash.get(agent);
  }

  changeDiscoveryType(assetCred: string): void {
    this.assetCred.subnet_mask = '';
    if (assetCred === 'cidr') {
      this.isEndIp = false;
      this.isNetMask = true;
      this.assetCred.end = '';
      this.assetCred.subnet_mask = '/24';
    } else if (assetCred === 'fromto') {
      this.isEndIp = true;
      this.isNetMask = false;
    } else if (assetCred === 'static') {
      this.isEndIp = false;
      this.isNetMask = false;
      this.assetCred.end = '';
    }
  }

  updateAssetCredentials(assetCred: any): void {
    if (assetCred.cred_type === 1){ delete assetCred.cred_port; }
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.assetCredentialsService.updateApiAssetcredentialsPut({body: assetCred}).subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', 'Updated successfully');
      this.isLoading = false;
      setTimeout(() => {
        this.getAssetCredentials();
      }, 2000);
      this.modalService.close('addAssetCredentials');
    });
  }

  saveAssetCredentials(): void {
    if (this.assetCred.cred_type !== 1){
      if (!this.assetCred.password && !this.assetCred.passkey){
        this.toast.sToast('error', 'Password/PrivateKey is required.');
        return;
      }
    }else{
      if (!this.assetCred.password){
        this.toast.sToast('error', 'Password is required.');
        return;
      }
    }
    if (this.assetCred.cred_type === 1){ delete this.assetCred.cred_port; }
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.assetCred.companyRef = {id: this.currentCompany._id, name: this.currentCompany.name};
    this.assetCredentialsService.createApiAssetcredentialsPost({body: this.assetCred})
      .subscribe((result: AssetCredentials) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = true;
        if (result._id) {
          this.modalService.close('addAssetCredentials');
          this.toast.sToast('success', 'AssetCredentials added successfully!');
          setTimeout(() => {
            this.getAssetCredentials();
          }, 2000);
          this.isLoading = false;
        } else {
          this.toast.sToast('error', JSON.stringify(result));
          this.isLoading = false;
        }
      });
  }

  deleteRange(assetCred: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to delete this AssetCredentials ' + assetCred.name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.assetCredentialsService.deleteApiAssetcredentialsIdDelete({id: assetCred._id}).subscribe((result: any) => {
          this.toast.sToast('success', 'Removed successfully');
          setTimeout(() => {
            this.getAssetCredentials();
          }, 2000);
        });
      }
    });
  }

  ngOnInit(): void {
    this.assetCredTableOptions.pageData = [];
    this.getAssetCredentials();
    this.getAgents();
  }

  assetCredshowHideLoading(status: any): void {
    const data = Object.assign({}, this.assetCredTableOptions);
    this.assetCredTableOptions = {};
    this.assetCredTableOptions = data;
    this.assetCredTableOptions.tableOptions.loading = status;
  }

  assetCredglobalActionCall(idata: any): void {
    if (idata.action.text === 'Delete') {
      idata.row.forEach((assetCred: any, index: number) => {
        this.assetCredentialsService.deleteApiAssetcredentialsIdDelete({id: assetCred._id}).subscribe((result: any) => {
          if (index === idata.row.length - 1) {
            this.toast.sToast('success', 'Removed successfully');
            setTimeout(() => {
              this.getAssetCredentials();
            }, 2000);
          }
        });
      });
    } else if (idata.action.text === 'Copy to Probe') {
      this.currentAssetCred = [];
      this.currentAssetCred = idata.row;
      this.openCopyToProbe();
    }
  }

  openCopyToProbe(): void {
    this.agentRef = {id: '', name: '', agents: []};
    this.probeList = this.probeList.filter((x: any) => x && x._id);
    this.agentRef.agents = this.probeList.filter((x: any) => x._id !== this.currentAgent._id);
    if (!this.agentRef.agents || !this.agentRef.agents.length) {
      this.toast.sToast('error', 'No other probes to copy!');
    } else {
      this.modalService.open('copyAssetCredToProbe');
    }
  }

  copyToSelectProbe(agentRef: any): void {
    this.isLoading = true;
    const param: any = {
      src_agent_id: this.currentAgent._id, dest_agent_id: agentRef.id, clone_records: []
    };
    this.currentAssetCred.forEach((obj: any) => {
      param.clone_records.push(obj._id);
    });
    this.companyService.migrateAgentSettingsApiCompanyIdCloneAgentSettingsPost({id: 'imaws', body: param})
    .subscribe((result: any) => {
      this.loaderService.Modeldisplay(false);
      this.toast.sToast('success', 'Copied successfully!');
      this.isLoading = false;
      this.toast.sToast('success', 'Copied successfully!');
      this.closeCopyProbe();
    });
  }

  closeCopyProbe(): void {
    this.currentAssetCred = []; this.agentRef = {id: '', name: '', agents: []};
    this.getAssetCredentials();
    this.modalService.close('copyAssetCredToProbe');
  }

  assetCredsortCall(idata: any): void {
    this.assetCredTableOptions.sortOptions = idata;
    this.getAssetCredentials();
  }

  assetCrededitFn(idata: any): void {
    console.log(idata);
    this.assetCred = idata;
    this.changeDiscoveryType(this.assetCred.discovery_type);
    this.modalService.open('addAssetCredentials');
  }

  assetCreddeleteFn(idata: any): void {
    this.deleteRange(idata);
  }

  assetCredactionCall(idata: any): void {
    if (idata.action.text === 'Edit') {
      const dataRow = idata.row;
      this.assetCrededitFn(dataRow);
    }
    if (idata.action.text === 'Copy to Probe') {
      this.isLoading = false;
      this.currentAssetCred = [];
      this.currentAssetCred = [idata.row];
      this.openCopyToProbe();
    }
    if (idata.action.text === 'Delete') {
      const dataRow = idata.row;
      this.assetCreddeleteFn(dataRow);
    }
  }

  assetCredfilterCall(idata: any): void {
    const fields: any[] = [];
    this.assetCredTableOptions.columns.forEach((obj: { columnDef: any; }) => {
      if (obj.columnDef !== 'c' && obj.columnDef !== 'u'){
      fields.push(obj.columnDef); }
    });
    this.assetCredfilterQuery = (idata && idata.length > 0) ? {
      multi_match: {
        query: idata,
        type: 'phrase_prefix',
        fields
      }
    } : undefined;
    this.getAssetCredentials();
  }

  assetCredpageCall(event: any): void {
    this.assetCredTableOptions.tableOptions.pageSize = event.pageSize;
    this.assetCredcurrentPage = event.pageIndex;
    this.getAssetCredentials();
  }

  assetCredaddTableData(): void {
    this.assetCred = {
      cred_type: 1,
      cred_port: 22,
      username: '',
      password: '',
      domain: '',
      host_name: '',
      privileged_password: '',
      passkey: '',
      assetid: '',
      name: '', agentRef: {id: '', name: ''}
    };
    this.assetCred.agentRef.id = (this.currentAgent) ? this.currentAgent._id : this.agentList[0]._id;
    this.assetCred.agentRef.name = (this.currentAgent) ? this.currentAgent.name : this.agentList[0].name;
    this.changeDiscoveryType(this.assetCred.discovery_type);
    this.modalService.open('addAssetCredentials');
  }

  assetCredtimerCallData(idata: any): void {
    this.getAssetCredentials();
  }

  getAgents(): void {
    if (this.currentCompany && this.currentCompany._id) {
      this.commonService.getAgents(this.currentCompany._id).then((v2: any) => {
        this.commonService.sortFn(v2.data, 'name').then((val: any) => {
          this.agentList = val.filter((x: any) => x._id);
          this.probeList = val.filter((x: any) => x?._id);
          this.agentHash = new Map(this.agentList.map((i: any) => [i._id, i.name]));
        });
      });
    } else {
      setTimeout(() => {
        this.getAgents();
      }, 1000);
    }
  }

  getAssetCredentials(): void {
    this.loaderService.display(true, 'Getting master credentials');
    this.assetCredshowHideLoading(true);
    let params;
    this.assetCredTableOptions.serverSide = {
      service: 'assetCredentialsService', fn: 'getAllApiAssetcredentialsGet', q: {
        query: {
          bool: {
            must: [
              {match: {'assetid.keyword': ''}},
              {exists: {field: 'cred_type'}}, {exists: {field: 'hostname'}}, {match: {'hostname.keyword': ''}},
              {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
              {match: {'agentRef.id.keyword': this.currentAgent._id + ''}}
            ]
          }
        }
      }
    };
    params = {
      query: {
        bool: {
          must: [
            {match: {'assetid.keyword': ''}},
            {exists: {field: 'cred_type'}}, {exists: {field: 'hostname'}}, {match: {'hostname.keyword': ''}},
            {match: {'companyRef.id.keyword': this.currentCompany._id + ''}},
            {match: {'agentRef.id.keyword': this.currentAgent._id + ''}}
          ]
        }
      }
    };
    if (this.assetCredfilterQuery && this.assetCredfilterQuery.multi_match) {
      // @ts-ignore
      params.query.bool.must.push(this.assetCredfilterQuery);
    }
    let sort: any = [{}];
    const orderArr = ['name'];
    if (orderArr.indexOf(this.assetCredTableOptions.sortOptions.active) !== -1) {
        sort[0][this.assetCredTableOptions.sortOptions.active + '.keyword'] = {order: this.assetCredTableOptions.sortOptions.direction};
      } else {
       sort[0][this.assetCredTableOptions.sortOptions.active] = {order: this.assetCredTableOptions.sortOptions.direction};
    }
    const q = JSON.stringify(params);
    const skip = this.assetCredcurrentPage;
    sort = JSON.stringify(sort);
    const limit = this.assetCredTableOptions.tableOptions.pageSize;
    this.assetCredentialsService.getAllApiAssetcredentialsGet({q, skip, limit, sort})
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.data.length) {
          result.data.map((port: any) => port.cred_port = (port.cred_port) ? port.cred_port : '');
          this.assetCredTableOptions.pageData = result.data;
          this.assetCredTableOptions.tableOptions.pageTotal = result.total;
          this.assetCredshowHideLoading(false);
        } else {
          this.assetCredTableOptions.pageData = [];
          this.assetCredTableOptions.tableOptions.pageTotal = 0;
          this.assetCredshowHideLoading(false);
        }
      });
  }
}
