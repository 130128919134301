<mat-card class="mat-elevation-z0 w-100">
  <mat-card-header>
    <mat-card-title>Job Details - {{currentJob.job_data.task}}</mat-card-title>
    <mat-card-subtitle>{{currentJob.job_data.status_message}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="currentJob && currentView && currentJob.job_data.job_message">
      <mat-card class="mat-elevation-z4 mb-2 w-100"
        *ngIf="currentView.assetInventoryStatus && Objectkeys(currentView.assetInventoryStatus).length">
        <mat-card-header>
          <mat-card-title>Asset Inventory Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <table class="rounded-corner table w-100 bor-1"
            *ngIf="currentView.assetInventoryStatus && Objectkeys(currentView.assetInventoryStatus).length">
            <tbody>
              <tr *ngFor="let entry of Objectkeys(currentView.assetInventoryStatus)">
                <td class="w-25 fs1 fw3">{{entry | uppercase}}</td>
                <td class="w-75 max-h-4 overflow-auto">{{currentView.assetInventoryStatus[entry]}}</td>
              </tr>
            </tbody>
          </table>
          <!--<mat-list dense class="w-100 bor-1 max-h-5 overflow-auto"
                    *ngIf="currentView.assetInventoryStatus && Objectkeys(currentView.assetInventoryStatus).length">
            <mat-list-item class="bb-1" *ngFor="let entry of Objectkeys(currentView.assetInventoryStatus)">
              <span class="w-25">
                {{entry | uppercase}}
              </span>
              <span class="w-75">
                {{currentView.assetInventoryStatus[entry]}}
              </span>
            </mat-list-item>
          </mat-list>-->
          <p *ngIf="!currentView.assetInventoryStatus || !Objectkeys(currentView.assetInventoryStatus).length"
            class="text-center"> No data available. </p>
        </mat-card-content>
      </mat-card>
      <mat-card class="mat-elevation-z4 mb-2 w-100"
        *ngIf="currentView.assetJobStatus && currentView.assetJobStatus.length">
        <mat-card-header>
          <mat-card-title>Asset Job Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <app-sa-table [showHeaderUpperCase]="showUpper" [apiDownload]="apiDownload"
            *ngIf="currentView.assetJobStatus && currentView.assetJobStatus.length" [columnsList]="assetJobStatusCols" [externalApi]="true"
            [customCols]="assetJobStatusCustomCols" [dataList]="currentView.assetJobStatus" (apiDataDownload)="apiDataDownload()"></app-sa-table>
            <p *ngIf="!currentView.assetJobStatus ||
             !currentView.assetJobStatus.length" class="text-center"> No data available. </p>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="mat-elevation-z4 mb-2 w-100"
        *ngIf="currentView.assetSnmpJobStatus && currentView.assetSnmpJobStatus.length">
        <mat-card-header>
          <mat-card-title>Asset SNMP Job Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <app-sa-table [showHeaderUpperCase]="showUpper" [apiDownload]="apiDownload"
              *ngIf="currentView.assetSnmpJobStatus && currentView.assetSnmpJobStatus.length"
              [columnsList]="assetSNMPJobCols" [externalApi]="true" [customCols]="assetSNMPJobCustomCols"
              [dataList]="currentView.assetSnmpJobStatus" (apiDataDownload)="apiDataDownload()"></app-sa-table>
            <p *ngIf="!currentView.assetSnmpJobStatus ||
             !currentView.assetSnmpJobStatus.length" class="text-center"> No data available. </p>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="currentJob && currentView && currentJob.job_data && currentJob.job_data.report_job_message">
      <mat-card class="mat-elevation-z4 mb-2 w-100"
        *ngIf="currentView && currentView.reportJobStatus && reportJobStatusCols.length">
        <mat-card-header>
          <mat-card-title>Report Scheduler Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <app-sa-table [showHeaderUpperCase]="showUpper"
              *ngIf="currentView.reportJobStatus && currentView.reportJobStatus.length"
              [columnsList]="reportJobStatusCols" [customCols]="reportJobStatusCustomCols"
              [dataList]="currentView.reportJobStatus"></app-sa-table>
            <p *ngIf="!currentView.reportJobStatus || !currentView.reportJobStatus.length" class="text-center"> No data
              available. </p>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="currentJob && currentView && currentJob.job_data && currentJob.job_data.azuread_job_message">
      <mat-card class="mat-elevation-z4 mb-2 w-100"
        *ngIf="currentView && currentView.azureJobStatus && azureJobStatusCols.length">
        <mat-card-header>
          <mat-card-title>Azure Active Directory Job Status</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <app-sa-table [showHeaderUpperCase]="showUpper"
              *ngIf="currentView.azureJobStatus && currentView.azureJobStatus.length" [columnsList]="azureJobStatusCols"
              [customCols]="azureJobStatusCustomCols" [dataList]="currentView.azureJobStatus"></app-sa-table>
            <p *ngIf="!currentView.azureJobStatus || !currentView.azureJobStatus.length" class="text-center"> No data
              available. </p>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </mat-card-content>
</mat-card>

<s-modal id="download1">
  <form #download12="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="download1Width">
      <mat-card class="modal mat-elevation-z0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
        (click)="modalService.close('download1');"> <mat-icon>close</mat-icon> </button>
        <div class="mat-title">Please filter the download option</div>
        <mat-card-content class="py-4 my-4">
          <form #action1Form="ngForm">
            <mat-form-field class="w-70p" appearance="outline">
              <mat-label>select the filter data</mat-label>
              <mat-select [multiple]="false" id="integrationActionSelects" placeholder="Choose action" [(ngModel)]="filterData" name="filterData"
                         [disabled]="!reasonList.length">
                <mat-option value="">All reason</mat-option>
                <mat-option *ngFor="let item of reasonList; let i=index" id="{{i}}" [value]="item">
                  <span matTooltip="{{item}}">{{item | ellipsis: 25}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-stroked-button class="mat-warn mr-2"  (click)="modalService.close('download1');" id="cancel11">Cancel</button>
          <button (click)="apiDataDownload2()" id="intActionNext"  mat-raised-button
           color="primary">Submit</button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>