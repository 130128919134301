<div class="remedia-container">
    <mat-sidenav-container class="remedia-sidenav-container">
        <mat-sidenav-content class="h-100vh">
            <ng-container>
                <app-s-table aria-label="ReportreportJobs" role="table" [sTableOptions]="reportJobTableOptions"
                    (refreshCallback)="getReportJobs()" (sortCallback)="reportJobssortCall($event)"
                    (filterCallback)="reportJobsfilterCall($event)" (timerCallback)="reportJobstimerCallData($event)"
                    (colFilterCallback)="colFilterCall($event)" (actionCallback)="reportJobsactionCall($event)"
                    (pageCallback)="reportJobspageCall($event)" (totalCallback)="totalCallbackCheck($event)"
                    (globalActionCallback)="reportJobsglobalActionCall($event)">
                </app-s-table>
            </ng-container>
        </mat-sidenav-content>
        <mat-sidenav #reportsnav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p z-index-150">
            <mat-card class="w-100">
                <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                    (click)="currentJob = undefined; reportsnav.close();"><mat-icon>close</mat-icon>
                </button>
                <mat-card-header>
                    <mat-card-title class="fs1 fw2 mr-4">Details View</mat-card-title>
                </mat-card-header>
                <mat-card-content class="overflow-auto"
                    *ngIf="currentJob && currentJob.job_data && currentJob.job_data.report_job_message">
                    <mat-card class="mat-elevation-z0 w-100">
                        <mat-card-header>
                            <mat-card-title>Job Details - {{currentJob.job_data.report_name}}</mat-card-title>
                            <mat-card-subtitle>{{currentJob.job_data.status_message}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <ng-container *ngIf="currentJob && currentJob.job_data.report_job_message">
                                <mat-card class="mat-elevation-z4 mb-2 w-100"
                                    *ngIf="currentJob.job_data.report_job_message && currentJob.job_data.report_job_message.length">
                                    <mat-card-header>
                                        <mat-card-title>Report Job Status</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <div>
                                            <app-sa-table [showHeaderUpperCase]="showUpper"
                                                *ngIf="currentJob.job_data.report_job_message && currentJob.job_data.report_job_message.length"
                                                [columnsList]="reportJobStatusCols"
                                                [customCols]="reportJobStatusCustomCols"
                                                [dataList]="currentJob.job_data.report_job_message"></app-sa-table>
                                            <p *ngIf="!currentJob.job_data.report_job_message ||
                           !currentJob.job_data.report_job_message.length" class="text-center"> No data available. </p>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>
                </mat-card-content>
            </mat-card>
        </mat-sidenav>
    </mat-sidenav-container>
</div>