<mat-card class="table-card mb-2">
    <mat-toolbar class="table-tools" role="heading">
      <div fxFlex fxLayout fxLayoutAlign="start">
        <span class="fs1">Asset Level Network Vulnerabilities<span class="fw3  badge badge-primary" *ngIf="sortedData.length">
      {{sortedData.length}} </span></span>
      </div>
      <div fxFlex fxLayout fxLayoutAlign="end">
        <div fxLayout fxLayoutGap="15px" class="navigation-items">
          <form fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field class="search-tool fs1" floatLabel="never">
              <input matInput name="valuesearch" id="NsFilter" [(ngModel)]="rmFilter" placeholder="Search"
                     autocomplete="off">
              <button mat-button *ngIf="!rmFilter || rmFilter === ''" aria-label="Search" matSuffix mat-icon-button>
                <mat-icon>search</mat-icon>
              </button>
              <button mat-button *ngIf="rmFilter" matSuffix mat-icon-button aria-label="Clear"
                      (click)="rmFilter='';">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <button class="ml-1" (click)="initRP()" mat-icon-button="">
              <mat-icon>refresh</mat-icon>
            </button>
          </form>
        </div>
      </div>
    </mat-toolbar>
    <mat-card-content>
      <div class="overflow-auto" *ngIf="mode === 'company'">
        <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
          <thead>
            <tr class="mat-header-row">
              <th class="mat-header-cell text-center" [ngClass]="{'w-20': currentAsset, 'w-40': !currentAsset}">
                <mat-checkbox [(ngModel)]="selectAll" (change)="selectAllToggle()" class="pt-2"
                              [checked]="selectAll && isAllSelected()"
                              [indeterminate]="hasSelected() && !isAllSelected()" value="" color="primary">
                </mat-checkbox>
              </th>
              <th mat-sort-header="vul_id" class="mat-header-cell text-center">CVE</th>
              <th mat-sort-header="title" class="mat-header-cell">Title</th>
              <th mat-sort-header="severity" class="mat-header-cell">Severity</th>
              <th mat-sort-header="score" class="mat-header-cell">CVSS Score</th>
              <th mat-sort-header="assets" class="mat-header-cell">Asset(s)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of sortedData | search: rmFilter" class="mat-row">
              <td class="mat-cell text-center">
                <mat-checkbox [(ngModel)]="data.selected" value="" class="pt-2" color="primary"></mat-checkbox>
              </td>
              <td class="mat-cell">{{data.vul_id}}</td>
              <td class="mat-cell"><span (click)="cs.copyClip(data.title)" [matTooltip]="data.title">{{data.title | ellipsis: 35}}</span></td>
              <td class="mat-cell">
                <span *ngIf="data.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                <span *ngIf="data.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                <span *ngIf="data.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                <span *ngIf="data.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                <span *ngIf="data.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
              </td>
              <td class="mat-cell">{{data.score}}</td>
              <td class="mat-cell">
                <span class="pointer pull-up bor-1 px-2" *ngIf="data.assets" (click)="showHosts(data)">
                  {{data.assets.length}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>

