<app-s-table aria-label="Snmpv2" role="table" [sTableOptions]="snmpv2TableOptions"
             (sortCallback)="snmpv2sortCall($event)"
             (filterCallback)="snmpv2filterCall($event)" (actionCallback)="snmpv2actionCall($event)"
             (pageCallback)="snmpv2pageCall($event)" (refreshCallback)="getSnmpv2()"
             (globalActionCallback)="snmpv2globalActionCall($event)" (addCallback)="snmpv2addTableData()"
             (timerCallback)="snmpv2timerCallData($event)"></app-s-table>
<s-modal id="addSnmpv2">
    <mat-card class="d-flex flex-row is-align-items-flex-start p-0">
      <div class="d-flex flex-row align-items-center list-right list-right w-25">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/dk/023-drawkit-folder-man-colour.svg" alt="Add Agent" width="200px">
          <p class="mat-body-1">Add SNMP v1/v2 <strong>goes</strong> here</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0" [style.width.px]="addSnmpv2Width">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close" type="button"
                (click)="modalService.close('addSnmpv2');">
          <mat-icon>close</mat-icon>
        </button>
        <form #addI="ngForm">
        <div class="mat-title">SNMP v1/v2</div>
        <mat-card-content class="fcard-content" *ngIf="snmpv2 && snmpv2.agentRef">
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput id="SNMPName" placeholder="Enter name" type="text" trim="blur" required name="name"
                   [(ngModel)]="snmpv2.name" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" apperance="outline">
            <mat-label>Version</mat-label>
            <mat-select [multiple]="false" id="SNMPVersion" name="snmpversion" placeholder="Choose Version"
                        [(ngModel)]="snmpv2.snmpVersion">
              <mat-option *ngFor="let item of snmpVersion" [value]="item.value" [id]="item.id"> {{item.text}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline">
            <mat-label>Community String</mat-label>
            <input matInput id="SNMPCommunityString" placeholder="Enter Community String" type="password" required name="community_string"
                   [(ngModel)]="snmpv2.community" autocomplete="off">
          </mat-form-field>
          <mat-form-field class="w-75" appearance="outline" *ngIf="!currentAgent">
            <mat-label>Choose Probe/Agent</mat-label>
            <mat-select placeholder="Choose Probe/Agent" name="agentRef" required
                        (selectionChange)="changeAgent(snmpv2.agentRef.id)"
                        [(ngModel)]="snmpv2.agentRef.id">
              <mat-option *ngFor="let item of agentList| sortBy:'asc':'name'" [value]="item._id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2" *ngIf="!snmpv2._id" type="button"
                  (click)="saveSnmpv2()" [disabled]="!addI.form.valid || isLoading"
                  id="saveip">Save
          </button>
          <button mat-raised-button class="mat-primary mr-2" *ngIf="snmpv2._id" type="button"
                  (click)="updateSnmpv2(snmpv2)" [disabled]="!addI.form.valid || isLoading"
                  id="updateip">Update
          </button>
          <button mat-stroked-button class="mat-warn" type="button" (click)="modalService.close('addSnmpv2')"
                  id="cancelip">Cancel
          </button>
        </mat-card-footer>
      </form>
      </mat-card>
    </mat-card>

</s-modal>
<s-modal id="copySnmpv2ToProbe">
  <form #copyToProbeForm="ngForm">
    <mat-card class="d-flex flex-row is-align-items-flex-start py-0" [style.width.px]="'600'">
      <div class="d-flex flex-row align-items-center list-right list-right">
        <div class="col-lg-12 text-center">
          <img src="/assets/images/releasenotes.png" alt="Release Notes" width="200px">
          <p class="mat-body-1">Copy To Other Probe</p>
        </div>
      </div>
      <mat-card class="modal mat-elevation-z0 pr-0 w-100">
        <button class="float-right" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="closeCopyProbe();">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-title">Copy To Other Probe</div>
        <mat-card-content class="">
          <p><strong>Selected SNMP V2 Credential(s):</strong></p>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <span class="mr-2 py-1 px-2 badge badge-primary mb-2" *ngFor="let range of currentSnmpv2Cred"> {{range.name}}</span>
          </div>
          <hr class="">
          <mat-form-field *ngIf="probeList && probeList.length" appearance="fill" class="w-100">
            <mat-label>Choose Probe</mat-label>
            <mat-select name="agentRef" [(ngModel)]="agentRef.id" placeholder="Choose Probe To Copy" required>
              <mat-option value="">Select Probe</mat-option>
              <mat-option *ngFor="let agent of agentRef.agents| sortBy:'asc':'name';" [value]="agent?._id">{{agent?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
          <button mat-raised-button class="mat-primary mr-2"
                  (click)="copyToSelectProbe(agentRef)" [disabled]="!copyToProbeForm.form.valid || isLoading"
                  id="copys">Copy
          </button>
          <button mat-stroked-button class="mat-warn" (click)="closeCopyProbe();"
                  id="cancels">Cancel
          </button>
        </mat-card-footer>
      </mat-card>
    </mat-card>
  </form>
</s-modal>
