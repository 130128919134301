<div class="remedia-container">
  <mat-sidenav-container class="remedia-sidenav-container" (backdropClick)="close('backdrop')">
    <mat-sidenav-content class="h-100vh">
        <app-s-table aria-label="attackSurfaceJobs" role="table" [sTableOptions]="attackSurfaceTableOptions"
          (sortCallback)="attackSurfacesortCall($event)" (filterCallback)="attackSurfacefilterCall($event)"
          (actionCallback)="attackSurfaceactionCall($event)" (pageCallback)="attackSurfacepageCall($event)"
          (colFilterCallback)="attackSurfacecolFilterCall($event)" (refreshCallback)="getAttackSurfaceJobs()"
          (hyperlinkCallback)="attackSurfacelinkCall($event)" (timerCallback)="attackSurfacetimerCallData($event)"
          (globalActionCallback)="attackSurfaceglobalActionCall($event)">
        </app-s-table>
    </mat-sidenav-content>
    <mat-sidenav #inte1 [mode]="'over'" fixedTopGap="56" position="end" class="w-70p z-index-150">
      <mat-card class="w-100">
        <button class="position-absolute r-1p r-1p mt--10" mat-icon-button aria-label="close modal icon"
          matTooltip="Close" (click)="currentJob1 = undefined; inte1.close();"><mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4">Details View</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto" *ngIf="currentJob1 && currentJob1.job_data.message">
          <mat-card class="mat-elevation-z0 w-100">
            <mat-card-header>
              <mat-card-title>Message - {{currentJob1.job_data.message}}</mat-card-title>
              <mat-card-subtitle class="fw5">Status :
                <span class="ml-2 badge tbg-info text-primary py-2 px-2 fs1 fw4"
                  *ngIf="currentJob1.job_data.status_message === 'Initiated'">
                  <img src="/assets/images/loading.gif" class="imageWidth ml-0 mr-1" alt="Pending">
                  {{currentJob1.job_data.status_message}}
                </span>
                <span class="ml-2 badge tbg-info text-primary py-2 px-2 fs1 fw4"
                  *ngIf="currentJob1.job_data.status_message === 'Running'"> 
                  <img src="/assets/images/loading.gif" class="imageWidth ml-0 mr-1" alt="Running">
                  {{currentJob1.job_data.status_message}}</span>
                <span class="ml-2 badge tbg-success t-g py-2 px-2 fs1 fw4" *ngIf="currentJob1.job_data.status_message === 'Completed'">
                  <img src="/assets/images/auditLogins/tick.svg" class="imageWidth ml-0 mr-1" alt="Completed">
                  {{currentJob1.job_data.status_message}}</span>
                <span class="ml-2 badge tbg-error text-danger py-2 px-2 fs1 fw4"
                  *ngIf="currentJob1.job_data.status_message === 'Failed'">
                  <img src="/assets/images/auditLogins/cross.svg" class="imageWidth ml-0 mr-1" alt="Failed">
                  {{currentJob1.job_data.status_message}}</span>
                <span class="ml-2 badge tbg-error text-danger py-2 px-2 fs1 fw4"
                  *ngIf="currentJob1.job_data.status_message === 'Terminated'">
                  <img src="assets/images/auditLogins/alert.svg" class="imageWidth ml-0 mr-1" alt="Terminated">
                  {{currentJob1.job_data.status_message}}</span>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <!-- <ng-container *ngIf=" currentJob1?.job_data && currentJob1?.job_data.length"> -->
                <ng-container *ngIf="currentJob1?.job_data?.status_message === 'Completed'">
                <mat-card class="mat-elevation-z4 mb-2 w-100">
                  <mat-card-header>
                    <mat-card-title>Details</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <table class="rounded-corner table w-100 bor-1">
                      <thead>
                        <tr>
                          <th class="fs1 fw3">Domain</th>
                          <th class="fs1 fw3">IP Address</th>
                          <th class="fs1 fw3">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="fs1 fw3">{{ currentJob1?.job_data?.job_message?.attack_surface_message?.domain }}</td>
                          <td class="fs1 fw3">{{ currentJob1?.job_data?.job_message?.attack_surface_message?.ipaddress }}</td>
                          <td class="fs1 fw3">
                            <img src="/assets/images/auditLogins/cross.svg" class="imageWidth ml-0 mr-1" alt="Failed"
                                 *ngIf="currentJob1?.job_data?.job_message?.attack_surface_message?.status === false">
                            <img src="/assets/images/auditLogins/tick.svg" class="imageWidth ml-0 mr-1" alt="Completed"
                                 *ngIf="currentJob1?.job_data?.job_message?.attack_surface_message?.status === true">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>