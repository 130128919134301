import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from '../../_services/loader.service';
import { ModalService } from '../../_services/modal.service';
import { MyToastrService } from '../../_services/toastr.service';
import { JobsService } from '../../api/services/jobs.service';
import { Jobs } from '../../api/models/jobs';
import { MatSidenav } from '@angular/material/sidenav';
import { CompanySharedService } from '../../_services/company-shared.service';
import { BaseRequestService } from '../../_services/base.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { CommonService } from '../../_services/common.services';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  @ViewChild('snav', { static: true }) snav: MatSidenav;
  @Input() currentCompany: any;
  @Input() isExternal: any;
  @Input() hidePatchJob: any;
  permissions = false;
  cView: any = 0;
  jobTableOptions: any;
  currentJob: any;
  filterQuery: any;
  assetJobcurrentPage = 0;
  colFilterQuery: any;
  colFilterCols: any = [];
  selectedIndex = 0;
  showReportJob = false;

  hyperLink =
    'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?redirect_uri=https%3A%2F%2Fportal.azure.com%2Fsignin%2Findex%2F&response_type=code%20id_token&scope=https%3A%2F%2Fmanagement.core.windows.net%2F%2Fuser_impersonation%20openid%20email%20profile&state=OpenIdConnect.AuthenticationProperties%3DZq3Iw4IV2JKJ3y8gI6CawyIZqxNaUEv9JH8PP_eWEnTqxwZYLgkM65AYMMK_2j799f4qk_dfo1IbUEmlVgdNkTHUIpbZ3K5LmgwcUzyhz0ulwHdh44ZrMNrirueaXvCyk1-JSj9ud-c2D36eC39rFMMFK99qgLbYo_TtSYTLKzGG6BI1yRKL1UORh-B_4KOeAAitWr_-kRowNNnb8BFW7hAeGIDs2ulPaJDstyOpzrWoVIDr0yjIc1f6yda195Op30ZKHH-0YAPkWG470hvzYsqTwDumA3MQa63H6BpwbwxA32i1Vp2z8bIfc2cOfDsdJYvN-_86GKZrYeJPVCfVMrLSF1pDG3Q0MmwHTbq6EBf7od05ItHpEC8uunHdAUsLB3UvDEgJUm1z2BYut3qg6ZLQ_4hc6Y0jLfgivVs4lwKS_DSoEpnGQLZuE3jQjsyLyyiTgW2d1bsAmzWbm6a2n_xBj_nj0dwIEdMadTeHUVc&response_mode=form_post&nonce=638144832804677870.MzM3ZGNjM2EtNGMwZC00N2Q2LWExZGQtNTI5ZDE0OGZiOTJmZmRiNDhkNGUtNzdmNi00YmIxLTgzZjktMWFlY2Y3ZjAwODcy&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&site_id=501430&client-request-id=3acc35a4-2c96-49ea-aebc-98da29979049&x-client-SKU=ID_NET472&x-client-ver=6.27.0.0';

  azureJobTableOptions: any;
  azurecurrentJob: any;
  azurefilterQuery: any;
  azureJobcurrentPage = 0;
  azurecolFilterQuery: any;
  azurecolFilterCols: any = [];
  showNotes = false;
  showAuthNotes: any = undefined;
  isAzureAD = false;
  showAzureReAuth = false;

  constructor(
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    private comS: CommonService,
    private cs: CompanySharedService,
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private jobsService: JobsService
  ) {
    this.jobTableOptions = {
      columns: [
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
          dateFilter: true,
          filterData: { start: '', end: '' }
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isHyperlink: true,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          dateFilter: true,
          filterData: { start: '', end: '' }
        },
        {
          header: 'Agent Name',
          columnDef: 'agentRef.name',
          filter: '',
          cell: '(element: any) => `${element.agentRef.name}`',
          order: 3,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Job Status',
          columnDef: 'job_data.job_status',
          filter: '',
          cell: '(element: any) => `${element.job_data.job_status}`',
          order: 4,
          img: true,
          conditionList: [
            {
              _img: '/assets/images/loading.gif',
              value: 1,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 2,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 3,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/tick.svg',
              value: 5,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/cross.svg',
              value: 6,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/alert.svg',
              value: 4,
              class: 'imageWidth',
            },
          ],
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Running', value: 3 },
            { name: 'Success', value: 5 },
            { name: 'Partial', value: 4 },
            { name: 'Failed', value: 6 },
          ],
        },

        {
          header: 'JOB ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Task',
          columnDef: 'job_data.task',
          filter: '',
          cell: '(element: any) => `${element.job_data.task}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Active Directory Scan', value: 'Active Directory Scan' },
            { name: 'Asset Inventory Scan', value: 'Asset Inventory Scan' },
            { name: 'Firewall Scan', value: 'Firewall Scan' },
            { name: 'Snmp Scan', value: 'Snmp Scan' },
            { name: 'PII Scan', value: 'PII Scan' },
          ],
        },
        {
          header: 'Time Elapsed',
          columnDef: 'timeConsumed',
          filter: '',
          cell: '(element: any) => `${element.timeConsumed}`',
          order: 7,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Status',
          columnDef: 'job_data.status_message',
          filter: 'html',
          cell: '(element: any) => `${element.job_data.status_message}`',
          order: 8,
          visible: true,
          isHtml: true,
          isHyperlink: false,
          isSort: false,
        },
        {
          header: 'Scheduler Name',
          columnDef: 'sch_name',
          filter: '',
          cell: '(element: any) => `${element.sch_name}`',
          order: 9,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: true,
        },
      ],
      title: 'Installed Programs',
      cFilter: true,
      faClass: 'Jobs',
      sortOptions: { active: 'u', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'jobTableOptions',
        title: 'Jobs',
        isServerSide: true,
        selectText: 'job(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        isActionCondition: true,
        actionConditons: { key: 'job_data.job_status' },
        actionConditionsList:{
          '1': [
            {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
            {text: 'Terminate',icon: 'stop_circle',callback: 'detailFn',isGlobal: true,},
          ],
          '2': [
            {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
            {text: 'Terminate',icon: 'stop_circle',callback: 'detailFn',isGlobal: true,},
          ],
          '3': [
            {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
            {text: 'Terminate',icon: 'stop_circle',callback: 'detailFn',isGlobal: true,},
          ],
          '4': [
            {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
            {text: 'Download Report',icon: 'cloud_download', callback: 'downloadFn',isGlobal: false},
          ],
          '5': [
            {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
            {text: 'Download Report',icon: 'cloud_download', callback: 'downloadFn',isGlobal: false},
          ],
          '6': [
            {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
            { text: 'Retry', icon: 'refresh',callback: 'retryFn',isGlobal: false},
          ],
        },
        actionMenuItems: [
          {text: 'Details',icon: 'info',callback: 'detailFn',isGlobal: false},
          {text: 'Terminate',icon: 'stop_circle',callback: 'detailFn',isGlobal: true,},
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        selectedTimer: "60",
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        saveData: true,
        columnSearch: false,
        compareData: false,
        hideDownload: true,
      },
    };

    this.azureJobTableOptions = {
      columns: [
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 1,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: false,
          dateFilter: true,
          filterData: { start: '', end: '' }
        },
        {
          header: 'Updated',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isHyperlink: true,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          dateFilter: true,
          filterData: { start: '', end: '' }
        },
        {
          header: 'Job Status',
          columnDef: 'job_data.job_status',
          filter: '',
          cell: '(element: any) => `${element.job_data.job_status}`',
          order: 4,
          img: true,
          conditionList: [
            {
              _img: '/assets/images/loading.gif',
              value: 1,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 2,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 3,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/tick.svg',
              value: 5,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/cross.svg',
              value: 6,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/alert.svg',
              value: 4,
              class: 'imageWidth',
            },
          ],
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Running', value: 3 },
            { name: 'Success', value: 5 },
            { name: 'Partial', value: 4 },
            { name: 'Failed', value: 6 },
          ],
        },

        {
          header: 'JOB ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Task',
          columnDef: 'job_data.task',
          filter: '',
          cell: '(element: any) => `${element.job_data.task}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
        },
        {
          header: 'Time Elapsed',
          columnDef: 'timeConsumed',
          filter: '',
          cell: '(element: any) => `${element.timeConsumed}`',
          order: 7,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: false,
        },
        {
          header: 'Status',
          columnDef: 'job_data.status_message',
          filter: 'html',
          cell: '(element: any) => `${element.job_data.status_message}`',
          order: 8,
          visible: true,
          isHtml: true,
          isHyperlink: false,
          isSort: false,
        },
      ],
      title: 'AzureAD',
      cFilter: true,
      faClass: 'Jobs',
      sortOptions: { active: 'u', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'azureTableOptions',
        title: 'Azure Active Directory Jobs',
        isServerSide: true,
        selectText: 'job(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Details',
            icon: 'info',
            callback: 'detailFn',
            isGlobal: false,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        selectedTimer: "60",
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        azureAdAuthenticate: true,
        saveData: false,
        columnSearch: false,
        compareData: false,
        hideDownload: true,
      },
    };
  }

  close(status?: any): any {
    if (status) {
      return;
    }
    this.snav.close();
  }

  ngOnInit(): void {
    this.jobTableOptions.pageData = [];
    this.jobTableOptions.tableOptions.isActionCondition = false;
    this.getJobs();
    this.checkAzureADIntegration();
  }

  checkAzureADIntegration(): any {
    if (!this.currentCompany || !this.currentCompany._id) {
      setTimeout(() => this.checkAzureADIntegration(), 1000);
      return;
    }
    this.isAzureAD = false;
    this.loaderService.display(true, 'Checking Azure AD...');
    this.baseService
      .doRequest(`/api/integrations/getcompanymappings`, 'post', {
        companyid: this.currentCompany._id,
      })
      .subscribe((r: any) => {
        this.loaderService.display(false);
        const aad = r.filter((x: any) => (x.integrationName === 'AzureAD' || x.integrationName === 'AzureADBeta' || x.integrationName === 'AzureADCSP'));
        const authcre = r.filter((x: any) => x.integrationName === 'AzureAD');
        if(authcre && authcre.length) { this.showAzureReAuth = true}
        else { this.showAzureReAuth = false}
        if (aad && aad.length) {
          aad.forEach((obj: any) => {
            if (obj.sourceCompanyId === this.currentCompany._id) {
              this.isAzureAD = true;
              return;
            }
          });
        }
      });
  }
  azureADReauthentication(type: any): void {
    const failedJob = this.azureJobTableOptions.pageData[0];
    this.showAuthNotes = failedJob.job_data.authentication_url ? true : false;
    if (type === 'popup') {
      if (failedJob && failedJob.job_data.job_status === 6) {
        this.modalService.open('jobAzureCredential');
        this.cView = 0;
        this.permissions = false;
      }
    } else {
      if (failedJob && failedJob.job_data.job_status === 6) {
        const url: any = failedJob.job_data.authentication_url
          ? failedJob.job_data.authentication_url
          : failedJob.job_data.consent_url;
        const rToken = uuidv4();
        const stateVar = btoa(
          `${window.location.host}@${rToken}@${failedJob.job_data.client_id}`
        ).replace(/=/g, '');
        const urlCSP = `${url}&state=${stateVar}`;
        const data = {
          name: failedJob.job_data.name,
          isCsp: failedJob.job_data.iscsp,
        };
        this.baseService.o365window = window.open(
          urlCSP,
          'popup',
          'width=600,height=600'
        );
        this.modalService.close('jobAzureCredential');
        localStorage.removeItem('oAuthC');
        localStorage.setItem('ncsp', JSON.stringify(data));
        this.checkIsOAuthCode();
        this.showAuthNotes = undefined;
      }
    }
  }

  checkIsOAuthCode(): void {
    const oAuthC = localStorage.getItem('oAuthC');
    const failedJob = this.azureJobTableOptions.pageData[0];
    if (oAuthC) {
      let ncsp: any;
      try {
        ncsp = JSON.parse(localStorage.getItem('ncsp') as string);
        console.log(ncsp);
      } catch (e) {
        console.log('Error parsing AD config');
      }
      if (failedJob.job_data.consent_url) {
        this.toast.sToast('success', 'Azure AD Consent added successfully, please click on Verify If Re-authenticated.');
        return;
      }
      const cred: any = { credential: { ...ncsp } };
      cred.credential.code = oAuthC;
      const data: any = { ...cred };
      data.integrationName = 'AzureAD';
      this.saveAAD(data);
      localStorage.removeItem('oAuthC');
      localStorage.removeItem('ncsp');
      return;
    }
    setTimeout(() => {
      this.checkIsOAuthCode();
    }, 1000);
  }

  verifyReauthenticate(): void {
    this.loaderService.display(true);
    const reqData = { companyId: this.currentCompany._id };
    this.baseService.doRequest(`/api/AzureadError/azureadrefresh`, 'post', reqData).subscribe((obj: any) => { 
      this.loaderService.display(false);
      if(obj[0]){
        this.toast.sToast('success', 'Process initiated successfully.');
      } else {
        this.toast.sToast('error', obj[1]);
      }
    });;
  }

  saveAAD(event: any): void {
    if (!event.credential) {
      this.azureADReauthentication('nonpop');
      return;
    }
    let data: any;
    data = {
      integrationName: `AzureAD`,
      credential: event.credential,
    };
    if (event.credential.access_token) {
      data = event;
    }
    this.loaderService.display(true, 'Saving credential...');
    this.baseService
      .doRequest(`/api/integrations/storecredentials`, 'post', data)
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast('success', 'Azure AD Re-authenticated successfully, please click on Verify If Re-authenticated.');
        } else {
          this.toast.sToast('error', result.errmsg);
        }
      });
  }
  tabFun(event: any): void {
    this.colFilterQuery = [];
    this.jobTableOptions.tableOptions.showAction = true;
    this.jobTableOptions.tableOptions.rowSelection = true;
    this.jobTableOptions.columns[1].isHyperlink = true;
    this.jobTableOptions.columns[5].iscolumnSearch = true;
    this.jobTableOptions.tableOptions.actionMenuItems = [
      { text: 'Details', icon: 'info', callback: 'detailFn', isGlobal: false },
      {
        text: 'Terminate',
        icon: 'stop_circle',
        callback: 'detailFn',
        isGlobal: true,
      },
    ];
    if (event.index === 0) {
      this.jobTableOptions.tableOptions.isActionCondition = false;
      this.jobTableOptions.columns.push({
        header: 'Scheduler Name',
        columnDef: 'sch_name',
        filter: '',
        cell: '(element: any) => `${element.sch_name}`',
        order: 9,
        visible: true,
        isToolTip: false,
        isToolTipCol: '',
        hasMultiData: false,
        class: '',
        color: '',
        isProgressCntrl: false,
        isColoredCntrl: false,
        colList: [],
        isfaicon: false,
        isAddingText: false,
        addingText: '',
        img: false,
        isSort: false,
        iscolumnSearch: true,
      });
      this.jobTableOptions.tableOptions.actionMenuItems = [
        {
          text: 'Details',
          icon: 'info',
          callback: 'detailFn',
          isGlobal: false,
        },
        {
          text: 'Terminate',
          icon: 'stop_circle',
          callback: 'detailFn',
          isGlobal: true,
        },
      ];
      this.jobTableOptions.columns[2].visible = true;
      this.jobTableOptions.columns[5].iscolumnSearch = true;
      this.showReportJob = false;
      this.getJobs();
    }
    if (event.index === 1) {
      this.jobTableOptions.tableOptions.isActionCondition = true;
      /*this.jobTableOptions.tableOptions.actionMenuItems = [
        {
          text: 'Details',
          icon: 'info',
          callback: 'detailFn',
          isGlobal: false,
        },
        {
          text: 'Terminate',
          icon: 'stop_circle',
          callback: 'detailFn',
          isGlobal: true,
        },
        {
          text: 'Retry',
          icon: 'refresh',
          callback: 'retryFn',
          isGlobal: false,
        },
        {
          text: 'Download Report',
          icon: 'cloud_download',
          callback: 'downloadFn',
          isGlobal: false,
        },
      ];*/
      this.jobTableOptions.columns.pop(9);
      this.jobTableOptions.tableOptions.rowSelection = false;
      this.jobTableOptions.columns[2].visible = false;
      this.jobTableOptions.columns[1].isHyperlink = false;
      this.jobTableOptions.columns[5].iscolumnSearch = false;
      this.showReportJob = true;
      this.getJobs();
    }
    if (event.index === 4) {
      this.azureJobTableOptions.pageData = [];
      this.getAzureJobs();
    }
  }

  jobtimerCallData(idata: any): void {
    this.getJobs();
  }

  adJobstimerCallData(idata: any): void {
    this.getAzureJobs();
  }
  jobsglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Terminate') {
      this.terminateJobs(idata);
    }
  }

  jobsshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.jobTableOptions);
    this.jobTableOptions = {};
    this.jobTableOptions = data;
    this.jobTableOptions.tableOptions.loading = status;
  }

  jobslinkCall(idata: any): void {
    if (idata.col === 'u') {
      this.currentJob = undefined;
      setTimeout(() => {
        this.currentJob = idata.row;
        this.showDetails();
      });
    }
  }

  jobsactionCall(idata: any): void {
    if (idata.action.text === 'Details') {
      this.currentJob = undefined;
      setTimeout(() => {
        this.currentJob = idata.row;
        this.showDetails();
      });
    } else if (idata.action.text === 'Terminate') {
      if (idata.row.job_data.job_status < 4) {
        this.terminateJob(idata.row);
      } else {
        this.toast.sToast('error', 'This job cannot be terminated');
      }
    } else if (idata.action.text === 'Retry') {
      if (idata.row.job_data.report_retries) {
        if (
          idata.row.job_data.report_retries <= 5 &&
          idata.row.job_data.job_status === 6
        ) {
          this.retryJob(idata.row);
        } else {
          if (
            idata.row.job_data.job_status !== 5 &&
            idata.row.job_data.job_status !== 6
          ) {
            this.toast.sToast('error', 'Please Retry if job fails');
          } else if (idata.row.job_data.job_status === 5) {
            this.toast.sToast(
              'error',
              ' Retry job is not allowed for successful job'
            );
          }
        }
      } else if (idata.row.job_data.job_status === 6) {
        this.retryJob(idata.row);
      } else {
        if (
          idata.row.job_data.job_status !== 5 &&
          idata.row.job_data.job_status !== 6
        ) {
          this.toast.sToast('error', 'Please Retry if job fails');
        } else if (idata.row.job_data.job_status === 5) {
          this.toast.sToast(
            'error',
            ' Retry job is not allowed for successful job'
          );
        }
      }
    } else if (idata.action.text === 'Download Report') {
      this.reportDownload(idata.row);
    }
  }

  reportDownload(idata: any): void {
    this.loaderService.display(true, 'Report Download...');
    this.loaderService.display(false);
    const reqData: any = {
      jobid: idata._id,
    };
    this.baseService
      .doRequest('/api/standardreports/downloadStandardReport', 'post', reqData)
      .subscribe((res: any) => {
        if (res[0]) {
          window.open(`${res[1]}`, '_blank');
        } else {
          this.toast.sToast('error', res[1]);
        }
      },(error: any) => {
        if (error.ok === false) {
          if (error.status === 403) {
            this.toast.sToast('error', 'Your role does not permit you to download the reports. Please contact your administrator to get the relevant permissions.');
          }
        }
      });
  }
  terminateJobs(idata: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to terminate the selected job entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        idata.row.forEach((obj: any, index: number) => {
          if (obj.job_data.job_status < 4) {
            this.loaderService.display(
              true,
              `Terminating ${obj.job_data.task} job....`
            );
            this.baseService
              .doRequest(`api/jobs/${obj._id}/terminatejob`, 'post', {
                jobid: obj._id,
              })
              .subscribe((result: any) => {
                this.loaderService.display(false);
                if (index === idata.row.length - 1) {
                  this.loaderService.display(false);
                  this.toast.sToast('success', result[1]);
                  setTimeout(() => {
                    this.getJobs();
                  }, 2000);
                }
              });
          } else {
            this.loaderService.display(false);
            this.toast.sToast(
              'error',
              `${obj.job_data.task} job cannot be terminated`
            );
            if (index === idata.row.length - 1) {
              setTimeout(() => {
                this.getJobs();
              }, 2000);
            }
          }
        });
      }
    });
  }

  terminateJob(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to terminate this ${idata.job_data.task} job ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        this.baseService
          .doRequest(`api/jobs/${idata._id}/terminatejob`, 'post', {
            jobid: idata._id,
          })
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  jobsfilterCall(event: any): void {
    const fields: any = [];
    this.jobTableOptions.columns.forEach((obj: any) => {
      fields.push(obj.columnDef);
    });
    this.filterQuery =
      event && event.length > 0
        ? {
            multi_match: {
              query: event,
              type: 'phrase_prefix',
              fields: [
                'job_data.task',
                'job_data.status_message',
                'agentRef.name',
              ],
            },
          }
        : undefined;
    if (this.comS.isUuid(event)) {
      this.filterQuery = { filter: [{ ids: { values: [event] } }] };
    }
    this.getJobs();
  }

  jobssortCall(event: any): void {
    this.jobTableOptions.sortOptions = event;
    this.getJobs();
  }

  jobspageCall(event: any): void {
    this.jobTableOptions.tableOptions.pageSize = event.pageSize;
    this.assetJobcurrentPage = event.pageIndex;
    this.getJobs();
  }

  colFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') {
        this.colFilterCols.push(event);
      }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      const dateData = this.jobTableOptions.columns.filter((x: any) => x.columnDef === obj.col)[0];
        if (!dateData.dateFilter) {
        let qval: any;
        const char = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"g, '\\$1'])/;
        const searchValue =
          typeof obj.value === 'string' ? obj.value.trim() : obj.value;
        if (obj.col === 'job_data.job_status') {
          qval =
            char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
              ? `\"${searchValue}\"`
              : '' + searchValue + '';
        } else {
          qval =
            char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
              ? `\"${searchValue}\"`
              : '*' + searchValue + '*';
        }
        const tmpObj = {
          bool: {
            should: [{ query_string: { fields: [obj.col], query: qval } }],
          },
        };
        this.colFilterQuery.push(tmpObj);
      } else {
        const tmpObj = { bool: { must: [{ range: {} }] } };
        console.log(this.comS.getLastDay(0, dateData.filterData.end));
        // @ts-ignore
        tmpObj.bool.must[0].range[obj.col] = {
          'gte': new Date(dateData.filterData.start).toLocaleDateString('en-CA') + 'T00:00:00',
          'lte': new Date(dateData.filterData.end).toLocaleDateString('en-CA') + 'T23:59:00'
        };
        this.colFilterQuery.push(tmpObj);
      }
      
    });
    this.getJobs();
  }

  totalCallbackCheck($event: any): void {
    // @ts-ignore
    if (this[$event.id]) {
      // @ts-ignore
      this[$event.id].hideTable = $event.value === 0;
    } else {
      console.log($event.id + ' not available');
    }
  }

  showDetails(): void {
    this.loaderService.display(true, 'Getting job details...');
    this.jobsService
      .getApiJobsIdGet({ id: this.currentJob._id })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        this.currentJob = undefined;
        setTimeout(() => {
          this.currentJob = result;
          if (
            this.currentJob &&
            this.currentJob.job_data &&
            (this.currentJob.job_data.job_message ||
              this.currentJob.job_data.report_job_message)
          ) {
            this.cs.jobsUpdateEVE.next(this.currentJob);
            this.snav.open();
          } else {
            this.toast.sToast('error', 'Sorry details not available.');
          }
        });
      });
  }

  retryJob(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to retry this job?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true);
        this.baseService
          .doRequest(`api/standardreports/retryReportScheduler`, 'post', {
            jobid: idata._id,
          })
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  getJobs(): void {
    this.jobsshowHideLoading(true);
    const query: any = {
      query: {
        bool: {
          must: [
            {
              match: { 'companyRef.id.keyword': `${this.currentCompany._id}` },
            },
            { exists: { field: 'job_data' } },
          ],
        },
      },
    };
    if (this.isExternal) {
      query.query.bool.must.push({
        match: { 'job_data.task.keyword': `External Scan` },
      });
      query.query.bool.must_not = [
        { match: { 'job_data.task.keyword': `Standard Reports` } },
        { match: { 'job_data.task.keyword': `Offline Vulnerability Scan` } },
        { match: { 'job_data.task.keyword': `Azure AD` } },
        { match: { 'job_data.task.keyword': `Windows EventLogs` } },
        { match: { 'job_data.task.keyword': `Attack Surface Mapper` } },
      ];
    }
    if (this.showReportJob) {
      query.query.bool.must.push({
        match: { 'job_data.task.keyword': `Report Scheduler` },
      });
      query.query.bool.must_not = [
        { match: { 'job_data.task.keyword': `Standard Reports` } },
        { match: { 'job_data.task.keyword': `Offline Vulnerability Scan` } },
        { match: { 'job_data.task.keyword': `Azure AD` } },
        { match: { 'job_data.task.keyword': `Windows EventLogs` } },
        { match: { 'job_data.task.keyword': `Attack Surface Mapper` } },
      ];
    } else {
      query.query.bool.must_not = [
        { match: { 'job_data.task.keyword': `Report Scheduler` } },
        { match: { 'job_data.task.keyword': `Standard Reports` } },
        { match: { 'job_data.task.keyword': `Offline Vulnerability Scan` } },
        { match: { 'job_data.task.keyword': `Azure AD` } },
        { match: { 'job_data.task.keyword': `Windows EventLogs` } },
        { match: { 'job_data.task.keyword': `Attack Surface Mapper` } },
      ];
    }
    if (this.currentCompany && this.currentCompany._id) {
      this.jobTableOptions.serverSide = {
        service: 'jobsService',
        fn: 'getAllApiJobsGet',
        q: query,
      };
      if (this.filterQuery && this.filterQuery.multi_match) {
        query.query.bool.must.push(this.filterQuery);
      }
      if (this.filterQuery && this.filterQuery.filter) {
        query.query.bool.filter = this.filterQuery.filter;
      }

      if (this.colFilterQuery && this.colFilterQuery.length) {
        query.query.bool.filter = [];
        this.colFilterQuery.forEach((obj: any) => {
          if (obj.bool && obj.bool.should && obj.bool.should[0].match) {
            query.query.bool.must.push(obj);
          } else {
            query.query.bool.filter.push(obj);
          }
        });
      }

      const q = JSON.stringify(query);
      const skip = this.assetJobcurrentPage;
      const limit = this.jobTableOptions.tableOptions.pageSize;
      let sort: any = [{}];
      if (
        this.jobTableOptions.sortOptions &&
        this.jobTableOptions.sortOptions.direction &&
        this.jobTableOptions.sortOptions.direction !== ''
      ) {
        const orderArr = [
          'job_data.task',
          'agentRef.name',
          'job_data.status_message',
        ];
        if (orderArr.indexOf(this.jobTableOptions.sortOptions.active) > -1) {
          sort[0][this.jobTableOptions.sortOptions.active + '.keyword'] = {
            order: this.jobTableOptions.sortOptions.direction,
          };
        } else {
          sort[0][this.jobTableOptions.sortOptions.active] = {
            order: this.jobTableOptions.sortOptions.direction,
          };
        }
      }
      sort = JSON.stringify(sort);
      const fields = JSON.stringify([
        'c',
        'u',
        'job_data.job_status',
        '_id',
        'job_data.task',
        'timeConsumed',
        'job_data.status_message',
        'agentRef.name',
        'task_id',
      ]);
      // @ts-ignore
      this.jobsService
        .getAllApiJobsGet({ q, skip, limit, sort, fields })
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result && result.data.length) {
            result.data.forEach((item: any) => {
              if (!item?.agentRef || item.agentRef === null) {
                item.agentRef = { name: '-', id: '-' };
              }
              if (!item?.sch_name || item.sch_name === null) {
                item.sch_name = '-';
              }
            });
            this.jobTableOptions.pageData = result.data;
            this.jobTableOptions.tableOptions.pageTotal = result.total;
            this.jobsshowHideLoading(false);
          } else {
            this.jobTableOptions.pageData = [];
            this.jobTableOptions.tableOptions.pageTotal = 0;
            this.jobsshowHideLoading(false);
          }
        });
    } else {
      setTimeout(() => {
        this.getJobs();
      }, 1000);
    }
  }

  getAzureJobs(): void {
    this.jobsshowHideLoading(true);
    const query: any = {
      query: {
        bool: {
          must: [
            {
              match: { 'companyRef.id.keyword': `${this.currentCompany._id}` },
            },
            { exists: { field: 'job_data' } },
            { match: { 'job_data.task.keyword': `Azure AD` } },
          ],
        },
      },
    };
    if (this.currentCompany && this.currentCompany._id) {
      this.azureJobTableOptions.serverSide = {
        service: 'jobsService',
        fn: 'getAllApiJobsGet',
        q: query,
      };
      if (this.azurefilterQuery && this.azurefilterQuery.multi_match) {
        query.query.bool.must.push(this.azurefilterQuery);
      }
      if (this.azurefilterQuery && this.azurefilterQuery.filter) {
        query.query.bool.filter = this.azurefilterQuery.filter;
      }

      if (this.azurecolFilterQuery && this.azurecolFilterQuery.length) {
        query.query.bool.filter = [];
        this.azurecolFilterQuery.forEach((obj: any) => {
          if (obj.bool.should[0].match) {
            query.query.bool.must.push(obj);
          } else {
            query.query.bool.filter.push(obj);
          }
        });
      }

      const q = JSON.stringify(query);
      const skip = this.azureJobcurrentPage;
      const limit = this.azureJobTableOptions.tableOptions.pageSize;
      let sort: any = [{}];
      if (
        this.azureJobTableOptions.sortOptions &&
        this.azureJobTableOptions.sortOptions.direction &&
        this.azureJobTableOptions.sortOptions.direction !== ''
      ) {
        const orderArr = [
          'job_data.task',
          'agentRef.name',
          'job_data.status_message',
        ];
        if (
          orderArr.indexOf(this.azureJobTableOptions.sortOptions.active) > -1
        ) {
          sort[0][this.azureJobTableOptions.sortOptions.active + '.keyword'] = {
            order: this.azureJobTableOptions.sortOptions.direction,
          };
        } else {
          sort[0][this.azureJobTableOptions.sortOptions.active] = {
            order: this.azureJobTableOptions.sortOptions.direction,
          };
        }
      }
      sort = JSON.stringify(sort);
      const fields = JSON.stringify([
        'c',
        'u',
        'job_data.job_status',
        '_id',
        'job_data.task',
        'timeConsumed',
        'job_data.status_message',
        'agentRef.name',
        'task_id',
      ]);
      // @ts-ignore
      this.jobsService
        .getAllApiJobsGet({ q, skip, limit, sort })
        .subscribe((result: any) => {
          this.loaderService.display(false);
          if (result && result.data.length) {
            const failedJob = result.data[0];
            if (failedJob && failedJob.job_data.job_status === 6) {
              this.showNotes = true;
            } else {
              this.showNotes = false;
            }
            result.data.forEach((item: any) => {
              if (!item?.agentRef || item.agentRef === null) {
                item.agentRef = { name: '-', id: '-' };
              }
              if (!item?.sch_name || item.sch_name === null) {
                item.sch_name = '-';
              }
            });
            this.azureJobTableOptions.pageData = result.data;
            this.azureJobTableOptions.tableOptions.pageTotal = result.total;
            this.azureshowHideLoading(false);
          } else {
            this.azureJobTableOptions.pageData = [];
            this.azureJobTableOptions.tableOptions.pageTotal = 0;
            this.azureshowHideLoading(false);
          }
        });
    } else {
      setTimeout(() => {
        this.getAzureJobs();
      }, 1000);
    }
  }

  azureJobglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Terminate') {
      this.terminateJobs(idata);
    }
  }

  azureshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.azureJobTableOptions);
    this.azureJobTableOptions = {};
    this.azureJobTableOptions = data;
    this.azureJobTableOptions.tableOptions.loading = status;
  }

  azureJobactionCall(idata: any): void {
    if (idata.action.text === 'Terminate') {
      if (idata.row.job_data.job_status < 4) {
        this.terminateJob(idata.row);
      } else {
        this.toast.sToast('error', 'This job cannot be terminated');
      }
    }
    if (idata.action.text === 'Details') {
      this.currentJob = undefined;
      setTimeout(() => {
        this.currentJob = idata.row;
        this.snav.open();
      });
    }
  }

  azureJobfilterCall(event: any): void {
    const fields: any = [];
    this.azureJobTableOptions.columns.forEach((obj: any) => {
      fields.push(obj.columnDef);
    });
    this.filterQuery =
      event && event.length > 0
        ? {
            multi_match: {
              query: event,
              type: 'phrase_prefix',
              fields: ['job_data.task', 'job_data.status_message'],
            },
          }
        : undefined;
    if (this.comS.isUuid(event)) {
      this.filterQuery = { filter: [{ ids: { values: [event] } }] };
    }
    this.getAzureJobs();
  }

  azureJobsortCall(event: any): void {
    this.azureJobTableOptions.sortOptions = event;
    this.getAzureJobs();
  }

  azureJobpageCall(event: any): void {
    this.azureJobTableOptions.tableOptions.pageSize = event.pageSize;
    this.azureJobcurrentPage = event.pageIndex;
    this.getAzureJobs();
  }

  azureJoblinkCall(idata: any): void {
    if (idata.col === 'u') {
      this.currentJob = undefined;
      setTimeout(() => {
        this.currentJob = idata.row;
        this.snav.open();
      });
    }
  }

  azurecolFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') {
        this.colFilterCols.push(event);
      }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      let qval: any;
      const char = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"g, '\\$1'])/;
      const searchValue =
        typeof obj.value === 'string' ? obj.value.trim() : obj.value;
      if (obj.col === 'job_data.job_status') {
        qval =
          char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
            ? `\"${searchValue}\"`
            : '' + searchValue + '';
      } else {
        qval =
          char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version'
            ? `\"${searchValue}\"`
            : '*' + searchValue + '*';
      }
      const tmpObj = {
        bool: {
          should: [{ query_string: { fields: [obj.col], query: qval } }],
        },
      };
      this.colFilterQuery.push(tmpObj);
    });
    this.getAzureJobs();
  }
}
