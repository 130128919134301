import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {MyToastrService} from '../../_services/toastr.service';
import {CompanyService} from '../../api/services/company.service';
import {CompanySharedService} from '../../_services/company-shared.service';
import { BaseRequestService } from 'src/app/_services/base.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-standard-reports',
  templateUrl: './standard-reports.component.html',
  styleUrls: ['./standard-reports.component.scss']
})
export class StandardReportsComponent implements OnInit {
  @ViewChild('search') searchInput: ElementRef;
  @Input() currentCompany: any;
  allReports: any = [];
  isDateRangeValid = true;
  minToDate = new Date();
  datefilter:any ={};
  tmpJobs: any = {};
  removable = true;
  selectable = true;
  noFilters = false;
  masterReports: any = [];
  operators: any = [{
    key: '=',
    title: 'equals',
    tag: ''
  },
  {
    key: '!=',
    title: 'notEquals',
    tag: ''
  },
  {
    key: ':',
    title: 'contains',
    tag: ''
  },
  {
    key: '!:',
    title: 'doesNotContain',
    tag: ''
  }

  ];
  Objectkeys = Object.keys;
  tagsList: any = ['one', 'two'];
  selectedFilter: any = '';
  filteredList: any =  [];
  companyTags: any = {};
  fromDate = new FormControl();
  toDate = new FormControl();
  maxDate = new Date()
  searchFilter = '';
  reportFilters: any = '';
  showSearch = true;
  showList = 0;
  filterRequest: any = {};
  @ViewChild('auto', {static: false}) auto!: ElementRef;
  constructor( private renderer: Renderer2,
               private loaderService: LoaderService, private toast: MyToastrService, public baseService: BaseRequestService,
               private companyService: CompanyService, private cs: CompanySharedService,
  ) {
    cs.reportFilterUpdate.subscribe((value: any) => {
      this.searchFilter = value;
    });
  }

  ngOnInit(): void {
    this.getReportList();
    this.getReportFilters();
  }

  handleChangeFilter(data: any): void {
    if (!data.target?.value.length && this.showList === 0){ this.showList = 1; this.showSearch = false; }
    if (data.target?.value.length === 0 && this.showList === 0) { this.showList = 0; this.showSearch = true; }
  }

  handledFilterKey(item: any): void {
    this.reportFilters = item;
    this.selectedFilter = item;
    this.showList = 2;
  }

  handleChangeOperator(oper: any): void {
    if (oper === ':' || oper === '!:'){
      this.showList = 4;
      setTimeout(() => this.renderer.selectRootElement('#search').focus(), 0);
    } else {
      this.tagsList = this.companyTags[this.reportFilters];
      this.showList = 3;
    }
    this.reportFilters = `${this.reportFilters}${oper}`;
  }

  handleClickValue(val?: any): void {
    if (val){
      this.filteredList.push(`${this.reportFilters}${val}`);
    } else {
      const data = this.reportFilters.indexOf('!:') > -1 ? this.reportFilters.split('!:') : this.reportFilters.split(':');
      if (!data[1] || data[1] === '' || data[1] === ' ' || data[1] === '  '){
        this.toast.sToast('error', 'Please enter a value');
        return;
      }else {
        this.filteredList.push(`${this.reportFilters}`);
      }

    }

    this.reportFilters = '';
    this.showList = 0;
    this.showSearch = true;
  }

  removeFilter(filter: any): void{
    const index = this.filteredList.indexOf(filter);
    if (index >= 0) {
      this.filteredList.splice(index, 1);
    }
  }

  clearDateFilters() {
    this.filteredList = [];
    this.fromDate.reset();
    this.toDate.reset();
    this.datefilter = '';
    this.isDateRangeValid = true;
  }

  getReportFilters(): void {
    this.baseService.doRequest('/api/standardreports/getReportFilters',
      'post', {companyid: [this.currentCompany._id]}).subscribe((res: any) => {
      if (res ) {
        if (res) { this.companyTags = res;  this.noFilters = false; }
        else {this.noFilters = true; }
      }
    });
  }

  getReportList(): void {
    this.loaderService.display(true, 'Getting reports list');
    this.companyService.companygetReportListApiCompanyIdGetReportListPost({
      id: this.currentCompany._id, body: { isGlobal: false}
    }).subscribe((result: any) => {
      this.loaderService.display(false);
      result.sort((a: any, b: any) => {
        const c = a.Section;
        const d = b.Section;
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
      result.forEach((obj: any) => {
        obj.Reports.sort((a: any, b: any) => {
          const c = a.title;
          const d = b.title;
          if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
        });
      });
      this.masterReports = result;
      this.allReports = result;
    });
  }

  downloadReport(report: any, title: any): void {
    const filteredTags: any = {
      equals: {},
      contains: {},
      notEquals: {},
      doesNotContain: {}
    };
    this.filteredList.map((list: any) => {
      let data: any = [];
      if (list.indexOf('!=') > -1) {
        data = list.split('!=');
        if (!filteredTags.notEquals[data[0]]){ filteredTags.notEquals[data[0]] = []; }
        filteredTags.notEquals[data[0]].push(data[1]);
      } else if (list.indexOf('!:') > -1) {
        data = list.split('!:');
        if (!filteredTags.doesNotContain[data[0]]){ filteredTags.doesNotContain[data[0]] = []; }
        filteredTags.doesNotContain[data[0]].push(data[1]);
      } else if (list.indexOf('=') > -1) {
        data = list.split('=');
        if (!filteredTags.equals[data[0]]){ filteredTags.equals[data[0]] = []; }
        filteredTags.equals[data[0]].push(data[1]);
      } else if (list.indexOf(':') > -1) {
        data = list.split(':');
        if (!filteredTags.contains[data[0]]){ filteredTags.contains[data[0]] = []; }
        filteredTags.contains[data[0]].push(data[1]);
      }
    });
    this.loaderService.display(true, 'Generating report... ');

    const resData: any = {};
    resData.companyid = this.currentCompany._id;
    resData.reportid = report.id;
    resData.report_type = report.reportType;
    resData.report_name = title;
    (this.filteredList.length) ? resData.filters = JSON.stringify(filteredTags) : '';
    if (Object.keys(this.datefilter).length > 0) {
      resData.datefilter = this.datefilter;
    }else {
      resData.datefilter = {
        from_date: "",
        end_date: ""
      };
    }
    this.companyService.companygenerateReportApiCompanyIdGenerateReportPost(
      {
        id: this.currentCompany._id, body: resData
      }).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]){
        // window.open(`${result[1]}`, '_blank');
        setTimeout(() => this.getJobStatus(report, result), 3000);
      } else {
        this.toast.sToast('error', result[1]);
      }
    },(error: any) => {
      if (error.ok === false) {
        if (error.status === 403) {
          this.toast.sToast('error', 'Your role does not permit you to download the reports. Please contact your administrator to get the relevant permissions.');
        }else if (error.status === 429) {
          this.toast.sToast('error', 'You have exceeded the request limit. Please try again later.');
        }
      this.loaderService.display(false);
      }
    });
  }

  getJobStatus(report: any, result: any): void {
    try {
        this.baseService.doRequest(`/api/jobs/${result[1]}`, 'get').subscribe((res: any) => {
          if (res && res._id === result[1] && res.job_data && res.job_data.job_status < 4) {
            this.tmpJobs[report.id] = {};
            this.tmpJobs[report.id][report.reportType] = result[1];
            this.toast.sToast('success', 'Report has been queued. Please check the Standard Report Jobs tab, to download the report');
          } else if (res && res._id === result[1] && res.job_data && res.job_data.job_status === 5) {
            setTimeout(() => this.downloadSReport(result[1]), 2000);
          } else {
            this.toast.sToast('info', res.job_data.status_message);
          }
          setTimeout(() => delete this.tmpJobs[report.id][report.reportType], 2000);
        });
    } catch (e) {
      console.log(e);
    }
  }

  reportChangeTab(tab:any): void {
    this.tmpJobs = {};
  }

  downloadSReport(jobid: any): void {
    this.baseService.doRequest('/api/standardreports/downloadStandardReport',
      'post', {jobid}).subscribe((res: any) => {
      if (res[0]){
        window.open(`${res[1]}`, '_blank');
      } else {
        this.toast.sToast('error', res[1]);
      }
    },(error: any) => {
      if (error.ok === false) {
        if (error.status === 403) {
          this.toast.sToast('error', 'You do not have the permission to download the reports. Please contact your administrator to get the relevant permissions');
        }
      }
    });
  }
  
  applyDateFilter() {
    const fromDateValue = this.fromDate.value;
    const toDateValue = this.toDate.value;
  
    if (fromDateValue && toDateValue) {
      const startDate = new Date(fromDateValue);
      const endDate = new Date(toDateValue);
  
      // Set the time zone to UTC (or any desired time zone)
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(23, 59, 59, 999);
  
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
  
      // Check if the selected date range is valid
      if (startDate <= endDate) {
        const formdate = startDate.toISOString();
        const enddate = endDate.toISOString();
  
        this.datefilter = { from_date: formdate, end_date: enddate };
  
        console.log('Form Date (UTC):', formdate);
        console.log('End Date (UTC):', enddate);
  
        // Set the flag to true to enable download buttons
        this.isDateRangeValid = true;
      } else {
        this.toast.sToast('error', 'Invalid date range. "From Date" must be before or equal to "To Date."');
        console.warn('Invalid date range. "From Date" must be before or equal to "To Date."');
        
        this.isDateRangeValid = false; // Set the flag to false to disable download buttons
        return; // Exit the function without setting this.datefilter
      }
    } else if (fromDateValue) {
      // If only "From Date" is selected, send "From Date" value and leave "To Date" empty
      const startDate = new Date(fromDateValue);
      startDate.setUTCHours(0, 0, 0, 0);
      startDate.setDate(startDate.getDate() + 1);
      const formdate = startDate.toISOString();
  
      this.datefilter = { from_date: formdate, end_date: '' };
  
      console.log('Form Date (UTC):', formdate);
    } else if (toDateValue) {
      // If only "To Date" is selected, send "To Date" value and leave "From Date" empty
      const endDate = new Date(toDateValue);
      endDate.setUTCHours(23, 59, 59, 999);
      endDate.setDate(endDate.getDate() + 1);
      const enddate = endDate.toISOString();
  
      this.datefilter = { from_date: '', end_date: enddate };
  
      console.log('End Date (UTC):', enddate);
    } else {
      // Handle the case where neither date is selected
      this.datefilter = { from_date: '', end_date: '' };
      this.isDateRangeValid = true; // Set the flag to true to enable download buttons
    }
  }

}
