/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttackSurfaceDomain } from '../models/attack-surface-domain';
import { AttackSurfaceDomainCreate } from '../models/attack-surface-domain-create';
import { AttackSurfaceDomainGetResp } from '../models/attack-surface-domain-get-resp';
import { ScanAsmParams } from '../models/scan-asm-params';

@Injectable({
  providedIn: 'root',
})
export class AttackSurfaceDomainService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation attackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPost
   */
  static readonly AttackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPostPath = '/api/attacksurfacedomain/scan_asm';

  /**
   * Attacksurfacedomainscan Asm.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPost$Response(params: {
    body: ScanAsmParams
  }

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceDomainService.AttackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Attacksurfacedomainscan Asm.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPost(params: {
    body: ScanAsmParams
  }

): Observable<any> {

    return this.attackSurfaceDomainscanAsmApiAttacksurfacedomainScanAsmPost$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation getAllApiAttacksurfacedomainGet
   */
  static readonly GetAllApiAttacksurfacedomainGetPath = '/api/attacksurfacedomain/';

  /**
   * Get All.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllApiAttacksurfacedomainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiAttacksurfacedomainGet$Response(params?: {

    /**
     * Filter query to be executed against database.
     */
    q?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
    scroll_id?: string;
  }

): Observable<StrictHttpResponse<AttackSurfaceDomainGetResp>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceDomainService.GetAllApiAttacksurfacedomainGetPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.query('sort', params.sort, {});
      rb.query('fields', params.fields, {});
      rb.query('scroll_id', params.scroll_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceDomainGetResp>;
      })
    );
  }

  /**
   * Get All.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllApiAttacksurfacedomainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllApiAttacksurfacedomainGet(params?: {

    /**
     * Filter query to be executed against database.
     */
    q?: string;
    skip?: number;
    limit?: number;
    sort?: string;

    /**
     * Comma seperated list of fields to return.
     */
    fields?: string;
    scroll_id?: string;
  }

): Observable<AttackSurfaceDomainGetResp> {

    return this.getAllApiAttacksurfacedomainGet$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceDomainGetResp>) => r.body as AttackSurfaceDomainGetResp)
    );
  }

  /**
   * Path part for operation updateApiAttacksurfacedomainPut
   */
  static readonly UpdateApiAttacksurfacedomainPutPath = '/api/attacksurfacedomain/';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApiAttacksurfacedomainPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiAttacksurfacedomainPut$Response(params: {
    body: AttackSurfaceDomain
  }

): Observable<StrictHttpResponse<AttackSurfaceDomain>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceDomainService.UpdateApiAttacksurfacedomainPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceDomain>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateApiAttacksurfacedomainPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateApiAttacksurfacedomainPut(params: {
    body: AttackSurfaceDomain
  }

): Observable<AttackSurfaceDomain> {

    return this.updateApiAttacksurfacedomainPut$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceDomain>) => r.body as AttackSurfaceDomain)
    );
  }

  /**
   * Path part for operation createApiAttacksurfacedomainPost
   */
  static readonly CreateApiAttacksurfacedomainPostPath = '/api/attacksurfacedomain/';

  /**
   * Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApiAttacksurfacedomainPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiAttacksurfacedomainPost$Response(params: {
    body: AttackSurfaceDomainCreate
  }

): Observable<StrictHttpResponse<AttackSurfaceDomain>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceDomainService.CreateApiAttacksurfacedomainPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceDomain>;
      })
    );
  }

  /**
   * Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApiAttacksurfacedomainPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApiAttacksurfacedomainPost(params: {
    body: AttackSurfaceDomainCreate
  }

): Observable<AttackSurfaceDomain> {

    return this.createApiAttacksurfacedomainPost$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceDomain>) => r.body as AttackSurfaceDomain)
    );
  }

  /**
   * Path part for operation getApiAttacksurfacedomainIdGet
   */
  static readonly GetApiAttacksurfacedomainIdGetPath = '/api/attacksurfacedomain/{id}';

  /**
   * Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiAttacksurfacedomainIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiAttacksurfacedomainIdGet$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<AttackSurfaceDomain>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceDomainService.GetApiAttacksurfacedomainIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AttackSurfaceDomain>;
      })
    );
  }

  /**
   * Get.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApiAttacksurfacedomainIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiAttacksurfacedomainIdGet(params: {
    id: string;
  }

): Observable<AttackSurfaceDomain> {

    return this.getApiAttacksurfacedomainIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<AttackSurfaceDomain>) => r.body as AttackSurfaceDomain)
    );
  }

  /**
   * Path part for operation deleteApiAttacksurfacedomainIdDelete
   */
  static readonly DeleteApiAttacksurfacedomainIdDeletePath = '/api/attacksurfacedomain/{id}';

  /**
   * Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApiAttacksurfacedomainIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiAttacksurfacedomainIdDelete$Response(params: {
    id: string;
  }

): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AttackSurfaceDomainService.DeleteApiAttacksurfacedomainIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApiAttacksurfacedomainIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApiAttacksurfacedomainIdDelete(params: {
    id: string;
  }

): Observable<any> {

    return this.deleteApiAttacksurfacedomainIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
