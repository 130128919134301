<ng-container>
  <div class="d-flex flex-row align-items-center mb-2" *ngIf="totalCompanies || totalMigrated">
    <div class="d-flex flex-column bor-1 card mat-elevation-z4 p-2 mr-4 pull-up col-lg-3">
      <span class="fs1-2 mb-3 text-primary">{{totalMigrated}}</span>
      <span class="fs1 fw2">Replicated/In-Progress</span>
    </div>
    <div class="d-flex flex-column bor-1 card mat-elevation-z4 p-2 mr-4 pull-up col-lg-3">
      <span class="fs1-2 mb-3">{{totalCompanies}}</span>
      <span class="fs1 fw2">Total Companies</span>
    </div>
    <div class="spacer"></div>
  </div>
  <div class="d-flex flex-column w-full" *ngIf="migrationData && migrationData.length">
    <div class="d-flex flex-row align-items-center mb-2">
      <span class="text-primary bold fs1">Companies</span>
      <span class="spacer"></span>
      <button mat-stroked-button class="small mr-2" appPreventMultiClick color="primary"
              (click)="getMigrationCompanies()">REFRESH</button>
      <button mat-raised-button class="small" appPreventMultiClick color="primary"
              [disabled]="!selectedCompanies || !selectedCompanies.length || inProgress || max10"
              (click)="migrateCompany()">REPLICATE</button>
    </div>
    <div class="d-flex flex-row align-items-center mb-2 text-secondary">
      <span>Please check companies checkbox you wish to select and proceed by
        clicking on the "REPLICATE" button to initiate the V4 replication process.</span>
    </div>
    <mat-list dense class="bor-1 w-100 mat-small">
      <ng-container *ngFor="let item of migrationData; let i = index;">
        <mat-list-item class="toolbar-bottom "  *ngIf="item && item.name !== ''">
          <mat-checkbox name="{{item.company_id}}"  id="{{item.company_id}}" class="mt-2" [checked]="item.selected"
                        [(ngModel)]="item.selected" color="primary"
                        [disabled]="!item.allowed" (change)="updateSelection($event)">
            <span class="ml-4"> {{item.name}}</span>
          </mat-checkbox>
          <span class="spacer"></span>
          <span class="ml-2 ibtn ofw-anywhere" *ngIf="!item.allowed">
           <span *ngIf="item.reason.indexOf('Migration Completed') > -1">
            <i class="fa fa-check-circle text-success fa-lg mr-1"></i> Replication Completed
           </span>
           <span *ngIf="item.reason.indexOf('Migration Completed') === -1">
            <i class="fa fa-spin fa-spinner mr-1"></i> {{item.reason}}
           </span>
          </span>
        </mat-list-item>
    </ng-container>
    </mat-list>
  </div>
  <p *ngIf="migrationData && !migrationData.length" class="text-center"> No data available. </p>
  <app-s-t-shimmer *ngIf="!migrationData"></app-s-t-shimmer>
  <h1>&nbsp;</h1>
</ng-container>
