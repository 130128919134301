<div class="d-flex flex-row w-100 overflow-auto">
  <table class="rounded-corners" *ngIf="tableType === 'property'">
    <tbody>
      <ng-container *ngFor="let rec of data;">
        <ng-container *ngIf="rec[0]">
          <tr>
            <th>{{rec[0]}}</th>
            <td>
              <span *ngIf="rec[1] === 'True'">
                <img src="/assets/images/devices/tick.svg" class="status-icon" alt="True" [matTooltip]="rec[1]">
              </span>
              <span *ngIf="rec[1] === 'False'">
                <img src="/assets/images/devices/cross.svg" class="status-icon" alt="False" [matTooltip]="rec[1]">
              </span>
              <span class="scritical br-10 pc4" *ngIf="rec[1] && rec[1] !== 'True' && rec[0].trim() === 'DMARC Status'">
                  {{rec[1]}}
              </span>
              <span *ngIf="rec[1] !== 'True' && rec[0].trim() !== 'DMARC Status'">
                  {{rec[1]}}
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <table class="rounded-corners" *ngIf="tableType === 'table'">
    <thead>
      <tr>
        <th *ngFor="let title of this.objectKeys(data[0])">{{title}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let rec of data;">
        <tr>
          <ng-container *ngFor="let title of this.objectKeys(data[0])">
          <td>{{rec[title]}}</td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="d-flex flex-column" *ngIf="tableType === 'cve'">
      <mat-card *ngFor="let ccve of data;" class="mb-2 bor-1 br-10">
          <div class="mat-title">
            <a href="https://nvd.nist.gov/vuln/detail/{{ccve.cve}}" target="_blank">
              <span>{{ccve.cve}}</span>
            </a>
          </div>
          <div class="main-section mt-2">
            <p class="pb-2">{{ccve.title}}</p>
            <mat-list dense class="w-100 bor-1">
              <mat-list-item class="bb-1" *ngIf="ccve.epss_score && ccve.epss_score > 0">
                <span class="w-25 fs1 fw4">EPSS Score:</span>
                <span class="badge px-1 py-1 scritical fs1 fw4" *ngIf="ccve.epss_score >= 0.95">{{ccve.epss_score}}</span>
                <span class="badge px-1 py-1 shigh fs1 fw4" *ngIf="ccve.epss_score >= 0.90 && ccve.epss_score <= 0.94">{{ccve.epss_score}}</span>
                <span class="badge px-1 py-1 smedium fs1 fw4" *ngIf="ccve.epss_score >= 0.85 && ccve.epss_score <= 0.89">{{ccve.epss_score}}</span>
                <span class="badge px-1 py-1 slow fs1 fw4" *ngIf="ccve.epss_score >= 0.00 && ccve.epss_score <= 0.84">{{ccve.epss_score}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 fs1 fw4">Severity:</span>
                <span class="badge px-1 py-1 shigh fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='HIGH'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 smedium fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='MEDIUM'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 scritical fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='CRITICAL'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 slow fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='LOW'">{{ccve.severity}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 float-left fs1 fw4">Base Score:</span>
                <span class="badge px-1 py-1 scritical fs1 fw4">{{ccve.baseScore}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 float-left fs1 fw4">Exploitability Score:</span>
                <span class="badge px-1 py-1 scritical fs1 fw4">{{ccve.exploitableScore}}</span>
              </mat-list-item>
              <mat-list-item>
                <span class="w-25 float-left fs1 fw4">Impact Score:</span>
                <span class="badge px-1 py-1 scritical fs1 fw4">{{ccve.impactScore}}</span>
              </mat-list-item>
            </mat-list>
          </div>
          <div class="m-h-400 mt-3 overflow-auto" *ngIf="!baseService.showCompany">
            <table class="infoTable w-100">
              <thead>
              <tr>
                <th>Company Name - {{ccve.companies_count}}</th>
                <th>Affected Asset(s) - {{ccve.assets_count}}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let key of objectKeys(ccve.companyRef); let i = index;">
                <tr>
                  <td>{{key}}</td>
                  <td>
                    <span class="badge pointer text-primary px-2 py-1" (click)="ccve.companyRef[key].visible = true;">
                      {{ccve.companyRef[key].assets.length}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="ccve.companyRef[key].visible">
                  <td colspan="5">
                    <mat-card>
                      <button class="float-right mt--10" mat-icon-button aria-label="close modal icon"
                              matTooltip="Close" (click)="ccve.companyRef[key].visible = !ccve.companyRef[key].visible">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-card-header>
                        <mat-card-title class="fs1 fw2">Asset Details</mat-card-title>
                      </mat-card-header>
                      <mat-card-content class="overflow-auto max-height-200">
                        <mat-list dense class="w-100 bor-1">
                          <mat-list-item [ngClass]="{'bb-1': i !== ccve.companyRef[key].assets.length - 1}"
                                         *ngFor="let asset of ccve.companyRef[key].assets; let i = index">
                            <span class="pointer text-primary fs1 fw5" (click)="navToAsset(key, asset.id)">{{asset.name}}</span>
                          </mat-list-item>
                          <mat-list-item *ngIf="!ccve.companyRef[key].assets || !ccve.companyRef[key].assets.length">
                           <i class="fa fa-check-circle text-success fa-lg mr-1"></i>  All good, no assets affected.
                          </mat-list-item>
                        </mat-list>
                      </mat-card-content>
                    </mat-card>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div class="main-section mt-3" *ngIf="baseService.showCompany">
            <h3 class="mb-1">Affected Assets <span *ngIf="ccve.assets && ccve.assets.length">({{ccve.assets.length}})</span></h3>
            <mat-list dense class="w-100 bor-1">
              <mat-list-item [ngClass]="{'bb-1': i !== ccve.assets.length - 1}"
                             *ngFor="let asset of ccve.assets; let i = index">
                <span class="pointer text-primary fs1 fw5" (click)="navToAsset(commonService.currentCompany.name, asset.id)">{{asset.name}}</span>
              </mat-list-item>
              <mat-list-item *ngIf="!ccve.assets || !ccve.assets.length">
               <i class="fa fa-check-circle text-success fa-lg mr-1"></i>  All good, no assets affected.
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
      <mat-card *ngFor="let ccve of vulsData;" class="mb-2 bor-1 br-10">
          <div class="mat-title">
              <span>{{ccve.name}}</span>
          </div>
          <div class="main-section mt-2">
            <p *ngIf="ccve.description"> {{ccve.description}}</p>
            <mat-list dense class="w-100 bor-1">
              <mat-list-item class="bb-1">
                <span class="w-25  fs1 fw4">Severity:</span>
                <span class="badge px-1 py-1 shigh fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='HIGH'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 smedium fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='MEDIUM'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 scritical fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='CRITICAL'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 slow fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='LOW'">{{ccve.severity}}</span>
                <span class="badge px-1 py-1 sinfo fs1 fw4" *ngIf="ccve.severity.toUpperCase()==='INFO'">{{ccve.severity}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 float-left  fs1 fw4">Type:</span>
                <span class="badge badge-primary p-2 fs1 fw4">{{ccve.type}}</span>
              </mat-list-item>
              <mat-list-item class="bb-1">
                <span class="w-25 float-left  fs1 fw4">Tags:</span>
                <span class="badge badge-primary p-2 fs1 fw4 mr-2 " *ngFor="let tag of ccve.tags">{{tag}}</span>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-card>
  </div>
</div>
